.contrato-es p, .contrato-es h5 {
  font-size: 13px !important;
  color: #616161 !important;
  line-height: 1.5 !important;
}
.contrato-br p, .contrato-br h5 {
  font-size: 13px !important;
  color: #616161 !important;
  line-height: 1.5 !important;
}
