#site-login {
  width: 100%;
  #login-content {
    padding-top: 150px;
    padding-bottom: 150px;
    min-height: 500px;
    .login-logo {
      margin-bottom: 20px;
      max-width: 200px;
    }
    #login-panel {
      max-width: 400px;
      margin: 0 auto;
      .panel-body {
        padding: 30px;
      }
      #login-form {
        button {
          margin-top: 20px;
        }
      }
      .user-name {
        margin-bottom: 0 !important;
        font-size: 18px;
        color: $base_color;
        text-align: center;
      }
      .user-email {
        font-size: 14px;
        color: $body_text_color;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: $small-devices) {
  #site-login {
    #login-content {
      padding-top: 100px;
      padding-bottom: 50px;
      #login-panel {
        .panel-body {
          padding: 15px;
        }
      }
    }
  }
}
