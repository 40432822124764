$base_color: #008ed6;
$base_color_fall: #037cb9;
$base_color_light: #79b3e3;
$hover_color: #005885;

$background_blue: #175679;

$title_text_color: #272727;
$body_text_color: #616161;
$gray: #f7f7f7;
$gray2: #abbac3;
$gray3: #8b9aa3;

$error_color: #a94442;

$small-devices: 768px;
$medium-devices: 992px;
$large-devices: 1200px;
