#site-404 {
  padding-top: 150px;
  padding-bottom: 150px;
  #error-img-top {
    margin-top: 20px;
  }
  #error-title {
    font-size: 120px !important;
    text-align: center;
    margin: 0 !important;
    padding: 0 !important;
    color: #4d4d4d;
    line-height: 110px;
  }
  #error-description {
    color: #8a8a8a;
    text-align: center;
    font-size: 18px !important;
    margin: 0 !important;
  }
}
