@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400');

.recuo {
  text-indent: 2em;
}
.label-desbravador {
  font-size: 24px !important;
  height: 58px !important;
  margin-top: 8% !important;
  border-radius: 0 !important;
  text-shadow: none !important;
  line-height: 1.7 !important;
  font-weight: normal !important;
  color: #ffffff !important;
  display: inline-block !important;
  background-color: #79b3e3 !important;
}
.margin-10 {
  margin-top: 10%;
}
.footer .footer-inner .footer-content {
  position: absolute;
  left: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  padding: 8px;
  line-height: 36px;
  border-top: none !important;
  background: #54b4e6 !important;
}

.layout-ace {
  background-color: #e4e6e9;
  min-height: 100%;
  padding-bottom: 0;
  font-family: 'Open Sans';
  font-size: 13px;
  color: #616161;
  line-height: 1.5;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    margin: 0;
  }
  .main-container:before {
    display: block;
    content: '';
    position: absolute;
    z-index: -2;
    width: 100%;
    max-width: inherit;
    bottom: 0;
    top: 0;
    background-color: #fff;
  }
  .main-container.container,
  .rtl .main-container.container {
    padding-left: 0;
    padding-right: 0;
  }
  .main-container.container:before {
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    width: inherit;
  }
  @media (max-width: 767px) {
    .main-container.container:before {
      -webkit-box-shadow: none;
      box-shadow: none;
      width: 100%;
    }
  }
  .main-content {
    margin-left: 0;
    min-height: 100%;
    padding: 0;
    background-color: #fff;
  }
  .main-content:before,
  .main-content:after {
    content: ' ';
    display: table;
  }
  .main-content:after {
    clear: both;
  }
  .sidebar + .main-content {
    margin-left: 190px;
  }
  .page-content {
    background-color: #ffffff;
    position: relative;
    margin: 0;
    padding: 8px 20px 24px;
  }
  .page-header {
    margin: 0 0 12px;
    border-bottom: 1px dotted #e2e2e2;
    padding-bottom: 16px;
    padding-top: 7px;
  }
  .page-header h1 {
    padding: 0;
    margin: 0 8px;
    font-size: 24px;
    font-weight: lighter;
    color: #2679b5;
  }
  .page-header h1 small {
    margin: 0 6px;
    font-size: 14px;
    font-weight: normal;
    color: #8089a0;
  }
  .ajax-loading-overlay {
    position: absolute;
    z-index: 1999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#80FFFFFF',
        endColorstr='#80FFFFFF',
        GradientType=0
      );
  }
  .ajax-loading-overlay > .ajax-loading-icon {
    position: relative;
    left: 8px;
    top: 8px;
  }
  .ajax-loading-overlay.content-loaded {
    background-color: rgba(255, 255, 255, 0.4);
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#66FFFFFF',
        endColorstr='#66FFFFFF',
        GradientType=0
      );
  }
  .ajax-loading-overlay.almost-loaded {
    background-color: rgba(255, 255, 255, 0.3);
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#4CFFFFFF',
        endColorstr='#4CFFFFFF',
        GradientType=0
      );
  }
  .ajax-loading-overlay.ajax-overlay-body {
    position: fixed;
    z-index: 2999;
  }
  .ajax-loading-overlay.ajax-overlay-body > .ajax-loading-icon {
    left: 8px;
    top: 8px;
  }
  .ace-icon {
    text-align: center;
  }
  a:focus,
  a:active {
    text-decoration: none;
  }
  h1 {
    font-size: 32px;
    font-weight: normal;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  h1.smaller {
    font-size: 31px;
  }
  h1.bigger {
    font-size: 33px;
  }
  h1.block {
    margin-bottom: 16px;
  }
  h2 {
    font-size: 26px;
    font-weight: normal;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  h2.smaller {
    font-size: 25px;
  }
  h2.bigger {
    font-size: 27px;
  }
  h2.block {
    margin-bottom: 16px;
  }
  h3 {
    font-size: 22px;
    font-weight: normal;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  h3.smaller {
    font-size: 21px;
  }
  h3.bigger {
    font-size: 23px;
  }
  h3.block {
    margin-bottom: 16px;
  }
  h4 {
    font-size: 18px;
    font-weight: normal;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  h4.smaller {
    font-size: 17px;
  }
  h4.bigger {
    font-size: 19px;
  }
  h4.block {
    margin-bottom: 16px;
  }
  h5 {
    font-size: 15px;
    font-weight: normal;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  h5.smaller {
    font-size: 14px;
  }
  h5.bigger {
    font-size: 16px;
  }
  h5.block {
    margin-bottom: 16px;
  }
  h6 {
    font-size: 13px;
    font-weight: normal;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  h6.smaller {
    font-size: 12px;
  }
  h6.bigger {
    font-size: 14px;
  }
  h6.block {
    margin-bottom: 16px;
  }
  ul,
  ol {
    margin-top: 0;
    margin-bottom: 10px;
    margin-right: 0;
    margin-left: 25px;
    padding: 0;
  }
  ul.margin-5,
  ol.margin-5 {
    margin-left: 5px;
  }
  ul.margin-10,
  ol.margin-10 {
    margin-left: 10px;
  }
  ul.margin-15,
  ol.margin-15 {
    margin-left: 15px;
  }
  ul.margin-20,
  ol.margin-20 {
    margin-left: 20px;
  }
  li > ul,
  li > ol {
    margin-left: 18px;
  }
  .list-unstyled,
  .list-inline {
    margin-left: 0;
  }
  .list-unstyled > li > .ace-icon:first-child,
  .list-inline > li > .ace-icon:first-child {
    width: 18px;
    text-align: center;
  }
  .spaced > li {
    margin-top: 9px;
    margin-bottom: 9px;
  }
  .spaced2 > li {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  li.divider {
    margin-top: 3px;
    margin-bottom: 3px;
    height: 0;
    font-size: 0;
  }
  .spaced > li.divider {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .spaced2 > li.divider {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  li.divider:before {
    content: '';
    display: inline-block;
  }
  blockquote,
  blockquote.pull-right,
  .blockquote-reverse {
    border-color: #e5eaf1;
  }
  pre.prettyprint {
    white-space: pre-wrap;
  }
  .well {
    border-radius: 0;
  }
  .well h1,
  .well h2,
  .well h3,
  .well h4,
  .well h5,
  .well h6 {
    margin-top: 0;
  }
  .well h1,
  .well h2,
  .well h3 {
    line-height: 36px;
  }
  .alert {
    font-size: 14px;
    border-radius: 0;
  }
  .alert .close {
    font-size: 16px;
  }
  .alert-block p + p {
    margin-top: 10px;
  }
  .pagination > li > span,
  .pager > li > span {
    border-width: 1px;
    border-radius: 0 !important;
  }
  .pagination > li > a,
  .pager > li > a {
    border-width: 1px;
    border-color: #d4dfe3;
    border-radius: 0 !important;
    color: #2283c5;
    background-color: #fafafa;
    margin: 0 -1px 0 0;
    position: relative;
    z-index: auto;
  }
  .pagination > li > a:hover,
  .pager > li > a:hover {
    color: #1c6ca2;
    background-color: #eaeff2;
    border-color: #c1d1d7;
    z-index: 1;
  }
  .pagination > li > a:focus,
  .pager > li > a:focus {
    background-color: #eaeef2;
  }
  .pagination > li.disabled > a,
  .pager > li.disabled > a,
  .pagination > li.disabled > a:hover,
  .pager > li.disabled > a:hover {
    background-color: #f9f9f9;
    border-color: #d9d9d9;
    z-index: auto;
  }
  .pagination > li.active > a,
  .pagination > li.active > a:hover,
  .pagination > li.active > a:focus {
    background-color: #6faed9;
    border-color: #6faed9;
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    z-index: 2;
  }
  .list-group {
    margin-left: auto;
  }
  .list-group-item {
    border-radius: 0 !important;
  }
  .lighter {
    font-weight: lighter;
  }
  .bolder {
    font-weight: bolder;
  }
  .inline {
    display: inline-block !important;
  }
  .block {
    display: block !important;
  }
  .center,
  .align-center {
    text-align: center !important;
  }
  .align-left {
    text-align: left !important;
  }
  .align-right {
    text-align: right !important;
  }
  .align-justify {
    text-align: justify;
  }
  .middle {
    vertical-align: middle;
  }
  .align-middle {
    vertical-align: middle !important;
  }
  .align-top {
    vertical-align: top !important;
  }
  .align-bottom {
    vertical-align: bottom !important;
  }
  .position-relative,
  .pos-rel {
    position: relative;
  }
  .position-absolute,
  .pos-abs {
    position: absolute;
  }
  .no-float {
    float: none !important;
  }
  .line-height-normal {
    line-height: normal !important;
  }
  .line-height-0 {
    line-height: 0 !important;
  }
  .line-height-1 {
    line-height: 1 !important;
  }
  .line-height-125 {
    line-height: 1.25 !important;
  }
  .line-height-150 {
    line-height: 1.5 !important;
  }
  .dark {
    color: #333333 !important;
  }
  .white {
    color: #ffffff !important;
  }
  .red {
    color: #dd5a43 !important;
  }
  .red2 {
    color: #e08374 !important;
  }
  .light-red {
    color: #ff7777 !important;
  }
  .blue {
    color: #478fca !important;
  }
  .light-blue {
    color: #93cbf9 !important;
  }
  .green {
    color: #69aa46 !important;
  }
  .light-green {
    color: #b0d877 !important;
  }
  .orange {
    color: #ff892a !important;
  }
  .orange2 {
    color: #feb902 !important;
  }
  .light-orange {
    color: #fcac6f !important;
  }
  .purple {
    color: #a069c3 !important;
  }
  .pink {
    color: #c6699f !important;
  }
  .pink2 {
    color: #d6487e !important;
  }
  .brown {
    color: brown !important;
  }
  .grey {
    color: #777777 !important;
  }
  .light-grey {
    color: #bbbbbb !important;
  }
  .bigger-110 {
    font-size: 110% !important;
  }
  .bigger-120 {
    font-size: 120% !important;
  }
  .bigger-130 {
    font-size: 130% !important;
  }
  .bigger-140 {
    font-size: 140% !important;
  }
  .bigger-150 {
    font-size: 150% !important;
  }
  .bigger-160 {
    font-size: 160% !important;
  }
  .bigger-170 {
    font-size: 170% !important;
  }
  .bigger-180 {
    font-size: 180% !important;
  }
  .bigger-190 {
    font-size: 190% !important;
  }
  .bigger-200 {
    font-size: 200% !important;
  }
  .bigger-210 {
    font-size: 210% !important;
  }
  .bigger-220 {
    font-size: 220% !important;
  }
  .bigger-230 {
    font-size: 230% !important;
  }
  .bigger-240 {
    font-size: 240% !important;
  }
  .bigger-250 {
    font-size: 250% !important;
  }
  .bigger-260 {
    font-size: 260% !important;
  }
  .bigger-270 {
    font-size: 270% !important;
  }
  .bigger-280 {
    font-size: 280% !important;
  }
  .bigger-290 {
    font-size: 290% !important;
  }
  .bigger-300 {
    font-size: 300% !important;
  }
  .bigger-115 {
    font-size: 115% !important;
  }
  .bigger-125 {
    font-size: 125% !important;
  }
  .bigger-175 {
    font-size: 175% !important;
  }
  .bigger-225 {
    font-size: 225% !important;
  }
  .bigger-275 {
    font-size: 275% !important;
  }
  .smaller-90 {
    font-size: 90% !important;
  }
  .smaller-80 {
    font-size: 80% !important;
  }
  .smaller-70 {
    font-size: 70% !important;
  }
  .smaller-60 {
    font-size: 60% !important;
  }
  .smaller-50 {
    font-size: 50% !important;
  }
  .smaller-40 {
    font-size: 40% !important;
  }
  .smaller-30 {
    font-size: 30% !important;
  }
  .smaller-20 {
    font-size: 20% !important;
  }
  .smaller-75 {
    font-size: 75% !important;
  }
  .width-20 {
    width: 20% !important;
  }
  .width-25 {
    width: 25% !important;
  }
  .width-30 {
    width: 30% !important;
  }
  .width-35 {
    width: 35% !important;
  }
  .width-40 {
    width: 40% !important;
  }
  .width-45 {
    width: 45% !important;
  }
  .width-50 {
    width: 50% !important;
  }
  .width-55 {
    width: 55% !important;
  }
  .width-60 {
    width: 60% !important;
  }
  .width-65 {
    width: 65% !important;
  }
  .width-70 {
    width: 70% !important;
  }
  .width-75 {
    width: 75% !important;
  }
  .width-80 {
    width: 80% !important;
  }
  .width-85 {
    width: 85% !important;
  }
  .width-90 {
    width: 90% !important;
  }
  .width-95 {
    width: 95% !important;
  }
  .width-100 {
    width: 100% !important;
  }
  .width-75 {
    width: 75% !important;
  }
  .width-25 {
    width: 25% !important;
  }
  .width-auto {
    width: auto !important;
  }
  .height-auto {
    height: auto !important;
  }
  .no-padding {
    padding: 0 !important;
  }
  .no-padding-bottom {
    padding-bottom: 0 !important;
  }
  .no-padding-top {
    padding-top: 0 !important;
  }
  .no-padding-left {
    padding-left: 0 !important;
  }
  .no-padding-right {
    padding-right: 0 !important;
  }
  .no-margin {
    margin: 0 !important;
  }
  .no-margin-bottom {
    margin-bottom: 0 !important;
  }
  .no-margin-top {
    margin-top: 0 !important;
  }
  .no-margin-left {
    margin-left: 0 !important;
  }
  .no-margin-right {
    margin-right: 0 !important;
  }
  .no-border {
    border-width: 0;
  }
  .no-border-bottom {
    border-bottom-width: 0;
  }
  .no-border-top {
    border-top-width: 0;
  }
  .no-border-left {
    border-left-width: 0;
  }
  .no-border-right {
    border-right-width: 0;
  }
  /**
.no-underline {
 text-decoration: none !important;
}
.no-hover-underline:hover {
 text-decoration: none !important;
}
.no-outline:focus {
 outline: none !important;
}

.no-box-shadow {
 .box-shadow(none) !important;
}

.overflow-auto {overflow: auto !important;}
.overflow-visible {overflow: visible !important;}
*/
  .overflow-hidden {
    overflow: hidden !important;
  }
  .overflow-scroll {
    overflow-x: hidden !important;
    overflow-y: scroll !important;
  }
  .no-radius {
    border-radius: 0 !important;
  }
  .no-text-shadow {
    text-shadow: none !important;
  }
  .hr {
    display: block;
    height: 0;
    overflow: hidden;
    font-size: 0;
    border-width: 1px 0 0 0;
    border-top: 1px solid #e3e3e3;
    margin: 12px 0;
    border-top-color: rgba(0, 0, 0, 0.11);
  }
  .hr-double {
    height: 3px;
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
    border-top-color: rgba(0, 0, 0, 0.11);
    border-bottom-color: rgba(0, 0, 0, 0.11);
  }
  .hr.dotted,
  .hr-dotted {
    border-style: dotted;
  }
  .hr-32,
  .hr32 {
    margin: 32px 0;
  }
  .hr-30,
  .hr30 {
    margin: 30px 0;
  }
  .hr-28,
  .hr28 {
    margin: 28px 0;
  }
  .hr-26,
  .hr26 {
    margin: 26px 0;
  }
  .hr-24,
  .hr24 {
    margin: 24px 0;
  }
  .hr-22,
  .hr22 {
    margin: 22px 0;
  }
  .hr-20,
  .hr20 {
    margin: 20px 0;
  }
  .hr-18,
  .hr18 {
    margin: 18px 0;
  }
  .hr-16,
  .hr16 {
    margin: 16px 0;
  }
  .hr-14,
  .hr14 {
    margin: 14px 0;
  }
  .hr-12,
  .hr12 {
    margin: 12px 0;
  }
  .hr-10,
  .hr10 {
    margin: 10px 0;
  }
  .hr-8,
  .hr8 {
    margin: 8px 0;
  }
  .hr-6,
  .hr6 {
    margin: 6px 0;
  }
  .hr-4,
  .hr4 {
    margin: 4px 0;
  }
  .hr-2,
  .hr2 {
    margin: 2px 0;
  }
  .space {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
  }
  [class*='vspace-'] {
    display: none;
  }
  .space-32 {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 32px 0 31px;
  }
  [class*='vspace-32'] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 32px 0 31px 0;
  }
  .space-30 {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 30px 0 29px;
  }
  [class*='vspace-30'] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 30px 0 29px 0;
  }
  .space-28 {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 28px 0 27px;
  }
  [class*='vspace-28'] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 28px 0 27px 0;
  }
  .space-26 {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 26px 0 25px;
  }
  [class*='vspace-26'] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 26px 0 25px 0;
  }
  .space-24 {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 24px 0 23px;
  }
  [class*='vspace-24'] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 24px 0 23px 0;
  }
  .space-22 {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 22px 0 21px;
  }
  [class*='vspace-22'] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 22px 0 21px 0;
  }
  .space-20 {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 20px 0 19px;
  }
  [class*='vspace-20'] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 20px 0 19px 0;
  }
  .space-18 {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 18px 0 17px;
  }
  [class*='vspace-18'] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 18px 0 17px 0;
  }
  .space-16 {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 16px 0 15px;
  }
  [class*='vspace-16'] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 16px 0 15px 0;
  }
  .space-14 {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 14px 0 13px;
  }
  [class*='vspace-14'] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 14px 0 13px 0;
  }
  .space-12 {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 12px 0 11px;
  }
  [class*='vspace-12'] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 12px 0 11px 0;
  }
  .space-10 {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 10px 0 9px;
  }
  [class*='vspace-10'] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 10px 0 9px 0;
  }
  .space-8 {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 8px 0 7px;
  }
  [class*='vspace-8'] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 8px 0 7px 0;
  }
  .space-6 {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 6px 0 5px;
  }
  [class*='vspace-6'] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 6px 0 5px 0;
  }
  .space-4 {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 4px 0 3px;
  }
  [class*='vspace-4'] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 4px 0 3px 0;
  }
  .space-2 {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 2px 0 1px;
  }
  [class*='vspace-2'] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 2px 0 1px 0;
  }
  .space-0,
  [class*='vspace-0'] {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 0;
  }
  @media only screen and (max-width: 479px) {
    [class*='vspace-'][class*='-xs'] {
      display: block;
    }
  }
  @media only screen and (max-width: 767px) {
    [class*='vspace-'][class*='-sm'] {
      display: block;
    }
  }
  @media only screen and (max-width: 991px) {
    [class*='vspace-'][class*='-md'] {
      display: block;
    }
  }
  @media only screen and (max-width: 1199px) {
    [class*='vspace-'][class*='-lg'] {
      display: block;
    }
  }
  .header {
    line-height: 28px;
    margin-bottom: 16px;
    margin-top: 18px;
    padding-bottom: 4px;
    border-bottom: 1px solid #ccc;
  }
  .header.less-margin {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .header.blue {
    border-bottom-color: #d5e3ef;
  }
  .header.green {
    border-bottom-color: #cce2c1;
  }
  .header.purple {
    border-bottom-color: #e0d1ea;
  }
  .header.orange {
    border-bottom-color: #fdd0ac;
  }
  .header.orange2 {
    border-bottom-color: #fbd36b;
  }
  .header.red {
    border-bottom-color: #f3cdc6;
  }
  .header.grey {
    border-bottom-color: #c3c3c3;
  }
  .header.pink {
    border-bottom-color: #f2e0eb;
  }
  .header.pink2 {
    border-bottom-color: #e69eb9;
  }
  .header.light-blue {
    border-bottom-color: #dceefc;
  }
  .header.light-red {
    border-bottom-color: #fec5c5;
  }
  .header.light-green {
    border-bottom-color: #d2e7b5;
  }
  .header.brown {
    border-bottom-color: #df9393;
  }
  .header > .ace-icon {
    margin-right: 2px;
  }
  .action-buttons a {
    margin: 0 3px;
    display: inline-block;
    opacity: 0.85;
    -webkit-transition: all 0.1s;
    -o-transition: all 0.1s;
    transition: all 0.1s;
  }
  .action-buttons a:hover {
    text-decoration: none;
    opacity: 1;
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
  }
  .navbar {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    border-width: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    min-height: 45px;
    background: #438eb9;
  }
  .navbar .navbar-text,
  .navbar .navbar-link {
    color: #ffffff;
  }
  .navbar .navbar-brand {
    color: #ffffff;
    font-size: 24px;
    text-shadow: none;
    padding-top: 10px;
    padding-bottom: 10px;
    height: auto;
  }
  .navbar .navbar-brand:hover,
  .navbar .navbar-brand:focus {
    color: #ffffff;
  }
  /**
@media (min-width: @grid-float-breakpoint) {
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
    margin-left: 0;
	margin-right: 0;
  }
}
*/
  @media only screen and (min-width: 992px) {
    .navbar-container {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .navbar-container.container {
    padding-left: 0;
    padding-right: 0;
  }
  .ace-nav {
    height: 100%;
    margin: 0 !important;
  }
  .ace-nav > li {
    line-height: 45px;
    height: 45px;
    border-left: 1px solid #e1e1e1;
    padding: 0;
    position: relative;
    float: left;
  }
  .ace-nav > li:first-child {
    border-left-width: 0;
  }
  .ace-nav > li > a {
    background-color: #2e6589;
    color: #fff;
    display: block;
    line-height: inherit;
    text-align: center;
    height: 100%;
    width: auto;
    min-width: 50px;
    padding: 0 8px;
    position: relative;
  }
  .ace-nav > li > a > .ace-icon {
    display: inline-block;
    font-size: 16px;
    color: #fff;
    text-align: center;
    width: 20px;
  }
  .ace-nav > li > a > .badge {
    position: relative;
    top: -4px;
    left: 2px;
    padding-right: 5px;
    padding-left: 5px;
  }
  .ace-nav > li > a:hover,
  .ace-nav > li > a:focus,
  .ace-nav > li.open > a {
    background-color: #2c5976;
    color: #fff;
  }
  .ace-nav > li.blue > a {
    background-color: #2e6589;
  }
  .ace-nav > li.blue > a:hover,
  .ace-nav > li.blue > a:focus,
  .ace-nav > li.open.blue > a {
    background-color: #2c5976;
  }
  .ace-nav > li.grey > a {
    background-color: #555555;
  }
  .ace-nav > li.grey > a:hover,
  .ace-nav > li.grey > a:focus,
  .ace-nav > li.open.grey > a {
    background-color: #4b4b4b;
  }
  .ace-nav > li.purple > a {
    background-color: #892e65;
  }
  .ace-nav > li.purple > a:hover,
  .ace-nav > li.purple > a:focus,
  .ace-nav > li.open.purple > a {
    background-color: #762c59;
  }
  .ace-nav > li.green > a {
    background-color: #2e8965;
  }
  .ace-nav > li.green > a:hover,
  .ace-nav > li.green > a:focus,
  .ace-nav > li.open.green > a {
    background-color: #2c7659;
  }
  .ace-nav > li.light-blue > a {
    background-color: #62a8d1;
  }
  .ace-nav > li.light-blue > a:hover,
  .ace-nav > li.light-blue > a:focus,
  .ace-nav > li.open.light-blue > a {
    background-color: #579ec8;
  }
  .ace-nav > li.light-blue2 > a {
    background-color: #42a8e1;
  }
  .ace-nav > li.light-blue2 > a:hover,
  .ace-nav > li.light-blue2 > a:focus,
  .ace-nav > li.open.light-blue2 > a {
    background-color: #359fd9;
  }
  .ace-nav > li.red > a {
    background-color: #b74635;
  }
  .ace-nav > li.red > a:hover,
  .ace-nav > li.red > a:focus,
  .ace-nav > li.open.red > a {
    background-color: #a34335;
  }
  .ace-nav > li.light-green > a {
    background-color: #9abc32;
  }
  .ace-nav > li.light-green > a:hover,
  .ace-nav > li.light-green > a:focus,
  .ace-nav > li.open.light-green > a {
    background-color: #8ba832;
  }
  .ace-nav > li.light-purple > a {
    background-color: #cb6fd7;
  }
  .ace-nav > li.light-purple > a:hover,
  .ace-nav > li.light-purple > a:focus,
  .ace-nav > li.open.light-purple > a {
    background-color: #c263ce;
  }
  .ace-nav > li.light-orange > a {
    background-color: #f79263;
  }
  .ace-nav > li.light-orange > a:hover,
  .ace-nav > li.light-orange > a:focus,
  .ace-nav > li.open.light-orange > a {
    background-color: #f28653;
  }
  .ace-nav > li.light-pink > a {
    background-color: #f4dae5;
  }
  .ace-nav > li.light-pink > a:hover,
  .ace-nav > li.light-pink > a:focus,
  .ace-nav > li.open.light-pink > a {
    background-color: #eeccda;
  }
  .ace-nav > li.dark > a {
    background-color: #404040;
  }
  .ace-nav > li.dark > a:hover,
  .ace-nav > li.dark > a:focus,
  .ace-nav > li.open.dark > a {
    background-color: #363636;
  }
  .ace-nav > li.white-opaque > a {
    background-color: rgba(255, 255, 255, 0.8);
  }
  .ace-nav > li.white-opaque > a:hover,
  .ace-nav > li.white-opaque > a:focus,
  .ace-nav > li.open.white-opaque > a {
    background-color: rgba(245, 245, 245, 0.8);
  }
  .ace-nav > li.dark-opaque > a {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .ace-nav > li.dark-opaque > a:hover,
  .ace-nav > li.dark-opaque > a:focus,
  .ace-nav > li.open.dark-opaque > a {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .ace-nav > li.transparent > a {
    background-color: transparent;
  }
  .ace-nav > li.transparent > a:hover,
  .ace-nav > li.transparent > a:focus,
  .ace-nav > li.open.transparent > a {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .ace-nav > li.light-10 > a {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .ace-nav > li.light-10 > a:hover,
  .ace-nav > li.light-10 > a:focus,
  .ace-nav > li.open.light-10 > a {
    background-color: rgba(255, 255, 255, 0.2);
  }
  .ace-nav > li.dark-10 > a {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .ace-nav > li.dark-10 > a:hover,
  .ace-nav > li.dark-10 > a:focus,
  .ace-nav > li.open.dark-10 > a {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .ace-nav > li.margin-4 {
    margin-left: 4px;
  }
  .ace-nav > li.margin-3 {
    margin-left: 3px;
  }
  .ace-nav > li.margin-2 {
    margin-left: 2px;
  }
  .ace-nav > li.margin-1 {
    margin-left: 1px;
  }
  .ace-nav > li.no-border {
    border-width: 0 !important;
  }
  .ace-nav > li .dropdown-menu {
    z-index: 1031;
  }
  .ace-nav .nav-user-photo {
    margin: -4px 8px 0 0;
    border-radius: 100%;
    border: 2px solid #fff;
    max-width: 40px;
  }
  .ace-nav li.dropdown-footer a .ace-icon {
    display: inline-block;
    width: 1.25em;
    text-align: center;
  }
  @media only screen and (max-height: 540px) {
    .ace-nav > li.dropdown-modal > .dropdown-menu {
      position: fixed;
      margin-top: auto;
      z-index: 1052;
    }
    .ace-nav > li.dropdown-modal > .dropdown-menu:before,
    .ace-nav > li.dropdown-modal > .dropdown-menu:after {
      display: none;
    }
    .ace-nav > li.dropdown-modal > .user-menu > li > a {
      padding-top: 6px;
      padding-bottom: 6px;
    }
    .ace-nav > li.dropdown-modal.open:before {
      background-color: #000;
      opacity: 0.5;
      border-width: 0;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      content: '';
      display: block;
      height: 2000px;
      width: auto;
      z-index: 1051;
    }
  }
  @media only screen and (max-width: 767px) {
    .ace-nav > li:nth-last-child(4) > .dropdown-menu.dropdown-menu-right {
      right: -100px;
    }
    .ace-nav > li:nth-last-child(4) > .dropdown-menu.dropdown-menu-right:before,
    .ace-nav > li:nth-last-child(4) > .dropdown-menu.dropdown-menu-right:after {
      right: 120px;
    }
    .ace-nav > li:nth-last-child(3) > .dropdown-menu.dropdown-menu-right {
      right: -80px;
    }
    .ace-nav > li:nth-last-child(3) > .dropdown-menu.dropdown-menu-right:before,
    .ace-nav > li:nth-last-child(3) > .dropdown-menu.dropdown-menu-right:after {
      right: 100px;
    }
    .user-menu.dropdown-close.dropdown-menu-right {
      right: 0 !important;
    }
  }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    .navbar.navbar-collapse
      .ace-nav
      > li:nth-last-child(2):nth-child(1)
      > .dropdown-menu.dropdown-menu-right,
    .navbar.navbar-collapse
      .ace-nav
      > li:nth-last-child(2):nth-child(2)
      > .dropdown-menu.dropdown-menu-right {
      left: auto;
      right: -60px;
    }
    .navbar.navbar-collapse
      .ace-nav
      > li:nth-last-child(2):nth-child(1)
      > .dropdown-menu.dropdown-menu-right:before,
    .navbar.navbar-collapse
      .ace-nav
      > li:nth-last-child(2):nth-child(2)
      > .dropdown-menu.dropdown-menu-right:before,
    .navbar.navbar-collapse
      .ace-nav
      > li:nth-last-child(2):nth-child(1)
      > .dropdown-menu.dropdown-menu-right:after,
    .navbar.navbar-collapse
      .ace-nav
      > li:nth-last-child(2):nth-child(2)
      > .dropdown-menu.dropdown-menu-right:after {
      right: 80px;
      left: auto;
    }
  }
  @media only screen and (max-width: 480px) {
    .ace-nav > li:nth-last-child(4) > .dropdown-menu.dropdown-menu-right {
      right: -120px;
    }
    .ace-nav > li:nth-last-child(4) > .dropdown-menu.dropdown-menu-right:before,
    .ace-nav > li:nth-last-child(4) > .dropdown-menu.dropdown-menu-right:after {
      right: 140px;
    }
    .ace-nav > li:nth-last-child(3) > .dropdown-menu.dropdown-menu-right {
      right: -90px;
    }
    .ace-nav > li:nth-last-child(3) > .dropdown-menu.dropdown-menu-right:before,
    .ace-nav > li:nth-last-child(3) > .dropdown-menu.dropdown-menu-right:after {
      right: 110px;
    }
    .ace-nav > li:nth-last-child(2) > .dropdown-menu.dropdown-menu-right {
      right: -50px;
    }
    .ace-nav > li:nth-last-child(2) > .dropdown-menu.dropdown-menu-right:before,
    .ace-nav > li:nth-last-child(2) > .dropdown-menu.dropdown-menu-right:after {
      right: 70px;
    }
  }
  @media only screen and (max-width: 479px) {
    .ace-nav > li:nth-last-child(4) > .dropdown-menu.dropdown-menu-right {
      left: -10px;
      right: auto;
    }
    .ace-nav > li:nth-last-child(4) > .dropdown-menu.dropdown-menu-right:before,
    .ace-nav > li:nth-last-child(4) > .dropdown-menu.dropdown-menu-right:after {
      right: auto;
      left: 30px;
    }
    .ace-nav > li:nth-last-child(3) > .dropdown-menu.dropdown-menu-right {
      left: -50px;
      right: auto;
    }
    .ace-nav > li:nth-last-child(3) > .dropdown-menu.dropdown-menu-right:before,
    .ace-nav > li:nth-last-child(3) > .dropdown-menu.dropdown-menu-right:after {
      left: 75px;
      right: auto;
    }
    .ace-nav > li:nth-last-child(2) > .dropdown-menu.dropdown-menu-right {
      left: -70px;
      right: auto;
    }
    .ace-nav > li:nth-last-child(2) > .dropdown-menu.dropdown-menu-right:before,
    .ace-nav > li:nth-last-child(2) > .dropdown-menu.dropdown-menu-right:after {
      left: 90px;
      right: auto;
    }
  }
  @media only screen and (max-width: 319px) {
    .ace-nav > li:nth-last-child(2) > .dropdown-menu.dropdown-menu-right {
      left: -110px;
      right: auto;
    }
    .ace-nav > li:nth-last-child(2) > .dropdown-menu.dropdown-menu-right:before,
    .ace-nav > li:nth-last-child(2) > .dropdown-menu.dropdown-menu-right:after {
      left: 130px;
      right: auto;
    }
    .ace-nav
      > li:nth-child(2):nth-last-child(2)
      > .dropdown-menu.dropdown-menu-right {
      left: -85px;
      right: auto;
    }
    .ace-nav
      > li:nth-child(2):nth-last-child(2)
      > .dropdown-menu.dropdown-menu-right:before,
    .ace-nav
      > li:nth-child(2):nth-last-child(2)
      > .dropdown-menu.dropdown-menu-right:after {
      left: 105px;
      right: auto;
    }
    .ace-nav
      > li:nth-child(1):nth-last-child(3)
      > .dropdown-menu.dropdown-menu-right {
      left: -35px;
      right: auto;
    }
    .ace-nav
      > li:nth-child(1):nth-last-child(3)
      > .dropdown-menu.dropdown-menu-right:before,
    .ace-nav
      > li:nth-child(1):nth-last-child(3)
      > .dropdown-menu.dropdown-menu-right:after {
      left: 55px;
      right: auto;
    }
    .ace-nav
      > li:nth-child(1):nth-last-child(2)
      > .dropdown-menu.dropdown-menu-right {
      left: -60px;
      right: auto;
    }
    .ace-nav
      > li:nth-child(1):nth-last-child(2)
      > .dropdown-menu.dropdown-menu-right:before,
    .ace-nav
      > li:nth-child(1):nth-last-child(2)
      > .dropdown-menu.dropdown-menu-right:after {
      left: 75px;
      right: auto;
    }
  }
  @media only screen and (max-width: 479px) {
    .navbar:not(.navbar-collapse) .navbar-header {
      float: none !important;
      display: block;
    }
    .navbar:not(.navbar-collapse) .ace-nav {
      display: block;
      float: none !important;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.15);
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-width: 1px 0 0;
      letter-spacing: -3px;
    }
    .navbar:not(.navbar-collapse) .ace-nav > li {
      display: inline-block;
      float: none !important;
      text-align: left;
      letter-spacing: normal;
    }
    .navbar:not(.navbar-collapse) .ace-nav > li:first-child {
      border-left: 1px solid rgba(255, 255, 255, 0.4);
    }
    .navbar:not(.navbar-collapse) .ace-nav > li:last-child {
      border-right: 1px solid rgba(255, 255, 255, 0.4);
    }
  }
  @media only screen and (max-width: 319px) {
    .ace-nav > li > a {
      padding: 0 5px !important;
    }
  }
  .user-menu > li > a {
    padding: 4px 12px;
  }
  .user-menu > li > a > .ace-icon {
    margin-right: 6px;
    font-size: 120%;
  }
  .user-info {
    max-width: 100px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    vertical-align: top;
    line-height: 15px;
    position: relative;
    top: 6px;
  }
  .user-info small {
    display: block;
  }
  @media (min-width: 480px) and (max-width: 540px), (max-width: 360px) {
    .navbar:not(.navbar-collapse) .navbar-buttons .user-menu {
      padding-top: 42px !important;
    }
    .navbar:not(.navbar-collapse) .navbar-buttons .nav-user-photo {
      margin-right: 0;
    }
    .navbar:not(.navbar-collapse) .navbar-buttons .user-info {
      position: absolute;
      margin-top: 40px;
      margin-left: 1px;
      right: 2px;
      z-index: 1032;
      color: #777;
      font-size: 14px;
      width: 156px;
      max-width: 156px;
      padding-left: 8px;
      padding-right: 8px;
      height: 32px;
      line-height: 26px !important;
      display: none;
      border-bottom: 1px solid #e5e5e5;
      text-align: center;
      vertical-align: none;
      line-height: normal;
      cursor: text;
    }
    .navbar:not(.navbar-collapse) .navbar-buttons .user-info > small {
      display: inline;
      opacity: 1;
    }
    li.open .user-info {
      display: inline-block !important;
    }
  }
  @media (max-width: 360px) {
    .navbar.navbar-collapse .navbar-buttons .user-menu {
      padding-top: 42px !important;
    }
    .navbar.navbar-collapse .navbar-buttons .nav-user-photo {
      margin-right: 0;
    }
    .navbar.navbar-collapse .navbar-buttons .user-info {
      position: absolute;
      margin-top: 40px;
      margin-left: 1px;
      right: 2px;
      z-index: 1032;
      color: #777;
      font-size: 14px;
      width: 156px;
      max-width: 156px;
      padding-left: 8px;
      padding-right: 8px;
      height: 32px;
      line-height: 26px !important;
      display: none;
      border-bottom: 1px solid #e5e5e5;
      text-align: center;
      vertical-align: none;
      line-height: normal;
      cursor: text;
    }
    .navbar.navbar-collapse .navbar-buttons .user-info > small {
      display: inline;
      opacity: 1;
    }
    li.open .user-info {
      display: inline-block !important;
      right: 2px;
    }
  }
  @media (min-width: 992px) {
    .user-min .user-menu {
      padding-top: 42px !important;
    }
    .user-min .nav-user-photo {
      margin-right: 0;
    }
    .user-min .user-info {
      position: absolute;
      margin-top: 40px;
      margin-left: 1px;
      right: 2px;
      z-index: 1032;
      color: #777;
      font-size: 14px;
      width: 156px;
      max-width: 156px;
      padding-left: 8px;
      padding-right: 8px;
      height: 32px;
      line-height: 26px !important;
      display: none;
      border-bottom: 1px solid #e5e5e5;
      text-align: center;
      vertical-align: none;
      line-height: normal;
      cursor: text;
    }
    .user-min .user-info > small {
      display: inline;
      opacity: 1;
    }
    .user-min .user-info {
      right: -3px;
    }
    .user-min.open .user-info {
      display: inline-block !important;
    }
  }
  @media (max-height: 540px) and (min-width: 480px) and (max-width: 540px),
    (max-height: 540px) and (max-width: 360px) {
    .navbar:not(.navbar-collapse) .ace-nav > li.dropdown-modal .user-info {
      position: fixed;
      right: auto;
      margin-top: auto;
      max-width: none;
    }
  }
  @media (max-height: 540px) and (max-width: 360px) {
    .navbar.navbar-collapse .ace-nav > li.dropdown-modal .user-info {
      position: fixed;
      right: auto;
      margin-top: auto;
      max-width: none;
    }
  }
  @media (max-height: 540px) and (min-width: 992px) {
    .ace-nav > li.dropdown-modal.user-min .user-info {
      position: fixed;
      right: auto;
      margin-top: auto;
      max-width: none;
    }
  }
  @media only screen and (max-width: 991px) {
    .navbar.navbar-collapse {
      max-height: none;
    }
    .navbar .navbar-header,
    .navbar .navbar-collapse {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    .navbar.navbar-collapse .navbar-header {
      float: none !important;
      min-height: 45px;
    }
    .navbar.navbar-collapse .navbar-buttons {
      min-height: 0;
      padding-left: 0;
      padding-right: 0;
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-width: 1px 0 0;
      width: 100%;
    }
    .navbar.navbar-collapse .navbar-buttons.in {
      overflow: visible !important;
    }
    .navbar.navbar-collapse .navbar-buttons > .ace-nav {
      display: block;
      float: none !important;
      text-align: center;
      background-color: transparent !important;
      border-width: 0;
      letter-spacing: -3px;
    }
    .navbar.navbar-collapse .navbar-buttons > .ace-nav > li {
      display: inline-block;
      float: none !important;
      text-align: left;
      letter-spacing: normal;
    }
    .navbar.navbar-collapse .navbar-buttons > .ace-nav > li:first-child {
      border-left: 1px solid rgba(255, 255, 255, 0.4);
    }
    .navbar.navbar-collapse .navbar-buttons > .ace-nav > li:last-child {
      border-right: 1px solid rgba(255, 255, 255, 0.4);
    }
  }
  .container > .navbar-buttons,
  .container-fluid > .navbar-buttons {
    padding-right: 0 !important;
  }
  @media only screen and (max-height: 400px) {
    .navbar-fixed-top .navbar-collapse {
      max-height: 280px;
    }
  }
  @media only screen and (max-height: 360px) {
    .navbar-fixed-top .navbar-collapse {
      max-height: 240px;
    }
  }
  @media only screen and (max-height: 320px) {
    .navbar-fixed-top .navbar-collapse {
      max-height: 200px;
    }
  }
  @media only screen and (max-height: 280px) {
    .navbar-fixed-top .navbar-collapse {
      max-height: 160px;
    }
  }
  @media only screen and (max-height: 240px) {
    .navbar-fixed-top .navbar-collapse {
      max-height: 120px;
    }
  }
  .navbar .navbar-nav > li {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-width: 0 1px 0 0;
  }
  .navbar .navbar-nav > li:first-child {
    border-width: 0 1px 0 1px;
  }
  .navbar .navbar-nav > li > a {
    padding-top: 12px;
    padding-bottom: 11px;
    height: 45px;
    background-color: transparent;
    font-size: 13px;
    color: #ffffff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
  }
  .navbar .navbar-nav > li > a:hover,
  .navbar .navbar-nav > li > a:focus,
  .navbar .navbar-nav > li.open > a {
    background-color: rgba(0, 0, 0, 0.1) !important;
    color: #ffffff !important;
  }
  .navbar .navbar-nav > li > .dropdown-menu > li > a {
    line-height: 2;
  }
  .navbar-nav .dropdown-menu > li > a > .ace-icon {
    display: inline-block;
    width: 20px;
  }
  .navbar-nav > li > .dropdown-menu {
    z-index: 1033;
  }
  @media only screen and (max-width: 991px) {
    .navbar.navbar-collapse .navbar-menu {
      float: none !important;
      clear: both;
    }
    .navbar.navbar-collapse .navbar-menu .dropdown-menu:after,
    .navbar.navbar-collapse .navbar-menu .dropdown-menu:before {
      display: none !important;
    }
    .navbar .navbar-nav {
      margin: 0 -6px;
    }
    .navbar .navbar-nav > li,
    .navbar .navbar-nav > li:first-child {
      border-width: 1px 0 0;
    }
    .navbar .navbar-nav .dropdown-menu {
      float: none;
      position: relative;
      background-color: transparent;
      box-shadow: none;
      border-width: 0;
    }
    .navbar .navbar-nav .dropdown-menu > li > a {
      color: #fff !important;
    }
    .navbar .navbar-nav .dropdown-menu > li > a:hover {
      background-color: rgba(0, 0, 0, 0.07) !important;
    }
    .navbar .navbar-nav .dropdown-menu > li > a > .ace-icon {
      color: #fff !important;
    }
    .navbar .navbar-nav .dropdown-hover > .dropdown-menu {
      left: 32px !important;
      right: auto !important;
      top: auto !important;
      width: 80% !important;
      width: calc(100% - 32px);
    }
  }
  .navbar-form.form-search {
    margin-top: 9px;
    margin-bottom: 0;
  }
  .navbar-form.form-search .form-group {
    margin-bottom: 0;
  }
  .navbar-form.form-search input[type='text'] {
    width: 100px;
  }
  .navbar-form input[type='text'] {
    font-size: 13px;
    line-height: 1.4;
    padding-top: 4px;
    padding-bottom: 4px;
    border-width: 0;
  }
  @media only screen and (max-width: 991px) {
    .navbar-form .form-group {
      display: inline-block;
      width: 50%;
      margin-bottom: 0;
      vertical-align: middle;
    }
    .navbar-form.form-search input[type='text'] {
      width: 100%;
    }
    .navbar-nav + .navbar-form {
      margin-top: 0;
    }
    .navbar .navbar-collapse,
    .navbar .navbar-form {
      border-color: rgba(255, 255, 255, 0.4);
    }
    .navbar .navbar-nav > li:first-child {
      border-top-width: 0;
    }
  }
  @media only screen and (max-width: 480px) {
    .navbar-form .form-group {
      width: 80%;
    }
  }
  .navbar-fixed-top .navbar-collapse,
  .navbar-static-top .navbar-collapse,
  .navbar-fixed-bottom .navbar-collapse {
    padding-left: 12px;
    padding-right: 12px;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar.navbar-collapse {
      background-color: transparent;
    }
    .navbar.navbar-collapse:before {
      background: #438eb9;
      content: '';
      display: block;
      position: absolute;
      min-height: inherit;
      left: 0;
      right: 0;
      top: 0;
      z-index: -1;
      border-radius: inherit;
    }
    .navbar.navbar-collapse .navbar-container {
      background-color: #438eb9;
    }
  }
  .navbar .navbar-toggle {
    border: 1px solid transparent;
    width: 40px;
    height: 35px;
    line-height: 0;
    padding: 0 0 1px;
    text-align: center;
  }
  .navbar .navbar-toggle.pull-right {
    margin: 5px 6px 4px 0;
  }
  .navbar .navbar-toggle.pull-left {
    margin: 5px 0 4px 6px;
  }
  .navbar .navbar-toggle:active {
    top: 1px;
  }
  .navbar .navbar-toggle.navbar-toggle-img {
    width: auto;
    height: auto;
    overflow: hidden;
    padding: 0;
    border-width: 0;
  }
  .navbar .navbar-toggle.navbar-toggle-img > img {
    border-radius: inherit;
    max-height: 35px;
    border-width: 0;
  }
  .navbar .navbar-toggle .icon-bar {
    background-color: #fff;
    border-radius: 0;
    width: 24px;
    margin-left: 7px;
  }
  .sidebar {
    width: 190px;
    float: left;
    position: static;
    padding-left: 0;
    padding-right: 0;
  }
  .sidebar:before {
    content: '';
    display: block;
    width: inherit;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
    background-color: inherit;
    border-style: inherit;
    border-color: inherit;
    border-width: inherit;
  }
  /**li .@{icon} {
 & , .nav-list & {
	width: auto;
 }
}*/
  .nav-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .nav-list > li,
  .nav-list > li > a,
  .nav-list .nav-header {
    margin: 0;
  }
  .nav-list li > a:focus {
    outline: none;
  }
  .nav-list > li {
    display: block;
    position: relative;
    float: none;
    padding: 0;
    border-style: solid;
    border-width: 1px 0 0;
  }
  .nav-list > li:last-child {
    border-bottom-width: 1px;
  }
  .nav-list > li.active > a {
    font-weight: bold;
  }
  .nav-list > li > a {
    display: block;
    height: 39px;
    line-height: 17px;
    padding-left: 7px;
    text-shadow: none !important;
    font-size: 13px;
    text-decoration: none;
  }
  .nav-list > li > a > .menu-icon {
    display: inline-block;
    min-width: 30px;
    margin-right: 2px;
    vertical-align: sub;
    text-align: center;
    font-size: 18px;
    font-weight: normal;
  }
  .nav-list > li:before {
    display: none;
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    z-index: 1;
    height: 41px;
    width: 3px;
    max-width: 3px;
    overflow: hidden;
    background-color: #629cc9;
  }
  @media only screen and (min-width: 992px) {
    .main-container.container .sidebar:not(.h-sidebar) .nav-list > li:before {
      left: -2px;
    }
  }
  .main-container.container
    .sidebar.sidebar-fixed:not(.h-sidebar)
    .nav-list
    > li:before {
    left: 0;
  }
  .nav-list > li:hover:before {
    display: block;
  }
  .nav-list > li a > .arrow {
    display: block;
    width: 14px !important;
    height: 14px;
    line-height: 14px;
    text-shadow: none;
    font-size: 18px;
    position: absolute;
    right: 10px;
    top: 12px;
    padding: 0;
    text-align: center;
  }
  .nav-list > li.separator {
    height: 3px;
    background-color: transparent;
    position: static;
    margin: 1px 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .nav-list > li.active .submenu {
    display: block;
  }
  .nav-list > li .submenu {
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 1.5;
    position: relative;
  }
  .nav-list > li .submenu > li {
    margin-left: 0;
    position: relative;
  }
  .nav-list > li .submenu > li > a {
    display: block;
    position: relative;
    padding: 7px 0 9px 37px;
    margin: 0;
    border-top-width: 1px;
    border-top-style: dotted;
  }
  .nav-list > li .submenu > li > a:hover {
    text-decoration: none;
  }
  .nav-list > li .submenu > li a > .menu-icon {
    display: none;
    font-size: 12px;
    font-weight: normal;
    width: 18px;
    height: auto;
    line-height: 12px;
    text-align: center;
    position: absolute;
    left: 10px;
    top: 11px;
    z-index: 1;
    background-color: inherit;
  }
  .nav-list > li .submenu > li.active > a > .menu-icon,
  .nav-list > li .submenu > li:hover > a > .menu-icon {
    display: inline-block;
  }
  .nav-list > li > .submenu {
    border-top: 1px solid;
  }
  .nav-list > li > .submenu > li:before {
    content: '';
    display: block;
    width: 7px;
    position: absolute;
    z-index: 1;
    left: 20px;
    top: 17px;
    border: 1px dotted;
    border-width: 1px 0 0;
  }
  .nav-list > li > .submenu:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    left: 18px;
    top: 0;
    bottom: 0;
    border: 1px dotted;
    border-width: 0 0 0 1px;
  }
  .nav-list li:hover > a > .arrow,
  .nav-list li.active > a > .arrow,
  .nav-list li.open > a > .arrow {
    color: inherit;
  }
  .nav-list li.open > .submenu,
  .nav-list > li > .submenu li.open > .submenu {
    display: block;
  }
  no_such_element:-o-prefocus,
  .nav-list > li > a > .menu-icon {
    vertical-align: middle;
  }
  .nav-list > li > .submenu li > .submenu {
    display: none;
  }
  .nav-list > li > .submenu li.active > .submenu {
    display: block;
  }
  .nav-list > li > .submenu a > .arrow {
    right: 10px;
    top: 10px;
    font-size: 16px;
  }
  .nav-list > li > .submenu > li:first-child > a {
    border-top-width: 0;
  }
  .nav-list > li > .submenu li > .submenu > li {
    line-height: 18px;
  }
  .nav-list > li > .submenu li > .submenu > li:before {
    display: none;
  }
  .nav-list > li > .submenu li > .submenu > li > a {
    margin-left: 20px;
    padding-left: 22px;
  }
  .nav-list > li > .submenu li > .submenu > li > .submenu > li > a {
    margin-left: 20px;
    padding-left: 38px;
  }
  .nav-list > li > .submenu li > .submenu > li a > .menu-icon {
    display: inline-block;
    width: auto;
    position: static;
    background-color: transparent;
    margin-right: 4px;
    color: inherit;
  }
  .nav-list > li > .submenu li > .submenu > li a .menu-icon,
  .nav-list > li > .submenu li > .submenu > li a .arrow {
    color: inherit;
  }
  .nav-list > li > .submenu li.open:not(.hover) > a > .menu-icon {
    display: inline-block;
  }
  .nav-list a .badge,
  .nav-list a .label {
    position: absolute;
    top: 9px;
    right: 10px;
    opacity: 0.88;
    font-size: 12px;
    padding-left: 6px;
    padding-right: 6px;
  }
  .nav-list a .badge .ace-icon,
  .nav-list a .label .ace-icon {
    vertical-align: middle;
    margin: 0;
  }
  .nav-list a.dropdown-toggle .badge,
  .nav-list a.dropdown-toggle .label {
    right: 26px;
  }
  .nav-list li:hover > a .badge,
  .nav-list li:hover > a .label {
    opacity: 1;
  }
  .nav-list .submenu .submenu a .badge,
  .nav-list .submenu .submenu a .label {
    top: 6px;
  }
  .sidebar.sidebar-fixed {
    position: fixed;
    top: auto;
    float: none !important;
    z-index: 1027;
  }
  .sidebar.sidebar-fixed:before {
    height: 5000px;
    top: auto;
    bottom: auto;
  }
  .sidebar-scroll-native {
    overflow-y: scroll !important;
    overflow-x: hidden;
  }
  body.mob-safari .sidebar.sidebar-fixed {
    top: 45px;
  }
  @media (max-width: 479px) {
    body.mob-safari
      .navbar-fixed-top:not(.navbar-collapse)
      + .main-container
      .sidebar-fixed {
      top: 90px;
    }
  }
  @media (max-width: 991px) {
    body.mob-safari .navbar-fixed-top + .main-container .sidebar.responsive {
      top: 45px;
    }
  }
  @media (max-width: 479px) {
    body.mob-safari .navbar-fixed-top + .main-container .sidebar.responsive {
      top: 90px;
    }
  }
  /**
//http://updates.html5rocks.com/2012/09/Stacking-Changes-Coming-to-position-fixed-elements
//webkit & Chrome22+ special case
//webkit is not following the standard specs as of Chrome22+
@media screen and (-webkit-min-device-pixel-ratio:0) {
	//like it happens in Firefox&IE, we expect when we set "z-index:auto" for fixed position .sidebar,
	//the child .submenu elements "z-index" is independent
	//and for example a "z-index:1031" .submenu appears on top of a fixed .navbar with "z-index:1030"
	//and a "z-index:1010" .popover appears on top of ".sidebar" but below ".submenu"
	//but webkit defines a new stacking context on "fixed" position elements(.sidebar here)
	//and the above doesn't happen, in fact .submenu inside fixed .sidebar with "z-index:auto" will appear below everything
	//so we set a good(?) "z-index" for .sidebar, but still it's not as good as standard specs (such as in Firefox)
	//and we may need other workarounds!
	//and you may change it based on your needs

	//it's possible have standard functionality in Chrome by going to:
	//chrome://flags/#fixed-position-creates-stacking-context
	//changing "Fixed position elements create stacking contexts" to "Disabled"
	//and removing the following rules
	//and assignning a higher "@submenu-zindex"

	.sidebar.sidebar-fixed {
		z-index: @zindex-sidebar-fixed;//???!

		&::before {
			height: 5000px;
			top: auto;
			bottom: auto;
		}
    }
}
*/
  @media only screen and (max-width: 991px) {
    .responsive.sidebar-fixed {
      left: auto;
      margin-left: auto;
    }
    .navbar-fixed-top + .main-container > .responsive {
      position: fixed;
      left: auto;
      margin-left: auto;
    }
    .navbar-fixed-top + .main-container > .menu-toggler + .responsive {
      position: absolute;
      left: 190px;
      margin-left: -190px;
    }
    .navbar-fixed-top
      + .main-container
      > .menu-toggler
      + .responsive.sidebar-fixed {
      position: fixed;
      left: auto;
      margin-left: auto;
    }
  }
  @media only screen and (max-width: 991px) {
    .sidebar.responsive.sidebar-fixed {
      position: fixed;
      left: auto;
      margin-left: auto;
    }
    .main-container .menu-toggler.fixed {
      position: fixed;
      left: auto;
      z-index: 1026;
    }
    .sidebar.sidebar-fixed.responsive-max {
      position: fixed;
      left: auto;
      margin-left: auto;
    }
  }
  .sidebar .sidebar-toggle {
    border-style: solid;
    border-width: 0 0 1px;
    text-align: center;
    padding: 3px 0;
    position: relative;
  }
  .sidebar .sidebar-toggle > .ace-icon {
    padding: 0 5px;
    line-height: 18px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 100%;
    border: 1px solid;
    position: relative;
  }
  .sidebar .sidebar-toggle:before {
    content: '';
    display: block;
    height: 0;
    border-top: 1px solid;
    border-color: inherit;
    position: absolute;
    left: 15px;
    right: 15px;
    top: 13px;
  }
  .sidebar .sidebar-shortcuts {
    text-align: center;
    min-height: 40px;
    margin-bottom: 0;
    overflow: hidden;
    position: relative;
    border: 0 solid;
  }
  .sidebar .sidebar-shortcuts:empty {
    display: none;
  }
  .sidebar .sidebar-shortcuts-large {
    line-height: 37px;
  }
  .sidebar .sidebar-shortcuts-large > .btn {
    text-align: center;
    width: 41px;
    line-height: 24px;
    padding: 0;
    border-width: 4px;
  }
  .sidebar .sidebar-shortcuts-large > .btn > .ace-icon {
    margin: 0;
  }
  .sidebar .sidebar-shortcuts-mini {
    display: none;
    font-size: 0;
    width: 42px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .sidebar .sidebar-shortcuts-mini > .btn {
    border-width: 0;
    font-size: 0;
    line-height: 0;
    padding: 8px;
    margin: 1px;
    border-radius: 0;
    opacity: 0.85;
    filter: alpha(opacity=85);
  }
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    .sidebar ::safari-only,
    .sidebar .sidebar-shortcuts-mini > .btn {
      margin: 0;
    }
  }
  .nav-list li.active > a:after {
    display: block;
    content: '';
    position: absolute;
    right: 0;
    top: 4px;
    border: 8px solid transparent;
    border-width: 14px 10px;
  }
  .nav-list li.open > a:after {
    display: none;
  }
  .nav-list li.active.no-active-child > a:after {
    display: block;
  }
  .nav-list > li li.active > a:after {
    top: 2px;
    border-width: 14px 8px;
  }
  .nav-list li.active:not(.open) li.active > a:after {
    display: none !important;
  }
  @media (min-width: 992px) {
    .sidebar.compact,
    .sidebar.compact.navbar-collapse {
      width: 105px;
    }
    .sidebar.compact .sidebar-shortcuts {
      max-height: 40px;
    }
    .sidebar.compact .nav-list > li:before {
      height: auto;
      bottom: -1px;
    }
    .sidebar.compact .nav-list > li > a {
      height: auto;
      line-height: 16px;
      padding: 2px 0 8px;
      text-align: center;
    }
    .sidebar.compact .nav-list > li > a > .menu-icon {
      display: block;
      margin: 0;
      vertical-align: inherit;
      line-height: 32px;
      height: 32px;
      font-size: 20px;
    }
    .sidebar.compact .nav-list > li > a > .arrow {
      display: none;
    }
    .sidebar.compact .nav-list a .badge,
    .sidebar.compact .nav-list a .label {
      right: 12px;
    }
    .sidebar.compact .nav-list > li.active > .submenu,
    .sidebar.compact .nav-list > li.open > .submenu {
      display: none;
    }
    .sidebar.compact + .main-content {
      margin-left: 105px;
    }
  }
  @media (min-width: 992px) and (max-width: 991px) {
    .sidebar.compact + .main-content {
      margin-left: 0;
    }
    .sidebar.navbar-collapse.compact .nav-list > li > a {
      text-align: left;
      height: 39px;
      line-height: 37px;
      padding: 0 16px 0 7px;
    }
    .sidebar.navbar-collapse.compact .nav-list > li > a > .menu-icon {
      display: inline-block;
      vertical-align: sub;
      height: auto;
      line-height: inherit;
      margin: 0 2px 0 0;
      font-size: 18px;
    }
    .sidebar.navbar-collapse.compact .nav-list > li > a > .menu-text {
      display: inline;
    }
    .sidebar.navbar-collapse.compact .nav-list > li > a > .arrow {
      display: inline;
    }
    .sidebar.navbar-collapse.compact + .main-content {
      margin-left: 0;
    }
  }
  @media only screen and (min-width: 992px) {
    .nav-list li.hover > .submenu {
      position: absolute;
      left: 100%;
      margin-left: -2px;
      top: -10px;
      bottom: auto;
      z-index: 1026;
      width: 190px;
      border: 1px solid;
      display: none !important;
    }
    .nav-list li.hover:hover > .submenu,
    .nav-list li.hover.hover-show > .submenu {
      display: block !important;
    }
    .nav-list li.hover > .submenu:before,
    .nav-list li.hover > .submenu > li:before {
      display: none;
    }
    .nav-list li.hover.active > a:after {
      display: block;
    }
    .nav-list li.hover.active:hover > a.dropdown-toggle:after,
    .nav-list li.hover.active.hover-show > a.dropdown-toggle:after {
      display: none;
    }
    .nav-list li.hover .submenu > li.active > a:after {
      display: none !important;
    }
    .nav-list li.hover > .submenu.bottom {
      top: auto;
      bottom: -10px;
    }
    .nav-list li.hover > .submenu.bottom:before,
    .nav-list li.hover > .submenu.bottom:after {
      top: auto;
      bottom: 18px;
    }
    .nav-list li.hover > .submenu {
      padding: 0 2px;
    }
    .nav-list li.hover > .submenu > li > a {
      margin-top: 2px;
      margin-bottom: 2px;
      padding-left: 18px;
      border-top-width: 0;
    }
    .nav-list > li.hover > .submenu > li.active > a {
      font-weight: bold;
    }
    .nav-list > li > .submenu li.hover > .submenu > li > a {
      padding-left: 12px !important;
      margin-left: auto !important;
    }
    .nav-list > li > .submenu li.hover > .submenu > li > a > .menu-icon {
      margin-right: 2px;
    }
    .nav-list > li .submenu > li.hover > a {
      padding-left: 22px;
    }
    .nav-list > li .submenu > li.hover > a > .menu-icon {
      left: 4px;
    }
  }
  @media (min-width: 992px) and (max-width: 991px) {
    .sidebar.navbar-collapse .nav-list li.hover > .submenu {
      position: relative;
      left: auto;
      top: auto;
      z-index: auto;
      margin: auto;
      padding: 0;
      width: auto;
      -webkit-box-shadow: none;
      box-shadow: none;
      border-width: 0;
    }
    .sidebar.navbar-collapse .nav-list li.hover > .submenu > li:before,
    .sidebar.navbar-collapse .nav-list li.hover > .submenu:before {
      display: block;
    }
    .sidebar.navbar-collapse .nav-list li.hover > .submenu li > a {
      padding-left: 37px;
    }
    .sidebar.navbar-collapse .nav-list > li.hover > .submenu {
      border-top-width: 1px;
    }
    .sidebar.navbar-collapse
      .nav-list
      li.hover
      > .submenu
      > li
      > a
      > .menu-icon {
      background-color: inherit;
      margin-right: auto;
      width: 18px;
      position: absolute;
    }
    .sidebar.navbar-collapse
      .nav-list
      > li
      .submenu
      > li.hover
      > a
      > .menu-icon {
      left: 10px;
    }
    .sidebar.navbar-collapse .nav-list li.hover > .submenu > li.active > a {
      font-weight: normal;
    }
    .sidebar.navbar-collapse
      .nav-list
      li.hover
      > .submenu
      > li:hover
      > a
      > .menu-icon,
    .sidebar.navbar-collapse
      .nav-list
      li.hover
      > .submenu
      > li.hover-show
      > a
      > .menu-icon,
    .sidebar.navbar-collapse
      .nav-list
      li.hover
      > .submenu
      > li.active
      > a
      > .menu-icon {
      display: inline-block;
    }
    .sidebar.navbar-collapse .nav-list > li > .submenu li.hover > .submenu {
      padding-left: 0;
      padding-right: 0;
    }
    .sidebar.navbar-collapse
      .nav-list
      > li
      > .submenu
      li.hover
      > .submenu
      > li
      > a {
      padding-left: 22px !important;
      margin-left: 20px !important;
    }
    .sidebar.navbar-collapse
      .nav-list
      > li
      > .submenu
      li.hover
      > .submenu
      > li
      > a
      > .menu-icon {
      display: inline-block;
      width: auto;
      position: static;
      background-color: transparent;
      margin-right: 4px;
    }
    .sidebar.navbar-collapse
      .nav-list
      > li
      > .submenu
      li
      > .submenu
      > li.hover
      > .submenu
      > li
      > a {
      margin-left: 20px !important;
      padding-left: 38px !important;
    }
    .sidebar.navbar-collapse .nav-list li.hover > .submenu > li > a {
      border-top-width: 1px;
      margin-top: 0;
      margin-bottom: 0;
    }
    .sidebar.navbar-collapse
      .nav-list
      > li.hover
      > .submenu
      > li:first-child
      > a {
      border-top-width: 0;
    }
    .sidebar.navbar-collapse .nav-list li.hover > .submenu,
    .sidebar.navbar-collapse .nav-list li.hover:hover > .submenu,
    .sidebar.navbar-collapse .nav-list li.hover.hover-show > .submenu,
    .sidebar.navbar-collapse .nav-list li.hover > .arrow {
      display: none !important;
    }
    .sidebar.navbar-collapse .nav-list li.hover.active > .submenu {
      display: block !important;
    }
    .sidebar.navbar-collapse .nav-list li.hover > .submenu.nav-hide {
      display: none !important;
    }
    .sidebar.navbar-collapse .nav-list li.hover > .submenu.nav-show {
      display: block !important;
    }
  }
  @media (min-width: 992px) and (max-width: 991px) {
    .sidebar.navbar-collapse.compact .nav-list > li.hover.active > a:after {
      display: none;
    }
  }
  .nav-list li > .arrow {
    display: none;
    position: absolute;
    top: 8px;
    right: 1px;
    z-index: 1027;
  }
  .nav-list li > .arrow:before,
  .nav-list li > .arrow:after {
    border: 1px solid transparent;
    display: block;
    height: 0;
    width: 0;
    position: absolute;
    left: -20px;
    top: 0;
    right: auto;
    bottom: auto;
    content: '';
    border-width: 10px;
  }
  .nav-list li > .arrow:before {
    z-index: 1;
    left: -21px;
  }
  .nav-list li > .arrow:after {
    z-index: 2;
  }
  .nav-list li:hover > a + .arrow,
  .nav-list li.hover-show > a + .arrow {
    display: none;
  }
  @media only screen and (min-width: 992px) {
    .nav-list li.hover:hover > a.dropdown-toggle + .arrow,
    .nav-list li.hover.hover-show > a.dropdown-toggle + .arrow {
      display: block;
    }
  }
  .sidebar.menu-min,
  .sidebar.menu-min.compact,
  .sidebar.menu-min.navbar-collapse {
    width: 43px;
  }
  .sidebar.menu-min + .main-content {
    margin-left: 43px;
  }
  .sidebar.menu-min .nav-list a .badge,
  .sidebar.menu-min .nav-list a .label {
    position: relative;
    top: -1px;
    right: auto;
    left: 4px;
  }
  .sidebar.menu-min .nav-list .submenu .submenu a .badge,
  .sidebar.menu-min .nav-list .submenu .submenu a .label {
    top: 0;
  }
  .sidebar.menu-min .nav-list > li > a {
    position: relative;
    height: 39px;
    line-height: 37px;
    padding: 0;
  }
  .sidebar.menu-min .nav-list > li > a > .menu-icon {
    font-size: 18px;
    height: auto;
    line-height: inherit;
    display: inline-block;
    vertical-align: sub;
    margin-right: 0;
    width: 44px;
  }
  .sidebar.menu-min .nav-list > li > a > .menu-text {
    display: none;
    text-align: left;
    position: absolute;
    top: -1px;
    left: 42px;
    z-index: 1025;
    width: 176px;
    height: 41px;
    line-height: 38px;
    padding-left: 12px;
    border: 1px solid;
  }
  .sidebar.menu-min .nav-list > li > a.dropdown-toggle > .menu-text {
    top: -1px;
    border-width: 1px 1px 0;
  }
  .sidebar.menu-min .nav-list > li > a .arrow {
    display: none;
  }
  .sidebar.menu-min .nav-list > li:hover > a > .menu-text,
  .sidebar.menu-min .nav-list > li.hover-show > a > .menu-text {
    display: block;
  }
  .sidebar.menu-min .nav-list > li.active > a:after {
    border-width: 10px 6px;
    top: 8px;
  }
  .sidebar.menu-min .nav-list > li.active.open > a:after {
    display: block;
  }
  .sidebar.menu-min .nav-list > li.active.open li.active > a:after {
    display: none;
  }
  .sidebar.menu-min .nav-list > li > .submenu {
    position: absolute;
    z-index: 1026;
    left: 42px;
    top: -1px;
    margin-top: 40px;
    padding-bottom: 2px;
    width: 176px;
    display: none !important;
    height: auto !important;
    visibility: visible !important;
  }
  .sidebar.menu-min .nav-list > li > .submenu:before {
    display: none;
  }
  .sidebar.menu-min .nav-list > li > .submenu:after {
    content: '';
    display: block;
    height: 1px;
    width: 1px;
    position: absolute;
    right: -1px;
    top: -1px;
    border-right: 1px solid;
    border-right-color: inherit;
  }
  .sidebar.menu-min .nav-list > li > .submenu li:before {
    display: none;
  }
  .sidebar.menu-min .nav-list > li > .submenu li > a {
    margin-left: 0;
    padding-left: 22px;
  }
  .sidebar.menu-min .nav-list > li > .submenu li > a > .menu-icon {
    left: 4px;
  }
  .sidebar.menu-min .nav-list > li.hover > .submenu {
    padding-bottom: 0;
  }
  .sidebar.menu-min .nav-list > li.open > .submenu {
    display: none;
  }
  .sidebar.menu-min .nav-list > li:hover > .submenu,
  .sidebar.menu-min .nav-list > li.hover-show > .submenu {
    display: block !important;
  }
  .sidebar.menu-min .nav-list > li > .arrow {
    top: 10px;
  }
  .sidebar.menu-min .nav-list > li > .arrow:after,
  .sidebar.menu-min .nav-list > li > .arrow:before {
    border-width: 8px;
    left: -16px;
  }
  .sidebar.menu-min .nav-list > li > .arrow:before {
    left: -17px;
  }
  .sidebar.menu-min .nav-list li > .arrow {
    right: -1px;
  }
  .sidebar.menu-min .nav-list > li:hover > .arrow,
  .sidebar.menu-min .nav-list > li.hover-show > .arrow {
    display: block;
  }
  .sidebar.menu-min .nav-list > li.pull_up > .arrow {
    z-index: 1027;
  }
  .sidebar.menu-min .nav-list > li.pull_up > .arrow:after,
  .sidebar.menu-min .nav-list > li.pull_up > .arrow:before {
    border-width: 10px;
    left: -20px;
  }
  .sidebar.menu-min .nav-list > li.pull_up > .arrow:before {
    left: -21px;
  }
  .sidebar.menu-min .sidebar-shortcuts {
    position: relative;
    overflow: visible;
    z-index: 1;
  }
  .sidebar.menu-min .sidebar-shortcuts-mini {
    display: block;
  }
  .sidebar.menu-min .sidebar-shortcuts-large {
    display: none;
    position: absolute;
    top: 0;
    left: 42px;
    width: 182px;
    padding: 0 2px 3px;
    border: 1px solid;
    border-top-width: 0;
  }
  .sidebar.menu-min .sidebar-shortcuts:hover .sidebar-shortcuts-large,
  .sidebar.menu-min .sidebar-shortcuts.hover-show .sidebar-shortcuts-large {
    display: block;
  }
  .sidebar.menu-min .sidebar-toggle:before {
    left: 5px;
    right: 5px;
  }
  .sidebar.menu-min .sidebar-toggle > .ace-icon {
    font-size: 13px;
    padding: 0 4px;
    line-height: 15px;
    border-width: 1px;
  }
  .sidebar.menu-min .nav-list > li > .submenu li > .submenu > li > a {
    margin-left: 2px;
    padding-left: 30px;
  }
  .sidebar.menu-min
    .nav-list
    > li
    > .submenu
    li
    > .submenu
    > li
    > .submenu
    > li
    > a {
    margin-left: 2px;
    padding-left: 45px;
  }
  .sidebar.menu-min .nav-list > li > .submenu li.active > a:after {
    display: none;
  }
  .sidebar.menu-min .nav-list li.active.open > .submenu > li.active > a:after {
    display: none;
  }
  .sidebar.menu-min .nav-list > li.active:hover > a:after,
  .sidebar.menu-min .nav-list > li.active.hover-show > a:after,
  .sidebar.menu-min .nav-list > li.active.open:hover > a:after {
    display: none;
  }
  .sidebar.menu-min .nav-list > li.active:hover:after {
    display: none;
  }
  @media (min-width: 992px) {
    .sidebar.menu-min .nav-list li.hover > .submenu {
      margin-left: 0;
    }
    .sidebar.menu-min .nav-list > li > .submenu li.hover > .submenu > li > a,
    .sidebar.menu-min
      .nav-list
      > li
      > .submenu
      li
      > .submenu
      > li.hover
      > .submenu
      > li
      > a {
      margin-left: 0 !important;
    }
  }
  @media (min-width: 992px) {
    .sidebar.menu-min.compact .nav-list > li > a {
      height: 39px;
      line-height: 37px;
      padding: 0 16px 0 7px;
      position: relative;
    }
    .sidebar.menu-min.compact .nav-list > li > a > .menu-icon {
      font-size: 18px;
      height: auto;
      line-height: inherit;
      display: inline-block;
      vertical-align: sub;
      width: auto;
    }
  }
  @media only screen and (min-width: 992px) {
    .navbar.h-navbar {
      -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    }
    .sidebar.h-sidebar {
      position: relative;
      float: none !important;
      width: auto;
      margin-top: 17px;
      border-width: 0 !important;
      box-shadow: none;
    }
    .sidebar.h-sidebar.menu-min,
    .sidebar.h-sidebar.compact {
      width: auto;
    }
    .sidebar.h-sidebar.sidebar-fixed {
      position: fixed;
    }
    .sidebar.h-sidebar.no-gap {
      margin-top: auto;
    }
    .sidebar.h-sidebar.no-gap .nav-list {
      padding-top: 3px;
    }
    .sidebar.h-sidebar:before {
      display: block;
      position: absolute;
      z-index: -1;
      left: 0 !important;
      right: 0 !important;
      top: -17px;
      bottom: auto;
      height: 17px;
      width: auto;
      border-width: 0;
      border-bottom: 1px solid;
      border-bottom-color: inherit;
    }
    .sidebar.h-sidebar.lower-highlight .nav-list > li:before {
      top: 0;
    }
    .main-content .sidebar.h-sidebar .nav-list > li:before {
      top: 0;
    }
    .sidebar.h-sidebar .nav-list {
      border-width: 0;
      border-bottom: 2px solid #79b0ce;
    }
    .sidebar.h-sidebar .nav-list > li {
      float: left;
      border-width: 0 0 0 1px;
      margin-left: 0;
    }
    .sidebar.h-sidebar .nav-list > li:last-child {
      border-right-width: 1px;
    }
    .sidebar.h-sidebar .nav-list > li:before {
      left: -1px;
      right: -1px;
      bottom: auto;
      top: -2px;
      max-width: none;
      width: auto;
      height: 2px;
      border-width: 0;
    }
    .sidebar.h-sidebar .nav-list > li.active:before {
      display: block;
    }
    .sidebar.h-sidebar .nav-list > li.active:after {
      display: none;
    }
    .sidebar.h-sidebar .nav-list > li:first-child {
      border-left-width: 0;
      margin-left: 0;
    }
    .sidebar.h-sidebar .sidebar-shortcuts + .nav-list > li:first-child {
      border-left-width: 1px;
      margin-left: -1px;
    }
    .sidebar.h-sidebar .nav-list > li > a {
      line-height: 22px;
      height: auto;
      padding: 10px 14px;
    }
    .sidebar.h-sidebar .nav-list > li > a > .menu-icon {
      display: block;
      margin: 1px 0 4px;
      line-height: inherit;
      width: auto;
    }
    .sidebar.h-sidebar .nav-list > li > a > .arrow {
      display: none;
    }
    .sidebar.h-sidebar .nav-list > li.hover.pull_right {
      float: none !important;
    }
    .sidebar.h-sidebar .nav-list > li.hover > .submenu {
      top: 100%;
      left: 1px;
      margin-top: auto;
      margin-left: -2px;
      width: 190px;
      z-index: 1026;
    }
    .sidebar.h-sidebar .nav-list > li.hover.pull_right > .submenu {
      left: auto;
      right: 1px;
    }
    .sidebar.h-sidebar .nav-list > li.hover > .arrow {
      right: auto;
      top: auto;
      left: 24px;
      bottom: 7px;
    }
    .sidebar.h-sidebar .nav-list > li.hover.pull_right > .arrow {
      left: auto;
      right: 24px;
    }
    .sidebar.h-sidebar .nav-list > li.hover > .arrow:before,
    .sidebar.h-sidebar .nav-list > li.hover > .arrow:after {
      border-width: 0 8px 8px !important;
    }
    .sidebar.h-sidebar .nav-list > li.hover > .arrow:after {
      border-color: transparent;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      -moz-border-right-colors: none !important;
      -moz-border-left-colors: none !important;
      -moz-border-top-colors: none;
      -moz-border-bottom-colors: none;
      left: -10px !important;
      right: auto !important;
    }
    .sidebar.h-sidebar .nav-list > li.hover > .arrow:before {
      border-color: transparent;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      -moz-border-right-colors: none !important;
      -moz-border-left-colors: none !important;
      -moz-border-top-colors: none;
      -moz-border-bottom-colors: none;
      right: auto !important;
      left: -10px !important;
      top: -1px !important;
    }
    .sidebar.h-sidebar .nav-list > li.hover:hover > a ~ .arrow,
    .sidebar.h-sidebar .nav-list > li.hover.hover-show > a ~ .arrow {
      display: none;
    }
    .sidebar.h-sidebar .nav-list > li.hover:hover > a.dropdown-toggle ~ .arrow,
    .sidebar.h-sidebar
      .nav-list
      > li.hover.hover-show
      > a.dropdown-toggle
      ~ .arrow {
      display: block;
    }
    .sidebar.h-sidebar .nav-list > li.hover-flip > .submenu {
      left: auto;
      right: 1px;
      margin-left: auto;
      margin-right: -2px;
    }
    .sidebar.h-sidebar .nav-list > li.hover-flip > .arrow {
      left: auto;
      right: 24px;
    }
    .sidebar.h-sidebar .nav-list li li.hover-flip > .submenu {
      left: auto;
      right: 100%;
      margin-left: auto;
      margin-right: -2px;
    }
    .sidebar.h-sidebar .nav-list li li.hover-flip > .arrow {
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
      -moz-transform: scale(-1, 1);
      -webkit-transform: scale(-1, 1);
      -o-transform: scale(-1, 1);
      -ms-transform: scale(-1, 1);
      transform: scale(-1, 1);
      left: 0;
      right: auto;
    }
    .sidebar.h-sidebar .sidebar-shortcuts-large:before,
    .sidebar.h-sidebar .sidebar-shortcuts-large:after {
      display: block;
      content: '';
      position: absolute;
      left: 50%;
      margin-left: -8px;
      border: 1px solid transparent;
      border-width: 0 8px 8px;
    }
    .sidebar.h-sidebar .sidebar-shortcuts-large:after {
      border-right-color: transparent;
      -moz-border-right-colors: none;
      top: -8px;
    }
    .sidebar.h-sidebar .sidebar-shortcuts-large:before {
      -moz-border-bottom-colors: #ccc;
      border-bottom-color: #ccc;
      top: -9px;
    }
    .sidebar.h-sidebar .sidebar-toggle {
      position: absolute;
      top: 12px;
      right: 6px;
      background-color: transparent;
      border-width: 0;
    }
    .sidebar.h-sidebar .sidebar-shortcuts {
      float: left;
      display: block;
      overflow: visible;
      position: relative;
      margin: 0;
      padding: 12px 8px 9px;
      max-height: none !important;
      background-color: transparent;
    }
    .sidebar.h-sidebar .sidebar-shortcuts:hover .sidebar-shortcuts-large,
    .sidebar.h-sidebar .sidebar-shortcuts.hover-show .sidebar-shortcuts-large {
      display: block;
    }
    .sidebar.h-sidebar .sidebar-shortcuts-large {
      display: none;
      width: 52px;
      height: auto;
      position: absolute;
      z-index: 1025;
      top: 100%;
      margin-top: -5px;
      left: 10px;
      padding: 3px 2px;
      border: 1px solid;
    }
    .sidebar.h-sidebar .sidebar-shortcuts-mini {
      width: auto;
      max-width: 52px;
      display: block;
      background-color: transparent;
      padding-top: 3px;
      padding-bottom: 5px;
    }
    .sidebar.h-sidebar .sidebar-shortcuts-mini > .btn {
      padding: 9px;
    }
    .sidebar.h-sidebar .nav-list a .badge,
    .sidebar.h-sidebar .nav-list a .label {
      position: absolute;
      top: 11px;
      right: auto !important;
      left: 50%;
      margin-left: 10px;
    }
    .sidebar.h-sidebar + .main-content {
      margin-left: 0;
    }
    .sidebar.h-sidebar + .main-content .breadcrumbs {
      margin: 6px 9px;
      border: 1px solid;
      border-color: #e5e5e5;
    }
    .h-sidebar.sidebar-fixed {
      top: auto;
      width: 100%;
      left: 0;
      right: 0;
    }
    .h-sidebar.sidebar-fixed:before {
      z-index: auto;
    }
    .h-sidebar.sidebar-fixed,
    .h-sidebar.sidebar-fixed .nav-list {
      border-right-width: 0;
      border-left-width: 0;
    }
    .h-sidebar.sidebar-fixed .sidebar-shortcuts {
      border-width: 0;
    }
    .h-sidebar.sidebar-fixed + .main-content {
      padding-top: 88px;
    }
    .h-sidebar.sidebar-fixed.no-gap + .main-content {
      padding-top: 74px;
    }
    .h-sidebar.sidebar-fixed.menu-min + .main-content {
      padding-top: 63px;
    }
    .h-sidebar.sidebar-fixed.menu-min.no-gap + .main-content {
      padding-top: 49px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 991px) {
    .sidebar.h-sidebar + .main-content .breadcrumbs {
      margin: 0;
      border-width: 0 0 1px;
    }
  }
  .main-container.container .h-sidebar.sidebar-fixed {
    left: 0;
    right: 0;
    width: auto;
  }
  @media (min-width: 768px) {
    .main-container.container .h-sidebar.sidebar-fixed {
      left: auto !important;
      right: auto !important;
      width: 744px;
    }
  }
  @media (min-width: 992px) {
    .main-container.container .h-sidebar.sidebar-fixed {
      width: 964px;
    }
  }
  @media (min-width: 1200px) {
    .main-container.container .h-sidebar.sidebar-fixed {
      width: 1164px;
    }
  }
  /**
	@media (min-width: @screen-sm-min) and (max-width: @grid-float-breakpoint-max) {
	 .main-container.container .sidebar.sidebar-fixed
	 {
		left: auto;// !important;
		right: auto;// !important;
		width: @container-sm;// !important;
	 }
	}


	@media (max-width: @grid-float-breakpoint-max) {
		.main-container.container .sidebar.sidebar-fixed {
			.sidebar-shortcuts {
				max-height: none;
				border-bottom-width: 0;
			}
		}
	}
	*/
  @media (min-width: 992px) {
    .sidebar.h-sidebar.menu-min .nav.nav-list > li > a,
    .sidebar.h-sidebar.menu-min.compact .nav.nav-list > li > a {
      padding: 9px 12px;
    }
    .sidebar.h-sidebar.menu-min .nav-list > li > a > .menu-text {
      left: -1px;
      top: 100%;
      width: 190px;
    }
    .sidebar.h-sidebar.menu-min .nav-list > li > a > .menu-icon {
      margin: 2px 0;
    }
    .sidebar.h-sidebar.menu-min .nav-list > li.hover > .submenu {
      top: 100%;
      margin-top: 41px;
    }
    .sidebar.h-sidebar.menu-min .nav-list > li.hover:hover > a ~ .arrow,
    .sidebar.h-sidebar.menu-min .nav-list > li.hover.hover-show > a ~ .arrow {
      display: block;
    }
    .sidebar.h-sidebar.menu-min .sidebar-toggle {
      top: 6px;
    }
    .sidebar.h-sidebar.menu-min .sidebar-shortcuts {
      padding: 0;
    }
    .sidebar.h-sidebar.menu-min .sidebar-shortcuts-mini {
      padding-bottom: 4px;
      padding-top: 4px;
    }
    .sidebar.h-sidebar.menu-min .sidebar-shortcuts-mini > .btn {
      padding: 8px;
    }
    .sidebar.h-sidebar.menu-min .sidebar-shortcuts-large {
      left: 1px;
      margin-top: 4px;
    }
    .sidebar.h-sidebar.compact .nav-list > li > a > .menu-icon {
      font-size: 18px;
      height: auto;
    }
    .sidebar.h-sidebar.compact.menu-min .nav-list > li > a {
      height: auto;
      line-height: 22px;
    }
  }
  /**
@media only screen and (max-width: @grid-float-breakpoint-max) {
	.menu-toggler + .sidebar.h-sidebar {
		margin-top: (@breadcrumb-height - 1) !important;
	}
	.sidebar.h-sidebar.responsive-min , .sidebar.h-sidebar.navbar-collapse {
		margin-top: auto !important;
	}
}
*/
  .main-container .menu-toggler {
    display: none;
  }
  @media only screen and (max-width: 991px) {
    .sidebar.responsive:before {
      display: none;
    }
    .sidebar.responsive {
      -webkit-box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.15);
      box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.15);
      z-index: 1027;
      overflow: hidden;
      max-height: 0;
      -moz-transform: translateX(-200px);
      -webkit-transform: translateX(-200px);
      -o-transform: translateX(-200px);
      -ms-transform: translateX(-200px);
      transform: translateX(-200px);
      -webkit-transition: -webkit-transform 0.2s linear 0s,
        max-height 0s linear 0.2s;
      -moz-transition: -moz-transform 0.2s linear 0s, max-height 0s linear 0.2s;
      -o-transition: -o-transform 0.2s linear 0s, max-height 0s linear 0.2s;
      transition: transform 0.2s linear 0s, max-height 0s linear 0.2s;
      position: relative;
      bottom: auto;
      left: 190px;
      margin-left: -190px;
    }
  }
  @media (min-width: 992px) and (max-width: 991px) {
    .sidebar.responsive.compact {
      -moz-transform: translateX(-115px);
      -webkit-transform: translateX(-115px);
      -o-transform: translateX(-115px);
      -ms-transform: translateX(-115px);
      transform: translateX(-115px);
    }
    .sidebar.responsive.compact.push_away.display + .main-content {
      -moz-transform: translateX(105px);
      -webkit-transform: translateX(105px);
      -o-transform: translateX(105px);
      -ms-transform: translateX(105px);
      transform: translateX(105px);
    }
    .navbar.navbar-fixed-top
      + .main-container
      .sidebar.responsive.compact.push_away.display
      ~ .footer
      .footer-inner {
      -moz-transform: translateX(105px);
      -webkit-transform: translateX(105px);
      -o-transform: translateX(105px);
      -ms-transform: translateX(105px);
      transform: translateX(105px);
    }
  }
  @media only screen and (max-width: 991px) {
    .sidebar.responsive.menu-min {
      -moz-transform: translateX(-53px);
      -webkit-transform: translateX(-53px);
      -o-transform: translateX(-53px);
      -ms-transform: translateX(-53px);
      transform: translateX(-53px);
    }
    .sidebar.responsive.display {
      -moz-transform: none !important;
      -webkit-transform: none !important;
      -o-transform: none !important;
      -ms-transform: none !important;
      transform: none !important;
      overflow: visible;
      max-height: 2000px;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -o-transition-delay: 0s;
      transition-delay: 0s;
    }
    .sidebar.responsive.push_away {
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }
    .sidebar.responsive.push_away:before {
      display: block !important;
    }
    .sidebar.responsive.push_away + .main-content {
      -webkit-transition: -webkit-transform 0.2s linear 0s;
      -moz-transition: -moz-transform 0.2s linear 0s;
      -o-transition: -o-transform 0.2s linear 0s;
      transition: transform 0.2s linear 0s;
    }
    .sidebar.responsive.push_away.display + .main-content {
      -moz-transform: translateX(190px);
      -webkit-transform: translateX(190px);
      -o-transform: translateX(190px);
      -ms-transform: translateX(190px);
      transform: translateX(190px);
    }
    .sidebar.responsive.push_away.display.menu-min + .main-content {
      -moz-transform: translateX(43px);
      -webkit-transform: translateX(43px);
      -o-transform: translateX(43px);
      -ms-transform: translateX(43px);
      transform: translateX(43px);
    }
    .navbar.navbar-fixed-top
      + .main-container
      .sidebar.responsive.push_away:before {
      height: 5000px;
    }
    .navbar.navbar-fixed-top
      + .main-container
      .sidebar.responsive.push_away
      ~ .footer
      .footer-inner {
      -webkit-transition: -webkit-transform 0.2s linear 0s;
      -moz-transition: -moz-transform 0.2s linear 0s;
      -o-transition: -o-transform 0.2s linear 0s;
      transition: transform 0.2s linear 0s;
    }
    .navbar.navbar-fixed-top
      + .main-container
      .sidebar.responsive.push_away.display
      ~ .footer
      .footer-inner {
      -moz-transform: translateX(190px);
      -webkit-transform: translateX(190px);
      -o-transform: translateX(190px);
      -ms-transform: translateX(190px);
      transform: translateX(190px);
    }
    .navbar.navbar-fixed-top
      + .main-container
      .sidebar.responsive.push_away.display.menu-min
      ~ .footer
      .footer-inner {
      -moz-transform: translateX(43px);
      -webkit-transform: translateX(43px);
      -o-transform: translateX(43px);
      -ms-transform: translateX(43px);
      transform: translateX(43px);
    }
    .sidebar.responsive + .main-content {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .main-container.container .sidebar.responsive {
      -webkit-transition: none;
      -moz-transition: none;
      -o-transition: none;
      transition: none;
      display: none;
    }
    .main-container.container .sidebar.responsive:before {
      display: none !important;
    }
    .main-container.container .sidebar.responsive.display {
      display: block;
    }
    .main-container.container .sidebar.responsive.push_away + .main-content,
    .main-container.container
      .sidebar.responsive.push_away
      ~ .footer
      .footer-inner {
      -webkit-transition: none !important;
      -moz-transition: none !important;
      -o-transition: none !important;
      transition: none !important;
      -moz-transform: none !important;
      -webkit-transform: none !important;
      -o-transform: none !important;
      -ms-transform: none !important;
      transform: none !important;
    }
  }
  @media only screen and (max-width: 991px) {
    .responsive.sidebar-fixed {
      position: fixed;
    }
    .main-container .menu-toggler.fixed {
      position: fixed;
      left: auto;
      z-index: 1026;
      -moz-backface-visibility: hidden;
    }
  }
  @media only screen and (max-width: 991px) {
    .menu-toggler + .sidebar.responsive {
      margin-top: 40px;
    }
    .main-container .menu-toggler {
      display: block;
      position: absolute;
      z-index: 1026;
      width: 52px;
      height: 32px;
      margin-right: 2px;
      line-height: normal;
      padding-left: 33px;
      padding-top: 7px;
      padding-bottom: 1px;
      font-size: 13px;
      font-weight: bold;
      text-transform: uppercase;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
    }
    .main-container .menu-toggler:hover {
      text-decoration: none;
    }
    .main-container .menu-toggler:focus {
      outline: none;
    }
    .main-container .menu-toggler:before {
      border-top: 1px solid #87b87f;
      border-bottom: 1px solid #6fb3e0;
      height: 2px;
      width: 24px;
      content: '';
      position: absolute;
      z-index: 1;
      top: 13px;
      left: 4px;
      -webkit-transition: all 0.1s ease;
      -o-transition: all 0.1s ease;
      transition: all 0.1s ease;
      -o-transition: none;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
    }
    .main-container .menu-toggler:after {
      border-top: 1px solid #ffa24d;
      border-bottom: 1px solid #d15b47;
      content: '';
      height: 2px;
      width: 24px;
      position: absolute;
      top: 19px;
      left: 4px;
      -webkit-transition: all 0.1s ease;
      -o-transition: all 0.1s ease;
      transition: all 0.1s ease;
      -o-transition: none;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
    }
    .main-container .menu-toggler.display:before {
      height: 4px;
      top: 8px;
      border-width: 2px;
    }
    .main-container .menu-toggler.display:after {
      height: 4px;
      top: 20px;
      border-width: 2px;
    }
    .main-container .menu-toggler > .toggler-text {
      display: block;
      position: absolute;
      bottom: -9px;
      left: 0;
      border: 1px solid transparent;
      border-width: 9px 42px 0;
      border-top-color: #444444;
      -moz-border-top-colors: #444444;
    }
    .main-container .menu-toggler > .toggler-text:after {
      display: block;
      content: 'MENU';
      color: #ffffff;
      position: absolute;
      left: -8px;
      top: -41px;
    }
    .menu-toggler + .responsive.sidebar-scroll .nav-wrap {
      overflow: hidden;
    }
  }
  @media only screen and (max-width: 320px) {
    .main-container .menu-toggler {
      width: 0;
    }
    .main-container .menu-toggler > .toggler-text {
      border-width: 7px 16px;
      bottom: -14px;
    }
    .main-container .menu-toggler > .toggler-text:after {
      font-size: 9px;
      font-weight: normal;
      color: #fff;
      position: absolute;
      left: -13px;
      top: -42px;
    }
    .main-container .menu-toggler:before,
    .main-container .menu-toggler:after {
      margin-top: 8px;
    }
    .main-container .menu-toggler.display:before,
    .main-container .menu-toggler.display:after {
      height: 2px;
      border-width: 1px;
    }
    .main-container .menu-toggler.display:before {
      top: 13px;
    }
    .main-container .menu-toggler.display:after {
      top: 19px;
    }
  }
  .sidebar-toggle.sidebar-expand {
    display: none;
  }
  @media (max-width: 991px) {
    .menu-toggler.invisible {
      position: fixed;
      z-index: -999;
      visibility: hidden;
      opacity: 0;
      width: 1px;
      height: 1px;
    }
    .sidebar.responsive-min,
    .sidebar.responsive-min.compact,
    .sidebar.responsive-min.navbar-collapse {
      width: 43px;
    }
    .sidebar.responsive-min + .main-content {
      margin-left: 43px;
    }
    .sidebar.responsive-min .nav-list a .badge,
    .sidebar.responsive-min .nav-list a .label {
      position: relative;
      top: -1px;
      right: auto;
      left: 4px;
    }
    .sidebar.responsive-min .nav-list .submenu .submenu a .badge,
    .sidebar.responsive-min .nav-list .submenu .submenu a .label {
      top: 0;
    }
    .sidebar.responsive-min .nav-list > li > a {
      position: relative;
      height: 39px;
      line-height: 37px;
      padding: 0;
    }
    .sidebar.responsive-min .nav-list > li > a > .menu-icon {
      font-size: 18px;
      height: auto;
      line-height: inherit;
      display: inline-block;
      vertical-align: sub;
      margin-right: 0;
      width: 44px;
    }
    .sidebar.responsive-min .nav-list > li > a > .menu-text {
      display: none;
      text-align: left;
      position: absolute;
      top: -1px;
      left: 42px;
      z-index: 1025;
      width: 176px;
      height: 41px;
      line-height: 38px;
      padding-left: 12px;
      border: 1px solid;
    }
    .sidebar.responsive-min .nav-list > li > a.dropdown-toggle > .menu-text {
      top: -1px;
      border-width: 1px 1px 0;
    }
    .sidebar.responsive-min .nav-list > li > a .arrow {
      display: none;
    }
    .sidebar.responsive-min .nav-list > li:hover > a > .menu-text,
    .sidebar.responsive-min .nav-list > li.hover-show > a > .menu-text {
      display: block;
    }
    .sidebar.responsive-min .nav-list > li.active > a:after {
      border-width: 10px 6px;
      top: 8px;
    }
    .sidebar.responsive-min .nav-list > li.active.open > a:after {
      display: block;
    }
    .sidebar.responsive-min .nav-list > li.active.open li.active > a:after {
      display: none;
    }
    .sidebar.responsive-min .nav-list > li > .submenu {
      position: absolute;
      z-index: 1026;
      left: 42px;
      top: -1px;
      margin-top: 40px;
      padding-bottom: 2px;
      width: 176px;
      display: none !important;
      height: auto !important;
      visibility: visible !important;
    }
    .sidebar.responsive-min .nav-list > li > .submenu:before {
      display: none;
    }
    .sidebar.responsive-min .nav-list > li > .submenu:after {
      content: '';
      display: block;
      height: 1px;
      width: 1px;
      position: absolute;
      right: -1px;
      top: -1px;
      border-right: 1px solid;
      border-right-color: inherit;
    }
    .sidebar.responsive-min .nav-list > li > .submenu li:before {
      display: none;
    }
    .sidebar.responsive-min .nav-list > li > .submenu li > a {
      margin-left: 0;
      padding-left: 22px;
    }
    .sidebar.responsive-min .nav-list > li > .submenu li > a > .menu-icon {
      left: 4px;
    }
    .sidebar.responsive-min .nav-list > li.hover > .submenu {
      padding-bottom: 0;
    }
    .sidebar.responsive-min .nav-list > li.open > .submenu {
      display: none;
    }
    .sidebar.responsive-min .nav-list > li:hover > .submenu,
    .sidebar.responsive-min .nav-list > li.hover-show > .submenu {
      display: block !important;
    }
    .sidebar.responsive-min .nav-list > li > .arrow {
      top: 10px;
    }
    .sidebar.responsive-min .nav-list > li > .arrow:after,
    .sidebar.responsive-min .nav-list > li > .arrow:before {
      border-width: 8px;
      left: -16px;
    }
    .sidebar.responsive-min .nav-list > li > .arrow:before {
      left: -17px;
    }
    .sidebar.responsive-min .nav-list li > .arrow {
      right: -1px;
    }
    .sidebar.responsive-min .nav-list > li:hover > .arrow,
    .sidebar.responsive-min .nav-list > li.hover-show > .arrow {
      display: block;
    }
    .sidebar.responsive-min .nav-list > li.pull_up > .arrow {
      z-index: 1027;
    }
    .sidebar.responsive-min .nav-list > li.pull_up > .arrow:after,
    .sidebar.responsive-min .nav-list > li.pull_up > .arrow:before {
      border-width: 10px;
      left: -20px;
    }
    .sidebar.responsive-min .nav-list > li.pull_up > .arrow:before {
      left: -21px;
    }
    .sidebar.responsive-min .sidebar-shortcuts {
      position: relative;
      overflow: visible;
      z-index: 1;
    }
    .sidebar.responsive-min .sidebar-shortcuts-mini {
      display: block;
    }
    .sidebar.responsive-min .sidebar-shortcuts-large {
      display: none;
      position: absolute;
      top: 0;
      left: 42px;
      width: 182px;
      padding: 0 2px 3px;
      border: 1px solid;
      border-top-width: 0;
    }
    .sidebar.responsive-min .sidebar-shortcuts:hover .sidebar-shortcuts-large,
    .sidebar.responsive-min
      .sidebar-shortcuts.hover-show
      .sidebar-shortcuts-large {
      display: block;
    }
    .sidebar.responsive-min .sidebar-toggle:before {
      left: 5px;
      right: 5px;
    }
    .sidebar.responsive-min .sidebar-toggle > .ace-icon {
      font-size: 13px;
      padding: 0 4px;
      line-height: 15px;
      border-width: 1px;
    }
    .sidebar.responsive-min .nav-list > li > .submenu li > .submenu > li > a {
      margin-left: 2px;
      padding-left: 30px;
    }
    .sidebar.responsive-min
      .nav-list
      > li
      > .submenu
      li
      > .submenu
      > li
      > .submenu
      > li
      > a {
      margin-left: 2px;
      padding-left: 45px;
    }
    .sidebar.responsive-min .nav-list > li > .submenu li.active > a:after {
      display: none;
    }
    .sidebar.responsive-min
      .nav-list
      li.active.open
      > .submenu
      > li.active
      > a:after {
      display: none;
    }
    .sidebar.responsive-min .nav-list > li.active:hover > a:after,
    .sidebar.responsive-min .nav-list > li.active.hover-show > a:after,
    .sidebar.responsive-min .nav-list > li.active.open:hover > a:after {
      display: none;
    }
    .sidebar.responsive-min .nav-list > li.active:hover:after {
      display: none;
    }
    .sidebar.responsive-min:before {
      display: block;
    }
    .sidebar.responsive-min .sidebar-toggle.sidebar-collapse {
      display: none;
    }
    .sidebar.responsive-min .sidebar-toggle.sidebar-expand {
      display: block;
    }
    .sidebar.responsive-min + .main-content {
      margin-left: 43px !important;
    }
    .rtl .sidebar.responsive-min + .main-content {
      margin-left: auto !important;
      margin-right: 43px !important;
    }
    .sidebar.responsive-min + .main-content .breadcrumb {
      margin-left: 12px;
    }
    .sidebar.responsive-max {
      display: block;
      position: relative;
      left: 190px;
      margin-left: -190px;
      z-index: 1027;
      -webkit-box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.15);
      box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.15);
    }
    .sidebar.responsive-max:before {
      display: none;
    }
    .sidebar.responsive-max .sidebar-toggle.sidebar-collapse {
      display: none;
    }
    .sidebar.responsive-max .sidebar-toggle.sidebar-expand {
      display: block;
      z-index: 1;
    }
    .sidebar.responsive-max + .main-content {
      margin-left: 43px !important;
    }
    .sidebar.responsive-max + .main-content .breadcrumb {
      margin-left: 12px;
    }
  }
  @media only screen and (min-width: 992px) {
    .sidebar.navbar-collapse {
      max-height: none;
    }
  }
  @media only screen and (max-width: 991px) {
    .sidebar.navbar-collapse {
      position: relative;
      float: none !important;
      margin-top: auto !important;
      z-index: 1;
      width: 100% !important;
      max-height: 340px;
      margin-left: 0;
      margin-right: 0;
    }
    .sidebar.navbar-collapse:before {
      display: none;
    }
    .sidebar.navbar-collapse .nav-list > li:last-child {
      border-bottom-width: 0;
    }
    .sidebar.navbar-collapse .nav-list > li > a {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      line-height: 20px;
      height: 41px;
    }
    .sidebar.navbar-collapse .nav-list > li:before {
      height: 43px;
    }
    .sidebar.navbar-collapse .nav-list > li.active:after {
      display: none;
    }
    .sidebar.navbar-collapse li > .arrow {
      display: none !important;
    }
    .sidebar.navbar-collapse .nav-list li > .submenu {
      border-left-width: 0;
    }
    .sidebar.navbar-collapse .sidebar-toggle {
      display: none;
    }
    .sidebar.navbar-collapse .sidebar-shortcuts {
      padding: 3px 0 6px;
      max-height: none;
      border-bottom-width: 0;
    }
    .sidebar.navbar-collapse + .main-content {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    .sidebar.navbar-collapse + .main-content .breadcrumb {
      margin-left: 12px;
    }
    .sidebar.navbar-collapse .nav-wrap + .ace-scroll {
      display: none;
    }
    .sidebar.navbar-collapse.menu-min .nav-list > li > .submenu {
      position: relative;
      left: auto;
      top: auto;
      width: auto;
      z-index: auto;
      margin-top: auto;
      padding-top: 0;
      padding-bottom: 0;
      border-width: 1px 0 0 0 !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }
    .sidebar.navbar-collapse.menu-min .nav-list > li > .submenu:before {
      display: block;
    }
    .sidebar.navbar-collapse.menu-min .nav-list > li > .submenu > li:before {
      display: block;
    }
    .sidebar.navbar-collapse.menu-min
      .nav-list
      > li
      > .submenu
      li
      > a
      > .menu-icon {
      left: 10px;
    }
    .sidebar.navbar-collapse.menu-min .nav-list > li > .submenu:after {
      display: none;
    }
    .sidebar.navbar-collapse.menu-min .nav-list > li > .submenu li > a {
      padding: 7px 0 9px 37px;
    }
    .sidebar.navbar-collapse.menu-min
      .nav-list
      > li
      > .submenu
      li
      > .submenu
      > li
      > a {
      margin-left: 20px;
      padding-left: 22px;
    }
    .sidebar.navbar-collapse.menu-min
      .nav-list
      > li
      > .submenu
      li
      > .submenu
      > li
      > .submenu
      > li
      > a {
      margin-left: 20px;
      padding-left: 38px;
    }
    .sidebar.navbar-collapse.menu-min .nav-list > li > a:hover:before {
      width: 3px;
    }
    .sidebar.navbar-collapse.menu-min .nav-list > li > a {
      text-align: left;
      padding-left: 7px;
    }
    .sidebar.navbar-collapse.menu-min .nav-list > li > a > .menu-icon {
      display: inline-block;
      width: auto;
      margin-right: 2px;
    }
    .sidebar.navbar-collapse.menu-min .nav-list > li > a > .menu-text {
      position: relative;
      display: inline;
      line-height: normal;
      padding-left: 0;
      height: auto;
      top: auto;
      left: auto;
      z-index: auto;
      width: auto;
      border-width: 0 !important;
      background-color: transparent !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }
    .sidebar.navbar-collapse.menu-min .nav-list a > .arrow {
      display: block;
    }
    .sidebar.navbar-collapse.menu-min .nav-list > li:hover > .submenu {
      display: none !important;
    }
    .sidebar.navbar-collapse.menu-min .nav-list > li.active > .submenu {
      display: block !important;
    }
    .sidebar.navbar-collapse.menu-min .nav-list > li > .submenu.nav-show {
      display: block !important;
    }
    .sidebar.navbar-collapse.menu-min .nav-list > li > .submenu.nav-hide {
      display: none !important;
    }
    .sidebar.navbar-collapse.menu-min .sidebar-shortcuts-large {
      display: block;
      position: static;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      border-width: 0 !important;
      background-color: transparent !important;
      width: auto;
      padding: 0;
    }
    .sidebar.navbar-collapse.menu-min .sidebar-shortcuts-mini {
      display: none;
    }
    .sidebar.navbar-collapse .nav-list a .badge,
    .sidebar.navbar-collapse .nav-list a .label {
      position: relative;
      top: -1px;
      right: auto;
      left: 4px;
    }
    .sidebar.navbar-collapse .nav-list > li.active:after,
    .sidebar.navbar-collapse .nav-list li.active > a:before,
    .sidebar.navbar-collapse .nav-list li.active > a:after {
      display: none !important;
    }
    .sidebar.navbar-collapse .nav-list > li.highlight.active > a:before,
    .sidebar.navbar-collapse .nav-list > li.highlight.active > a:after {
      display: none !important;
    }
  }
  @media (max-width: 991px) and (max-height: 400px) {
    .sidebar.navbar-collapse {
      max-height: 300px;
    }
  }
  @media (max-width: 991px) and (max-height: 350px) {
    .sidebar.navbar-collapse {
      max-height: 250px;
    }
  }
  @media (max-width: 991px) and (max-height: 300px) {
    .sidebar.navbar-collapse {
      max-height: 200px;
    }
  }
  @media (max-width: 991px) and (max-height: 250px) {
    .sidebar.navbar-collapse {
      max-height: 150px;
    }
  }
  @media (min-width: 992px) and (max-width: 991px) {
    .sidebar.navbar-collapse
      .nav-list
      > li
      > .submenu
      li.hover
      > .submenu
      > li
      > a,
    .sidebar.navbar-collapse
      .nav-list
      > li
      > .submenu
      li
      > .submenu
      > li.hover
      > .submenu
      > li
      > a {
      margin-left: 20px !important;
    }
  }
  @media (max-width: 991px) {
    .navbar-fixed-top + .main-container .sidebar.navbar-collapse {
      position: fixed;
      z-index: 1027;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .navbar-fixed-top + .main-container.container .sidebar.navbar-collapse {
      width: 744px !important;
    }
  }
  .main-content-inner {
    float: left;
    width: 100%;
  }
  .main-content .sidebar:before {
    top: 150px;
  }
  @media only screen and (min-width: 992px) {
    body.mob-safari
      .sidebar.h-sidebar
      + .main-content
      .sidebar.sidebar-fixed:not(.h-sidebar) {
      top: 131px;
    }
    body.mob-safari
      .sidebar.h-sidebar.no-gap
      + .main-content
      .sidebar.sidebar-fixed:not(.h-sidebar) {
      top: 114px;
    }
    body.mob-safari
      .sidebar.h-sidebar.menu-min
      + .main-content
      .sidebar.sidebar-fixed:not(.h-sidebar) {
      top: 106px;
    }
    body.mob-safari
      .sidebar.h-sidebar.menu-min.no-gap
      + .main-content
      .sidebar.sidebar-fixed:not(.h-sidebar) {
      top: 89px;
    }
  }
  @media only screen and (min-width: 992px) {
    .h-navbar.navbar-fixed-top
      + .main-container
      .sidebar:not(.h-sidebar):before {
      top: 150px;
    }
    .h-navbar.navbar-fixed-top
      + .main-container
      .sidebar:not(.h-sidebar)
      .nav-list {
      padding-top: 3px;
    }
    .h-navbar.navbar-fixed-top
      + .main-container
      .sidebar:not(.h-sidebar)
      .sidebar-shortcuts
      ~ .nav-list,
    .h-navbar.navbar-fixed-top
      + .main-container
      .sidebar:not(.h-sidebar)
      .sidebar-shortcuts
      ~ .nav-wrap-up
      .nav-list {
      padding-top: 0;
    }
    .h-navbar.navbar-fixed-top
      + .main-container
      .sidebar:not(.h-sidebar)
      .sidebar-shortcuts {
      padding-top: 3px;
      min-height: 43px;
    }
    .h-navbar.navbar-fixed-top
      + .main-container
      .sidebar:not(.h-sidebar).menu-min
      .sidebar-shortcuts-large {
      line-height: 40px;
    }
    .h-navbar.navbar-fixed-top
      + .main-container
      .main-content
      .sidebar:not(.h-sidebar)
      .nav-list {
      padding-top: 0;
    }
    .h-navbar.navbar-fixed-top
      + .main-container
      .main-content
      .sidebar.sidebar-fixed {
      z-index: 1026;
    }
    .main-content .h-sidebar {
      padding-top: 1px;
      margin-top: 0;
    }
    .main-content .h-sidebar:before {
      display: none;
    }
    .main-content .h-sidebar + .page-content {
      margin-left: auto;
    }
    .main-content .h-sidebar.sidebar-fixed {
      left: 190px;
    }
    .main-content .h-sidebar.sidebar-fixed + .page-content {
      margin-top: 74px;
    }
    .main-content .h-sidebar.sidebar-fixed.menu-min + .page-content {
      margin-top: 49px;
    }
    .main-content .h-sidebar.menu-min .nav-list > li > a {
      padding: 8px 12px 7px;
    }
    .sidebar.compact + .main-content .h-sidebar.sidebar-fixed {
      left: 105px;
    }
    .sidebar.menu-min + .main-content .h-sidebar.sidebar-fixed {
      left: 43px;
    }
    .navbar.navbar-fixed-top
      + .main-container
      .main-content
      .h-sidebar.sidebar-fixed {
      padding-top: 3px;
    }
  }
  .container.main-container .sidebar + .main-content .h-sidebar.sidebar-fixed {
    left: auto;
    right: auto;
  }
  @media (min-width: 992px) {
    .container.main-container
      .sidebar.compact
      + .main-content
      .h-sidebar.sidebar-fixed {
      width: 639px;
    }
  }
  @media (min-width: 992px) {
    .container.main-container .main-content .h-sidebar.sidebar-fixed {
      width: 744px;
    }
    .container.main-container
      .sidebar
      + .main-content
      .h-sidebar.sidebar-fixed {
      width: 554px;
    }
    .container.main-container
      .sidebar.menu-min
      + .main-content
      .h-sidebar.sidebar-fixed {
      width: 701px;
    }
  }
  @media (min-width: 992px) {
    .container.main-container
      .sidebar.compact
      + .main-content
      .h-sidebar.sidebar-fixed {
      width: 859px;
    }
  }
  @media (min-width: 992px) {
    .container.main-container .main-content .h-sidebar.sidebar-fixed {
      width: 964px;
    }
    .container.main-container
      .sidebar
      + .main-content
      .h-sidebar.sidebar-fixed {
      width: 774px;
    }
    .container.main-container
      .sidebar.menu-min
      + .main-content
      .h-sidebar.sidebar-fixed {
      width: 921px;
    }
  }
  @media (min-width: 1200px) {
    .container.main-container
      .sidebar.compact
      + .main-content
      .h-sidebar.sidebar-fixed {
      width: 1059px;
    }
  }
  @media (min-width: 1200px) {
    .container.main-container .main-content .h-sidebar.sidebar-fixed {
      width: 1164px;
    }
    .container.main-container
      .sidebar
      + .main-content
      .h-sidebar.sidebar-fixed {
      width: 974px;
    }
    .container.main-container
      .sidebar.menu-min
      + .main-content
      .h-sidebar.sidebar-fixed {
      width: 1121px;
    }
  }
  @media only screen and (max-width: 991px) {
    .main-container .main-content .sidebar.sidebar-fixed {
      z-index: 1026;
    }
    .navbar-fixed-top + .main-container .main-content .sidebar {
      position: fixed;
      z-index: 1026;
    }
  }
  .sidebar-scroll .sidebar-shortcuts {
    min-height: 41px;
    border-bottom: 1px solid;
  }
  .sidebar-scroll .sidebar-toggle {
    border-top: 1px solid;
  }
  .sidebar-scroll .nav-list > li:first-child {
    border-top-width: 0;
  }
  .sidebar-scroll .nav-list > li:last-child {
    border-bottom-width: 0;
  }
  .sidebar-scroll .nav-wrap .sidebar-shortcuts {
    min-height: 40px;
    border-bottom-width: 0;
  }
  .sidebar-scroll .nav-wrap .sidebar-shortcuts + .nav-list > li:first-child,
  .sidebar-scroll
    .nav-wrap
    .sidebar-shortcuts
    + .nav-wrap-up
    .nav-list
    > li:first-child {
    border-top-width: 1px;
  }
  .sidebar-scroll .nav-wrap-t .sidebar-toggle {
    border-top-width: 0;
  }
  .sidebar-scroll .nav-wrap-t .nav-list > li:last-child {
    border-bottom-width: 1px;
  }
  .sidebar .nav-wrap + .ace-scroll {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
  .sidebar .nav-wrap + .scrollout {
    right: -8px;
  }
  .sidebar .submenu.sub-scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
  .ace-scroll {
    overflow: hidden;
  }
  .scroll-content {
    position: static;
    overflow: hidden;
  }
  .scroll-disabled.ace-scroll,
  .scroll-disabled.ace-scroll .scroll-content {
    overflow: visible;
  }
  .scroll-track {
    position: absolute;
    top: auto;
    bottom: auto;
    right: 0;
    height: auto;
    background-color: #e7e7e7;
    z-index: 99;
    width: 0;
    opacity: 0;
  }
  .scroll-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    background-color: #ace;
    background: #bbd4e5;
  }
  .scroll-track.scroll-hz {
    top: auto;
    left: auto;
    right: auto;
    bottom: 0;
    height: 8px;
    width: auto;
  }
  .scroll-hz .scroll-bar {
    height: inherit;
    width: auto;
  }
  .scroll-track.scroll-active {
    -webkit-transition: width 0.25s ease 0.75s, opacity 0.25s ease 0.75s;
    -o-transition: width 0.25s ease 0.75s, opacity 0.25s ease 0.75s;
    transition: width 0.25s ease 0.75s, opacity 0.25s ease 0.75s;
  }
  .ace-scroll:hover .scroll-active,
  .scroll-active.scroll-track:hover,
  .scroll-active.scroll-track.scroll-hover,
  .scroll-active.scroll-track.active,
  .scroll-active.scroll-track:active {
    width: 8px;
    opacity: 1;
    -webkit-transition-duration: 0.15s;
    transition-duration: 0.15s;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
  }
  .scroll-track.active > .scroll-bar {
    transition-property: none !important;
    transition-duration: 0s !important;
  }
  .scroll-track.scroll-margin {
    margin-left: -1px;
  }
  .scroll-track.scroll-left {
    right: auto;
    left: 0;
  }
  .scroll-track.scroll-left.scroll-margin {
    margin-left: 1px;
  }
  .scroll-track.scroll-top {
    bottom: auto;
    top: 0;
  }
  .scroll-track.scroll-top.scroll-margin {
    top: 1px;
  }
  .scroll-dark .scroll-bar {
    background-color: transparent;
    background-color: rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#40000000',
        endColorstr='#40000000',
        GradientType=0
      );
  }
  .scroll-track.scroll-dark {
    background-color: transparent;
    background-color: rgba(0, 0, 0, 0.15);
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#26000000',
        endColorstr='#26000000',
        GradientType=0
      );
  }
  .scroll-light .scroll-bar {
    background-color: transparent;
    background-color: rgba(0, 0, 0, 0.14);
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#24000000',
        endColorstr='#24000000',
        GradientType=0
      );
  }
  .scroll-track.scroll-light {
    background-color: transparent;
    background-color: rgba(0, 0, 0, 0.07);
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#12000000',
        endColorstr='#12000000',
        GradientType=0
      );
  }
  .scroll-white .scroll-bar {
    background-color: transparent;
    background-color: rgba(255, 255, 255, 0.33);
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#55FFFFFF',
        endColorstr='#55FFFFFF',
        GradientType=0
      );
  }
  .scroll-track.scroll-white {
    background-color: transparent;
    background-color: rgba(255, 255, 255, 0.2);
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#33FFFFFF',
        endColorstr='#33FFFFFF',
        GradientType=0
      );
  }
  .no-track.scroll-track {
    background-color: transparent;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  }
  .scroll-track.scroll-visible {
    opacity: 1;
    width: 8px;
  }
  .scroll-hz.scroll-visible {
    height: 8px;
    width: auto;
  }
  .scroll-thin.scroll-track:hover,
  .scroll-thin.scroll-track.scroll-hover,
  .scroll-thin.scroll-track:active,
  .scroll-thin.scroll-track.active,
  .scroll-thin.scroll-track.scroll-visible {
    width: 6px;
  }
  .ace-scroll:hover .scroll-thin.scroll-track {
    width: 6px;
  }
  .scroll-thin.scroll-hz:hover,
  .scroll-thin.scroll-hz.scroll-hover,
  .scroll-thin.scroll-hz:active,
  .scroll-thin.scroll-hz.active,
  .scroll-thin.scroll-hz.scroll-visible {
    width: auto;
    height: 6px;
  }
  .ace-scroll:hover .scroll-thin.scroll-hz {
    width: auto;
    height: 6px;
  }
  .scroll-chrome.scroll-active .scroll-bar {
    background-color: transparent;
    width: 11px;
  }
  .scroll-chrome.scroll-active .scroll-bar:before {
    display: block;
    content: '';
    position: absolute;
    top: 1px;
    bottom: 3px;
    left: 1px;
    right: 1px;
    background-color: #d9d9d9;
    border: 1px solid #bbb;
    border-radius: 1px;
  }
  .scroll-chrome.scroll-active .scroll-track {
    width: 12px;
    background-color: #f2f2f2;
    border: 1px solid;
    border-width: 1px 0 1px 1px;
    border-color: #e6e6e6 transparent #e6e6e6 #dbdbdb;
  }
  .scroll-chrome.scroll-active .scroll-track:hover .scroll-bar:before {
    background-color: #c0c0c0;
    border-color: #a6a6a6;
  }
  .scroll-chrome.scroll-active .scroll-track.active .scroll-bar:before {
    background-color: #a9a9a9;
    border-color: #8b8b8b;
  }
  .scroll-active.scroll-track.idle-hide {
    opacity: 0;
    filter: alpha(opacity=0);
    width: 8px;
  }
  .scroll-active.scroll-track.idle-hide.not-idle {
    width: 8px;
    opacity: 1;
    filter: alpha(opacity=100);
  }
  .scroll-active.scroll-thin.scroll-track.idle-hide {
    width: 6px;
  }
  .scroll-active.scroll-chrome .scroll-track.idle-hide {
    width: 12px;
  }
  .nav-wrap + .scroll-active .scroll-track {
    width: 8px;
  }
  .nav-scroll.scroll-active .scroll-track {
    width: 8px;
    right: 0;
  }
  .nav-wrap:hover + .scroll-active .scroll-track,
  .nav-scroll.scroll-active:hover .scroll-track {
    width: 8px;
    opacity: 1;
    -webkit-transition-duration: 0.15s;
    transition-duration: 0.15s;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
  }
  .nav-scroll.scroll-active:hover .scroll-track {
    width: 8px;
  }
  .nav-scroll.ace-scroll,
  .nav-scroll.ace-scroll .scroll-content {
    overflow: hidden;
  }
  .nav-scroll.ace-scroll.scroll-disabled,
  .nav-scroll.ace-scroll.scroll-disabled .scroll-content {
    overflow: visible;
  }
  .scroll-track.scroll-detached {
    position: absolute;
    z-index: 1029;
    bottom: auto;
    right: auto;
  }
  .modal-content {
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .modal-footer {
    padding-top: 12px;
    padding-bottom: 14px;
    border-top-color: #e4e9ee;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #eff3f8;
  }
  .modal-header .close {
    font-size: 32px;
  }
  .modal-body.padding-25 {
    padding-left: 25px;
    padding-right: 25px;
  }
  .modal.aside-dark .modal-content {
    background-color: rgba(0, 0, 0, 0.85);
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#D8000000',
        endColorstr='#D8000000',
        GradientType=0
      );
  }
  .modal.aside {
    z-index: 999;
    position: absolute;
  }
  .navbar-fixed-top ~ .modal.aside-vc {
    z-index: 1025;
  }
  .modal.aside-fixed.aside-hz,
  .navbar-fixed-top ~ .modal.aside-hz,
  .navbar-fixed-bottom ~ .modal.aside-hz {
    position: fixed;
    z-index: 1049;
  }
  .modal.aside-fixed.aside-vc {
    position: fixed;
  }
  .modal.aside.in {
    z-index: 1050;
    position: fixed;
  }
  .aside.aside-contained {
    position: fixed;
  }
  .modal.aside-vc {
    margin: auto;
    width: 0;
    left: auto;
    right: auto;
    top: 0;
    bottom: 0;
    display: block !important;
    overflow: visible;
  }
  .modal.in.aside-vc {
    width: 100%;
  }
  .modal.aside-vc .modal-dialog {
    margin: inherit;
    overflow: inherit;
    width: 250px;
    max-width: 66%;
    height: inherit;
    position: inherit;
    right: inherit;
    top: inherit;
    bottom: inherit;
    left: inherit;
    opacity: 1;
    transition: transform 0.3s ease-out 0s;
    -webkit-transition: -webkit-transform 0.3s ease-out 0s;
  }
  @media only screen and (max-width: 319px) {
    .modal.aside-vc .modal-dialog {
      max-width: none;
      width: 200px;
    }
    .aside-contained.aside-vc .modal-dialog {
      width: 180px;
    }
  }
  @media only screen and (max-width: 240px) {
    .modal.aside-vc .modal-dialog {
      max-width: none;
      width: 160px;
    }
    .aside-contained.aside-vc .modal-dialog {
      width: 140px;
    }
  }
  .modal.aside-vc .modal-content {
    height: 100%;
    overflow: hidden;
  }
  .modal.in.aside-vc .modal-dialog {
    transform: none;
    -webkit-transform: none;
    height: auto;
  }
  .modal.aside-vc .aside-trigger {
    position: absolute;
    top: 155px;
    right: auto;
    left: auto;
    bottom: auto;
    margin-top: -1px;
    width: 37px;
    outline: none;
  }
  .modal.aside-vc .aside-trigger.ace-settings-btn {
    width: 42px;
  }
  .modal.in.aside-vc .aside-trigger {
    z-index: -1;
  }
  .aside.modal.in .modal-backdrop.in + .modal-dialog .aside-trigger {
    z-index: auto;
  }
  @media only screen and (max-height: 240px) {
    .modal.aside-vc .aside-trigger {
      top: 130px;
    }
  }
  .modal.aside-vc.navbar-offset .modal-dialog {
    top: 45px;
  }
  .modal.aside-vc.navbar-offset .modal-dialog .aside-trigger {
    top: 110px;
  }
  @media (max-width: 479px) {
    .navbar:not(.navbar-collapse)
      ~ .modal.aside-vc.navbar-offset
      .modal-dialog {
      top: 90px;
    }
  }
  .modal.aside-right {
    right: 0;
  }
  .modal.aside-right .modal-content {
    border-width: 0 0 0 1px;
    box-shadow: -2px 1px 2px 0 rgba(0, 0, 0, 0.15);
  }
  .modal.aside-right .aside-trigger {
    right: 100%;
  }
  .modal.aside-right .modal-dialog {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
  }
  .modal.aside-left {
    left: 0;
  }
  .modal.aside-left .modal-content {
    border-width: 0 1px 0 0;
    box-shadow: 2px -1px 2px 0 rgba(0, 0, 0, 0.15);
  }
  .modal.aside-left .aside-trigger {
    left: 100%;
  }
  .modal.aside-right .aside-trigger.btn.ace-settings-btn {
    border-radius: 6px 0 0 6px;
  }
  .modal.aside-left .aside-trigger.btn.ace-settings-btn {
    border-radius: 0 6px 6px 0;
  }
  .modal.aside-left .modal-dialog {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
  .modal.aside-hz {
    margin: auto;
    height: 0;
    left: 0;
    right: 0;
    top: auto;
    bottom: auto;
    display: block !important;
    overflow: visible;
  }
  .modal.in.aside-hz {
    height: 100%;
  }
  .modal.aside-hz .modal-dialog {
    margin: inherit;
    height: auto;
    overflow: inherit;
    max-height: 50%;
    width: inherit;
    position: inherit;
    right: inherit;
    top: inherit;
    bottom: inherit;
    left: inherit;
    opacity: 1;
    transition: transform 0.3s ease-out 0s;
    -webkit-transition: -webkit-transform 0.3s ease-out 0s;
  }
  @media only screen and (max-height: 320px) {
    .modal.aside-hz .modal-dialog {
      max-height: 66%;
    }
  }
  .modal.aside-hz .modal-content {
    width: 100%;
    overflow: hidden;
  }
  .modal.in.aside-hz .modal-dialog {
    transform: none;
    -webkit-transform: none;
    height: auto;
  }
  .modal.aside-hz .aside-trigger {
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    margin-top: -1px;
    z-index: auto;
    outline: none;
    margin-left: -15px;
    left: 50%;
  }
  .modal.aside-hz .aside-trigger.ace-settings-btn {
    margin-left: -20px;
  }
  .modal.in.aside-hz .aside-trigger {
    z-index: -1;
  }
  .modal.aside-top {
    top: 0;
  }
  .modal.aside-top .modal-dialog {
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
  }
  .modal.aside-top .modal-content {
    border-width: 0;
    box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.15);
  }
  .modal.aside-bottom {
    bottom: 0;
  }
  .modal.aside-bottom .modal-dialog {
    transform: translateY(100%);
    -webkit-transform: translateY(100%);
  }
  .modal.aside-bottom .modal-content {
    border-width: 0;
    box-shadow: -1px 2px 2px 0 rgba(0, 0, 0, 0.15);
  }
  .modal.aside-bottom .aside-trigger {
    bottom: 100%;
    margin-top: auto;
    margin-bottom: -1px;
  }
  .modal.aside-top .aside-trigger.ace-settings-btn {
    border-radius: 0 0 6px 6px !important;
  }
  .modal.aside-bottom .aside-trigger.ace-settings-btn {
    border-radius: 6px 6px 0 0 !important;
  }
  .aside.aside-hidden .modal-content {
    display: none;
  }
  @media only screen and (min-width: 768px) {
    .container.main-container ~ .modal.aside-vc .modal-dialog {
      transition: none;
      -webkit-transition: none;
    }
    .container.main-container ~ .modal.aside-vc:not(.in) .modal-content {
      display: none;
    }
  }
  .aside-vc.in.no-backdrop {
    width: auto;
  }
  .aside-hz.in.no-backdrop {
    height: auto;
  }
  .modal.aside .modal-backdrop {
    position: fixed;
    bottom: 0;
    height: auto !important;
  }
  .modal.aside-hz .aside-trigger.align-left {
    left: 15px;
    margin-left: auto;
    text-align: center;
  }
  .modal.aside-hz .aside-trigger.align-right {
    text-align: center;
    left: auto;
    margin-left: auto;
    right: 15px;
  }
  .modal.transition-off .modal-dialog {
    transition: none;
    -webkit-transition: none;
  }
  .breadcrumbs {
    position: relative;
    z-index: auto;
    border-bottom: 1px solid #e5e5e5;
    background-color: #f5f5f5;
    min-height: 41px;
    line-height: 40px;
    padding: 0 12px 0 0;
  }
  .breadcrumb {
    background-color: transparent;
    display: inline-block;
    line-height: 20px;
    margin: 6px 22px 0 12px;
    padding: 0;
    font-size: 13px;
    color: #333;
    border-radius: 0;
  }
  .breadcrumb > li,
  .breadcrumb > li.active {
    color: #555555;
    padding: 0 3px 0 3px;
  }
  .breadcrumb > li > a {
    display: inline-block;
    color: #4c8fbd;
  }
  .breadcrumb > li + li:before {
    font-family: FontAwesome;
    font-size: 14px;
    content: '\f105';
    color: #b2b6bf;
    padding: 0;
    margin: 0 8px 0 0;
    position: relative;
    top: 1px;
  }
  .breadcrumb .home-icon {
    font-size: 20px;
    margin-left: 2px;
    margin-right: 2px;
    vertical-align: top;
  }
  @media only screen and (max-width: 480px) {
    .breadcrumb > li > a {
      padding: 0 1px;
    }
  }
  @media only screen and (max-width: 991px) {
    .menu-toggler + .sidebar.responsive + .main-content .breadcrumb {
      margin-left: 90px;
    }
  }
  @media only screen and (max-width: 320px) {
    .breadcrumb {
      margin-left: 8px;
    }
    .menu-toggler + .sidebar.responsive + .main-content .breadcrumb {
      margin-left: 36px;
    }
  }
  @media (min-width: 992px) {
    .breadcrumbs-fixed {
      position: fixed;
      right: 0;
      left: 0;
      top: auto;
      z-index: 1024;
    }
    .breadcrumbs-fixed + .page-content {
      padding-top: 49px;
    }
    .sidebar + .main-content .breadcrumbs-fixed {
      left: 190px;
    }
    body.mob-safari {
      /**
	 //not needed because breadcrumbs is not fixed at this point
	 media (max-width: @screen-topbar-down) {
	  .navbar-fixed-top:not(.navbar-collapse) + .main-container .breadcrumbs-fixed {
		top: (@navbar-min-height * 2);
	  }
	 }*/
    }
    body.mob-safari .breadcrumbs-fixed {
      top: 45px;
    }
  }
  @media (min-width: 992px) {
    .sidebar.compact + .main-content .breadcrumbs-fixed {
      left: 105px;
    }
  }
  @media (min-width: 992px) {
    .sidebar.menu-min + .main-content .breadcrumbs-fixed {
      left: 43px;
    }
  }
  @media (min-width: 992px) and (max-width: 991px) {
    .breadcrumbs-fixed,
    .sidebar.menu-min + .main-content .breadcrumbs-fixed {
      left: 0;
    }
    .sidebar.responsive-min + .main-content .breadcrumbs-fixed,
    .sidebar.responsive-max + .main-content .breadcrumbs-fixed {
      left: 43px;
    }
  }
  @media (min-width: 992px) {
    .container.main-container .breadcrumbs-fixed {
      left: auto;
      right: auto;
      width: 744px;
    }
    .container.main-container .sidebar + .main-content .breadcrumbs-fixed {
      left: auto;
      right: auto;
      width: 554px;
    }
    .container.main-container
      .sidebar.compact
      + .main-content
      .breadcrumbs-fixed {
      width: 639px;
    }
    .container.main-container
      .sidebar.menu-min
      + .main-content
      .breadcrumbs-fixed {
      left: auto;
      right: auto;
      width: 701px;
    }
  }
  @media (min-width: 992px) {
    .container.main-container
      .sidebar.compact
      + .main-content
      .breadcrumbs-fixed {
      width: 859px;
    }
  }
  @media (min-width: 992px) {
    .container.main-container .breadcrumbs-fixed {
      width: 964px;
    }
    .container.main-container .sidebar + .main-content .breadcrumbs-fixed {
      width: 774px;
    }
    .container.main-container
      .sidebar.menu-min
      + .main-content
      .breadcrumbs-fixed {
      width: 921px;
    }
  }
  @media (min-width: 1200px) {
    .container.main-container
      .sidebar.compact
      + .main-content
      .breadcrumbs-fixed {
      width: 1059px;
    }
  }
  @media (min-width: 1200px) {
    .container.main-container .breadcrumbs-fixed {
      width: 1164px;
    }
    .container.main-container .sidebar + .main-content .breadcrumbs-fixed {
      width: 974px;
    }
    .container.main-container
      .sidebar.menu-min
      + .main-content
      .breadcrumbs-fixed {
      width: 1121px;
    }
  }
  @media (min-width: 992px) and (max-width: 991px) {
    .container.main-container
      .sidebar.compact
      + .main-content
      .breadcrumbs-fixed {
      width: 744px;
    }
  }
  @media (min-width: 992px) and (max-width: 991px) {
    .container.main-container .breadcrumbs-fixed,
    .container.main-container
      .sidebar.menu-min
      + .main-content
      .breadcrumbs-fixed {
      width: 744px;
    }
    .container.main-container
      .sidebar.responsive-min
      + .main-content
      .breadcrumbs-fixed,
    .container.main-container
      .sidebar.responsive-max
      + .main-content
      .breadcrumbs-fixed {
      left: auto;
      right: auto;
      width: 701px;
    }
  }
  /**
@media (max-width: @grid-float-breakpoint-max) {
 .breadcrumbs-fixed {
	position: relative;
	left: auto !important;
	right: auto !important;
	top: auto !important;
	width: auto !important;

	+ .page-content {
		padding-top: @page-content-padding-top;
	}
 }
}
*/
  @media (min-width: 992px) {
    .h-sidebar + .main-content .breadcrumbs.breadcrumbs-fixed {
      position: relative;
      top: auto;
      left: auto;
      z-index: auto;
      width: auto;
    }
    .h-sidebar + .main-content .page-content {
      padding-top: 12px;
    }
  }
  .nav-search {
    position: absolute;
    right: 22px;
    line-height: 24px;
  }
  .breadcrumbs .nav-search {
    top: 6px;
  }
  .nav-search .form-search {
    margin-bottom: 0;
  }
  .nav-search .nav-search-input {
    border: 1px solid #6fb3e0;
    width: 152px;
    height: 28px !important;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 4px !important;
    font-size: 13px;
    line-height: 1.3;
    color: #666666 !important;
    z-index: 11;
    -webkit-transition: width ease 0.15s;
    -o-transition: width ease 0.15s;
    transition: width ease 0.15s;
  }
  .nav-search .nav-search-input + .dropdown-menu {
    min-width: 0;
    left: 0;
    right: 0;
  }
  .nav-search .nav-search-input:focus,
  .nav-search .nav-search-input:hover {
    border-color: #6fb3e0;
  }
  .nav-search .nav-search-icon {
    color: #6fb3e0 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    background-color: transparent;
  }
  .nav-search.minimized .nav-search-input {
    width: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    max-width: 0;
  }
  .nav-search.minimized:hover .nav-search-input,
  .nav-search.minimized .nav-search-btn:active + .nav-search-input,
  .nav-search.minimized .nav-search-input:focus,
  .nav-search.minimized .nav-search-input:hover,
  .nav-search.minimized .nav-search-input:active {
    opacity: 1;
    filter: alpha(opacity=100);
    width: 152px;
    max-width: 152px;
  }
  .nav-search.minimized .nav-search-icon {
    border: 1px solid;
    border-radius: 100%;
    background-color: #fff;
    padding: 0 5px !important;
  }
  .nav-search.minimized:hover .nav-search-icon,
  .nav-search.minimized .nav-search-input:focus ~ .nav-search-icon,
  .nav-search.minimized .nav-search-input:hover ~ .nav-search-icon,
  .nav-search.minimized .nav-search-input:active ~ .nav-search-icon {
    border: none;
    border-radius: 0;
    padding: 0 3px !important;
  }
  .nav-search-icon {
    border: none;
    border-radius: 0;
    padding: 0 3px !important;
  }
  .sidebar > .nav-search {
    position: static;
    background-color: #fafafa;
    border-bottom: 1px solid #ddd;
    text-align: center;
    height: 35px;
    padding-top: 6px;
  }
  .sidebar > .nav-search .nav-search-input {
    width: 162px !important;
    border-radius: 0 !important;
    max-width: 162px !important;
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
  }
  .sidebar > .nav-search .nav-search-input + .dropdown-menu {
    text-align: left;
  }
  .sidebar.menu-min .nav-search .form-search {
    position: absolute;
    left: 5px;
    z-index: 14;
  }
  .sidebar.menu-min .nav-search .nav-search-input {
    width: 0 !important;
    max-width: 0 !important;
    opacity: 0 !important;
    filter: alpha(opacity=0) !important;
  }
  .sidebar.menu-min .nav-search .nav-search-input:hover,
  .sidebar.menu-min .nav-search .nav-search-input:focus,
  .sidebar.menu-min .nav-search .nav-search-input:active {
    width: 162px !important;
    max-width: 162px !important;
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
  }
  .sidebar.menu-min .nav-search .nav-search-input:hover ~ #nav-search-icon,
  .sidebar.menu-min .nav-search .nav-search-input:focus ~ #nav-search-icon,
  .sidebar.menu-min .nav-search .nav-search-input:active ~ #nav-search-icon {
    border: none;
    border-radius: 0;
    padding: 0 3px !important;
  }
  .sidebar.menu-min .nav-search:hover .nav-search-input {
    width: 162px !important;
    max-width: 162px !important;
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
  }
  .sidebar.menu-min .nav-search:hover .nav-search-input ~ .nav-search-icon {
    border: none;
    border-radius: 0;
    padding: 0 3px !important;
  }
  .sidebar.menu-min .nav-search .nav-search-icon {
    /* inside minimized sidebar */
    border: 1px solid;
    border-radius: 32px;
    background-color: #fff;
    padding: 0 5px !important;
  }
  @media (max-width: 991px) {
    .sidebar.responsive-min .nav-search .form-search {
      position: absolute;
      left: 5px;
      z-index: 14;
    }
    .sidebar.responsive-min .nav-search .nav-search-input {
      width: 0 !important;
      max-width: 0 !important;
      opacity: 0 !important;
      filter: alpha(opacity=0) !important;
    }
    .sidebar.responsive-min .nav-search .nav-search-input:hover,
    .sidebar.responsive-min .nav-search .nav-search-input:focus,
    .sidebar.responsive-min .nav-search .nav-search-input:active {
      width: 162px !important;
      max-width: 162px !important;
      opacity: 1 !important;
      filter: alpha(opacity=100) !important;
    }
    .sidebar.responsive-min
      .nav-search
      .nav-search-input:hover
      ~ #nav-search-icon,
    .sidebar.responsive-min
      .nav-search
      .nav-search-input:focus
      ~ #nav-search-icon,
    .sidebar.responsive-min
      .nav-search
      .nav-search-input:active
      ~ #nav-search-icon {
      border: none;
      border-radius: 0;
      padding: 0 3px !important;
    }
    .sidebar.responsive-min .nav-search:hover .nav-search-input {
      width: 162px !important;
      max-width: 162px !important;
      opacity: 1 !important;
      filter: alpha(opacity=100) !important;
    }
    .sidebar.responsive-min
      .nav-search:hover
      .nav-search-input
      ~ .nav-search-icon {
      border: none;
      border-radius: 0;
      padding: 0 3px !important;
    }
    .sidebar.responsive-min .nav-search .nav-search-icon {
      /* inside minimized sidebar */
      border: 1px solid;
      border-radius: 32px;
      background-color: #fff;
      padding: 0 5px !important;
    }
  }
  @media only screen and (max-width: 991px) {
    .nav-search {
      right: 10px;
    }
    .nav-search .nav-search-input {
      width: 105px;
    }
    .nav-search:hover .nav-search-input,
    .nav-search .nav-search-btn:active + .nav-search-input,
    .nav-search .nav-search-input:focus,
    .nav-search .nav-search-input:hover,
    .nav-search .nav-search-input:active {
      width: 145px;
    }
  }
  @media only screen and (max-width: 767px) {
    .nav-search .nav-search-input {
      width: 0;
      opacity: 0;
      filter: alpha(opacity=0);
      max-width: 0;
    }
    .nav-search:hover .nav-search-input,
    .nav-search .nav-search-btn:active + .nav-search-input,
    .nav-search .nav-search-input:focus,
    .nav-search .nav-search-input:hover,
    .nav-search .nav-search-input:active {
      opacity: 1;
      filter: alpha(opacity=100);
      width: 152px;
      max-width: 152px;
    }
    .nav-search .nav-search-icon {
      border: 1px solid;
      border-radius: 100%;
      background-color: #fff;
      padding: 0 5px !important;
    }
    .nav-search:hover .nav-search-icon,
    .nav-search .nav-search-input:focus ~ .nav-search-icon,
    .nav-search .nav-search-input:hover ~ .nav-search-icon,
    .nav-search .nav-search-input:active ~ .nav-search-icon {
      border: none;
      border-radius: 0;
      padding: 0 3px !important;
    }
  }
  .footer {
    padding-top: 75px;
    height: 0;
    width: 0;
  }
  .footer .footer-inner {
    text-align: center;
    position: absolute;
    z-index: auto;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .sidebar ~ .footer .footer-inner {
    left: 190px;
  }
  @media (min-width: 992px) {
    .sidebar.compact ~ .footer .footer-inner {
      left: 105px;
    }
  }
  .sidebar.menu-min ~ .footer .footer-inner {
    left: 43px;
  }
  @media (min-width: 992px) {
    .sidebar.h-sidebar ~ .footer .footer-inner {
      left: 0;
    }
  }
  @media (max-width: 991px) {
    .footer .footer-inner,
    .sidebar ~ .footer .footer-inner,
    .sidebar.compact ~ .footer .footer-inner,
    .sidebar.menu-min ~ .footer .footer-inner {
      left: 0;
    }
    .sidebar.responsive-min ~ .footer .footer-inner {
      left: 43px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .container.main-container .footer .footer-inner {
      left: auto !important;
      right: auto !important;
      margin-left: auto;
      width: 744px;
    }
  }
  @media (min-width: 992px) {
    .container.main-container .footer .footer-inner {
      left: auto !important;
      right: auto !important;
      width: 964px;
    }
  }
  @media (min-width: 1200px) {
    .container.main-container .footer .footer-inner {
      width: 1164px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .container.main-container .sidebar ~ .footer .footer-inner {
      left: auto !important;
      right: auto !important;
      margin-left: auto;
      width: 744px;
    }
  }
  @media (min-width: 992px) {
    .container.main-container .sidebar ~ .footer .footer-inner {
      left: auto !important;
      right: auto !important;
      margin-left: 190px;
      width: 774px;
    }
  }
  @media (min-width: 1200px) {
    .container.main-container .sidebar ~ .footer .footer-inner {
      margin-left: 190px;
      width: 974px;
    }
  }
  @media (min-width: 992px) {
    .container.main-container .sidebar.compact ~ .footer .footer-inner {
      margin-left: 105px;
      width: 859px;
    }
  }
  @media (min-width: 1200px) {
    .container.main-container .sidebar.compact ~ .footer .footer-inner {
      margin-left: 105px;
      width: 1059px;
    }
  }
  @media (min-width: 992px) {
    .container.main-container .sidebar.menu-min ~ .footer .footer-inner {
      margin-left: 43px;
      width: 921px;
    }
  }
  @media (min-width: 1200px) {
    .container.main-container .sidebar.menu-min ~ .footer .footer-inner {
      margin-left: 43px;
      width: 1121px;
    }
  }
  @media (min-width: 992px) {
    .container.main-container .sidebar.h-sidebar ~ .footer .footer-inner {
      margin-left: 0;
      width: 964px;
    }
  }
  @media (min-width: 992px) {
    .container.main-container .sidebar.h-sidebar ~ .footer .footer-inner {
      margin-left: 0;
      width: 964px;
    }
  }
  @media (min-width: 1200px) {
    .container.main-container .sidebar.h-sidebar ~ .footer .footer-inner {
      margin-left: 0;
      width: 1164px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .container.main-container .sidebar.responsive-min ~ .footer .footer-inner {
      margin-left: 43px;
      width: 701px;
    }
  }
  .footer .footer-inner .footer-content {
    position: absolute;
    left: 12px;
    right: 12px;
    bottom: 4px;
    padding: 8px;
    line-height: 36px;
    border-top: 3px double #e5e5e5;
  }
  .footer.footer-fixed .footer-inner {
    position: fixed;
    z-index: 999;
    bottom: 0;
  }
  .footer.footer-fixed .footer-inner .footer-content {
    left: 2px;
    right: 2px;
    bottom: 2px;
    border: 1px solid #ddd;
    background-color: #f2f2f2;
  }
  .footer.footer-fixed + .btn-scroll-up {
    z-index: 999;
  }
  .btn {
    display: inline-block;
    color: #fff !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-image: none !important;
    border: 5px solid #fff;
    border-radius: 0;
    box-shadow: none !important;
    -webkit-transition: background-color 0.15s, border-color 0.15s,
      opacity 0.15s;
    -o-transition: background-color 0.15s, border-color 0.15s, opacity 0.15s;
    transition: background-color 0.15s, border-color 0.15s, opacity 0.15s;
    cursor: pointer;
    vertical-align: middle;
    margin: 0;
    position: relative;
    /**
 &:hover, &:focus, &.focus, .open > &.dropdown-toggle {
	color: #FFF;
	box-shadow: none;
 }
 */
  }
  .btn.active,
  .open > .btn.active.dropdown-toggle {
    box-shadow: none;
  }
  .btn-lg,
  .btn-group-lg > .btn {
    border-width: 5px;
    line-height: 1.4;
    padding: 5px 16px 6px;
  }
  .btn-xlg,
  .btn-group-xlg > .btn {
    border-width: 5px;
    line-height: 1.35;
    padding: 7px 16px;
    font-size: 18px;
  }
  .btn-sm,
  .btn-group-sm > .btn {
    border-width: 4px;
    font-size: 13px;
    padding: 4px 9px;
    line-height: 1.38;
  }
  .btn-xs,
  .btn-group-xs > .btn {
    padding-top: 3px;
    padding-bottom: 3px;
    border-width: 3px;
  }
  .btn-mini,
  .btn-group-mini > .btn {
    padding: 1px 5px;
    border-width: 3px;
    font-size: 12px;
    line-height: 1.5;
  }
  .btn-minier,
  .btn-group-minier > .btn {
    padding: 0 4px;
    line-height: 18px;
    border-width: 2px;
    font-size: 12px;
  }
  button.btn:active {
    top: 1px;
  }
  .input-group-lg > .form-control,
  .input-group-lg > .input-group-addon,
  .input-group-lg > .input-group-btn > .btn {
    border-radius: 0;
  }
  .btn,
  .btn-default,
  .btn:focus,
  .btn-default:focus {
    background-color: #abbac3 !important;
    border-color: #abbac3;
  }
  .btn:hover,
  .btn-default:hover,
  .btn:active,
  .btn-default:active,
  .btn:focus:active,
  .btn-default:focus:active,
  .btn:focus:hover,
  .btn-default:focus:hover,
  .btn:active:hover,
  .btn-default:active:hover,
  .btn.active:hover,
  .btn-default.active:hover,
  .open > .btn.dropdown-toggle,
  .open > .btn-default.dropdown-toggle,
  .open > .btn.dropdown-toggle:active,
  .open > .btn-default.dropdown-toggle:active,
  .open > .btn.dropdown-toggle:hover,
  .open > .btn-default.dropdown-toggle:hover,
  .open > .btn.dropdown-toggle:focus,
  .open > .btn-default.dropdown-toggle:focus,
  .open > .btn.dropdown-toggle.active,
  .open > .btn-default.dropdown-toggle.active {
    background-color: #8b9aa3 !important;
    border-color: #abbac3;
  }
  .btn.no-border:hover,
  .btn-default.no-border:hover,
  .btn.no-border:active,
  .btn-default.no-border:active {
    border-color: #8b9aa3;
  }
  .btn.no-hover:hover,
  .btn-default.no-hover:hover,
  .btn.no-hover:active,
  .btn-default.no-hover:active {
    background-color: #abbac3 !important;
  }
  .btn.active,
  .btn-default.active {
    background-color: #9baab3 !important;
    border-color: #8799a4;
  }
  .btn.no-border.active,
  .btn-default.no-border.active {
    background-color: #92a3ac !important;
    border-color: #92a3ac;
  }
  .btn.disabled,
  .btn-default.disabled,
  .btn[disabled],
  .btn-default[disabled],
  fieldset[disabled] .btn,
  fieldset[disabled] .btn-default,
  .btn.disabled:hover,
  .btn-default.disabled:hover,
  .btn[disabled]:hover,
  .btn-default[disabled]:hover,
  fieldset[disabled] .btn:hover,
  fieldset[disabled] .btn-default:hover,
  .btn.disabled:focus,
  .btn-default.disabled:focus,
  .btn[disabled]:focus,
  .btn-default[disabled]:focus,
  fieldset[disabled] .btn:focus,
  fieldset[disabled] .btn-default:focus,
  .btn.disabled:active,
  .btn-default.disabled:active,
  .btn[disabled]:active,
  .btn-default[disabled]:active,
  fieldset[disabled] .btn:active,
  fieldset[disabled] .btn-default:active,
  .btn.disabled.active,
  .btn-default.disabled.active,
  .btn[disabled].active,
  .btn-default[disabled].active,
  fieldset[disabled] .btn.active,
  fieldset[disabled] .btn-default.active {
    background-color: #abbac3 !important;
    border-color: #abbac3;
  }
  .btn-primary,
  .btn-primary:focus {
    background-color: #428bca !important;
    border-color: #428bca;
  }
  .btn-primary:hover,
  .btn-primary:active,
  .btn-primary:focus:active,
  .btn-primary:focus:hover,
  .btn-primary:active:hover,
  .btn-primary.active:hover,
  .open > .btn-primary.dropdown-toggle,
  .open > .btn-primary.dropdown-toggle:active,
  .open > .btn-primary.dropdown-toggle:hover,
  .open > .btn-primary.dropdown-toggle:focus,
  .open > .btn-primary.dropdown-toggle.active {
    background-color: #1b6aaa !important;
    border-color: #428bca;
  }
  .btn-primary.no-border:hover,
  .btn-primary.no-border:active {
    border-color: #1b6aaa;
  }
  .btn-primary.no-hover:hover,
  .btn-primary.no-hover:active {
    background-color: #428bca !important;
  }
  .btn-primary.active {
    background-color: #2f7bba !important;
    border-color: #27689d;
  }
  .btn-primary.no-border.active {
    background-color: #2b72ae !important;
    border-color: #2b72ae;
  }
  .btn-primary.disabled,
  .btn-primary[disabled],
  fieldset[disabled] .btn-primary,
  .btn-primary.disabled:hover,
  .btn-primary[disabled]:hover,
  fieldset[disabled] .btn-primary:hover,
  .btn-primary.disabled:focus,
  .btn-primary[disabled]:focus,
  fieldset[disabled] .btn-primary:focus,
  .btn-primary.disabled:active,
  .btn-primary[disabled]:active,
  fieldset[disabled] .btn-primary:active,
  .btn-primary.disabled.active,
  .btn-primary[disabled].active,
  fieldset[disabled] .btn-primary.active {
    background-color: #428bca !important;
    border-color: #428bca;
  }
  .btn-info,
  .btn-info:focus {
    background-color: #6fb3e0 !important;
    border-color: #6fb3e0;
  }
  .btn-info:hover,
  .btn-info:active,
  .btn-info:focus:active,
  .btn-info:focus:hover,
  .btn-info:active:hover,
  .btn-info.active:hover,
  .open > .btn-info.dropdown-toggle,
  .open > .btn-info.dropdown-toggle:active,
  .open > .btn-info.dropdown-toggle:hover,
  .open > .btn-info.dropdown-toggle:focus,
  .open > .btn-info.dropdown-toggle.active {
    background-color: #4f99c6 !important;
    border-color: #6fb3e0;
  }
  .btn-info.no-border:hover,
  .btn-info.no-border:active {
    border-color: #4f99c6;
  }
  .btn-info.no-hover:hover,
  .btn-info.no-hover:active {
    background-color: #6fb3e0 !important;
  }
  .btn-info.active {
    background-color: #5fa6d3 !important;
    border-color: #4396cb;
  }
  .btn-info.no-border.active {
    background-color: #539fd0 !important;
    border-color: #539fd0;
  }
  .btn-info.disabled,
  .btn-info[disabled],
  fieldset[disabled] .btn-info,
  .btn-info.disabled:hover,
  .btn-info[disabled]:hover,
  fieldset[disabled] .btn-info:hover,
  .btn-info.disabled:focus,
  .btn-info[disabled]:focus,
  fieldset[disabled] .btn-info:focus,
  .btn-info.disabled:active,
  .btn-info[disabled]:active,
  fieldset[disabled] .btn-info:active,
  .btn-info.disabled.active,
  .btn-info[disabled].active,
  fieldset[disabled] .btn-info.active {
    background-color: #6fb3e0 !important;
    border-color: #6fb3e0;
  }
  .btn-info2,
  .btn-info2:focus {
    background-color: #95c6e5 !important;
    border-color: #95c6e5;
  }
  .btn-info2:hover,
  .btn-info2:active,
  .btn-info2:focus:active,
  .btn-info2:focus:hover,
  .btn-info2:active:hover,
  .btn-info2.active:hover,
  .open > .btn-info2.dropdown-toggle,
  .open > .btn-info2.dropdown-toggle:active,
  .open > .btn-info2.dropdown-toggle:hover,
  .open > .btn-info2.dropdown-toggle:focus,
  .open > .btn-info2.dropdown-toggle.active {
    background-color: #67a6ce !important;
    border-color: #95c6e5;
  }
  .btn-info2.no-border:hover,
  .btn-info2.no-border:active {
    border-color: #67a6ce;
  }
  .btn-info2.no-hover:hover,
  .btn-info2.no-hover:active {
    background-color: #95c6e5 !important;
  }
  .btn-info2.active {
    background-color: #7eb6da !important;
    border-color: #62a6d1;
  }
  .btn-info2.no-border.active {
    background-color: #72afd6 !important;
    border-color: #72afd6;
  }
  .btn-info2.disabled,
  .btn-info2[disabled],
  fieldset[disabled] .btn-info2,
  .btn-info2.disabled:hover,
  .btn-info2[disabled]:hover,
  fieldset[disabled] .btn-info2:hover,
  .btn-info2.disabled:focus,
  .btn-info2[disabled]:focus,
  fieldset[disabled] .btn-info2:focus,
  .btn-info2.disabled:active,
  .btn-info2[disabled]:active,
  fieldset[disabled] .btn-info2:active,
  .btn-info2.disabled.active,
  .btn-info2[disabled].active,
  fieldset[disabled] .btn-info2.active {
    background-color: #95c6e5 !important;
    border-color: #95c6e5;
  }
  .btn-success,
  .btn-success:focus {
    background-color: #87b87f !important;
    border-color: #87b87f;
  }
  .btn-success:hover,
  .btn-success:active,
  .btn-success:focus:active,
  .btn-success:focus:hover,
  .btn-success:active:hover,
  .btn-success.active:hover,
  .open > .btn-success.dropdown-toggle,
  .open > .btn-success.dropdown-toggle:active,
  .open > .btn-success.dropdown-toggle:hover,
  .open > .btn-success.dropdown-toggle:focus,
  .open > .btn-success.dropdown-toggle.active {
    background-color: #629b58 !important;
    border-color: #87b87f;
  }
  .btn-success.no-border:hover,
  .btn-success.no-border:active {
    border-color: #629b58;
  }
  .btn-success.no-hover:hover,
  .btn-success.no-hover:active {
    background-color: #87b87f !important;
  }
  .btn-success.active {
    background-color: #75aa6c !important;
    border-color: #629959;
  }
  .btn-success.no-border.active {
    background-color: #6ba462 !important;
    border-color: #6ba462;
  }
  .btn-success.disabled,
  .btn-success[disabled],
  fieldset[disabled] .btn-success,
  .btn-success.disabled:hover,
  .btn-success[disabled]:hover,
  fieldset[disabled] .btn-success:hover,
  .btn-success.disabled:focus,
  .btn-success[disabled]:focus,
  fieldset[disabled] .btn-success:focus,
  .btn-success.disabled:active,
  .btn-success[disabled]:active,
  fieldset[disabled] .btn-success:active,
  .btn-success.disabled.active,
  .btn-success[disabled].active,
  fieldset[disabled] .btn-success.active {
    background-color: #87b87f !important;
    border-color: #87b87f;
  }
  .btn-warning,
  .btn-warning:focus {
    background-color: #ffb752 !important;
    border-color: #ffb752;
  }
  .btn-warning:hover,
  .btn-warning:active,
  .btn-warning:focus:active,
  .btn-warning:focus:hover,
  .btn-warning:active:hover,
  .btn-warning.active:hover,
  .open > .btn-warning.dropdown-toggle,
  .open > .btn-warning.dropdown-toggle:active,
  .open > .btn-warning.dropdown-toggle:hover,
  .open > .btn-warning.dropdown-toggle:focus,
  .open > .btn-warning.dropdown-toggle.active {
    background-color: #e59729 !important;
    border-color: #ffb752;
  }
  .btn-warning.no-border:hover,
  .btn-warning.no-border:active {
    border-color: #e59729;
  }
  .btn-warning.no-hover:hover,
  .btn-warning.no-hover:active {
    background-color: #ffb752 !important;
  }
  .btn-warning.active {
    background-color: #f2a73e !important;
    border-color: #f0981c;
  }
  .btn-warning.no-border.active {
    background-color: #f1a02f !important;
    border-color: #f1a02f;
  }
  .btn-warning.disabled,
  .btn-warning[disabled],
  fieldset[disabled] .btn-warning,
  .btn-warning.disabled:hover,
  .btn-warning[disabled]:hover,
  fieldset[disabled] .btn-warning:hover,
  .btn-warning.disabled:focus,
  .btn-warning[disabled]:focus,
  fieldset[disabled] .btn-warning:focus,
  .btn-warning.disabled:active,
  .btn-warning[disabled]:active,
  fieldset[disabled] .btn-warning:active,
  .btn-warning.disabled.active,
  .btn-warning[disabled].active,
  fieldset[disabled] .btn-warning.active {
    background-color: #ffb752 !important;
    border-color: #ffb752;
  }
  .btn-danger,
  .btn-danger:focus {
    background-color: #d15b47 !important;
    border-color: #d15b47;
  }
  .btn-danger:hover,
  .btn-danger:active,
  .btn-danger:focus:active,
  .btn-danger:focus:hover,
  .btn-danger:active:hover,
  .btn-danger.active:hover,
  .open > .btn-danger.dropdown-toggle,
  .open > .btn-danger.dropdown-toggle:active,
  .open > .btn-danger.dropdown-toggle:hover,
  .open > .btn-danger.dropdown-toggle:focus,
  .open > .btn-danger.dropdown-toggle.active {
    background-color: #b74635 !important;
    border-color: #d15b47;
  }
  .btn-danger.no-border:hover,
  .btn-danger.no-border:active {
    border-color: #b74635;
  }
  .btn-danger.no-hover:hover,
  .btn-danger.no-hover:active {
    background-color: #d15b47 !important;
  }
  .btn-danger.active {
    background-color: #c4513e !important;
    border-color: #aa4434;
  }
  .btn-danger.no-border.active {
    background-color: #ba4b39 !important;
    border-color: #ba4b39;
  }
  .btn-danger.disabled,
  .btn-danger[disabled],
  fieldset[disabled] .btn-danger,
  .btn-danger.disabled:hover,
  .btn-danger[disabled]:hover,
  fieldset[disabled] .btn-danger:hover,
  .btn-danger.disabled:focus,
  .btn-danger[disabled]:focus,
  fieldset[disabled] .btn-danger:focus,
  .btn-danger.disabled:active,
  .btn-danger[disabled]:active,
  fieldset[disabled] .btn-danger:active,
  .btn-danger.disabled.active,
  .btn-danger[disabled].active,
  fieldset[disabled] .btn-danger.active {
    background-color: #d15b47 !important;
    border-color: #d15b47;
  }
  .btn-inverse,
  .btn-inverse:focus {
    background-color: #555555 !important;
    border-color: #555555;
  }
  .btn-inverse:hover,
  .btn-inverse:active,
  .btn-inverse:focus:active,
  .btn-inverse:focus:hover,
  .btn-inverse:active:hover,
  .btn-inverse.active:hover,
  .open > .btn-inverse.dropdown-toggle,
  .open > .btn-inverse.dropdown-toggle:active,
  .open > .btn-inverse.dropdown-toggle:hover,
  .open > .btn-inverse.dropdown-toggle:focus,
  .open > .btn-inverse.dropdown-toggle.active {
    background-color: #303030 !important;
    border-color: #555555;
  }
  .btn-inverse.no-border:hover,
  .btn-inverse.no-border:active {
    border-color: #303030;
  }
  .btn-inverse.no-hover:hover,
  .btn-inverse.no-hover:active {
    background-color: #555555 !important;
  }
  .btn-inverse.active {
    background-color: #434343 !important;
    border-color: #313131;
  }
  .btn-inverse.no-border.active {
    background-color: #3b3b3b !important;
    border-color: #3b3b3b;
  }
  .btn-inverse.disabled,
  .btn-inverse[disabled],
  fieldset[disabled] .btn-inverse,
  .btn-inverse.disabled:hover,
  .btn-inverse[disabled]:hover,
  fieldset[disabled] .btn-inverse:hover,
  .btn-inverse.disabled:focus,
  .btn-inverse[disabled]:focus,
  fieldset[disabled] .btn-inverse:focus,
  .btn-inverse.disabled:active,
  .btn-inverse[disabled]:active,
  fieldset[disabled] .btn-inverse:active,
  .btn-inverse.disabled.active,
  .btn-inverse[disabled].active,
  fieldset[disabled] .btn-inverse.active {
    background-color: #555555 !important;
    border-color: #555555;
  }
  .btn-pink,
  .btn-pink:focus {
    background-color: #d6487e !important;
    border-color: #d6487e;
  }
  .btn-pink:hover,
  .btn-pink:active,
  .btn-pink:focus:active,
  .btn-pink:focus:hover,
  .btn-pink:active:hover,
  .btn-pink.active:hover,
  .open > .btn-pink.dropdown-toggle,
  .open > .btn-pink.dropdown-toggle:active,
  .open > .btn-pink.dropdown-toggle:hover,
  .open > .btn-pink.dropdown-toggle:focus,
  .open > .btn-pink.dropdown-toggle.active {
    background-color: #b73766 !important;
    border-color: #d6487e;
  }
  .btn-pink.no-border:hover,
  .btn-pink.no-border:active {
    border-color: #b73766;
  }
  .btn-pink.no-hover:hover,
  .btn-pink.no-hover:active {
    background-color: #d6487e !important;
  }
  .btn-pink.active {
    background-color: #c74072 !important;
    border-color: #af3462;
  }
  .btn-pink.no-border.active {
    background-color: #be386a !important;
    border-color: #be386a;
  }
  .btn-pink.disabled,
  .btn-pink[disabled],
  fieldset[disabled] .btn-pink,
  .btn-pink.disabled:hover,
  .btn-pink[disabled]:hover,
  fieldset[disabled] .btn-pink:hover,
  .btn-pink.disabled:focus,
  .btn-pink[disabled]:focus,
  fieldset[disabled] .btn-pink:focus,
  .btn-pink.disabled:active,
  .btn-pink[disabled]:active,
  fieldset[disabled] .btn-pink:active,
  .btn-pink.disabled.active,
  .btn-pink[disabled].active,
  fieldset[disabled] .btn-pink.active {
    background-color: #d6487e !important;
    border-color: #d6487e;
  }
  .btn-purple,
  .btn-purple:focus {
    background-color: #9585bf !important;
    border-color: #9585bf;
  }
  .btn-purple:hover,
  .btn-purple:active,
  .btn-purple:focus:active,
  .btn-purple:focus:hover,
  .btn-purple:active:hover,
  .btn-purple.active:hover,
  .open > .btn-purple.dropdown-toggle,
  .open > .btn-purple.dropdown-toggle:active,
  .open > .btn-purple.dropdown-toggle:hover,
  .open > .btn-purple.dropdown-toggle:focus,
  .open > .btn-purple.dropdown-toggle.active {
    background-color: #7461aa !important;
    border-color: #9585bf;
  }
  .btn-purple.no-border:hover,
  .btn-purple.no-border:active {
    border-color: #7461aa;
  }
  .btn-purple.no-hover:hover,
  .btn-purple.no-hover:active {
    background-color: #9585bf !important;
  }
  .btn-purple.active {
    background-color: #8573b5 !important;
    border-color: #705ca8;
  }
  .btn-purple.no-border.active {
    background-color: #7c69af !important;
    border-color: #7c69af;
  }
  .btn-purple.disabled,
  .btn-purple[disabled],
  fieldset[disabled] .btn-purple,
  .btn-purple.disabled:hover,
  .btn-purple[disabled]:hover,
  fieldset[disabled] .btn-purple:hover,
  .btn-purple.disabled:focus,
  .btn-purple[disabled]:focus,
  fieldset[disabled] .btn-purple:focus,
  .btn-purple.disabled:active,
  .btn-purple[disabled]:active,
  fieldset[disabled] .btn-purple:active,
  .btn-purple.disabled.active,
  .btn-purple[disabled].active,
  fieldset[disabled] .btn-purple.active {
    background-color: #9585bf !important;
    border-color: #9585bf;
  }
  .btn-grey,
  .btn-grey:focus {
    background-color: #a0a0a0 !important;
    border-color: #a0a0a0;
  }
  .btn-grey:hover,
  .btn-grey:active,
  .btn-grey:focus:active,
  .btn-grey:focus:hover,
  .btn-grey:active:hover,
  .btn-grey.active:hover,
  .open > .btn-grey.dropdown-toggle,
  .open > .btn-grey.dropdown-toggle:active,
  .open > .btn-grey.dropdown-toggle:hover,
  .open > .btn-grey.dropdown-toggle:focus,
  .open > .btn-grey.dropdown-toggle.active {
    background-color: #888888 !important;
    border-color: #a0a0a0;
  }
  .btn-grey.no-border:hover,
  .btn-grey.no-border:active {
    border-color: #888888;
  }
  .btn-grey.no-hover:hover,
  .btn-grey.no-hover:active {
    background-color: #a0a0a0 !important;
  }
  .btn-grey.active {
    background-color: #949494 !important;
    border-color: #828282;
  }
  .btn-grey.no-border.active {
    background-color: #8c8c8c !important;
    border-color: #8c8c8c;
  }
  .btn-grey.disabled,
  .btn-grey[disabled],
  fieldset[disabled] .btn-grey,
  .btn-grey.disabled:hover,
  .btn-grey[disabled]:hover,
  fieldset[disabled] .btn-grey:hover,
  .btn-grey.disabled:focus,
  .btn-grey[disabled]:focus,
  fieldset[disabled] .btn-grey:focus,
  .btn-grey.disabled:active,
  .btn-grey[disabled]:active,
  fieldset[disabled] .btn-grey:active,
  .btn-grey.disabled.active,
  .btn-grey[disabled].active,
  fieldset[disabled] .btn-grey.active {
    background-color: #a0a0a0 !important;
    border-color: #a0a0a0;
  }
  .btn-yellow {
    color: #996633 !important;
    text-shadow: 0 -1px 0 rgba(255, 255, 255, 0.4) !important;
  }
  .btn-yellow,
  .btn-yellow:focus {
    background-color: #fee188 !important;
    border-color: #fee188;
  }
  .btn-yellow:hover,
  .btn-yellow:active,
  .btn-yellow:focus:active,
  .btn-yellow:focus:hover,
  .btn-yellow:active:hover,
  .btn-yellow.active:hover,
  .open > .btn-yellow.dropdown-toggle,
  .open > .btn-yellow.dropdown-toggle:active,
  .open > .btn-yellow.dropdown-toggle:hover,
  .open > .btn-yellow.dropdown-toggle:focus,
  .open > .btn-yellow.dropdown-toggle.active {
    background-color: #f7d05b !important;
    border-color: #fee188;
  }
  .btn-yellow.no-border:hover,
  .btn-yellow.no-border:active {
    border-color: #f7d05b;
  }
  .btn-yellow.no-hover:hover,
  .btn-yellow.no-hover:active {
    background-color: #fee188 !important;
  }
  .btn-yellow.active {
    background-color: #fbd972 !important;
    border-color: #f9cf4f;
  }
  .btn-yellow.no-border.active {
    background-color: #fad463 !important;
    border-color: #fad463;
  }
  .btn-yellow.disabled,
  .btn-yellow[disabled],
  fieldset[disabled] .btn-yellow,
  .btn-yellow.disabled:hover,
  .btn-yellow[disabled]:hover,
  fieldset[disabled] .btn-yellow:hover,
  .btn-yellow.disabled:focus,
  .btn-yellow[disabled]:focus,
  fieldset[disabled] .btn-yellow:focus,
  .btn-yellow.disabled:active,
  .btn-yellow[disabled]:active,
  fieldset[disabled] .btn-yellow:active,
  .btn-yellow.disabled.active,
  .btn-yellow[disabled].active,
  fieldset[disabled] .btn-yellow.active {
    background-color: #fee188 !important;
    border-color: #fee188;
  }
  .btn-yellow:hover,
  .btn-yellow:focus,
  .btn-yellow.focus,
  .open > .btn-yellow.dropdown-toggle,
  .btn-yellow.active,
  .open > .btn-yellow.active.dropdown-toggle {
    color: #996633;
  }
  .btn-light {
    color: #888888 !important;
    text-shadow: 0 -1px 0 rgba(250, 250, 250, 0.25) !important;
  }
  .btn-light,
  .btn-light:focus {
    background-color: #e7e7e7 !important;
    border-color: #e7e7e7;
  }
  .btn-light:hover,
  .btn-light:active,
  .btn-light:focus:active,
  .btn-light:focus:hover,
  .btn-light:active:hover,
  .btn-light.active:hover,
  .open > .btn-light.dropdown-toggle,
  .open > .btn-light.dropdown-toggle:active,
  .open > .btn-light.dropdown-toggle:hover,
  .open > .btn-light.dropdown-toggle:focus,
  .open > .btn-light.dropdown-toggle.active {
    background-color: #d9d9d9 !important;
    border-color: #e7e7e7;
  }
  .btn-light.no-border:hover,
  .btn-light.no-border:active {
    border-color: #d9d9d9;
  }
  .btn-light.no-hover:hover,
  .btn-light.no-hover:active {
    background-color: #e7e7e7 !important;
  }
  .btn-light.active {
    background-color: #e0e0e0 !important;
    border-color: #cecece;
  }
  .btn-light.no-border.active {
    background-color: #d8d8d8 !important;
    border-color: #d8d8d8;
  }
  .btn-light.disabled,
  .btn-light[disabled],
  fieldset[disabled] .btn-light,
  .btn-light.disabled:hover,
  .btn-light[disabled]:hover,
  fieldset[disabled] .btn-light:hover,
  .btn-light.disabled:focus,
  .btn-light[disabled]:focus,
  fieldset[disabled] .btn-light:focus,
  .btn-light.disabled:active,
  .btn-light[disabled]:active,
  fieldset[disabled] .btn-light:active,
  .btn-light.disabled.active,
  .btn-light[disabled].active,
  fieldset[disabled] .btn-light.active {
    background-color: #e7e7e7 !important;
    border-color: #e7e7e7;
  }
  .btn-light:hover,
  .btn-light:focus,
  .btn-light.focus,
  .open > .btn-light.dropdown-toggle,
  .btn-light.active,
  .open > .btn-light.active.dropdown-toggle {
    color: #888888;
  }
  .btn-light.btn-xs:after,
  .btn-light.btn-mini:after {
    left: -2px;
    right: -2px;
    top: -2px;
    bottom: -2px;
  }
  .btn-light.btn-sm:after {
    left: -4px;
    right: -4px;
    top: -4px;
    bottom: -4px;
  }
  .btn-light .btn-lg:after {
    left: -6px;
    right: -6px;
    top: -6px;
    bottom: -6px;
  }
  .btn.btn-white {
    text-shadow: none !important;
    background-color: #fff !important;
  }
  .btn.btn-white.no-hover:hover,
  .btn.btn-white.no-hover:active {
    background-color: #fff !important;
  }
  .btn.btn-white:focus,
  .btn.btn-white.active {
    box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1) !important;
  }
  .btn.btn-white:focus.btn-bold,
  .btn.btn-white.active.btn-bold {
    box-shadow: inset 1px 1px 3px 0 rgba(0, 0, 0, 0.15) !important;
  }
  .btn.btn-white.active:after {
    display: none;
  }
  .btn.btn-white {
    border-color: #cccccc;
    color: #444444 !important;
    /**
 //&:hover, &:focus, &.focus, .open > &.dropdown-toggle {
	//color: saturate(darken(@txt-color , 5%) , 5%) !important;
 //}
 //&.no-border:hover , &.no-border:active {
	//border-color: @border-color;
 //}
 */
  }
  .btn.btn-white:hover,
  .btn.btn-white:focus,
  .btn.btn-white.active,
  .btn.btn-white:active,
  .open > .btn.btn-white.dropdown-toggle,
  .open > .btn.btn-white.active.dropdown-toggle {
    background-color: #ebebeb !important;
    border-color: #cccccc;
    color: #3a3434 !important;
  }
  .btn.btn-white:hover.no-border,
  .btn.btn-white:focus.no-border,
  .btn.btn-white.active.no-border,
  .btn.btn-white:active.no-border,
  .open > .btn.btn-white.dropdown-toggle.no-border,
  .open > .btn.btn-white.active.dropdown-toggle.no-border {
    border-color: #cccccc;
  }
  .btn.btn-white.disabled,
  .btn.btn-white[disabled],
  fieldset[disabled] .btn.btn-white,
  .btn.btn-white.disabled:hover,
  .btn.btn-white[disabled]:hover,
  fieldset[disabled] .btn.btn-white:hover,
  .btn.btn-white.disabled:focus,
  .btn.btn-white[disabled]:focus,
  fieldset[disabled] .btn.btn-white:focus,
  .btn.btn-white.disabled:active,
  .btn.btn-white[disabled]:active,
  fieldset[disabled] .btn.btn-white:active,
  .btn.btn-white.disabled.active,
  .btn.btn-white[disabled].active,
  fieldset[disabled] .btn.btn-white.active {
    border-color: #cccccc;
  }
  .btn-white.btn-default {
    border-color: #abbac3;
    color: #80909a !important;
    /**
 //&:hover, &:focus, &.focus, .open > &.dropdown-toggle {
	//color: saturate(darken(@txt-color , 5%) , 5%) !important;
 //}
 //&.no-border:hover , &.no-border:active {
	//border-color: @border-color;
 //}
 */
  }
  .btn-white.btn-default:hover,
  .btn-white.btn-default:focus,
  .btn-white.btn-default.active,
  .btn-white.btn-default:active,
  .open > .btn-white.btn-default.dropdown-toggle,
  .open > .btn-white.btn-default.active.dropdown-toggle {
    background-color: #eff2f4 !important;
    border-color: #abbac3;
    color: #6b8595 !important;
  }
  .btn-white.btn-default:hover.no-border,
  .btn-white.btn-default:focus.no-border,
  .btn-white.btn-default.active.no-border,
  .btn-white.btn-default:active.no-border,
  .open > .btn-white.btn-default.dropdown-toggle.no-border,
  .open > .btn-white.btn-default.active.dropdown-toggle.no-border {
    border-color: #abbac3;
  }
  .btn-white.btn-default.disabled,
  .btn-white.btn-default[disabled],
  fieldset[disabled] .btn-white.btn-default,
  .btn-white.btn-default.disabled:hover,
  .btn-white.btn-default[disabled]:hover,
  fieldset[disabled] .btn-white.btn-default:hover,
  .btn-white.btn-default.disabled:focus,
  .btn-white.btn-default[disabled]:focus,
  fieldset[disabled] .btn-white.btn-default:focus,
  .btn-white.btn-default.disabled:active,
  .btn-white.btn-default[disabled]:active,
  fieldset[disabled] .btn-white.btn-default:active,
  .btn-white.btn-default.disabled.active,
  .btn-white.btn-default[disabled].active,
  fieldset[disabled] .btn-white.btn-default.active {
    border-color: #abbac3;
  }
  .btn-white.btn-primary {
    border-color: #8aafce;
    color: #6688a6 !important;
    /**
 //&:hover, &:focus, &.focus, .open > &.dropdown-toggle {
	//color: saturate(darken(@txt-color , 5%) , 5%) !important;
 //}
 //&.no-border:hover , &.no-border:active {
	//border-color: @border-color;
 //}
 */
  }
  .btn-white.btn-primary:hover,
  .btn-white.btn-primary:focus,
  .btn-white.btn-primary.active,
  .btn-white.btn-primary:active,
  .open > .btn-white.btn-primary.dropdown-toggle,
  .open > .btn-white.btn-primary.active.dropdown-toggle {
    background-color: #eaf2f8 !important;
    border-color: #8aafce;
    color: #537c9f !important;
  }
  .btn-white.btn-primary:hover.no-border,
  .btn-white.btn-primary:focus.no-border,
  .btn-white.btn-primary.active.no-border,
  .btn-white.btn-primary:active.no-border,
  .open > .btn-white.btn-primary.dropdown-toggle.no-border,
  .open > .btn-white.btn-primary.active.dropdown-toggle.no-border {
    border-color: #8aafce;
  }
  .btn-white.btn-primary.disabled,
  .btn-white.btn-primary[disabled],
  fieldset[disabled] .btn-white.btn-primary,
  .btn-white.btn-primary.disabled:hover,
  .btn-white.btn-primary[disabled]:hover,
  fieldset[disabled] .btn-white.btn-primary:hover,
  .btn-white.btn-primary.disabled:focus,
  .btn-white.btn-primary[disabled]:focus,
  fieldset[disabled] .btn-white.btn-primary:focus,
  .btn-white.btn-primary.disabled:active,
  .btn-white.btn-primary[disabled]:active,
  fieldset[disabled] .btn-white.btn-primary:active,
  .btn-white.btn-primary.disabled.active,
  .btn-white.btn-primary[disabled].active,
  fieldset[disabled] .btn-white.btn-primary.active {
    border-color: #8aafce;
  }
  .btn-white.btn-success {
    border-color: #a7c9a1;
    color: #81a87b !important;
    /**
 //&:hover, &:focus, &.focus, .open > &.dropdown-toggle {
	//color: saturate(darken(@txt-color , 5%) , 5%) !important;
 //}
 //&.no-border:hover , &.no-border:active {
	//border-color: @border-color;
 //}
 */
  }
  .btn-white.btn-success:hover,
  .btn-white.btn-success:focus,
  .btn-white.btn-success.active,
  .btn-white.btn-success:active,
  .open > .btn-white.btn-success.dropdown-toggle,
  .open > .btn-white.btn-success.active.dropdown-toggle {
    background-color: #edf4eb !important;
    border-color: #a7c9a1;
    color: #6ea465 !important;
  }
  .btn-white.btn-success:hover.no-border,
  .btn-white.btn-success:focus.no-border,
  .btn-white.btn-success.active.no-border,
  .btn-white.btn-success:active.no-border,
  .open > .btn-white.btn-success.dropdown-toggle.no-border,
  .open > .btn-white.btn-success.active.dropdown-toggle.no-border {
    border-color: #a7c9a1;
  }
  .btn-white.btn-success.disabled,
  .btn-white.btn-success[disabled],
  fieldset[disabled] .btn-white.btn-success,
  .btn-white.btn-success.disabled:hover,
  .btn-white.btn-success[disabled]:hover,
  fieldset[disabled] .btn-white.btn-success:hover,
  .btn-white.btn-success.disabled:focus,
  .btn-white.btn-success[disabled]:focus,
  fieldset[disabled] .btn-white.btn-success:focus,
  .btn-white.btn-success.disabled:active,
  .btn-white.btn-success[disabled]:active,
  fieldset[disabled] .btn-white.btn-success:active,
  .btn-white.btn-success.disabled.active,
  .btn-white.btn-success[disabled].active,
  fieldset[disabled] .btn-white.btn-success.active {
    border-color: #a7c9a1;
  }
  .btn-white.btn-danger {
    border-color: #d7a59d;
    color: #b7837a !important;
    /**
 //&:hover, &:focus, &.focus, .open > &.dropdown-toggle {
	//color: saturate(darken(@txt-color , 5%) , 5%) !important;
 //}
 //&.no-border:hover , &.no-border:active {
	//border-color: @border-color;
 //}
 */
  }
  .btn-white.btn-danger:hover,
  .btn-white.btn-danger:focus,
  .btn-white.btn-danger.active,
  .btn-white.btn-danger:active,
  .open > .btn-white.btn-danger.dropdown-toggle,
  .open > .btn-white.btn-danger.active.dropdown-toggle {
    background-color: #fbf4f3 !important;
    border-color: #d7a59d;
    color: #b46f64 !important;
  }
  .btn-white.btn-danger:hover.no-border,
  .btn-white.btn-danger:focus.no-border,
  .btn-white.btn-danger.active.no-border,
  .btn-white.btn-danger:active.no-border,
  .open > .btn-white.btn-danger.dropdown-toggle.no-border,
  .open > .btn-white.btn-danger.active.dropdown-toggle.no-border {
    border-color: #d7a59d;
  }
  .btn-white.btn-danger.disabled,
  .btn-white.btn-danger[disabled],
  fieldset[disabled] .btn-white.btn-danger,
  .btn-white.btn-danger.disabled:hover,
  .btn-white.btn-danger[disabled]:hover,
  fieldset[disabled] .btn-white.btn-danger:hover,
  .btn-white.btn-danger.disabled:focus,
  .btn-white.btn-danger[disabled]:focus,
  fieldset[disabled] .btn-white.btn-danger:focus,
  .btn-white.btn-danger.disabled:active,
  .btn-white.btn-danger[disabled]:active,
  fieldset[disabled] .btn-white.btn-danger:active,
  .btn-white.btn-danger.disabled.active,
  .btn-white.btn-danger[disabled].active,
  fieldset[disabled] .btn-white.btn-danger.active {
    border-color: #d7a59d;
  }
  .btn-white.btn-warning {
    border-color: #e7b979;
    color: #daa458 !important;
    /**
 //&:hover, &:focus, &.focus, .open > &.dropdown-toggle {
	//color: saturate(darken(@txt-color , 5%) , 5%) !important;
 //}
 //&.no-border:hover , &.no-border:active {
	//border-color: @border-color;
 //}
 */
  }
  .btn-white.btn-warning:hover,
  .btn-white.btn-warning:focus,
  .btn-white.btn-warning.active,
  .btn-white.btn-warning:active,
  .open > .btn-white.btn-warning.dropdown-toggle,
  .open > .btn-white.btn-warning.active.dropdown-toggle {
    background-color: #fef7ec !important;
    border-color: #e7b979;
    color: #db9a3d !important;
  }
  .btn-white.btn-warning:hover.no-border,
  .btn-white.btn-warning:focus.no-border,
  .btn-white.btn-warning.active.no-border,
  .btn-white.btn-warning:active.no-border,
  .open > .btn-white.btn-warning.dropdown-toggle.no-border,
  .open > .btn-white.btn-warning.active.dropdown-toggle.no-border {
    border-color: #e7b979;
  }
  .btn-white.btn-warning.disabled,
  .btn-white.btn-warning[disabled],
  fieldset[disabled] .btn-white.btn-warning,
  .btn-white.btn-warning.disabled:hover,
  .btn-white.btn-warning[disabled]:hover,
  fieldset[disabled] .btn-white.btn-warning:hover,
  .btn-white.btn-warning.disabled:focus,
  .btn-white.btn-warning[disabled]:focus,
  fieldset[disabled] .btn-white.btn-warning:focus,
  .btn-white.btn-warning.disabled:active,
  .btn-white.btn-warning[disabled]:active,
  fieldset[disabled] .btn-white.btn-warning:active,
  .btn-white.btn-warning.disabled.active,
  .btn-white.btn-warning[disabled].active,
  fieldset[disabled] .btn-white.btn-warning.active {
    border-color: #e7b979;
  }
  .btn-white.btn-info {
    border-color: #8fbcd9;
    color: #70a0c1 !important;
    /**
 //&:hover, &:focus, &.focus, .open > &.dropdown-toggle {
	//color: saturate(darken(@txt-color , 5%) , 5%) !important;
 //}
 //&.no-border:hover , &.no-border:active {
	//border-color: @border-color;
 //}
 */
  }
  .btn-white.btn-info:hover,
  .btn-white.btn-info:focus,
  .btn-white.btn-info.active,
  .btn-white.btn-info:active,
  .open > .btn-white.btn-info.dropdown-toggle,
  .open > .btn-white.btn-info.active.dropdown-toggle {
    background-color: #eef5fa !important;
    border-color: #8fbcd9;
    color: #5896bf !important;
  }
  .btn-white.btn-info:hover.no-border,
  .btn-white.btn-info:focus.no-border,
  .btn-white.btn-info.active.no-border,
  .btn-white.btn-info:active.no-border,
  .open > .btn-white.btn-info.dropdown-toggle.no-border,
  .open > .btn-white.btn-info.active.dropdown-toggle.no-border {
    border-color: #8fbcd9;
  }
  .btn-white.btn-info.disabled,
  .btn-white.btn-info[disabled],
  fieldset[disabled] .btn-white.btn-info,
  .btn-white.btn-info.disabled:hover,
  .btn-white.btn-info[disabled]:hover,
  fieldset[disabled] .btn-white.btn-info:hover,
  .btn-white.btn-info.disabled:focus,
  .btn-white.btn-info[disabled]:focus,
  fieldset[disabled] .btn-white.btn-info:focus,
  .btn-white.btn-info.disabled:active,
  .btn-white.btn-info[disabled]:active,
  fieldset[disabled] .btn-white.btn-info:active,
  .btn-white.btn-info.disabled.active,
  .btn-white.btn-info[disabled].active,
  fieldset[disabled] .btn-white.btn-info.active {
    border-color: #8fbcd9;
  }
  .btn-white.btn-inverse {
    border-color: #959595;
    color: #555555 !important;
    /**
 //&:hover, &:focus, &.focus, .open > &.dropdown-toggle {
	//color: saturate(darken(@txt-color , 5%) , 5%) !important;
 //}
 //&.no-border:hover , &.no-border:active {
	//border-color: @border-color;
 //}
 */
  }
  .btn-white.btn-inverse:hover,
  .btn-white.btn-inverse:focus,
  .btn-white.btn-inverse.active,
  .btn-white.btn-inverse:active,
  .open > .btn-white.btn-inverse.dropdown-toggle,
  .open > .btn-white.btn-inverse.active.dropdown-toggle {
    background-color: #e4e4e4 !important;
    border-color: #959595;
    color: #4c4545 !important;
  }
  .btn-white.btn-inverse:hover.no-border,
  .btn-white.btn-inverse:focus.no-border,
  .btn-white.btn-inverse.active.no-border,
  .btn-white.btn-inverse:active.no-border,
  .open > .btn-white.btn-inverse.dropdown-toggle.no-border,
  .open > .btn-white.btn-inverse.active.dropdown-toggle.no-border {
    border-color: #959595;
  }
  .btn-white.btn-inverse.disabled,
  .btn-white.btn-inverse[disabled],
  fieldset[disabled] .btn-white.btn-inverse,
  .btn-white.btn-inverse.disabled:hover,
  .btn-white.btn-inverse[disabled]:hover,
  fieldset[disabled] .btn-white.btn-inverse:hover,
  .btn-white.btn-inverse.disabled:focus,
  .btn-white.btn-inverse[disabled]:focus,
  fieldset[disabled] .btn-white.btn-inverse:focus,
  .btn-white.btn-inverse.disabled:active,
  .btn-white.btn-inverse[disabled]:active,
  fieldset[disabled] .btn-white.btn-inverse:active,
  .btn-white.btn-inverse.disabled.active,
  .btn-white.btn-inverse[disabled].active,
  fieldset[disabled] .btn-white.btn-inverse.active {
    border-color: #959595;
  }
  .btn-white.btn-pink {
    border-color: #d299ae;
    color: #af6f87 !important;
    /**
 //&:hover, &:focus, &.focus, .open > &.dropdown-toggle {
	//color: saturate(darken(@txt-color , 5%) , 5%) !important;
 //}
 //&.no-border:hover , &.no-border:active {
	//border-color: @border-color;
 //}
 */
  }
  .btn-white.btn-pink:hover,
  .btn-white.btn-pink:focus,
  .btn-white.btn-pink.active,
  .btn-white.btn-pink:active,
  .open > .btn-white.btn-pink.dropdown-toggle,
  .open > .btn-white.btn-pink.active.dropdown-toggle {
    background-color: #fbeff4 !important;
    border-color: #d299ae;
    color: #ac5978 !important;
  }
  .btn-white.btn-pink:hover.no-border,
  .btn-white.btn-pink:focus.no-border,
  .btn-white.btn-pink.active.no-border,
  .btn-white.btn-pink:active.no-border,
  .open > .btn-white.btn-pink.dropdown-toggle.no-border,
  .open > .btn-white.btn-pink.active.dropdown-toggle.no-border {
    border-color: #d299ae;
  }
  .btn-white.btn-pink.disabled,
  .btn-white.btn-pink[disabled],
  fieldset[disabled] .btn-white.btn-pink,
  .btn-white.btn-pink.disabled:hover,
  .btn-white.btn-pink[disabled]:hover,
  fieldset[disabled] .btn-white.btn-pink:hover,
  .btn-white.btn-pink.disabled:focus,
  .btn-white.btn-pink[disabled]:focus,
  fieldset[disabled] .btn-white.btn-pink:focus,
  .btn-white.btn-pink.disabled:active,
  .btn-white.btn-pink[disabled]:active,
  fieldset[disabled] .btn-white.btn-pink:active,
  .btn-white.btn-pink.disabled.active,
  .btn-white.btn-pink[disabled].active,
  fieldset[disabled] .btn-white.btn-pink.active {
    border-color: #d299ae;
  }
  .btn-white.btn-purple {
    border-color: #b7b1c6;
    color: #7d6fa2 !important;
    /**
 //&:hover, &:focus, &.focus, .open > &.dropdown-toggle {
	//color: saturate(darken(@txt-color , 5%) , 5%) !important;
 //}
 //&.no-border:hover , &.no-border:active {
	//border-color: @border-color;
 //}
 */
  }
  .btn-white.btn-purple:hover,
  .btn-white.btn-purple:focus,
  .btn-white.btn-purple.active,
  .btn-white.btn-purple:active,
  .open > .btn-white.btn-purple.dropdown-toggle,
  .open > .btn-white.btn-purple.active.dropdown-toggle {
    background-color: #efedf5 !important;
    border-color: #b7b1c6;
    color: #6d5b9c !important;
  }
  .btn-white.btn-purple:hover.no-border,
  .btn-white.btn-purple:focus.no-border,
  .btn-white.btn-purple.active.no-border,
  .btn-white.btn-purple:active.no-border,
  .open > .btn-white.btn-purple.dropdown-toggle.no-border,
  .open > .btn-white.btn-purple.active.dropdown-toggle.no-border {
    border-color: #b7b1c6;
  }
  .btn-white.btn-purple.disabled,
  .btn-white.btn-purple[disabled],
  fieldset[disabled] .btn-white.btn-purple,
  .btn-white.btn-purple.disabled:hover,
  .btn-white.btn-purple[disabled]:hover,
  fieldset[disabled] .btn-white.btn-purple:hover,
  .btn-white.btn-purple.disabled:focus,
  .btn-white.btn-purple[disabled]:focus,
  fieldset[disabled] .btn-white.btn-purple:focus,
  .btn-white.btn-purple.disabled:active,
  .btn-white.btn-purple[disabled]:active,
  fieldset[disabled] .btn-white.btn-purple:active,
  .btn-white.btn-purple.disabled.active,
  .btn-white.btn-purple[disabled].active,
  fieldset[disabled] .btn-white.btn-purple.active {
    border-color: #b7b1c6;
  }
  .btn-white.btn-yellow {
    border-color: #ecd181;
    color: #d3a61a !important;
    /**
 //&:hover, &:focus, &.focus, .open > &.dropdown-toggle {
	//color: saturate(darken(@txt-color , 5%) , 5%) !important;
 //}
 //&.no-border:hover , &.no-border:active {
	//border-color: @border-color;
 //}
 */
  }
  .btn-white.btn-yellow:hover,
  .btn-white.btn-yellow:focus,
  .btn-white.btn-yellow.active,
  .btn-white.btn-yellow:active,
  .open > .btn-white.btn-yellow.dropdown-toggle,
  .open > .btn-white.btn-yellow.active.dropdown-toggle {
    background-color: #fdf7e4 !important;
    border-color: #ecd181;
    color: #c29712 !important;
  }
  .btn-white.btn-yellow:hover.no-border,
  .btn-white.btn-yellow:focus.no-border,
  .btn-white.btn-yellow.active.no-border,
  .btn-white.btn-yellow:active.no-border,
  .open > .btn-white.btn-yellow.dropdown-toggle.no-border,
  .open > .btn-white.btn-yellow.active.dropdown-toggle.no-border {
    border-color: #ecd181;
  }
  .btn-white.btn-yellow.disabled,
  .btn-white.btn-yellow[disabled],
  fieldset[disabled] .btn-white.btn-yellow,
  .btn-white.btn-yellow.disabled:hover,
  .btn-white.btn-yellow[disabled]:hover,
  fieldset[disabled] .btn-white.btn-yellow:hover,
  .btn-white.btn-yellow.disabled:focus,
  .btn-white.btn-yellow[disabled]:focus,
  fieldset[disabled] .btn-white.btn-yellow:focus,
  .btn-white.btn-yellow.disabled:active,
  .btn-white.btn-yellow[disabled]:active,
  fieldset[disabled] .btn-white.btn-yellow:active,
  .btn-white.btn-yellow.disabled.active,
  .btn-white.btn-yellow[disabled].active,
  fieldset[disabled] .btn-white.btn-yellow.active {
    border-color: #ecd181;
  }
  .btn-white.btn-grey {
    border-color: #c6c6c6;
    color: #8c8c8c !important;
    /**
 //&:hover, &:focus, &.focus, .open > &.dropdown-toggle {
	//color: saturate(darken(@txt-color , 5%) , 5%) !important;
 //}
 //&.no-border:hover , &.no-border:active {
	//border-color: @border-color;
 //}
 */
  }
  .btn-white.btn-grey:hover,
  .btn-white.btn-grey:focus,
  .btn-white.btn-grey.active,
  .btn-white.btn-grey:active,
  .open > .btn-white.btn-grey.dropdown-toggle,
  .open > .btn-white.btn-grey.active.dropdown-toggle {
    background-color: #ededed !important;
    border-color: #c6c6c6;
    color: #857979 !important;
  }
  .btn-white.btn-grey:hover.no-border,
  .btn-white.btn-grey:focus.no-border,
  .btn-white.btn-grey.active.no-border,
  .btn-white.btn-grey:active.no-border,
  .open > .btn-white.btn-grey.dropdown-toggle.no-border,
  .open > .btn-white.btn-grey.active.dropdown-toggle.no-border {
    border-color: #c6c6c6;
  }
  .btn-white.btn-grey.disabled,
  .btn-white.btn-grey[disabled],
  fieldset[disabled] .btn-white.btn-grey,
  .btn-white.btn-grey.disabled:hover,
  .btn-white.btn-grey[disabled]:hover,
  fieldset[disabled] .btn-white.btn-grey:hover,
  .btn-white.btn-grey.disabled:focus,
  .btn-white.btn-grey[disabled]:focus,
  fieldset[disabled] .btn-white.btn-grey:focus,
  .btn-white.btn-grey.disabled:active,
  .btn-white.btn-grey[disabled]:active,
  fieldset[disabled] .btn-white.btn-grey:active,
  .btn-white.btn-grey.disabled.active,
  .btn-white.btn-grey[disabled].active,
  fieldset[disabled] .btn-white.btn-grey.active {
    border-color: #c6c6c6;
  }
  .btn-white.btn-transparent {
    background-color: rgba(0, 0, 0, 0.4) !important;
  }
  .btn-white.btn-transparent:hover {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
  .btn-white.btn-transparent:focus,
  .btn-white.btn-transparent:active {
    background-color: rgba(0, 0, 0, 0.6) !important;
  }
  .btn-white.no-border {
    border-color: transparent !important;
  }
  .btn.disabled.active,
  .btn[disabled].active,
  .btn.disabled:focus,
  .btn[disabled]:focus,
  .btn.disabled:active,
  .btn[disabled]:active {
    outline: none;
  }
  .btn.disabled:active,
  .btn[disabled]:active {
    top: 0;
    left: 0;
  }
  .btn.active:after {
    display: inline-block;
    content: '';
    position: absolute;
    border-bottom: 1px solid #efe5b5;
    left: -4px;
    right: -4px;
    bottom: -4px;
  }
  .btn.active.btn-sm:after {
    left: -3px;
    right: -3px;
    bottom: -3px;
  }
  .btn.active.btn-lg:after {
    left: -5px;
    right: -5px;
    bottom: -5px;
  }
  .btn.active.btn-xs:after,
  .btn.active.btn-mini:after,
  .btn.active.btn-minier:after {
    left: -1px;
    right: -1px;
    bottom: -2px;
  }
  .btn.active.btn-minier:after {
    bottom: -1px;
  }
  .btn.active.btn-yellow:after {
    border-bottom-color: #c96338;
  }
  .btn.active.btn-light {
    color: #515151;
  }
  .btn.active.btn-light:after {
    border-bottom-color: #b5b5b5;
  }
  .btn > .ace-icon {
    margin-right: 4px;
  }
  .btn > .ace-icon.icon-on-right {
    margin-right: 0;
    margin-left: 4px;
  }
  .btn > .icon-only.ace-icon {
    margin: 0 !important;
    text-align: center;
    padding: 0;
  }
  .btn-lg > .ace-icon {
    margin-right: 6px;
  }
  .btn-lg > .ace-icon.icon-on-right {
    margin-right: 0;
    margin-left: 6px;
  }
  .btn-sm > .ace-icon {
    margin-right: 3px;
  }
  .btn-sm > .ace-icon.icon-on-right {
    margin-right: 0;
    margin-left: 3px;
  }
  .btn-xs > .ace-icon,
  .btn-mini > .ace-icon,
  .btn-minier > .ace-icon {
    margin-right: 2px;
  }
  .btn-xs > .ace-icon.icon-on-right,
  .btn-mini > .ace-icon.icon-on-right,
  .btn-minier > .ace-icon.icon-on-right {
    margin-right: 0;
    margin-left: 2px;
  }
  .btn.btn-link {
    border-width: 0 !important;
    background: transparent none !important;
    color: #0088cc !important;
    text-shadow: none !important;
    padding: 4px 12px !important;
    line-height: 20px !important;
  }
  .btn.btn-link:hover {
    background: transparent none !important;
    text-shadow: none !important;
  }
  .btn.btn-link.active,
  .btn.btn-link:active,
  .btn.btn-link:focus,
  .open > .btn.btn-link.active,
  .open > .btn.btn-link:active,
  .open > .btn.btn-link:focus {
    background: transparent none !important;
    text-decoration: underline;
    color: #009ceb !important;
  }
  .btn.btn-link.active:after,
  .btn.btn-link:active:after,
  .btn.btn-link:focus:after {
    display: none;
  }
  .btn.btn-link.disabled,
  .btn.btn-link[disabled] {
    background: transparent none !important;
    opacity: 0.65;
    filter: alpha(opacity=65);
    text-decoration: none !important;
  }
  .btn.btn-no-border {
    border-width: 0 !important;
  }
  .btn-group:first-child {
    margin-left: 0;
  }
  .btn-group > .btn,
  .btn-group > .btn + .btn {
    margin: 0 1px 0 0;
  }
  .btn-group > .btn:first-child {
    margin: 0 1px 0 0;
  }
  .btn-group > .btn > .caret {
    margin-top: 15px;
    margin-left: 1px;
    border-width: 5px;
    border-top-color: #fff;
  }
  .btn-group > .btn.btn-sm > .caret {
    margin-top: 10px;
    border-width: 4px;
  }
  .btn-group > .btn.btn-lg > .caret {
    margin-top: 18px;
    border-width: 6px;
  }
  .btn-group > .btn.btn-xs > .caret,
  .btn-group > .btn.btn-mini > .caret {
    margin-top: 9px;
    border-width: 4px;
  }
  .btn-group > .btn.btn-minier > .caret {
    margin-top: 7px;
    border-width: 3px;
  }
  .btn-group > .btn + .btn.dropdown-toggle {
    padding-right: 3px;
    padding-left: 3px;
  }
  .btn-group > .btn + .btn-lg.dropdown-toggle {
    padding-right: 4px;
    padding-left: 4px;
  }
  .btn-group .dropdown-toggle {
    border-radius: 0;
  }
  .btn-group > .btn,
  .btn-group + .btn {
    margin: 0 1px 0 0;
    border-width: 3px;
    /* the border under an active button in button groups */
  }
  .btn-group > .btn.active:after,
  .btn-group + .btn.active:after {
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-bottom-width: 1px;
  }
  .btn-group > .btn-lg,
  .btn-group + .btn-lg {
    border-width: 4px;
    /* the border under an active button in button groups */
  }
  .btn-group > .btn-lg.active:after,
  .btn-group + .btn-lg.active:after {
    left: -3px;
    right: -3px;
    bottom: -3px;
    border-bottom-width: 1px;
  }
  .btn-group > .btn-sm,
  .btn-group + .btn-sm {
    border-width: 2px;
    /* the border under an active button in button groups */
  }
  .btn-group > .btn-sm.active:after,
  .btn-group + .btn-sm.active:after {
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-bottom-width: 1px;
  }
  .btn-group > .btn-xs,
  .btn-group + .btn-xs,
  .btn-group > .btn-mini,
  .btn-group + .btn-mini {
    border-width: 1px;
    /* the border under an active button in button groups */
  }
  .btn-group > .btn-xs.active:after,
  .btn-group + .btn-xs.active:after,
  .btn-group > .btn-mini.active:after,
  .btn-group + .btn-mini.active:after {
    left: 0px;
    right: 0px;
    bottom: 0px;
    border-bottom-width: 1px;
  }
  .btn-group > .btn-minier,
  .btn-group + .btn-minier {
    border-width: 1px;
    /* the border under an active button in button groups */
  }
  .btn-group > .btn-minier.active:after,
  .btn-group + .btn-minier.active:after {
    left: 0px;
    right: 0px;
    bottom: 0px;
    border-bottom-width: 1px;
  }
  .btn-group-vertical > .btn:last-child:not(:first-child),
  .btn-group-vertical > .btn:first-child:not(:last-child) {
    border-radius: 0;
  }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn + .btn {
    margin: 1px 0 0;
  }
  .btn-group-vertical > .btn:first-child {
    margin-top: 0;
  }
  .btn-group.btn-overlap > .btn {
    margin-right: -1px;
  }
  .btn-group.btn-corner > .btn:first-child {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }
  .btn-group.btn-corner > .btn:last-child {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
  }
  .btn-group.btn-corner > .btn.btn-sm:first-child {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }
  .btn-group.btn-corner > .btn.btn-sm:last-child {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
  .btn-group.btn-corner > .btn.btn-xs:first-child,
  .btn-group.btn-corner > .btn.btn-mini:first-child {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
  .btn-group.btn-corner > .btn.btn-xs:last-child,
  .btn-group.btn-corner > .btn.btn-mini:last-child {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
  .btn.btn-white {
    border-width: 1px;
  }
  .btn.btn-bold {
    border-bottom-width: 2px;
  }
  .btn.btn-round {
    border-bottom-width: 2px;
    border-radius: 4px !important;
  }
  .btn.btn-app {
    display: inline-block;
    width: 100px;
    font-size: 18px;
    font-weight: normal;
    color: #fff;
    text-align: center;
    text-shadow: 0 -1px -1px rgba(0, 0, 0, 0.2) !important;
    border: none;
    border-radius: 12px;
    padding: 12px 0 8px;
    margin: 2px;
    line-height: 1.7;
    position: relative;
  }
  .btn-app,
  .btn-app.btn-default,
  .btn-app.no-hover:hover,
  .btn-app.btn-default.no-hover:hover,
  .btn-app.disabled:hover,
  .btn-app.btn-default.disabled:hover {
    background: #b4c2cc !important;
    background-image: -webkit-linear-gradient(
      top,
      #bcc9d5 0%,
      #abbac3 100%
    ) !important;
    background-image: -o-linear-gradient(
      top,
      #bcc9d5 0%,
      #abbac3 100%
    ) !important;
    background-image: linear-gradient(
      to bottom,
      #bcc9d5 0%,
      #abbac3 100%
    ) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ffbcc9d5',
        endColorstr='#ffabbac3',
        GradientType=0
      ) !important;
  }
  .btn-app:hover,
  .btn-app.btn-default:hover {
    background: #9baebc !important;
    background-image: -webkit-linear-gradient(
      top,
      #a3b5c5 0%,
      #93a6b2 100%
    ) !important;
    background-image: -o-linear-gradient(
      top,
      #a3b5c5 0%,
      #93a6b2 100%
    ) !important;
    background-image: linear-gradient(
      to bottom,
      #a3b5c5 0%,
      #93a6b2 100%
    ) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ffa3b5c5',
        endColorstr='#ff93a6b2',
        GradientType=0
      ) !important;
  }
  .btn-app.btn-primary,
  .btn-app.btn-primary.no-hover:hover,
  .btn-app.btn-primary.disabled:hover {
    background: #2a8bcb !important;
    background-image: -webkit-linear-gradient(
      top,
      #3b98d6 0%,
      #197ec1 100%
    ) !important;
    background-image: -o-linear-gradient(
      top,
      #3b98d6 0%,
      #197ec1 100%
    ) !important;
    background-image: linear-gradient(
      to bottom,
      #3b98d6 0%,
      #197ec1 100%
    ) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ff3b98d6',
        endColorstr='#ff197ec1',
        GradientType=0
      ) !important;
  }
  .btn-app.btn-primary:hover {
    background: #1d6fa6 !important;
    background-image: -webkit-linear-gradient(
      top,
      #267eb8 0%,
      #136194 100%
    ) !important;
    background-image: -o-linear-gradient(
      top,
      #267eb8 0%,
      #136194 100%
    ) !important;
    background-image: linear-gradient(
      to bottom,
      #267eb8 0%,
      #136194 100%
    ) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ff267eb8',
        endColorstr='#ff136194',
        GradientType=0
      ) !important;
  }
  .btn-app.btn-info,
  .btn-app.btn-info.no-hover:hover,
  .btn-app.btn-info.disabled:hover {
    background: #68adde !important;
    background-image: -webkit-linear-gradient(
      top,
      #75b5e6 0%,
      #5ba4d5 100%
    ) !important;
    background-image: -o-linear-gradient(
      top,
      #75b5e6 0%,
      #5ba4d5 100%
    ) !important;
    background-image: linear-gradient(
      to bottom,
      #75b5e6 0%,
      #5ba4d5 100%
    ) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ff75b5e6',
        endColorstr='#ff5ba4d5',
        GradientType=0
      ) !important;
  }
  .btn-app.btn-info:hover {
    background: #3f96d4 !important;
    background-image: -webkit-linear-gradient(
      top,
      #4a9ede 0%,
      #348dc9 100%
    ) !important;
    background-image: -o-linear-gradient(
      top,
      #4a9ede 0%,
      #348dc9 100%
    ) !important;
    background-image: linear-gradient(
      to bottom,
      #4a9ede 0%,
      #348dc9 100%
    ) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ff4a9ede',
        endColorstr='#ff348dc9',
        GradientType=0
      ) !important;
  }
  .btn-app.btn-success,
  .btn-app.btn-success.no-hover:hover,
  .btn-app.btn-success.disabled:hover {
    background: #85b558 !important;
    background-image: -webkit-linear-gradient(
      top,
      #8ebf60 0%,
      #7daa50 100%
    ) !important;
    background-image: -o-linear-gradient(
      top,
      #8ebf60 0%,
      #7daa50 100%
    ) !important;
    background-image: linear-gradient(
      to bottom,
      #8ebf60 0%,
      #7daa50 100%
    ) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ff8ebf60',
        endColorstr='#ff7daa50',
        GradientType=0
      ) !important;
  }
  .btn-app.btn-success:hover {
    background: #6c9842 !important;
    background-image: -webkit-linear-gradient(
      top,
      #74a844 0%,
      #648740 100%
    ) !important;
    background-image: -o-linear-gradient(
      top,
      #74a844 0%,
      #648740 100%
    ) !important;
    background-image: linear-gradient(
      to bottom,
      #74a844 0%,
      #648740 100%
    ) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ff74a844',
        endColorstr='#ff648740',
        GradientType=0
      ) !important;
  }
  .btn-app.btn-danger,
  .btn-app.btn-danger.no-hover:hover,
  .btn-app.btn-danger.disabled:hover {
    background: #d3413b !important;
    background-image: -webkit-linear-gradient(
      top,
      #d55b52 0%,
      #d12723 100%
    ) !important;
    background-image: -o-linear-gradient(
      top,
      #d55b52 0%,
      #d12723 100%
    ) !important;
    background-image: linear-gradient(
      to bottom,
      #d55b52 0%,
      #d12723 100%
    ) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ffd55b52',
        endColorstr='#ffd12723',
        GradientType=0
      ) !important;
  }
  .btn-app.btn-danger:hover {
    background: #b52c26 !important;
    background-image: -webkit-linear-gradient(
      top,
      #c43a30 0%,
      #a51f1c 100%
    ) !important;
    background-image: -o-linear-gradient(
      top,
      #c43a30 0%,
      #a51f1c 100%
    ) !important;
    background-image: linear-gradient(
      to bottom,
      #c43a30 0%,
      #a51f1c 100%
    ) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ffc43a30',
        endColorstr='#ffa51f1c',
        GradientType=0
      ) !important;
  }
  .btn-app.btn-warning,
  .btn-app.btn-warning.no-hover:hover,
  .btn-app.btn-warning.disabled:hover {
    background: #ffb44b !important;
    background-image: -webkit-linear-gradient(
      top,
      #ffbf66 0%,
      #ffa830 100%
    ) !important;
    background-image: -o-linear-gradient(
      top,
      #ffbf66 0%,
      #ffa830 100%
    ) !important;
    background-image: linear-gradient(
      to bottom,
      #ffbf66 0%,
      #ffa830 100%
    ) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ffffbf66',
        endColorstr='#ffffa830',
        GradientType=0
      ) !important;
  }
  .btn-app.btn-warning:hover {
    background: #fe9e19 !important;
    background-image: -webkit-linear-gradient(
      top,
      #ffaa33 0%,
      #fc9200 100%
    ) !important;
    background-image: -o-linear-gradient(
      top,
      #ffaa33 0%,
      #fc9200 100%
    ) !important;
    background-image: linear-gradient(
      to bottom,
      #ffaa33 0%,
      #fc9200 100%
    ) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ffffaa33',
        endColorstr='#fffc9200',
        GradientType=0
      ) !important;
  }
  .btn-app.btn-purple,
  .btn-app.btn-purple.no-hover:hover,
  .btn-app.btn-purple.disabled:hover {
    background: #9889c1 !important;
    background-image: -webkit-linear-gradient(
      top,
      #a696ce 0%,
      #8a7cb4 100%
    ) !important;
    background-image: -o-linear-gradient(
      top,
      #a696ce 0%,
      #8a7cb4 100%
    ) !important;
    background-image: linear-gradient(
      to bottom,
      #a696ce 0%,
      #8a7cb4 100%
    ) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ffa696ce',
        endColorstr='#ff8a7cb4',
        GradientType=0
      ) !important;
  }
  .btn-app.btn-purple:hover {
    background: #7b68af !important;
    background-image: -webkit-linear-gradient(
      top,
      #8973be 0%,
      #6d5ca1 100%
    ) !important;
    background-image: -o-linear-gradient(
      top,
      #8973be 0%,
      #6d5ca1 100%
    ) !important;
    background-image: linear-gradient(
      to bottom,
      #8973be 0%,
      #6d5ca1 100%
    ) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ff8973be',
        endColorstr='#ff6d5ca1',
        GradientType=0
      ) !important;
  }
  .btn-app.btn-pink,
  .btn-app.btn-pink.no-hover:hover,
  .btn-app.btn-pink.disabled:hover {
    background: #d54c7e !important;
    background-image: -webkit-linear-gradient(
      top,
      #db5e8c 0%,
      #ce3970 100%
    ) !important;
    background-image: -o-linear-gradient(
      top,
      #db5e8c 0%,
      #ce3970 100%
    ) !important;
    background-image: linear-gradient(
      to bottom,
      #db5e8c 0%,
      #ce3970 100%
    ) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ffdb5e8c',
        endColorstr='#ffce3970',
        GradientType=0
      ) !important;
  }
  .btn-app.btn-pink:hover {
    background: #be2f64 !important;
    background-image: -webkit-linear-gradient(
      top,
      #d2346e 0%,
      #aa2a59 100%
    ) !important;
    background-image: -o-linear-gradient(
      top,
      #d2346e 0%,
      #aa2a59 100%
    ) !important;
    background-image: linear-gradient(
      to bottom,
      #d2346e 0%,
      #aa2a59 100%
    ) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ffd2346e',
        endColorstr='#ffaa2a59',
        GradientType=0
      ) !important;
  }
  .btn-app.btn-inverse,
  .btn-app.btn-inverse.no-hover:hover,
  .btn-app.btn-inverse.disabled:hover {
    background: #444444 !important;
    background-image: -webkit-linear-gradient(
      top,
      #555555 0%,
      #333333 100%
    ) !important;
    background-image: -o-linear-gradient(
      top,
      #555555 0%,
      #333333 100%
    ) !important;
    background-image: linear-gradient(
      to bottom,
      #555555 0%,
      #333333 100%
    ) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ff555555',
        endColorstr='#ff333333',
        GradientType=0
      ) !important;
  }
  .btn-app.btn-inverse:hover {
    background: #2b2b2b !important;
    background-image: -webkit-linear-gradient(
      top,
      #3b3b3b 0%,
      #1a1a1a 100%
    ) !important;
    background-image: -o-linear-gradient(
      top,
      #3b3b3b 0%,
      #1a1a1a 100%
    ) !important;
    background-image: linear-gradient(
      to bottom,
      #3b3b3b 0%,
      #1a1a1a 100%
    ) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ff3b3b3b',
        endColorstr='#ff1a1a1a',
        GradientType=0
      ) !important;
  }
  .btn-app.btn-grey,
  .btn-app.btn-grey.no-hover:hover,
  .btn-app.btn-grey.disabled:hover {
    background: #797979 !important;
    background-image: -webkit-linear-gradient(
      top,
      #898989 0%,
      #696969 100%
    ) !important;
    background-image: -o-linear-gradient(
      top,
      #898989 0%,
      #696969 100%
    ) !important;
    background-image: linear-gradient(
      to bottom,
      #898989 0%,
      #696969 100%
    ) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ff898989',
        endColorstr='#ff696969',
        GradientType=0
      ) !important;
  }
  .btn-app.btn-grey:hover {
    background: #6c6c6c !important;
    background-image: -webkit-linear-gradient(
      top,
      #7c7c7c 0%,
      #5c5c5c 100%
    ) !important;
    background-image: -o-linear-gradient(
      top,
      #7c7c7c 0%,
      #5c5c5c 100%
    ) !important;
    background-image: linear-gradient(
      to bottom,
      #7c7c7c 0%,
      #5c5c5c 100%
    ) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ff7c7c7c',
        endColorstr='#ff5c5c5c',
        GradientType=0
      ) !important;
  }
  .btn.btn-app.btn-light {
    color: #5a5a5a !important;
    text-shadow: 0 1px 1px #eee !important;
  }
  .btn.btn-app.btn-light,
  .btn.btn-app.btn-light.no-hover:hover,
  .btn.btn-app.btn-light.disabled:hover {
    background: #ededed !important;
    background-image: -webkit-linear-gradient(
      top,
      #f4f4f4 0%,
      #e6e6e6 100%
    ) !important;
    background-image: -o-linear-gradient(
      top,
      #f4f4f4 0%,
      #e6e6e6 100%
    ) !important;
    background-image: linear-gradient(
      to bottom,
      #f4f4f4 0%,
      #e6e6e6 100%
    ) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#fff4f4f4',
        endColorstr='#ffe6e6e6',
        GradientType=0
      ) !important;
  }
  .btn.btn-app.btn-light:hover {
    background: #e0e0e0 !important;
    background-image: -webkit-linear-gradient(
      top,
      #e7e7e7 0%,
      #d9d9d9 100%
    ) !important;
    background-image: -o-linear-gradient(
      top,
      #e7e7e7 0%,
      #d9d9d9 100%
    ) !important;
    background-image: linear-gradient(
      to bottom,
      #e7e7e7 0%,
      #d9d9d9 100%
    ) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ffe7e7e7',
        endColorstr='#ffd9d9d9',
        GradientType=0
      ) !important;
  }
  .btn.btn-app.btn-yellow {
    color: #996633 !important;
    text-shadow: 0 -1px 0 rgba(255, 255, 255, 0.4) !important;
  }
  .btn.btn-app.btn-yellow,
  .btn.btn-app.btn-yellow.no-hover:hover,
  .btn.btn-app.btn-yellow.disabled:hover {
    background: #fee088 !important;
    background-image: -webkit-linear-gradient(
      top,
      #ffe8a5 0%,
      #fcd76a 100%
    ) !important;
    background-image: -o-linear-gradient(
      top,
      #ffe8a5 0%,
      #fcd76a 100%
    ) !important;
    background-image: linear-gradient(
      to bottom,
      #ffe8a5 0%,
      #fcd76a 100%
    ) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ffffe8a5',
        endColorstr='#fffcd76a',
        GradientType=0
      ) !important;
  }
  .btn.btn-app.btn-yellow:hover {
    background: #fdd96e !important;
    background-image: -webkit-linear-gradient(
      top,
      #ffe18b 0%,
      #fbd051 100%
    ) !important;
    background-image: -o-linear-gradient(
      top,
      #ffe18b 0%,
      #fbd051 100%
    ) !important;
    background-image: linear-gradient(
      to bottom,
      #ffe18b 0%,
      #fbd051 100%
    ) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ffffe18b',
        endColorstr='#fffbd051',
        GradientType=0
      ) !important;
  }
  .btn.btn-app > .ace-icon {
    opacity: 0.88;
  }
  .btn.btn-app:hover > .ace-icon {
    opacity: 1;
  }
  .btn.btn-app.btn-sm {
    width: 80px;
    font-size: 16px;
    border-radius: 10px;
    line-height: 1.5;
  }
  .btn.btn-app.btn-xs {
    width: 64px;
    font-size: 15px;
    border-radius: 8px;
    padding-bottom: 7px;
    padding-top: 8px;
    line-height: 1.45;
  }
  .btn.btn-app > .ace-icon {
    display: block;
    font-size: 42px;
    margin: 0 0 4px;
    line-height: 36px;
    min-width: 0;
    padding: 0;
  }
  .btn.btn-app.btn-sm > .ace-icon {
    display: block;
    font-size: 32px;
    line-height: 30px;
    margin: 0 0 3px;
  }
  .btn.btn-app.btn-xs > .ace-icon {
    display: block;
    font-size: 24px;
    line-height: 24px;
    margin: 0;
  }
  .btn.btn-app.no-radius {
    border-radius: 0;
  }
  .btn.btn-app.radius-4 {
    border-radius: 4px;
  }
  .btn.btn-app > .badge,
  .btn.btn-app > .label {
    position: absolute !important;
    top: -2px;
    right: -2px;
    padding: 1px 3px;
    text-align: center;
    font-size: 12px;
    color: #fff;
  }
  .btn.btn-app > .badge.badge-left,
  .btn.btn-app > .label.badge-left,
  .btn.btn-app > .badge.label-left,
  .btn.btn-app > .label.label-left {
    right: auto;
    left: -2px;
  }
  .btn.btn-app > .badge-yellow,
  .btn.btn-app > .label-yellow {
    color: #996633;
  }
  .btn.btn-app > .badge-light,
  .btn.btn-app > .label-light {
    color: #888888;
  }
  .btn.btn-app > .label {
    padding: 1px 6px 3px;
    font-size: 13px;
  }
  .btn.btn-app.radius-4 > .badge,
  .btn.btn-app.no-radius > .badge {
    border-radius: 3px;
  }
  .btn.btn-app.radius-4 > .badge.no-radius,
  .btn.btn-app.no-radius > .badge.no-radius {
    border-radius: 0;
  }
  .btn.btn-app.active {
    color: #ffffff;
  }
  .btn.btn-app.active:after {
    display: none;
  }
  .btn.btn-app.active.btn-yellow {
    color: #996633;
    border-color: #fee188;
  }
  .btn.btn-app.active.btn-light {
    color: #515151;
  }
  .btn-group > .btn-app:first-child:not(:last-child):not(.dropdown-toggle) {
    margin-right: 24px;
  }
  .btn-group > .btn-app + .btn-app.dropdown-toggle {
    position: absolute;
    width: auto;
    height: 100%;
    padding-left: 6px;
    padding-right: 6px;
    margin-left: -23px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    right: 0;
  }
  .btn.btn-app.btn-light,
  .btn.btn-app.btn-yellow {
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08) inset !important;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08) inset !important;
  }
  .label {
    border-radius: 0;
    text-shadow: none;
    font-weight: normal;
    color: #ffffff;
    display: inline-block;
    background-color: #abbac3;
  }
  .label[class*='col-'][class*='arrow'] {
    min-height: 0;
  }
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    ::safari-only,
    .label:empty {
      display: inline-block;
    }
    ::safari-only,
    .badge:empty {
      display: inline-block;
    }
  }
  .badge {
    text-shadow: none;
    font-size: 12px;
    padding-top: 1px;
    padding-bottom: 3px;
    font-weight: normal;
    line-height: 15px;
    background-color: #abbac3;
  }
  .badge.no-radius {
    border-radius: 0;
  }
  .badge.radius-1 {
    border-radius: 1px;
  }
  .badge.radius-2 {
    border-radius: 2px;
  }
  .badge.radius-3 {
    border-radius: 3px;
  }
  .badge.radius-4 {
    border-radius: 4px;
  }
  .badge.radius-5 {
    border-radius: 5px;
  }
  .badge.radius-6 {
    border-radius: 6px;
  }
  .label.label-transparent,
  .label-transparent,
  .badge.badge-transparent,
  .badge-transparent {
    background-color: transparent;
  }
  .label-grey,
  .label.label-grey,
  .badge.badge-grey,
  .badge-grey {
    background-color: #a0a0a0;
  }
  .label-info,
  .label.label-info,
  .badge.badge-info,
  .badge-info {
    background-color: #3a87ad;
  }
  .label-primary,
  .label.label-primary,
  .badge.badge-primary,
  .badge-primary {
    background-color: #428bca;
  }
  .label-success,
  .label.label-success,
  .badge.badge-success,
  .badge-success {
    background-color: #82af6f;
  }
  .label-danger,
  .label.label-danger,
  .badge.badge-danger,
  .badge-danger {
    background-color: #d15b47;
  }
  .label-important,
  .label.label-important,
  .badge.badge-important,
  .badge-important {
    background-color: #d15b47;
  }
  .label-inverse,
  .label.label-inverse,
  .badge.badge-inverse,
  .badge-inverse {
    background-color: #333333;
  }
  .label-warning,
  .label.label-warning,
  .badge.badge-warning,
  .badge-warning {
    background-color: #f89406;
  }
  .label-pink,
  .label.label-pink,
  .badge.badge-pink,
  .badge-pink {
    background-color: #d6487e;
  }
  .label-purple,
  .label.label-purple,
  .badge.badge-purple,
  .badge-purple {
    background-color: #9585bf;
  }
  .label-yellow,
  .label.label-yellow,
  .badge.badge-yellow,
  .badge-yellow {
    background-color: #fee188;
  }
  .label-light,
  .label.label-light,
  .badge.badge-light,
  .badge-light {
    background-color: #e7e7e7;
  }
  .badge-yellow,
  .label-yellow {
    color: #996633;
    border-color: #fee188;
  }
  .badge-light,
  .label-light {
    color: #888888;
  }
  .label.arrowed,
  .label.arrowed-in {
    position: relative;
    z-index: 1;
  }
  .label.arrowed:before,
  .label.arrowed-in:before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    z-index: -1;
    border: 1px solid transparent;
    border-right-color: #abbac3;
    -moz-border-right-colors: #abbac3;
  }
  .label.arrowed-in:before {
    border-color: #abbac3;
    border-left-color: transparent;
    -moz-border-left-colors: none;
  }
  .label.arrowed-right,
  .label.arrowed-in-right {
    position: relative;
    z-index: 1;
  }
  .label.arrowed-right:after,
  .label.arrowed-in-right:after {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    z-index: -1;
    border: 1px solid transparent;
    border-left-color: #abbac3;
    -moz-border-left-colors: #abbac3;
  }
  .label.arrowed-in-right:after {
    border-color: #abbac3;
    border-right-color: transparent;
    -moz-border-right-colors: none;
  }
  .label-info.arrowed:before {
    border-right-color: #3a87ad;
    -moz-border-right-colors: #3a87ad;
  }
  .label-info.arrowed-in:before {
    border-color: #3a87ad #3a87ad #3a87ad transparent;
    -moz-border-right-colors: #3a87ad;
  }
  .label-info.arrowed-right:after {
    border-left-color: #3a87ad;
    -moz-border-left-colors: #3a87ad;
  }
  .label-info.arrowed-in-right:after {
    border-color: #3a87ad transparent #3a87ad #3a87ad;
    -moz-border-left-colors: #3a87ad;
  }
  .label-primary.arrowed:before {
    border-right-color: #428bca;
    -moz-border-right-colors: #428bca;
  }
  .label-primary.arrowed-in:before {
    border-color: #428bca #428bca #428bca transparent;
    -moz-border-right-colors: #428bca;
  }
  .label-primary.arrowed-right:after {
    border-left-color: #428bca;
    -moz-border-left-colors: #428bca;
  }
  .label-primary.arrowed-in-right:after {
    border-color: #428bca transparent #428bca #428bca;
    -moz-border-left-colors: #428bca;
  }
  .label-success.arrowed:before {
    border-right-color: #82af6f;
    -moz-border-right-colors: #82af6f;
  }
  .label-success.arrowed-in:before {
    border-color: #82af6f #82af6f #82af6f transparent;
    -moz-border-right-colors: #82af6f;
  }
  .label-success.arrowed-right:after {
    border-left-color: #82af6f;
    -moz-border-left-colors: #82af6f;
  }
  .label-success.arrowed-in-right:after {
    border-color: #82af6f transparent #82af6f #82af6f;
    -moz-border-left-colors: #82af6f;
  }
  .label-warning.arrowed:before {
    border-right-color: #f89406;
    -moz-border-right-colors: #f89406;
  }
  .label-warning.arrowed-in:before {
    border-color: #f89406 #f89406 #f89406 transparent;
    -moz-border-right-colors: #f89406;
  }
  .label-warning.arrowed-right:after {
    border-left-color: #f89406;
    -moz-border-left-colors: #f89406;
  }
  .label-warning.arrowed-in-right:after {
    border-color: #f89406 transparent #f89406 #f89406;
    -moz-border-left-colors: #f89406;
  }
  .label-important.arrowed:before {
    border-right-color: #d15b47;
    -moz-border-right-colors: #d15b47;
  }
  .label-important.arrowed-in:before {
    border-color: #d15b47 #d15b47 #d15b47 transparent;
    -moz-border-right-colors: #d15b47;
  }
  .label-important.arrowed-right:after {
    border-left-color: #d15b47;
    -moz-border-left-colors: #d15b47;
  }
  .label-important.arrowed-in-right:after {
    border-color: #d15b47 transparent #d15b47 #d15b47;
    -moz-border-left-colors: #d15b47;
  }
  .label-danger.arrowed:before {
    border-right-color: #d15b47;
    -moz-border-right-colors: #d15b47;
  }
  .label-danger.arrowed-in:before {
    border-color: #d15b47 #d15b47 #d15b47 transparent;
    -moz-border-right-colors: #d15b47;
  }
  .label-danger.arrowed-right:after {
    border-left-color: #d15b47;
    -moz-border-left-colors: #d15b47;
  }
  .label-danger.arrowed-in-right:after {
    border-color: #d15b47 transparent #d15b47 #d15b47;
    -moz-border-left-colors: #d15b47;
  }
  .label-inverse.arrowed:before {
    border-right-color: #333333;
    -moz-border-right-colors: #333333;
  }
  .label-inverse.arrowed-in:before {
    border-color: #333333 #333333 #333333 transparent;
    -moz-border-right-colors: #333333;
  }
  .label-inverse.arrowed-right:after {
    border-left-color: #333333;
    -moz-border-left-colors: #333333;
  }
  .label-inverse.arrowed-in-right:after {
    border-color: #333333 transparent #333333 #333333;
    -moz-border-left-colors: #333333;
  }
  .label-pink.arrowed:before {
    border-right-color: #d6487e;
    -moz-border-right-colors: #d6487e;
  }
  .label-pink.arrowed-in:before {
    border-color: #d6487e #d6487e #d6487e transparent;
    -moz-border-right-colors: #d6487e;
  }
  .label-pink.arrowed-right:after {
    border-left-color: #d6487e;
    -moz-border-left-colors: #d6487e;
  }
  .label-pink.arrowed-in-right:after {
    border-color: #d6487e transparent #d6487e #d6487e;
    -moz-border-left-colors: #d6487e;
  }
  .label-purple.arrowed:before {
    border-right-color: #9585bf;
    -moz-border-right-colors: #9585bf;
  }
  .label-purple.arrowed-in:before {
    border-color: #9585bf #9585bf #9585bf transparent;
    -moz-border-right-colors: #9585bf;
  }
  .label-purple.arrowed-right:after {
    border-left-color: #9585bf;
    -moz-border-left-colors: #9585bf;
  }
  .label-purple.arrowed-in-right:after {
    border-color: #9585bf transparent #9585bf #9585bf;
    -moz-border-left-colors: #9585bf;
  }
  .label-yellow.arrowed:before {
    border-right-color: #fee188;
    -moz-border-right-colors: #fee188;
  }
  .label-yellow.arrowed-in:before {
    border-color: #fee188 #fee188 #fee188 transparent;
    -moz-border-right-colors: #fee188;
  }
  .label-yellow.arrowed-right:after {
    border-left-color: #fee188;
    -moz-border-left-colors: #fee188;
  }
  .label-yellow.arrowed-in-right:after {
    border-color: #fee188 transparent #fee188 #fee188;
    -moz-border-left-colors: #fee188;
  }
  .label-light.arrowed:before {
    border-right-color: #e7e7e7;
    -moz-border-right-colors: #e7e7e7;
  }
  .label-light.arrowed-in:before {
    border-color: #e7e7e7 #e7e7e7 #e7e7e7 transparent;
    -moz-border-right-colors: #e7e7e7;
  }
  .label-light.arrowed-right:after {
    border-left-color: #e7e7e7;
    -moz-border-left-colors: #e7e7e7;
  }
  .label-light.arrowed-in-right:after {
    border-color: #e7e7e7 transparent #e7e7e7 #e7e7e7;
    -moz-border-left-colors: #e7e7e7;
  }
  .label-grey.arrowed:before {
    border-right-color: #a0a0a0;
    -moz-border-right-colors: #a0a0a0;
  }
  .label-grey.arrowed-in:before {
    border-color: #a0a0a0 #a0a0a0 #a0a0a0 transparent;
    -moz-border-right-colors: #a0a0a0;
  }
  .label-grey.arrowed-right:after {
    border-left-color: #a0a0a0;
    -moz-border-left-colors: #a0a0a0;
  }
  .label-grey.arrowed-in-right:after {
    border-color: #a0a0a0 transparent #a0a0a0 #a0a0a0;
    -moz-border-left-colors: #a0a0a0;
  }
  .label {
    font-size: 12px;
    line-height: 1.15;
    height: 20px;
  }
  .label.arrowed {
    margin-left: 5px;
  }
  .label.arrowed:before {
    left: -10px;
    border-width: 10px 5px;
  }
  .label.arrowed-in {
    margin-left: 5px;
  }
  .label.arrowed-in:before {
    left: -5px;
    border-width: 10px 5px;
  }
  .label.arrowed-right {
    margin-right: 5px;
  }
  .label.arrowed-right:after {
    right: -10px;
    border-width: 10px 5px;
  }
  .label.arrowed-in-right {
    margin-right: 5px;
  }
  .label.arrowed-in-right:after {
    right: -5px;
    border-width: 10px 5px;
  }
  .label-lg {
    padding: 0.3em 0.6em 0.4em;
    font-size: 13px;
    line-height: 1.1;
    height: 24px;
  }
  .label-lg.arrowed {
    margin-left: 6px;
  }
  .label-lg.arrowed:before {
    left: -12px;
    border-width: 12px 6px;
  }
  .label-lg.arrowed-in {
    margin-left: 6px;
  }
  .label-lg.arrowed-in:before {
    left: -6px;
    border-width: 12px 6px;
  }
  .label-lg.arrowed-right {
    margin-right: 6px;
  }
  .label-lg.arrowed-right:after {
    right: -12px;
    border-width: 12px 6px;
  }
  .label-lg.arrowed-in-right {
    margin-right: 6px;
  }
  .label-lg.arrowed-in-right:after {
    right: -6px;
    border-width: 12px 6px;
  }
  .label-xlg {
    padding: 0.3em 0.7em 0.4em;
    font-size: 14px;
    line-height: 1.3;
    height: 28px;
  }
  .label-xlg.arrowed {
    margin-left: 7px;
  }
  .label-xlg.arrowed:before {
    left: -14px;
    border-width: 14px 7px;
  }
  .label-xlg.arrowed-in {
    margin-left: 7px;
  }
  .label-xlg.arrowed-in:before {
    left: -7px;
    border-width: 14px 7px;
  }
  .label-xlg.arrowed-right {
    margin-right: 7px;
  }
  .label-xlg.arrowed-right:after {
    right: -14px;
    border-width: 14px 7px;
  }
  .label-xlg.arrowed-in-right {
    margin-right: 7px;
  }
  .label-xlg.arrowed-in-right:after {
    right: -7px;
    border-width: 14px 7px;
  }
  .label-sm {
    padding: 0.2em 0.4em 0.3em;
    font-size: 11px;
    line-height: 1;
    height: 18px;
  }
  .label-sm.arrowed {
    margin-left: 4px;
  }
  .label-sm.arrowed:before {
    left: -8px;
    border-width: 9px 4px;
  }
  .label-sm.arrowed-in {
    margin-left: 4px;
  }
  .label-sm.arrowed-in:before {
    left: -4px;
    border-width: 9px 4px;
  }
  .label-sm.arrowed-right {
    margin-right: 4px;
  }
  .label-sm.arrowed-right:after {
    right: -8px;
    border-width: 9px 4px;
  }
  .label-sm.arrowed-in-right {
    margin-right: 4px;
  }
  .label-sm.arrowed-in-right:after {
    right: -4px;
    border-width: 9px 4px;
  }
  .label > span,
  .label > .ace-icon {
    line-height: 1;
    vertical-align: bottom;
  }
  .label.label-white {
    color: #879da9;
    border: 1px solid #abbac3;
    background-color: #f2f5f6;
    border-right-width: 1px;
    border-left-width: 2px;
  }
  .label-white.label-success {
    color: #7b9e6c;
    border-color: #9fbf92;
    background-color: #edf3ea;
  }
  .label-white.label-warning {
    color: #d9993e;
    border-color: #e4ae62;
    background-color: #fef6eb;
  }
  .label-white.label-primary {
    color: #6688a6;
    border-color: #8aafce;
    background-color: #eaf2f8;
  }
  .label-white.label-danger {
    color: #bd7f75;
    border-color: #d28679;
    background-color: #fcf4f2;
  }
  .label-white.label-info {
    color: #4e7a8f;
    border-color: #7aa1b4;
    background-color: #eaf3f7;
  }
  .label-white.label-inverse {
    color: #404040;
    border-color: #737373;
    background-color: #ededed;
  }
  .label-white.label-pink {
    color: #af6f87;
    border-color: #d299ae;
    background-color: #fbeff4;
  }
  .label-white.label-purple {
    color: #7d6fa2;
    border-color: #b7b1c6;
    background-color: #efedf5;
  }
  .label-white.label-yellow {
    color: #cfa114;
    border-color: #ecd181;
    background-color: #fdf7e4;
  }
  .label-white.label-grey {
    color: #878787;
    border-color: #cecece;
    background-color: #ededed;
  }
  @media screen and (-webkit-min-device-pixel-ratio: 1.08) and (-webkit-max-device-pixel-ratio: 1.15),
    screen and (min--moz-device-pixel-ratio: 1.08) and (max--moz-device-pixel-ratio: 1.15) {
    .label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed:before {
      border-width: 10.5px 6px 11px;
      left: -11px;
    }
    .label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed-right:after {
      border-width: 10.5px 6px 11px;
      right: -11px;
    }
    .label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed-in:before {
      border-width: 10.5px 5px 11px;
      left: -6px;
    }
    .label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed-in-right:after {
      border-width: 10.5px 5px 11px;
      right: -6px;
    }
  }
  @media screen and (-webkit-min-device-pixel-ratio: 1.2) and (-webkit-max-device-pixel-ratio: 1.45),
    screen and (min--moz-device-pixel-ratio: 1.2) and (max--moz-device-pixel-ratio: 1.45),
    screen and (-webkit-min-device-pixel-ratio: 1.6) and (-webkit-max-device-pixel-ratio: 1.9),
    screen and (min--moz-device-pixel-ratio: 1.6) and (max--moz-device-pixel-ratio: 1.9) {
    .label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed:before {
      border-width: 10.5px 6px;
      left: -11px;
    }
    .label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed-right:after {
      border-width: 10.5px 6px;
      right: -11px;
    }
    .label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed-in:before {
      border-width: 10.5px 5px 10px;
      left: -6px;
    }
    .label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed-in-right:after {
      border-width: 10.5px 5px 10px;
      right: -6px;
    }
  }
  @media screen and (-webkit-min-device-pixel-ratio: 1.5) and (-webkit-max-device-pixel-ratio: 1.6),
    screen and (min--moz-device-pixel-ratio: 1.5) and (max--moz-device-pixel-ratio: 1.6) {
    .label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed:before {
      border-width: 10px 6px;
      left: -12px;
    }
    .label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed-right:after {
      border-width: 10px 6px;
      right: -12px;
    }
  }
  @media screen and (-webkit-min-device-pixel-ratio: 1.7) and (-webkit-max-device-pixel-ratio: 1.8),
    screen and (min--moz-device-pixel-ratio: 1.7) and (max--moz-device-pixel-ratio: 1.8) {
    .label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed:before {
      border-width: 10px 6px;
      left: -11.5px;
    }
    .label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed-right:after {
      border-width: 10px 6px;
      right: -11.5px;
    }
    .label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed-in:before {
      border-width: 10px 5px;
      left: -6px;
    }
    .label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed-in-right:after {
      border-width: 10px 5px;
      right: -6px;
    }
  }
  @media screen and (-webkit-min-device-pixel-ratio: 0.8) and (-webkit-max-device-pixel-ratio: 0.9),
    screen and (min--moz-device-pixel-ratio: 0.8) and (max--moz-device-pixel-ratio: 0.9) {
    .label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed:before {
      border-width: 11px 6px;
      left: -11.5px;
    }
    .label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed-right:after {
      border-width: 11px 6px;
      right: -11.5px;
    }
    .label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed-in:before {
      border-width: 11px 5px;
      left: -6px;
    }
    .label:not(.label-lg):not(.label-xlg):not(.label-sm).arrowed-in-right:after {
      border-width: 11px 5px;
      right: -6px;
    }
  }
  @media screen and (-webkit-min-device-pixel-ratio: 1.08) and (-webkit-max-device-pixel-ratio: 1.15),
    screen and (min--moz-device-pixel-ratio: 1.08) and (max--moz-device-pixel-ratio: 1.15) {
    .label-lg.arrowed:before {
      left: -11px;
    }
    .label-lg.arrowed-right:after {
      right: -11px;
    }
  }
  @media screen and (-webkit-min-device-pixel-ratio: 1.7) and (-webkit-max-device-pixel-ratio: 1.8),
    screen and (min--moz-device-pixel-ratio: 1.7) and (max--moz-device-pixel-ratio: 1.8) {
    .label-lg.arrowed:before {
      left: -11.5px;
    }
    .label-lg.arrowed-right:after {
      right: -11.5px;
    }
    .label-lg.arrowed-in:before {
      border-width: 12.5px 6px 12px;
      left: -6px;
    }
    .label-lg.arrowed-in-right:after {
      border-width: 12.5px 6px 12px;
      right: -6px;
    }
  }
  @media screen and (-webkit-min-device-pixel-ratio: 1.08) and (-webkit-max-device-pixel-ratio: 1.15),
    screen and (min--moz-device-pixel-ratio: 1.08) and (max--moz-device-pixel-ratio: 1.15) {
    .label-xlg.arrowed:before {
      left: -13px;
    }
    .label-xlg.arrowed-right:after {
      right: -13px;
    }
    .label-xlg.arrowed-in:before {
      border-width: 14px 7px 14.5px;
    }
    .label-xlg.arrowed-in-right:after {
      border-width: 14px 7px 14.5px;
    }
  }
  @media screen and (-webkit-min-device-pixel-ratio: 1.2) and (-webkit-max-device-pixel-ratio: 1.3),
    screen and (min--moz-device-pixel-ratio: 1.2) and (max--moz-device-pixel-ratio: 1.3) {
    .label-xlg.arrowed:before {
      border-width: 14.5px 7px;
      left: -13.5px;
    }
    .label-xlg.arrowed-right:after {
      border-width: 14.5px 7px;
      right: -13.5px;
    }
    .label-xlg.arrowed-in:before {
      border-width: 14.5px 7px 14.5px;
    }
    .label-xlg.arrowed-in-right:after {
      border-width: 14.5px 7px 14.5px;
    }
  }
  @media screen and (-webkit-min-device-pixel-ratio: 1.3) and (-webkit-max-device-pixel-ratio: 1.4),
    screen and (-webkit-min-device-pixel-ratio: 1.5) and (-webkit-max-device-pixel-ratio: 1.6),
    screen and (min--moz-device-pixel-ratio: 1.3) and (max--moz-device-pixel-ratio: 1.4),
    screen and (min--moz-device-pixel-ratio: 1.5) and (max--moz-device-pixel-ratio: 1.6) {
    .label-xlg.arrowed:before {
      border-width: 14.5px 7.5px;
      left: -14.5px;
    }
    .label-xlg.arrowed-right:after {
      border-width: 14.5px 7.5px;
      right: -14.5px;
    }
    .label-xlg.arrowed-in:before {
      border-width: 14.5px 7px;
    }
    .label-xlg.arrowed-in-right:after {
      border-width: 14.5px 7px;
    }
  }
  @media screen and (-webkit-min-device-pixel-ratio: 1.08) and (-webkit-max-device-pixel-ratio: 1.15),
    screen and (min--moz-device-pixel-ratio: 1.08) and (max--moz-device-pixel-ratio: 1.15) {
    .label-sm.arrowed:before {
      border-width: 9px 5px;
      left: -9px;
    }
    .label-sm.arrowed-right:after {
      border-width: 9px 5px;
      right: -9px;
    }
    .label-sm.arrowed-in:before {
      border-width: 10px 4px;
    }
    .label-sm.arrowed-in-right:after {
      border-width: 10px 4px;
    }
  }
  @media screen and (-webkit-min-device-pixel-ratio: 1.2) and (-webkit-max-device-pixel-ratio: 1.3),
    screen and (min--moz-device-pixel-ratio: 1.2) and (max--moz-device-pixel-ratio: 1.3) {
    .label-sm.arrowed:before {
      border-width: 9.5px 5px;
      left: -10px;
    }
    .label-sm.arrowed-right:after {
      border-width: 9.5px 5px;
      right: -10px;
    }
    .label-sm.arrowed-in:before {
      border-width: 9.5px 4px;
    }
    .label-sm.arrowed-in-right:after {
      border-width: 9.5px 4px;
    }
  }
  .icon-white,
  .nav-pills > .active > a > .ace-icon,
  .nav-list > .active > a > .ace-icon,
  .navbar-inverse .nav > .active > a > .ace-icon,
  .dropdown-menu > li > a:hover > .ace-icon,
  .dropdown-menu > li > a:focus > .ace-icon,
  .dropdown-menu > .active > a > .ace-icon,
  .dropdown-submenu:hover > a > .ace-icon,
  .dropdown-submenu:focus > a > .ace-icon {
    background-image: none;
  }
  .dropdown-menu {
    border-radius: 0 !important;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  .dropdown-menu > li > a {
    font-size: 13px;
    padding-left: 11px;
    padding-right: 11px;
    padding-bottom: 4px;
    margin-bottom: 1px;
    margin-top: 1px;
  }
  .dropdown-menu.dropdown-only-icon {
    min-width: 50px;
  }
  .dropdown-menu.dropdown-only-icon > li {
    margin: 0 4px;
  }
  .dropdown-menu.dropdown-only-icon > li > a .ace-icon {
    width: 18px;
    display: inline-block;
    text-align: center;
  }
  .dropdown-menu.dropdown-only-icon > li > a .icon-2x {
    width: 36px;
  }
  .dropdown-menu > li:hover > a,
  .dropdown-menu > li > a:focus,
  .dropdown-menu > li > a:active,
  .dropdown-menu > li.active > a,
  .dropdown-menu > li.active:hover > a {
    background: #4f99c6;
    color: #ffffff;
  }
  .dropdown-default > li:hover > a,
  .dropdown-default > li > a:focus,
  .dropdown-default > li > a:active,
  .dropdown-default > li.active > a,
  .dropdown-default > li.active:hover > a {
    background: #abbac3;
    color: #ffffff;
  }
  .dropdown-info > li:hover > a,
  .dropdown-info > li > a:focus,
  .dropdown-info > li > a:active,
  .dropdown-info > li.active > a,
  .dropdown-info > li.active:hover > a {
    background: #6fb3e0;
    color: #ffffff;
  }
  .dropdown-primary > li:hover > a,
  .dropdown-primary > li > a:focus,
  .dropdown-primary > li > a:active,
  .dropdown-primary > li.active > a,
  .dropdown-primary > li.active:hover > a {
    background: #428bca;
    color: #ffffff;
  }
  .dropdown-success > li:hover > a,
  .dropdown-success > li > a:focus,
  .dropdown-success > li > a:active,
  .dropdown-success > li.active > a,
  .dropdown-success > li.active:hover > a {
    background: #87b87f;
    color: #ffffff;
  }
  .dropdown-warning > li:hover > a,
  .dropdown-warning > li > a:focus,
  .dropdown-warning > li > a:active,
  .dropdown-warning > li.active > a,
  .dropdown-warning > li.active:hover > a {
    background: #ffa24d;
    color: #ffffff;
  }
  .dropdown-danger > li:hover > a,
  .dropdown-danger > li > a:focus,
  .dropdown-danger > li > a:active,
  .dropdown-danger > li.active > a,
  .dropdown-danger > li.active:hover > a {
    background: #d15b47;
    color: #ffffff;
  }
  .dropdown-inverse > li:hover > a,
  .dropdown-inverse > li > a:focus,
  .dropdown-inverse > li > a:active,
  .dropdown-inverse > li.active > a,
  .dropdown-inverse > li.active:hover > a {
    background: #555555;
    color: #ffffff;
  }
  .dropdown-purple > li:hover > a,
  .dropdown-purple > li > a:focus,
  .dropdown-purple > li > a:active,
  .dropdown-purple > li.active > a,
  .dropdown-purple > li.active:hover > a {
    background: #9585bf;
    color: #ffffff;
  }
  .dropdown-pink > li:hover > a,
  .dropdown-pink > li > a:focus,
  .dropdown-pink > li > a:active,
  .dropdown-pink > li.active > a,
  .dropdown-pink > li.active:hover > a {
    background: #d6487e;
    color: #ffffff;
  }
  .dropdown-grey > li:hover > a,
  .dropdown-grey > li > a:focus,
  .dropdown-grey > li > a:active,
  .dropdown-grey > li.active > a,
  .dropdown-grey > li.active:hover > a {
    background: #a0a0a0;
    color: #ffffff;
  }
  .dropdown-light > li:hover > a,
  .dropdown-light > li > a:focus,
  .dropdown-light > li > a:active,
  .dropdown-light > li.active > a,
  .dropdown-light > li.active:hover > a {
    background: #e7e7e7;
    color: #333333;
  }
  .dropdown-lighter > li:hover > a,
  .dropdown-lighter > li > a:focus,
  .dropdown-lighter > li > a:active,
  .dropdown-lighter > li.active > a,
  .dropdown-lighter > li.active:hover > a {
    background: #efefef;
    color: #444444;
  }
  .dropdown-lightest > li:hover > a,
  .dropdown-lightest > li > a:focus,
  .dropdown-lightest > li > a:active,
  .dropdown-lightest > li.active > a,
  .dropdown-lightest > li.active:hover > a {
    background: #f3f3f3;
    color: #444444;
  }
  .dropdown-yellow > li:hover > a,
  .dropdown-yellow > li > a:focus,
  .dropdown-yellow > li > a:active,
  .dropdown-yellow > li.active > a,
  .dropdown-yellow > li.active:hover > a {
    background: #fee188;
    color: #444444;
  }
  .dropdown-yellow2 > li:hover > a,
  .dropdown-yellow2 > li > a:focus,
  .dropdown-yellow2 > li > a:active,
  .dropdown-yellow2 > li.active > a,
  .dropdown-yellow2 > li.active:hover > a {
    background: #f9e8b3;
    color: #444444;
  }
  .dropdown-light-blue > li:hover > a,
  .dropdown-light-blue > li > a:focus,
  .dropdown-light-blue > li > a:active,
  .dropdown-light-blue > li.active > a,
  .dropdown-light-blue > li.active:hover > a {
    background: #ecf3f9;
    color: #445566;
  }
  .dropdown-menu.dropdown-close {
    top: 92%;
    left: -5px;
  }
  .dropdown-menu.dropdown-close.dropdown-menu-right {
    left: auto;
    right: -5px;
  }
  .dropdown-menu.dropdown-closer {
    top: 80%;
    left: -10px;
  }
  .dropdown-menu.dropdown-closer.dropdown-menu-right {
    right: -10px;
    left: auto;
  }
  .dropup > .dropdown-menu,
  .navbar-fixed-bottom .dropdown > .dropdown-menu {
    top: auto !important;
    bottom: 100%;
  }
  .dropup > .dropdown-menu.dropdown-close,
  .navbar-fixed-bottom .dropdown > .dropdown-menu.dropdown-close {
    bottom: 92%;
  }
  .dropup > .dropdown-menu.dropdown-closer,
  .navbar-fixed-bottom .dropdown > .dropdown-menu.dropdown-closer {
    bottom: 80%;
  }
  .dropdown-submenu > .dropdown-menu {
    border-radius: 0;
  }
  .dropdown-submenu > a:after {
    margin-right: -5px;
  }
  .dropdown-50 {
    min-width: 50px;
  }
  .dropdown-75 {
    min-width: 75px;
  }
  .dropdown-100 {
    min-width: 100px;
  }
  .dropdown-125 {
    min-width: 125px;
  }
  .dropdown-150 {
    min-width: 150px;
  }
  .dropdown-hover {
    position: relative;
  }
  .dropdown-hover:before {
    display: block;
    content: '';
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
    background-color: transparent;
  }
  .dropdown-hover:hover > .dropdown-menu {
    display: block;
  }
  .dropdown-menu > .dropdown-hover > .dropdown-menu {
    top: -5px;
    left: 99%;
    right: auto;
  }
  .dropdown-menu > .dropdown-hover > .dropdown-menu.dropdown-menu-right {
    left: auto;
    right: 99%;
  }
  .dropup .dropdown-menu > .dropdown-hover > .dropdown-menu,
  .dropdown-menu > .dropdown-hover.dropup > .dropdown-menu {
    top: auto;
    bottom: -5px;
  }
  .dropdown-menu.dropdown-caret:before {
    border-bottom: 7px solid rgba(0, 0, 0, 0.2);
    -moz-border-bottom-colors: rgba(0, 0, 0, 0.2);
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    content: '';
    display: inline-block;
    left: 9px;
    position: absolute;
    top: -7px;
  }
  .dropdown-menu.dropdown-caret:after {
    border-bottom: 6px solid #fff;
    -moz-border-bottom-colors: #fff;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    content: '';
    display: inline-block;
    left: 10px;
    position: absolute;
    top: -6px;
  }
  .dropdown-menu.dropdown-menu-right.dropdown-caret:before,
  .dropdown-menu.dropdown-caret-right.dropdown-caret:before {
    left: auto;
    right: 9px;
  }
  .dropdown-menu.dropdown-menu-right.dropdown-caret:after,
  .dropdown-menu.dropdown-caret-right.dropdown-caret:after {
    left: auto;
    right: 10px;
  }
  .dropup > .dropdown-menu.dropdown-caret:before,
  .navbar-fixed-bottom .dropdown > .dropdown-menu.dropdown-caret:before {
    border-bottom-width: 0;
    border-top: 7px solid rgba(0, 0, 0, 0.2);
    -moz-border-top-colors: rgba(0, 0, 0, 0.2);
    bottom: -7px;
    top: auto;
  }
  .dropup > .dropdown-menu.dropdown-caret:after,
  .navbar-fixed-bottom .dropdown > .dropdown-menu.dropdown-caret:after {
    border-bottom-width: 0;
    border-top: 6px solid #fff;
    -moz-border-top-colors: #fff;
    bottom: -6px;
    top: auto;
  }
  .dropdown-colorpicker > .dropdown-menu {
    padding: 4px;
    min-width: 130px;
    max-width: 130px;
    top: 80%;
    left: -7px;
  }
  .dropdown-colorpicker > .dropdown-menu.dropdown-menu-right {
    right: -7px;
    left: auto;
  }
  .dropdown-colorpicker > .dropdown-menu > li {
    display: block;
    float: left;
    width: 20px;
    height: 20px;
    margin: 2px;
  }
  .dropdown-colorpicker > .dropdown-menu > li > .colorpick-btn {
    display: block;
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0;
    border-radius: 0;
    position: relative;
    -webkit-transition: all ease 0.1s;
    -o-transition: all ease 0.1s;
    transition: all ease 0.1s;
  }
  .dropdown-colorpicker > .dropdown-menu > li > .colorpick-btn:hover {
    text-decoration: none;
    opacity: 0.8;
    filter: alpha(opacity=80);
    -webkit-transform: scale(1.08, 1.08);
    -ms-transform: scale(1.08, 1.08);
    -o-transform: scale(1.08, 1.08);
    transform: scale(1.08, 1.08);
  }
  .dropdown-colorpicker > .dropdown-menu > li > .colorpick-btn.selected:after {
    content: '\f00c';
    display: inline-block;
    font-family: FontAwesome;
    font-size: 11px;
    color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 20px;
  }
  .btn-colorpicker {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #ddd;
    vertical-align: middle;
    border-radius: 0;
  }
  .dropdown-navbar {
    padding: 0;
    width: 240px;
    -webkit-box-shadow: 0 2px 4px rgba(30, 30, 100, 0.25);
    box-shadow: 0 2px 4px rgba(30, 30, 100, 0.25);
    border-color: #bcd4e5;
  }
  .dropdown-navbar > li {
    padding: 0 8px;
    background-color: #fff;
  }
  .dropdown-navbar > li.dropdown-header {
    text-shadow: none;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 34px;
    font-size: 13px;
    font-weight: bold;
    text-transform: none;
    border-bottom: 1px solid;
  }
  .dropdown-navbar > li > .ace-icon,
  .dropdown-navbar > li > a > .ace-icon {
    margin-right: 5px !important;
    color: #555;
    font-size: 14px;
  }
  .dropdown-navbar > li > a {
    padding: 10px 2px;
    margin: 0;
    border-top: 1px solid;
    font-size: 12px;
    line-height: 16px;
    color: #555 !important;
    background-color: transparent !important;
    white-space: normal;
  }
  .dropdown-navbar > li > a .progress {
    margin-bottom: 0;
    margin-top: 4px;
  }
  .dropdown-navbar > li > a .badge {
    line-height: 16px;
    padding-right: 4px;
    padding-left: 4px;
    font-size: 12px;
  }
  .dropdown-navbar > li:first-child > a,
  .dropdown-navbar > li.dropdown-header + li > a {
    border-top-width: 0;
  }
  .dropdown-navbar > li.dropdown-footer > a {
    color: #4f99c6 !important;
    text-align: center;
    font-size: 13px;
  }
  .dropdown-navbar > li.dropdown-footer > a:hover {
    background-color: #fff;
    text-decoration: underline;
  }
  .dropdown-navbar > li.dropdown-footer > a:hover > .ace-icon {
    text-decoration: none;
  }
  .dropdown-navbar > li:hover {
    background-color: #f4f9fc;
  }
  .dropdown-navbar > li.dropdown-header {
    background-color: #ecf2f7;
    color: #8090a0;
    border-bottom-color: #bcd4e5;
  }
  .dropdown-navbar > li.dropdown-header > .ace-icon {
    color: #8090a0;
  }
  .dropdown-navbar > li > a {
    border-top-color: #e4ecf3;
  }
  .dropdown-navbar.navbar-pink {
    border-color: #e5bcd4;
  }
  .dropdown-navbar.navbar-pink > li:hover {
    background-color: #fcf4f9;
  }
  .dropdown-navbar.navbar-pink > li.dropdown-header {
    background-color: #f7ecf2;
    color: #b471a0;
    border-bottom-color: #e5bcd4;
  }
  .dropdown-navbar.navbar-pink > li.dropdown-header > .ace-icon {
    color: #c06090;
  }
  .dropdown-navbar.navbar-pink > li > a {
    border-top-color: #f3e4ec;
  }
  .dropdown-navbar.navbar-grey {
    border-color: #e5e5e5;
  }
  .dropdown-navbar.navbar-grey > li:hover {
    background-color: #f8f8f8;
  }
  .dropdown-navbar.navbar-grey > li.dropdown-header {
    background-color: #f2f2f2;
    color: #3a87ad;
    border-bottom-color: #e5e5e5;
  }
  .dropdown-navbar.navbar-grey > li.dropdown-header > .ace-icon {
    color: #3a87ad;
  }
  .dropdown-navbar.navbar-grey > li > a {
    border-top-color: #eeeeee;
  }
  .dropdown-navbar.navbar-green {
    border-color: #b4d5ac;
  }
  .dropdown-navbar.navbar-green > li:hover {
    background-color: #f4f9ef;
  }
  .dropdown-navbar.navbar-green > li.dropdown-header {
    background-color: #ebf7e4;
    color: #88aa66;
    border-bottom-color: #b4d5ac;
  }
  .dropdown-navbar.navbar-green > li.dropdown-header > .ace-icon {
    color: #90c060;
  }
  .dropdown-navbar.navbar-green > li > a {
    border-top-color: #ecf3e4;
  }
  .dropdown-navbar [class*='btn'].ace-icon {
    display: inline-block;
    margin: 0 5px 0 0;
    width: 24px;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
  .dropdown-navbar .msg-photo {
    max-width: 42px;
    float: left;
    margin-top: 2px;
  }
  .dropdown-navbar .msg-body {
    display: block;
    line-height: 20px;
    white-space: normal;
    vertical-align: middle;
    margin-left: 50px;
  }
  .dropdown-navbar .msg-title {
    display: inline-block;
    line-height: 14px;
  }
  .dropdown-navbar .msg-time {
    display: block;
    font-size: 11px;
    color: #777;
  }
  .dropdown-navbar .msg-time > .ace-icon {
    font-size: 14px;
    color: #555;
  }
  .dropdown-navbar > .dropdown-content {
    padding: 0;
  }
  .dropdown-navbar > .dropdown-content .dropdown-navbar {
    list-style: none;
    margin: 0;
    padding: 0 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: auto;
    display: block;
    float: none;
    border-width: 0;
    position: static;
    z-index: auto;
  }
  .dropdown-navbar .nav-tabs {
    border-width: 0;
    box-shadow: none;
    background-color: #f9f9f9 !important;
    top: auto;
    width: 100%;
  }
  .dropdown-navbar .nav-tabs > li {
    display: table-cell;
    width: 1%;
    float: none !important;
  }
  .dropdown-navbar .nav-tabs > li > a {
    margin: 0 0 0 1px !important;
    text-align: center;
    box-shadow: none !important;
    background-color: #f9f9f9 !important;
    border-width: 0 !important;
    border-bottom: 2px solid #becad1 !important;
  }
  .dropdown-navbar .nav-tabs > li > a:before {
    display: block;
    content: '';
    position: absolute;
    left: -1px;
    top: 3px;
    bottom: 3px;
    width: 1px;
    background-color: #becad1;
  }
  .dropdown-navbar .nav-tabs > li:first-child > a {
    margin-left: 0 !important;
  }
  .dropdown-navbar .nav-tabs > li:first-child > a:before {
    display: none;
  }
  .dropdown-navbar .nav-tabs > li.active > a {
    border-bottom: 2px solid #79b0ce !important;
    color: #5085af !important;
    background-color: #ecf2f7 !important;
  }
  .dropdown-navbar .tab-content {
    padding: 0 !important;
    border-color: #fff !important;
  }
  .dropdown-navbar .tab-content .dropdown-menu {
    display: block;
    position: static;
    margin: 0 !important;
    padding-top: 2px;
    border-color: #fff !important;
    box-shadow: none !important;
    width: 100%;
  }
  .dropdown-navbar.navbar-pink .nav-tabs > li.active > a {
    border-bottom: 2px solid #e5bcd4 !important;
    color: #c06090 !important;
    background-color: #f7ecf2 !important;
  }
  .dropdown-navbar.navbar-pink .nav-tabs > li:not(.active) > a:hover {
    color: #ba7396;
  }
  .dropdown-navbar.navbar-pink .nav-tabs > li > a:before {
    background-color: #cebbcb;
  }
  .dropdown-navbar.navbar-pink .nav-tabs > li > a {
    border-bottom-color: #cebbcd !important;
  }
  .form-line {
    margin-bottom: 24px;
    padding-bottom: 12px;
    border-bottom: 1px solid #eee;
  }
  .form-actions {
    display: block;
    background-color: #f5f5f5;
    border-top: 1px solid #e5e5e5;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 19px 20px 20px;
  }
  .help-button {
    display: inline-block;
    height: 22px;
    width: 22px;
    line-height: 22px;
    text-align: center;
    padding: 0;
    background-color: #65bcda;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    cursor: default;
    margin-left: 4px;
    border-radius: 100%;
    border-color: #fff;
    border: 2px solid #fff;
    -webkit-box-shadow: 0px 1px 0px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 0px 1px rgba(0, 0, 0, 0.2);
  }
  .help-button:hover {
    background-color: #65bcda;
    text-shadow: none;
  }
  label {
    font-weight: normal;
    font-size: 14px;
  }
  .form-group > label[class*='col-'] {
    margin-bottom: 4px;
  }
  td > label,
  th > label {
    margin-bottom: 0;
    line-height: inherit;
    vertical-align: middle;
  }
  label.inline {
    margin-bottom: 3px;
  }
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'],
  input[type='search'],
  input[type='tel'],
  input[type='color'] {
    border-radius: 0 !important;
    color: #858585;
    background-color: #ffffff;
    border: 1px solid #d5d5d5;
    padding: 5px 4px 6px;
    font-size: 14px;
    font-family: inherit;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
  }
  textarea:hover,
  input[type='text']:hover,
  input[type='password']:hover,
  input[type='datetime']:hover,
  input[type='datetime-local']:hover,
  input[type='date']:hover,
  input[type='month']:hover,
  input[type='time']:hover,
  input[type='week']:hover,
  input[type='number']:hover,
  input[type='email']:hover,
  input[type='url']:hover,
  input[type='search']:hover,
  input[type='tel']:hover,
  input[type='color']:hover {
    border-color: #b5b5b5;
  }
  textarea:focus,
  input[type='text']:focus,
  input[type='password']:focus,
  input[type='datetime']:focus,
  input[type='datetime-local']:focus,
  input[type='date']:focus,
  input[type='month']:focus,
  input[type='time']:focus,
  input[type='week']:focus,
  input[type='number']:focus,
  input[type='email']:focus,
  input[type='url']:focus,
  input[type='search']:focus,
  input[type='tel']:focus,
  input[type='color']:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #696969;
    border-color: #f59942;
    background-color: #ffffff;
    outline: none;
  }
  input::-webkit-input-placeholder,
  .form-control::-webkit-input-placeholder {
    color: #c0c0c0;
  }
  input:-moz-placeholder,
  .form-control:-moz-placeholder {
    color: #c0c0c0;
    opacity: 1;
  }
  input::-moz-placeholder,
  .form-control::-moz-placeholder {
    color: #c0c0c0;
    opacity: 1;
  }
  input:-ms-input-placeholder,
  .form-control:-ms-input-placeholder {
    color: #c0c0c0;
  }
  .form-control,
  select {
    border-radius: 0;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: #858585;
    background-color: #ffffff;
    border: 1px solid #d5d5d5;
  }
  .form-control:focus,
  select:focus {
    color: #696969;
    border-color: #f59942;
    background-color: #ffffff;
    outline: none;
  }
  textarea.form-control {
    padding: 5px 9px;
  }
  select {
    padding: 3px 4px;
  }
  select.form-control {
    padding: 4px 6px;
  }
  select[multiple],
  select.form-control[multiple] {
    height: auto;
  }
  select.input-sm {
    border-radius: 0;
    padding: 2px 3px;
  }
  select.input-lg {
    border-radius: 0;
    padding: 6px 8px;
  }
  input.block {
    display: block;
    margin-bottom: 9px;
  }
  textarea.autosize-transition {
    -webkit-transition-duration: 'height 0.2s';
    transition-duration: 'height 0.2s';
  }
  .limiterBox {
    border: 1px solid #222;
    border-top: none;
    background-color: #333;
    padding: 3px 6px;
    font-size: 13px;
    color: #fff;
    margin-top: 6px;
  }
  .limiterBox:after {
    display: none;
  }
  .limiterBox:before {
    display: block;
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: -8px;
    left: 50%;
    margin-left: -5px;
    border-color: transparent;
    border-style: solid;
    border-bottom-color: #333;
    -moz-border-bottom-colors: #333;
    border-width: 0 8px 8px;
  }
  select option,
  select.form-control option {
    padding: 3px 4px 5px;
  }
  select option:active,
  select.form-control option:active,
  select option:hover,
  select.form-control option:hover,
  select option:focus,
  select.form-control option:focus {
    background-color: #eeeeee;
    color: #444;
  }
  select option.no-option,
  select.form-control option.no-option {
    padding: 1px 0;
  }
  input[disabled] {
    color: #848484 !important;
    background-color: #eeeeee !important;
  }
  input[disabled]:hover {
    border-color: #d5d5d5 !important;
  }
  input[readonly] {
    color: #939192;
    background: #f5f5f5 !important;
    cursor: default;
  }
  input[readonly]:hover {
    border-color: #c3c3c3;
  }
  input[readonly]:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #aaaaaa;
    background-color: #f9f9f9;
  }
  .help-inline {
    font-size: 13px !important;
  }
  .input-icon {
    position: relative;
  }
  span.input-icon {
    display: inline-block;
  }
  .input-icon > input {
    padding-left: 24px;
    padding-right: 6px;
  }
  .input-icon.input-icon-right > input {
    padding-left: 6px;
    padding-right: 24px;
  }
  .input-icon > .ace-icon {
    padding: 0 3px;
    z-index: 2;
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 3px;
    line-height: 30px;
    display: inline-block;
    color: #909090;
    font-size: 16px;
  }
  .input-icon.input-icon-right > .ace-icon {
    left: auto;
    right: 3px;
  }
  .input-icon > input:focus + .ace-icon {
    color: #579;
  }
  .input-icon ~ .help-inline {
    padding-left: 8px;
  }
  .form-search .radio [type='radio'] + label,
  .form-inline .radio [type='radio'] + label,
  .form-search .checkbox [type='checkbox'] + label,
  .form-inline .checkbox [type='checkbox'] + label {
    float: left;
    margin-left: -20px;
  }
  .form-search .form-search .radio [type='radio'] + label,
  .form-search .form-inline .radio [type='radio'] + label,
  .form-search .form-search .checkbox [type='checkbox'] + label,
  .form-search .form-inline .checkbox [type='checkbox'] + label,
  .form-inline .form-search .radio [type='radio'] + label,
  .form-inline .form-inline .radio [type='radio'] + label,
  .form-inline .form-search .checkbox [type='checkbox'] + label,
  .form-inline .form-inline .checkbox [type='checkbox'] + label {
    margin-left: 0;
    margin-right: 3px;
  }
  .form-search .input-append .search-query:focus,
  .form-search .input-prepend .search-query:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .input-append input,
  .input-prepend input,
  .input-append select,
  .input-prepend select,
  .input-append .uneditable-input,
  .input-prepend .uneditable-input {
    border-radius: 0;
  }
  .input-mini {
    width: 60px;
    max-width: 100%;
  }
  .input-small {
    width: 90px;
    max-width: 100%;
  }
  .input-medium,
  .input-md {
    width: 150px;
    max-width: 100%;
  }
  .input-large {
    width: 210px;
    max-width: 100%;
  }
  .input-xlarge {
    width: 270px;
    max-width: 100%;
  }
  .input-xxlarge {
    width: 530px;
    max-width: 100%;
  }
  input.input-lg {
    font-size: 18px;
  }
  input[type='checkbox'].ace,
  input[type='radio'].ace {
    opacity: 0;
    position: absolute;
    z-index: 1;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  label input[type='checkbox'].ace,
  label input[type='radio'].ace {
    z-index: -100 !important;
    width: 1px !important;
    height: 1px !important;
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute;
  }
  input[type='checkbox'].ace:checked,
  input[type='radio'].ace:checked,
  input[type='checkbox'].ace:focus,
  input[type='radio'].ace:focus {
    outline: none !important;
  }
  input[type='checkbox'].ace + .lbl,
  input[type='radio'].ace + .lbl {
    position: relative;
    display: inline-block;
    margin: 0;
    line-height: 20px;
    min-height: 18px;
    min-width: 18px;
    font-weight: normal;
    cursor: pointer;
  }
  input[type='checkbox'].ace + .lbl::before,
  input[type='radio'].ace + .lbl::before {
    cursor: pointer;
    font-family: fontAwesome;
    font-weight: normal;
    font-size: 12px;
    color: #fff;
    content: '\a0';
    background-color: #fafafa;
    border: 1px solid #c8c8c8;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    /*, inset 0px -15px 10px -12px rgba(0,0,0,0.05);*/
    border-radius: 0;
    display: inline-block;
    text-align: center;
    height: 16px;
    line-height: 14px;
    min-width: 16px;
    margin-right: 1px;
    position: relative;
    top: -1px;
  }
  input[type='checkbox'].ace:checked + .lbl::before,
  input[type='radio'].ace:checked + .lbl::before {
    display: inline-block;
    content: '\f00c';
    color: #32a3ce;
    background-color: #f5f8fc;
    border-color: #adb8c0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
      inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  }
  input[type='checkbox'].ace:hover + .lbl::before,
  input[type='radio'].ace:hover + .lbl::before,
  input[type='checkbox'].ace + .lbl:hover::before,
  input[type='radio'].ace + .lbl:hover::before {
    border-color: #ff893c;
  }
  input[type='checkbox'].ace:focus + .lbl::before,
  input[type='radio'].ace:focus + .lbl::before {
    border-color: #f59942;
  }
  input[type='checkbox'].ace:active + .lbl::before,
  input[type='radio'].ace:active + .lbl::before,
  input[type='checkbox'].ace:checked:active + .lbl::before,
  input[type='radio'].ace:checked:active + .lbl::before {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  }
  input[type='checkbox'].ace.ace-checkbox-2 + .lbl::before,
  input[type='radio'].ace.ace-checkbox-2 + .lbl::before {
    box-shadow: none;
  }
  input[type='checkbox'].ace.ace-checkbox-2:checked + .lbl::before,
  input[type='radio'].ace.ace-checkbox-2:checked + .lbl::before {
    background-color: #f9a021;
    border-color: #f9a021;
    color: #fff;
  }
  input[type='checkbox'].ace:disabled + .lbl::before,
  input[type='radio'].ace:disabled + .lbl::before,
  input[type='checkbox'].ace[disabled] + .lbl::before,
  input[type='radio'].ace[disabled] + .lbl::before,
  input[type='checkbox'].ace.disabled + .lbl::before,
  input[type='radio'].ace.disabled + .lbl::before {
    background-color: #ddd !important;
    border-color: #ccc !important;
    box-shadow: none !important;
    color: #bbb;
  }
  .checkbox label input[type='checkbox'].ace + .lbl,
  .radio label input[type='radio'].ace + .lbl {
    margin-left: -10px;
  }
  /**
input[type=radio].ace @{lbl}::before {
	border-radius:100%;
	font-size: 34px;
	font-family: Helvetica, Arial, "Sans-Serif";//looks better but incosistent
	line-height: 13px;
}
*/
  input[type='radio'].ace + .lbl::before {
    border-radius: 100%;
    font-size: 10px;
    font-family: FontAwesome;
    text-shadow: 0 0 1px #32a3ce;
    line-height: 15px;
    height: 17px;
    min-width: 17px;
  }
  input[type='radio'].ace:checked + .lbl::before {
    content: '\f111';
  }
  input[type='checkbox'].ace.input-lg + .lbl::before {
    border-radius: 4px;
    font-size: 16px;
    height: 24px;
    line-height: 21px;
    min-width: 24px;
    top: auto;
  }
  input[type='radio'].ace.input-lg + .lbl::before {
    font-size: 14px;
    height: 24px;
    line-height: 22px;
    min-width: 24px;
    top: auto;
  }
  /* CSS3 on/off switches */
  input[type='checkbox'].ace.ace-switch {
    width: 55px;
    height: 25px;
  }
  input[type='checkbox'].ace.ace-switch + .lbl {
    margin: 0 4px;
    min-height: 24px;
  }
  input[type='checkbox'].ace.ace-switch + .lbl::before {
    font-family: 'Open Sans';
    content: 'ON\a0\a0\a0\a0\a0\a0\a0\a0\a0OFF';
    color: #999;
    text-shadow: 0 0 0 #999;
    font-weight: normal;
    font-size: 11px;
    line-height: 17px;
    height: 20px;
    overflow: hidden;
    border-radius: 12px;
    background-color: #f5f5f5;
    -webkit-box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.15);
    border: 1px solid #ccc;
    text-align: left;
    float: left;
    padding: 0;
    width: 52px;
    text-indent: -21px;
    margin-right: 0;
    -webkit-transition: text-indent 0.25s ease;
    -o-transition: text-indent 0.25s ease;
    transition: text-indent 0.25s ease;
    top: auto;
  }
  input[type='checkbox'].ace.ace-switch + .lbl::after {
    font-family: 'Open Sans';
    content: 'III';
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0;
    color: #aaa;
    text-shadow: none;
    background-color: #fff;
    border-radius: 100%;
    width: 22px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    position: absolute;
    top: -2px;
    left: -3px;
    -webkit-box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.3);
    -webkit-transition: left 0.25s ease;
    -o-transition: left 0.25s ease;
    transition: left 0.25s ease;
  }
  input[type='checkbox'].ace.ace-switch:checked + .lbl::before {
    text-indent: 8px;
    color: #fff;
    text-shadow: 0 0 0 #fff;
    background-color: #8ab2c9;
    border-color: #6a8ca8;
  }
  input[type='checkbox'].ace.ace-switch:checked + .lbl::after {
    left: 34px;
    background-color: #fff;
    color: #98a0a5;
  }
  input[type='checkbox'].ace.ace-switch.ace-switch-2 + .lbl::before {
    content: 'YES\a0\a0\a0\a0\a0\a0\a0\a0\a0NO';
  }
  input[type='checkbox'].ace.ace-switch.ace-switch-3 + .lbl::after {
    font-family: FontAwesome;
    font-size: 13px;
    line-height: 22px;
    content: '\f00d';
    top: -1px;
    text-shadow: none;
    padding: 0;
    text-align: center;
    color: #bbb;
    letter-spacing: 0;
  }
  input[type='checkbox'].ace.ace-switch.ace-switch-3:checked + .lbl::after {
    content: '\f00c';
    color: #8ab2c9;
    text-shadow: none;
  }
  input[type='checkbox'].ace.ace-switch.ace-switch-4,
  input[type='checkbox'].ace.ace-switch.ace-switch-5 {
    width: 60px;
  }
  input[type='checkbox'].ace.ace-switch.ace-switch-4 + .lbl::before,
  input[type='checkbox'].ace.ace-switch.ace-switch-5 + .lbl::before {
    content: 'ON\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0OFF';
    font-size: 12px;
    line-height: 21px;
    height: 24px;
    overflow: hidden;
    border-radius: 12px;
    background-color: #8b9aa3;
    border: 1px solid #8b9aa3;
    color: #fff;
    width: 56px;
    text-indent: -25px;
    text-shadow: 0 0 0 #fff;
    display: inline-block;
    position: relative;
    box-shadow: none;
    -webkit-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
  input[type='checkbox'].ace.ace-switch.ace-switch-4 + .lbl::after,
  input[type='checkbox'].ace.ace-switch.ace-switch-5 + .lbl::after {
    content: 'III';
    font-size: 11px;
    position: absolute;
    top: 2px;
    left: 2px;
    letter-spacing: 0;
    width: 20px;
    height: 20px;
    line-height: 19px;
    text-shadow: none !important;
    color: #939393;
    background-color: #fff;
    -webkit-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
  input[type='checkbox'].ace.ace-switch.ace-switch-4:checked + .lbl::before,
  input[type='checkbox'].ace.ace-switch.ace-switch-5:checked + .lbl::before {
    text-indent: 9px;
    background-color: #468fcc;
    border-color: #468fcc;
  }
  input[type='checkbox'].ace.ace-switch.ace-switch-4:checked + .lbl::after,
  input[type='checkbox'].ace.ace-switch.ace-switch-5:checked + .lbl::after {
    left: 34px;
    background-color: #fff;
    color: #848484;
  }
  input[type='checkbox'].ace.ace-switch.ace-switch-5 + .lbl::before {
    content: 'YES\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0NO';
  }
  input[type='checkbox'].ace.ace-switch.ace-switch-5:checked + .lbl::before {
    text-indent: 8px;
  }
  input[type='checkbox'].ace.ace-switch.ace-switch-6 + .lbl {
    position: relative;
  }
  input[type='checkbox'].ace.ace-switch.ace-switch-6 + .lbl::before {
    font-family: FontAwesome;
    content: '\f00d';
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    box-shadow: none;
    border-width: 0;
    font-weight: lighter;
    font-size: 16px;
    border-radius: 12px;
    display: inline-block;
    background-color: #888;
    color: #f2f2f2;
    width: 52px;
    height: 22px;
    line-height: 21px;
    text-indent: 32px;
    -webkit-transition: background 0.25s ease;
    -o-transition: background 0.25s ease;
    transition: background 0.25s ease;
  }
  input[type='checkbox'].ace.ace-switch.ace-switch-6 + .lbl::after {
    content: '';
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 2px;
    left: 3px;
    border-radius: 12px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    width: 18px;
    height: 18px;
    text-align: center;
    background-color: #f2f2f2;
    border: 4px solid #f2f2f2;
    -webkit-transition: left 0.25s ease;
    -o-transition: left 0.25s ease;
    transition: left 0.25s ease;
  }
  input[type='checkbox'].ace.ace-switch.ace-switch-6:checked + .lbl::before {
    content: '\f00c';
    text-indent: 6px;
    color: #fff;
    border-color: #b7d3e5;
    background-color: #ff893c;
  }
  input[type='checkbox'].ace.ace-switch.ace-switch-6:checked + .lbl::after {
    left: 32px;
    background-color: #fff;
    border: 4px solid #fff;
    text-shadow: 0 -1px 0 rgba(0, 200, 0, 0.25);
  }
  input[type='checkbox'].ace.ace-switch.ace-switch-7 {
    width: 75px;
  }
  input[type='checkbox'].ace.ace-switch.ace-switch-7 + .lbl {
    position: relative;
  }
  input[type='checkbox'].ace.ace-switch.ace-switch-7 + .lbl::before {
    content: 'OFF\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0ON';
    font-weight: bolder;
    font-size: 13px;
    line-height: 20px;
    background-color: #fff;
    border: 2px solid #aaa;
    border-radius: 0;
    box-shadow: none;
    color: #aaa;
    width: 74px;
    height: 26px;
    line-height: 22px;
    overflow: hidden;
    text-indent: 5px;
    display: inline-block;
    position: relative;
    -webkit-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
  input[type='checkbox'].ace.ace-switch.ace-switch-7 + .lbl::after {
    content: '\f00d';
    font-family: FontAwesome;
    font-size: 16px;
    position: absolute;
    top: 3px;
    left: 39px;
    width: 32px;
    height: 20px;
    line-height: 18px;
    text-align: center;
    padding: 0;
    text-indent: 0;
    background-color: #aaa;
    color: #fff;
    border-radius: 0;
    box-shadow: none;
    -webkit-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
  input[type='checkbox'].ace.ace-switch.ace-switch-7:checked + .lbl::before {
    color: #468fcc;
    background-color: #fff;
    text-indent: -28px;
    border-color: #6fb3e0;
  }
  input[type='checkbox'].ace.ace-switch.ace-switch-7:checked + .lbl::after {
    left: 3px;
    content: '\f00c';
    background-color: #468fcc;
    color: #fff;
  }
  input[type='checkbox'].ace.ace-switch + .lbl[data-lbl]::before {
    content: attr(data-lbl);
  }
  input[type='checkbox'].ace.ace-switch.btn-empty + .lbl::after {
    content: '';
  }
  input[type='checkbox'].ace.ace-switch.btn-rotate + .lbl::after {
    content: '\2261';
    line-height: 20px;
    font-size: 17px;
  }
  input[type='checkbox'].ace.ace-switch-4.btn-rotate + .lbl::after,
  input[type='checkbox'].ace.ace-switch-5.btn-rotate + .lbl::after {
    line-height: 17px;
  }
  input[type='checkbox'].ace.ace-switch.btn-flat + .lbl::before,
  input[type='checkbox'].ace.ace-switch.btn-flat + .lbl::after {
    border-radius: 0 !important;
  }
  input[type='checkbox'].ace.ace-switch.ace-switch-4 + .lbl::before,
  input[type='checkbox'].ace.ace-switch.ace-switch-5 + .lbl::before {
    text-indent: -24px;
  }
  input[type='checkbox'].ace.ace-switch.ace-switch-4:checked + .lbl::before,
  input[type='checkbox'].ace.ace-switch.ace-switch-5:checked + .lbl::before {
    text-indent: 7px;
  }
  input.ace + .lbl.padding-16::before {
    margin-right: 16px;
  }
  input.ace + .lbl.padding-14::before {
    margin-right: 14px;
  }
  input.ace + .lbl.padding-12::before {
    margin-right: 12px;
  }
  input.ace + .lbl.padding-10::before {
    margin-right: 10px;
  }
  input.ace + .lbl.padding-8::before {
    margin-right: 8px;
  }
  input.ace + .lbl.padding-6::before {
    margin-right: 6px;
  }
  input.ace + .lbl.padding-4::before {
    margin-right: 4px;
  }
  input.ace + .lbl.padding-2::before {
    margin-right: 2px;
  }
  input.ace + .lbl.padding-0::before {
    margin-right: 0px;
  }
  .ace-file-input {
    display: block;
    font-size: inherit;
    position: relative;
    height: 30px;
  }
  .ace-file-input input[type='file'] {
    position: absolute;
    z-index: -999;
    width: 1px;
    height: 1px;
    overflow: hidden;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  .ace-file-input input[type='file']:focus {
    outline: none;
  }
  .ace-file-input .ace-file-container {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 30px;
    background-color: #ffffff;
    border: 1px solid #d5d5d5;
    cursor: pointer;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: all 0.15s;
    -o-transition: all 0.15s;
    transition: all 0.15s;
  }
  .ace-file-input .ace-file-container:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #f59942;
  }
  .ace-file-input .ace-file-container:before {
    display: inline-block;
    content: attr(data-title);
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    line-height: 24px;
    text-align: center;
    padding: 0 8px;
    background-color: #6fb3e0;
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    border: 2px solid #fff;
    border-left-width: 4px;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }
  .ace-file-input .ace-file-container .ace-file-name {
    display: inline-block;
    height: 28px;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    line-height: 28px;
    color: #888888;
    font-size: 13px;
    vertical-align: top;
    position: static;
    padding-left: 30px;
  }
  .ace-file-input .ace-file-container .ace-file-name:after {
    display: inline-block;
    content: attr(data-title);
  }
  .ace-file-input .ace-file-container.selected {
    right: 16px;
  }
  .ace-file-input .ace-file-container.selected .ace-file-name {
    color: #666666;
  }
  .ace-file-input .ace-file-container .ace-icon {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    line-height: 24px;
    width: 26px;
    text-align: center;
    font-family: FontAwesome;
    font-size: 13px;
    border: 2px solid #fff;
    color: #fff;
    -webkit-transition: all 0.1s;
    -o-transition: all 0.1s;
    transition: all 0.1s;
    background-color: #d1d1d1;
  }
  .ace-file-input .ace-file-container.selected .ace-file-name .ace-icon {
    background-color: #efad62;
  }
  .ace-file-input .ace-file-container.selected .ace-file-name .file-image {
    background-color: #bd7a9d;
  }
  .ace-file-input .ace-file-container.selected .ace-file-name .file-video {
    background-color: #87b87f;
  }
  .ace-file-input .ace-file-container.selected .ace-file-name .file-audio {
    background-color: #8b7ac9;
  }
  .ace-file-input .ace-file-container.selected .ace-file-name .file-archive {
    background-color: #efad62;
  }
  .ace-file-input .ace-file-container.hide-placeholder:before {
    display: none;
  }
  .ace-file-input a:hover {
    text-decoration: none;
  }
  .ace-file-input .remove {
    position: absolute;
    right: -8px;
    top: 6px;
    display: none;
    width: 17px;
    text-align: center;
    height: 17px;
    line-height: 15px;
    font-size: 11px;
    font-weight: normal;
    background-color: #fb7142;
    border-radius: 100%;
    color: #fff;
    text-decoration: none;
  }
  .ace-file-input .ace-file-container.selected + .remove {
    display: inline-block;
  }
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    .ace-file-input input[type='file'] {
      position: absolute;
      width: 0;
      height: 0;
    }
  }
  .ace-file-input input[type='file'].disabled + .ace-file-container,
  .ace-file-input input[type='file'][disabled] + .ace-file-container,
  .ace-file-input input[type='file'][readonly] + .ace-file-container {
    cursor: not-allowed;
    background-color: #eee;
  }
  .ace-file-input input[type='file'].disabled + .ace-file-container:hover,
  .ace-file-input input[type='file'][disabled] + .ace-file-container:hover,
  .ace-file-input input[type='file'][readonly] + .ace-file-container:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #e3e3e3;
  }
  .ace-file-input input[type='file'].disabled + .ace-file-container:before,
  .ace-file-input input[type='file'][disabled] + .ace-file-container:before,
  .ace-file-input input[type='file'][readonly] + .ace-file-container:before {
    border-color: #eee;
    background-color: #a1aaaf;
  }
  .ace-file-input input[type='file'][readonly] + .ace-file-container {
    cursor: default;
  }
  .ace-file-input .ace-file-overlay {
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -10px;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .ace-file-input .ace-file-overlay > .overlay-content {
    display: inline-block;
    position: relative;
    top: 10%;
    left: 0;
    right: 0;
    text-align: center;
  }
  .ace-file-multiple {
    height: auto;
  }
  .ace-file-multiple .ace-file-container {
    position: relative;
    height: auto;
    border: 1px dashed #aaaaaa;
    border-radius: 4px;
    text-align: center;
  }
  .ace-file-multiple .ace-file-container:before {
    display: inline-block;
    content: attr(data-title);
    position: relative;
    right: 0;
    left: 0;
    margin: 12px;
    line-height: 22px;
    background-color: #fff;
    color: #ccc;
    font-size: 18px;
    font-weight: bold;
    border-width: 0;
  }
  .ace-file-multiple .ace-file-container.selected .ace-file-name .ace-icon {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    line-height: 24px;
    width: 26px;
    text-align: center;
    font-family: FontAwesome;
    font-size: 13px;
    border: 2px solid #fff;
    color: #fff;
    -webkit-transition: all 0.1s;
    -o-transition: all 0.1s;
    transition: all 0.1s;
  }
  .ace-file-multiple .ace-file-container .ace-file-name {
    position: relative;
    display: block;
    padding: 0;
    height: auto;
    width: auto;
    max-width: 100%;
    margin: 0 4px;
    border-bottom: 1px solid #ddd;
    text-align: left;
  }
  .ace-file-multiple .ace-file-container .ace-file-name:first-child {
    margin-top: 1px;
  }
  .ace-file-multiple .ace-file-container .ace-file-name:last-child {
    border-bottom-width: 0;
    margin-bottom: 1px;
  }
  .ace-file-multiple .ace-file-container .ace-file-name img {
    padding: 2px;
    border: 1px solid #d7d7d7;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    margin: 4px 8px 4px 1px;
  }
  .ace-file-multiple .ace-file-container .ace-file-name:after {
    display: none;
  }
  .ace-file-multiple .ace-file-container.selected .ace-file-name:after {
    display: inline-block;
    white-space: pre;
  }
  .ace-file-multiple .ace-file-container .ace-file-name img + .ace-icon,
  .ace-file-multiple
    .ace-file-container.selected
    .ace-file-name
    img
    + .ace-icon {
    display: none;
  }
  .ace-file-multiple .remove {
    right: -11px;
    top: -11px;
    border: 3px solid #bbb;
    border-radius: 32px;
    background-color: #fff;
    color: red;
    width: 23px;
    height: 23px;
    line-height: 16px;
  }
  .ace-file-multiple .ace-file-container.selected + .remove:hover {
    border-color: #f4c0b1;
  }
  .ace-file-multiple .ace-file-overlay {
    position: absolute;
    top: -12px;
    bottom: -6px;
    left: -12px;
    right: -12px;
  }
  .ace-file-multiple .ace-file-overlay > .overlay-content {
    top: 20%;
  }
  .ace-file-multiple .ace-file-container .ace-file-name .ace-icon {
    position: relative;
    display: block;
    text-align: center;
    height: auto;
    line-height: 64px;
    width: auto;
    font-size: 64px;
    color: #d5d5d5;
    margin: 4px 0;
    background-color: transparent;
  }
  .ace-file-multiple .ace-file-container.selected:after {
    display: none;
  }
  .ace-file-multiple .ace-file-container.selected .ace-file-name .ace-icon {
    position: relative;
    margin-right: 4px;
    margin-left: 2px;
    line-height: 24px;
  }
  .ace-file-multiple .ace-file-container .ace-file-name.large {
    text-align: center;
    border-bottom: 1px solid #222;
    margin: 0 1px 3px;
  }
  .ace-file-multiple .ace-file-container .ace-file-name.large:last-child {
    margin: 0 1px;
  }
  .ace-file-multiple .ace-file-container .ace-file-name.large:after {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 4px;
    background-color: #555;
    color: #fff;
    opacity: 0.8;
    filter: alpha(opacity=80);
  }
  .ace-file-multiple .ace-file-container .ace-file-name.large img {
    border-width: 0;
    margin: 0 !important;
    padding: 0;
  }
  .ace-file-multiple input[type='file'].disabled + .ace-file-container:hover,
  .ace-file-multiple input[type='file'][disabled] + .ace-file-container:hover,
  .ace-file-multiple input[type='file'][readonly] + .ace-file-container:hover {
    border-color: #aaa;
  }
  .ace-file-multiple input[type='file'].disabled + .ace-file-container:before,
  .ace-file-multiple input[type='file'][disabled] + .ace-file-container:before,
  .ace-file-multiple input[type='file'][readonly] + .ace-file-container:before {
    background-color: transparent;
  }
  .ace-file-multiple
    input[type='file'].disabled
    + .ace-file-container
    .ace-icon,
  .ace-file-multiple
    input[type='file'][disabled]
    + .ace-file-container
    .ace-icon,
  .ace-file-multiple
    input[type='file'][readonly]
    + .ace-file-container
    .ace-icon {
    border-color: #eee;
  }
  .input-group .input-group-addon {
    border-radius: 0 !important;
  }
  .form-group.has-success .input-group .input-group-addon {
    border-color: #9cc573;
  }
  .form-group.has-error .input-group .input-group-addon {
    border-color: #f2a696;
  }
  .form-group.has-warning .input-group .input-group-addon {
    border-color: #e3c94c;
  }
  .form-group.has-info .input-group .input-group-addon {
    border-color: #72aec2;
  }
  .input-group > .btn {
    line-height: 20px;
    padding: 0 6px;
    border-radius: 0 !important;
  }
  .input-group > .btn.btn-sm {
    line-height: 22px;
  }
  .input-group > .btn + .btn {
    margin-left: 1px;
  }
  .input-group > .btn-group > .btn {
    line-height: 23px;
  }
  .input-group > .btn-group > .btn.btn-sm {
    line-height: 26px;
  }
  .input-group > .btn > .caret,
  .input-group > .btn-group > .btn > .caret,
  .input-group > .btn.btn-sm > .caret,
  .input-group > .btn-group > .btn.btn-sm > .caret {
    margin-top: 10px;
  }
  .input-group.input-group-compact {
    width: 1px;
  }
  .input-group-lg > .form-control,
  .input-group-lg > .input-group-addon,
  .input-group-lg > .input-group-btn > .btn {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .form-group select,
  .form-group textarea,
  .form-group input[type='text'],
  .form-group input[type='password'],
  .form-group input[type='datetime'],
  .form-group input[type='datetime-local'],
  .form-group input[type='date'],
  .form-group input[type='month'],
  .form-group input[type='time'],
  .form-group input[type='week'],
  .form-group input[type='number'],
  .form-group input[type='email'],
  .form-group input[type='url'],
  .form-group input[type='search'],
  .form-group input[type='tel'],
  .form-group input[type='color'] {
    background: #fff;
  }
  .form-group.has-success input,
  .form-group.has-success select,
  .form-group.has-success textarea {
    border-color: #9cc573;
    color: #8bad4c;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .form-group.has-success input:focus,
  .form-group.has-success select:focus,
  .form-group.has-success textarea:focus {
    -webkit-box-shadow: 0px 0px 0px 2px rgba(130, 188, 58, 0.3);
    box-shadow: 0px 0px 0px 2px rgba(130, 188, 58, 0.3);
    color: #6f8a3c;
    border-color: #779c52;
    background-color: #f4f9f0;
  }
  .form-group.has-success input:focus + .ace-icon,
  .form-group.has-success select:focus + .ace-icon,
  .form-group.has-success textarea:focus + .ace-icon {
    color: #8bad4c;
  }
  .form-group.has-success .ace-icon {
    color: #8bad4c;
  }
  .form-group.has-success .btn .ace-icon {
    color: inherit;
  }
  .form-group.has-success .control-label,
  .form-group.has-success .help-block,
  .form-group.has-success .help-inline {
    color: #7ba065;
  }
  .form-group.has-info input,
  .form-group.has-info select,
  .form-group.has-info textarea {
    border-color: #72aec2;
    color: #4b89aa;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .form-group.has-info input:focus,
  .form-group.has-info select:focus,
  .form-group.has-info textarea:focus {
    -webkit-box-shadow: 0px 0px 0px 2px rgba(58, 120, 188, 0.3);
    box-shadow: 0px 0px 0px 2px rgba(58, 120, 188, 0.3);
    color: #3b6c87;
    border-color: #488ea5;
    background-color: #f1f7f9;
  }
  .form-group.has-info input:focus + .ace-icon,
  .form-group.has-info select:focus + .ace-icon,
  .form-group.has-info textarea:focus + .ace-icon {
    color: #4b89aa;
  }
  .form-group.has-info .ace-icon {
    color: #4b89aa;
  }
  .form-group.has-info .btn .ace-icon {
    color: inherit;
  }
  .form-group.has-info .control-label,
  .form-group.has-info .help-block,
  .form-group.has-info .help-inline {
    color: #657ba0;
  }
  .form-group.has-error input,
  .form-group.has-error select,
  .form-group.has-error textarea {
    border-color: #f2a696;
    color: #d68273;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .form-group.has-error input:focus,
  .form-group.has-error select:focus,
  .form-group.has-error textarea:focus {
    -webkit-box-shadow: 0px 0px 0px 2px rgba(219, 137, 120, 0.3);
    box-shadow: 0px 0px 0px 2px rgba(219, 137, 120, 0.3);
    color: #ca5f4c;
    border-color: #d77b68;
    background-color: #fef9f8;
  }
  .form-group.has-error input:focus + .ace-icon,
  .form-group.has-error select:focus + .ace-icon,
  .form-group.has-error textarea:focus + .ace-icon {
    color: #d68273;
  }
  .form-group.has-error .ace-icon {
    color: #d68273;
  }
  .form-group.has-error .btn .ace-icon {
    color: inherit;
  }
  .form-group.has-error .control-label,
  .form-group.has-error .help-block,
  .form-group.has-error .help-inline {
    color: #d16e6c;
  }
  .form-group.has-warning input,
  .form-group.has-warning select,
  .form-group.has-warning textarea {
    border-color: #e3c94c;
    color: #d3bd50;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .form-group.has-warning input:focus,
  .form-group.has-warning select:focus,
  .form-group.has-warning textarea:focus {
    -webkit-box-shadow: 0px 0px 0px 2px rgba(216, 188, 65, 0.3);
    box-shadow: 0px 0px 0px 2px rgba(216, 188, 65, 0.3);
    color: #c0a830;
    border-color: #d5b630;
    background-color: #fdfbf3;
  }
  .form-group.has-warning input:focus + .ace-icon,
  .form-group.has-warning select:focus + .ace-icon,
  .form-group.has-warning textarea:focus + .ace-icon {
    color: #d3bd50;
  }
  .form-group.has-warning .ace-icon {
    color: #d3bd50;
  }
  .form-group.has-warning .btn .ace-icon {
    color: inherit;
  }
  .form-group.has-warning .control-label,
  .form-group.has-warning .help-block,
  .form-group.has-warning .help-inline {
    color: #d19d59;
  }
  .form-group input[disabled],
  .form-group input:disabled {
    color: #848484 !important;
    background-color: #eeeeee !important;
  }
  input[type].input-transparent,
  textarea.input-transparent,
  .input-transparent {
    background-color: rgba(0, 0, 0, 0.5);
    color: #bbb;
    border-color: transparent !important;
  }
  input[type].input-transparent:focus,
  textarea.input-transparent:focus,
  .input-transparent:focus {
    background-color: rgba(0, 0, 0, 0.66);
    color: #f0f0f0;
  }
  @media only screen and (max-width: 767px) {
    .help-inline,
    .input-icon + .help-inline {
      padding-left: 0;
      display: block !important;
    }
  }
  .tab-content {
    border: 1px solid #c5d0dc;
    padding: 16px 12px;
    position: relative;
  }
  .tab-content.no-padding {
    padding: 0;
  }
  .tab-content.no-border {
    border: none;
    padding: 12px;
  }
  .tab-content.padding-32 {
    padding: 32px 24px;
  }
  .tab-content.no-border.padding-32 {
    padding: 32px;
  }
  .tab-content.padding-30 {
    padding: 30px 23px;
  }
  .tab-content.no-border.padding-30 {
    padding: 30px;
  }
  .tab-content.padding-28 {
    padding: 28px 21px;
  }
  .tab-content.no-border.padding-28 {
    padding: 28px;
  }
  .tab-content.padding-26 {
    padding: 26px 20px;
  }
  .tab-content.no-border.padding-26 {
    padding: 26px;
  }
  .tab-content.padding-24 {
    padding: 24px 18px;
  }
  .tab-content.no-border.padding-24 {
    padding: 24px;
  }
  .tab-content.padding-22 {
    padding: 22px 17px;
  }
  .tab-content.no-border.padding-22 {
    padding: 22px;
  }
  .tab-content.padding-20 {
    padding: 20px 15px;
  }
  .tab-content.no-border.padding-20 {
    padding: 20px;
  }
  .tab-content.padding-18 {
    padding: 18px 14px;
  }
  .tab-content.no-border.padding-18 {
    padding: 18px;
  }
  .tab-content.padding-16 {
    padding: 16px 12px;
  }
  .tab-content.no-border.padding-16 {
    padding: 16px;
  }
  .tab-content.padding-14 {
    padding: 14px 11px;
  }
  .tab-content.no-border.padding-14 {
    padding: 14px;
  }
  .tab-content.padding-12 {
    padding: 12px 9px;
  }
  .tab-content.no-border.padding-12 {
    padding: 12px;
  }
  .tab-content.padding-10 {
    padding: 10px 8px;
  }
  .tab-content.no-border.padding-10 {
    padding: 10px;
  }
  .tab-content.padding-8 {
    padding: 8px 6px;
  }
  .tab-content.no-border.padding-8 {
    padding: 8px;
  }
  .tab-content.padding-6 {
    padding: 6px 5px;
  }
  .tab-content.no-border.padding-6 {
    padding: 6px;
  }
  .tab-content.padding-4 {
    padding: 4px 3px;
  }
  .tab-content.no-border.padding-4 {
    padding: 4px;
  }
  .tab-content.padding-2 {
    padding: 2px 2px;
  }
  .tab-content.no-border.padding-2 {
    padding: 2px;
  }
  .tab-content.padding-0 {
    padding: 0px 0px;
  }
  .tab-content.no-border.padding-0 {
    padding: 0px;
  }
  .nav.nav-tabs.padding-32 {
    padding-left: 32px;
  }
  .tabs-right > .nav.nav-tabs.padding-32,
  .tabs-left > .nav.nav-tabs.padding-32 {
    padding-left: 0;
    padding-top: 32px;
  }
  .nav.nav-tabs.padding-30 {
    padding-left: 30px;
  }
  .tabs-right > .nav.nav-tabs.padding-30,
  .tabs-left > .nav.nav-tabs.padding-30 {
    padding-left: 0;
    padding-top: 30px;
  }
  .nav.nav-tabs.padding-28 {
    padding-left: 28px;
  }
  .tabs-right > .nav.nav-tabs.padding-28,
  .tabs-left > .nav.nav-tabs.padding-28 {
    padding-left: 0;
    padding-top: 28px;
  }
  .nav.nav-tabs.padding-26 {
    padding-left: 26px;
  }
  .tabs-right > .nav.nav-tabs.padding-26,
  .tabs-left > .nav.nav-tabs.padding-26 {
    padding-left: 0;
    padding-top: 26px;
  }
  .nav.nav-tabs.padding-24 {
    padding-left: 24px;
  }
  .tabs-right > .nav.nav-tabs.padding-24,
  .tabs-left > .nav.nav-tabs.padding-24 {
    padding-left: 0;
    padding-top: 24px;
  }
  .nav.nav-tabs.padding-22 {
    padding-left: 22px;
  }
  .tabs-right > .nav.nav-tabs.padding-22,
  .tabs-left > .nav.nav-tabs.padding-22 {
    padding-left: 0;
    padding-top: 22px;
  }
  .nav.nav-tabs.padding-20 {
    padding-left: 20px;
  }
  .tabs-right > .nav.nav-tabs.padding-20,
  .tabs-left > .nav.nav-tabs.padding-20 {
    padding-left: 0;
    padding-top: 20px;
  }
  .nav.nav-tabs.padding-18 {
    padding-left: 18px;
  }
  .tabs-right > .nav.nav-tabs.padding-18,
  .tabs-left > .nav.nav-tabs.padding-18 {
    padding-left: 0;
    padding-top: 18px;
  }
  .nav.nav-tabs.padding-16 {
    padding-left: 16px;
  }
  .tabs-right > .nav.nav-tabs.padding-16,
  .tabs-left > .nav.nav-tabs.padding-16 {
    padding-left: 0;
    padding-top: 16px;
  }
  .nav.nav-tabs.padding-14 {
    padding-left: 14px;
  }
  .tabs-right > .nav.nav-tabs.padding-14,
  .tabs-left > .nav.nav-tabs.padding-14 {
    padding-left: 0;
    padding-top: 14px;
  }
  .nav.nav-tabs.padding-12 {
    padding-left: 12px;
  }
  .tabs-right > .nav.nav-tabs.padding-12,
  .tabs-left > .nav.nav-tabs.padding-12 {
    padding-left: 0;
    padding-top: 12px;
  }
  .nav.nav-tabs.padding-10 {
    padding-left: 10px;
  }
  .tabs-right > .nav.nav-tabs.padding-10,
  .tabs-left > .nav.nav-tabs.padding-10 {
    padding-left: 0;
    padding-top: 10px;
  }
  .nav.nav-tabs.padding-8 {
    padding-left: 8px;
  }
  .tabs-right > .nav.nav-tabs.padding-8,
  .tabs-left > .nav.nav-tabs.padding-8 {
    padding-left: 0;
    padding-top: 8px;
  }
  .nav.nav-tabs.padding-6 {
    padding-left: 6px;
  }
  .tabs-right > .nav.nav-tabs.padding-6,
  .tabs-left > .nav.nav-tabs.padding-6 {
    padding-left: 0;
    padding-top: 6px;
  }
  .nav.nav-tabs.padding-4 {
    padding-left: 4px;
  }
  .tabs-right > .nav.nav-tabs.padding-4,
  .tabs-left > .nav.nav-tabs.padding-4 {
    padding-left: 0;
    padding-top: 4px;
  }
  .nav.nav-tabs.padding-2 {
    padding-left: 2px;
  }
  .tabs-right > .nav.nav-tabs.padding-2,
  .tabs-left > .nav.nav-tabs.padding-2 {
    padding-left: 0;
    padding-top: 2px;
  }
  .nav-tabs {
    border-color: #c5d0dc;
    margin-bottom: 0 !important;
    margin-left: 0;
    position: relative;
    top: 1px;
  }
  .nav-tabs > li > a {
    padding: 7px 12px 8px;
  }
  .nav-tabs > li > a,
  .nav-tabs > li > a:focus {
    border-radius: 0 !important;
    border-color: #c5d0dc;
    background-color: #f9f9f9;
    color: #999;
    margin-right: -1px;
    line-height: 18px;
    position: relative;
  }
  .nav-tabs > li > a:hover {
    background-color: #fff;
    color: #4c8fbd;
    border-color: #c5d0dc;
  }
  .nav-tabs > li > a:active,
  .nav-tabs > li > a:focus {
    outline: none !important;
  }
  .nav-tabs > li:first-child > a {
    margin-left: 0;
  }
  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover,
  .nav-tabs > li.active > a:focus {
    color: #576373;
    border-color: #c5d0dc;
    border-top: 2px solid #4c8fbd;
    border-bottom-color: transparent;
    background-color: #fff;
    z-index: 1;
    line-height: 18px;
    margin-top: -1px;
    box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.15);
  }
  .tabs-below > .nav-tabs {
    /* tabs below */
    top: auto;
    margin-bottom: 0;
    margin-top: -1px;
    border-color: #c5d0dc;
    border-bottom-width: 0;
  }
  .tabs-below > .nav-tabs > li > a,
  .tabs-below > .nav-tabs > li > a:hover,
  .tabs-below > .nav-tabs > li > a:focus {
    border-color: #c5d0dc;
  }
  .tabs-below > .nav-tabs > li.active > a,
  .tabs-below > .nav-tabs > li.active > a:hover,
  .tabs-below > .nav-tabs > li.active > a:focus {
    border-color: #c5d0dc;
    border-top-width: 1px;
    border-bottom: 2px solid #4c8fbd;
    border-top-color: transparent;
    margin-top: 0;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
  }
  .tabs-left > .nav-tabs > li > a,
  .tabs-right > .nav-tabs > li > a {
    min-width: 60px;
  }
  .tabs-left > .nav-tabs {
    top: auto;
    margin-bottom: 0;
    border-color: #c5d0dc;
    float: left;
  }
  .tabs-left > .nav-tabs > li {
    float: none !important;
  }
  .tabs-left > .nav-tabs > li > a,
  .tabs-left > .nav-tabs > li > a:focus,
  .tabs-left > .nav-tabs > li > a:hover {
    border-color: #c5d0dc;
    margin: 0 -1px 0 0;
  }
  .tabs-left > .nav-tabs > li.active > a,
  .tabs-left > .nav-tabs > li.active > a:focus,
  .tabs-left > .nav-tabs > li.active > a:hover {
    border-color: #c5d0dc;
    border-top-width: 1px;
    border-left: 2px solid #4c8fbd;
    border-right-color: transparent;
    margin: 0 -1px 0 -1px;
    -webkit-box-shadow: -2px 0 3px 0 rgba(0, 0, 0, 0.15) !important;
    box-shadow: -2px 0 3px 0 rgba(0, 0, 0, 0.15) !important;
  }
  .tabs-right > .nav-tabs {
    top: auto;
    margin-bottom: 0;
    border-color: #c5d0dc;
    float: right;
  }
  .tabs-right > .nav-tabs > li {
    float: none !important;
  }
  .tabs-right > .nav-tabs > li > a,
  .tabs-right > .nav-tabs > li > a:focus,
  .tabs-right > .nav-tabs > li > a:hover {
    border-color: #c5d0dc;
    margin: 0 -1px;
  }
  .tabs-right > .nav-tabs > li.active > a,
  .tabs-right > .nav-tabs > li.active > a:focus,
  .tabs-right > .nav-tabs > li.active > a:hover {
    border-color: #c5d0dc;
    border-top-width: 1px;
    border-right: 2px solid #4c8fbd;
    border-left-color: transparent;
    margin: 0 -2px 0 -1px;
    -webkit-box-shadow: 2px 0 3px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 2px 0 3px 0 rgba(0, 0, 0, 0.15);
  }
  .nav-tabs > li > a .badge {
    padding: 1px 5px;
    line-height: 15px;
    opacity: 0.75;
    vertical-align: initial;
  }
  .nav-tabs > li > a .ace-icon {
    opacity: 0.75;
  }
  .nav-tabs > li.active > a .badge,
  .nav-tabs > li.active > a .ace-icon {
    opacity: 1;
  }
  .nav-tabs li .ace-icon {
    width: 1.25em;
    display: inline-block;
    text-align: center;
  }
  .nav-tabs > li.open .dropdown-toggle {
    background-color: #4f99c6;
    border-color: #4f99c6;
    color: #fff;
  }
  .nav-tabs > li.open .dropdown-toggle > .ace-icon {
    color: #fff !important;
  }
  .tabs-left .tab-content,
  .tabs-right .tab-content {
    overflow: auto;
  }
  .nav-pills .open .dropdown-toggle,
  .nav > li.dropdown.open.active > a:hover,
  .nav > li.dropdown.open.active > a:focus {
    background-color: #4f99c6;
    border-color: #4f99c6;
    color: #ffffff;
  }
  .nav-pills .open .dropdown-toggle > .ace-icon,
  .nav > li.dropdown.open.active > a:hover > .ace-icon,
  .nav > li.dropdown.open.active > a:focus > .ace-icon {
    color: #fff !important;
  }
  /* bigger tab buttons */
  .nav-tabs
    > li:not(.active):not(.open)
    > a:not(:hover)
    > .ace-icon:first-child.disabled {
    color: #909090 !important;
  }
  /* bigger tab buttons */
  .nav-tabs.tab-size-bigger > li > a {
    padding-left: 14px;
    padding-right: 14px;
  }
  .nav-tabs.tab-size-bigger > li > a > .ace-icon:first-child {
    display: block;
    margin-bottom: 6px;
    width: auto;
  }
  .nav-tabs.tab-space-1 > li > a {
    margin-right: 1px;
  }
  .nav-tabs.tab-space-2 > li > a {
    margin-right: 2px;
  }
  .nav-tabs.tab-space-3 > li > a {
    margin-right: 3px;
  }
  .nav-tabs.tab-space-4 > li > a {
    margin-right: 4px;
  }
  .nav-tabs[class*='tab-color-'] > li > a,
  .nav-tabs[class*='tab-color-'] > li > a:focus,
  .nav-tabs[class*='tab-color-'] > li > a:hover {
    color: #fff;
    border-color: transparent;
    margin-right: 3px;
  }
  .nav-tabs[class*='tab-color-'] > li > a > .badge {
    border-radius: 2px;
  }
  .nav-tabs[class*='tab-color-'] > li:not(.active) > a:hover {
    opacity: 0.85;
    border-color: rgba(0, 0, 0, 0.15);
    border-bottom-color: transparent;
  }
  .nav-tabs[class*='tab-color-'] > li:not(.active) > a > .ace-icon:first-child {
    color: #fff !important;
  }
  .nav-tabs[class*='tab-color-'] > li:not(.active) > a > .badge {
    color: rgba(0, 0, 0, 0.4) !important;
    background-color: #fff !important;
    border-radius: 2px;
  }
  .nav-tabs.tab-color-blue > li > a,
  .nav-tabs.tab-color-blue > li > a:focus {
    background-color: #7db4d8;
  }
  .nav-tabs[class*='tab-color-'] > li.active > a,
  .nav-tabs[class*='tab-color-'] > li.active > a:focus,
  .nav-tabs[class*='tab-color-'] > li.active > a:hover {
    background-color: #fff;
    color: #4f80a0;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .nav-tabs.tab-color-blue > li.active > a,
  .nav-tabs.tab-color-blue > li.active > a:focus,
  .nav-tabs.tab-color-blue > li.active > a:hover {
    color: #4c718a;
    border-color: #7db4d8 #7db4d8 transparent;
  }
  .tabs-below .nav-tabs.tab-color-blue > li.active > a {
    border-color: transparent #7db4d8 #7db4d8;
  }
  .nav-tabs.tab-color-blue {
    border-bottom-color: #c5d0dc;
  }
  /* background for tab links */
  .nav-tabs.background-blue {
    padding-top: 6px;
    background-color: #eff3f8;
    border: 1px solid #c5d0dc;
  }
  .tabs-below .nav-tabs.background-blue {
    padding-top: 0;
    padding-bottom: 6px;
  }
  .tabs-below .nav-tabs.tab-color-blue {
    border-top: none;
    border-bottom-color: #c5d0dc;
  }
  .accordion-style1.panel-group .panel {
    border-radius: 0;
    border-color: #cdd8e3;
    background-color: #fff;
    box-shadow: none;
  }
  .accordion-style1.panel-group .panel:last-child {
    border-bottom-width: 1px;
  }
  .accordion-style1.panel-group .panel .collapse {
    background-color: #fff;
  }
  .accordion-style1.panel-group .panel + .panel {
    margin-top: 2px;
  }
  .accordion-style1.panel-group .panel-heading + .panel-collapse .panel-body {
    border-top-color: #cdd8e3 !important;
  }
  .accordion-style1.panel-group .panel-heading {
    padding: 0;
  }
  .accordion-style1.panel-group .panel-heading .accordion-toggle {
    color: #4c8fbd;
    background-color: #eef4f9;
    position: relative;
    font-weight: bold;
    font-size: 13px;
    line-height: 1;
    padding: 10px;
    display: block;
  }
  .accordion-style1.panel-group .panel-heading .accordion-toggle.collapsed {
    color: #478fca;
    font-weight: normal;
    background-color: #f9f9f9;
  }
  .accordion-style1.panel-group .panel-heading .accordion-toggle:hover {
    color: #6ea6cc;
    background-color: #f1f8fd;
    text-decoration: none;
  }
  .accordion-style1.panel-group .panel-heading .accordion-toggle:focus,
  .accordion-style1.panel-group .panel-heading .accordion-toggle:active {
    outline: none;
    text-decoration: none;
  }
  .accordion-style1.panel-group
    .panel-heading
    .accordion-toggle
    > .ace-icon:first-child {
    width: 16px;
  }
  .accordion-style1.panel-group
    .panel-heading
    .accordion-toggle:hover
    > .ace-icon:first-child {
    text-decoration: none;
  }
  .accordion-style1.panel-group .panel-body,
  .accordion-style1.panel-group .collapse.in > .panel-body {
    border-top: 1px solid #cdd8e3;
  }
  .accordion-style1.panel-group.no-padding {
    padding: 0;
  }
  .accordion-style2.panel-group .panel {
    border-width: 0;
  }
  .accordion-style2.panel-group .panel:last-child {
    border-bottom-width: 0;
  }
  .accordion-style2.panel-group .panel + .panel {
    margin-top: 4px;
  }
  .accordion-style2.panel-group .panel .panel-body {
    border-top: none;
  }
  .accordion-style2.panel-group .panel-heading .accordion-toggle {
    background-color: #edf3f7;
    border: 2px solid #6eaed1;
    border-width: 0 0 0 2px;
  }
  .accordion-style2.panel-group .panel-heading .accordion-toggle:hover {
    text-decoration: none;
  }
  .accordion-style2.panel-group .panel-heading .accordion-toggle.collapsed {
    background-color: #f3f3f3;
    color: #606060;
    border-width: 0 0 0 1px;
    border-color: #d9d9d9;
  }
  .accordion-style2.panel-group
    .panel-heading
    .accordion-toggle.collapsed:hover {
    background-color: #f6f6f6;
    color: #438eb9;
    text-decoration: none;
  }
  .accordion-style2.panel-group .panel-body,
  .accordion-style2.panel-group .collapse.in > .panel-body {
    border-top: none;
  }
  .accordion-style2.panel-group .accordion-style2.panel-group .panel {
    border-bottom: 1px dotted #d9d9d9;
  }
  .accordion-style2.panel-group
    .accordion-style2.panel-group
    .panel:last-child {
    border-bottom: none;
  }
  .accordion-style2.panel-group
    .accordion-style2.panel-group
    .panel
    .panel-heading,
  .accordion-style2.panel-group
    .accordion-style2.panel-group
    .panel
    .panel-heading
    .accordion-toggle {
    background-color: transparent;
    border-width: 0;
    font-size: 13px;
    padding-top: 6px;
    padding-bottom: 8px;
  }
  .accordion-style2.panel-group
    .accordion-style2.panel-group
    .panel
    .panel-heading {
    padding-top: 0;
    padding-bottom: 0;
  }
  /* tables */
  th,
  td,
  .table-bordered {
    border-radius: 0 !important;
  }
  .table > thead > tr {
    color: #707070;
    font-weight: normal;
    background: #f2f2f2;
    background-image: -webkit-linear-gradient(top, #f8f8f8 0%, #ececec 100%);
    background-image: -o-linear-gradient(top, #f8f8f8 0%, #ececec 100%);
    background-image: linear-gradient(to bottom, #f8f8f8 0%, #ececec 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#fff8f8f8',
        endColorstr='#ffececec',
        GradientType=0
      );
  }
  .table > thead > tr > th {
    border-color: #dddddd;
    font-weight: bold;
  }
  .table > thead > tr > th .ace-icon:first-child {
    margin-right: 2px;
  }
  .table > thead > tr > th:first-child {
    border-left-color: #f1f1f1;
  }
  .table > thead > tr > th:last-child {
    border-right-color: #f1f1f1;
  }
  .table.table-bordered > thead > tr > th {
    /* border-color: @table-border-color; */
    vertical-align: middle;
  }
  .table.table-bordered > thead > tr > th:first-child {
    border-left-color: #dddddd;
  }
  th.center,
  td.center {
    text-align: center;
  }
  th .lbl,
  td .lbl {
    margin-bottom: 0;
  }
  th .lbl:only-child,
  td .lbl:only-child {
    vertical-align: top;
  }
  .table-header {
    background-color: #307ecc;
    color: #fff;
    font-size: 14px;
    line-height: 38px;
    padding-left: 12px;
    margin-bottom: 1px;
  }
  .table-header .close {
    margin-right: 8px;
    margin-top: 0;
    opacity: 0.45;
    filter: alpha(opacity=45);
  }
  .table-header .close:hover {
    opacity: 0.75;
    filter: alpha(opacity=75);
  }
  th.detail-col {
    width: 48px;
    text-align: center;
  }
  tr.detail-row {
    display: none;
  }
  tr.detail-row.open {
    display: block;
    display: table-row;
  }
  tr.detail-row > td {
    background-color: #f1f6f8;
    border-top: 3px solid #d1e1ea !important;
  }
  .table-detail {
    background-color: #fff;
    border: 1px solid #dcebf7;
    width: 100%;
    padding: 12px;
  }
  .table-detail td > .profile-user-info {
    width: 100%;
  }
  .widget-box {
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin: 3px 0;
    border: 1px solid #ccc;
  }
  @media only screen and (max-width: 767px) {
    .widget-box {
      margin-top: 7px;
      margin-bottom: 7px;
    }
  }
  .widget-header {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    position: relative;
    min-height: 38px;
    background: #f7f7f7;
    background-image: -webkit-linear-gradient(top, #ffffff 0%, #eeeeee 100%);
    background-image: -o-linear-gradient(top, #ffffff 0%, #eeeeee 100%);
    background-image: linear-gradient(to bottom, #ffffff 0%, #eeeeee 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ffffffff',
        endColorstr='#ffeeeeee',
        GradientType=0
      );
    color: #669fc7;
    border-bottom: 1px solid #ddd;
    padding-left: 12px;
  }
  .widget-header:before,
  .widget-header:after {
    content: '';
    display: table;
    line-height: 0;
  }
  .widget-header:after {
    clear: right;
  }
  .widget-box.collapsed > .widget-header {
    border-bottom-width: 0;
  }
  .collapsed.fullscreen > .widget-header {
    border-bottom-width: 1px;
  }
  .collapsed > .widget-body {
    display: none;
  }
  .widget-header-flat {
    background: #f7f7f7;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  }
  .widget-header-large {
    min-height: 49px;
    padding-left: 18px;
  }
  .widget-header-small {
    min-height: 31px;
    padding-left: 10px;
  }
  .widget-header > .widget-title {
    line-height: 36px;
    padding: 0;
    margin: 0;
    display: inline;
  }
  .widget-header > .widget-title > .ace-icon {
    margin-right: 5px;
    font-weight: normal;
    display: inline-block;
  }
  .widget-header-large > .widget-title {
    line-height: 48px;
  }
  .widget-header-small > .widget-title {
    line-height: 30px;
  }
  .widget-toolbar {
    display: inline-block;
    padding: 0 10px;
    line-height: 37px;
    float: right;
    position: relative;
  }
  .widget-header-large > .widget-toolbar {
    line-height: 48px;
  }
  .widget-header-small > .widget-toolbar {
    line-height: 29px;
  }
  .widget-toolbar.no-padding {
    padding: 0;
  }
  .widget-toolbar.padding-5 {
    padding: 0 5px;
  }
  .widget-toolbar:before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: -1px;
    border: 1px solid #d9d9d9;
    border-width: 0 1px 0 0;
  }
  .widget-header-large > .widget-toolbar:before {
    top: 6px;
    bottom: 6px;
  }
  [class*='widget-color-'] > .widget-header > .widget-toolbar:before {
    border-color: #eee;
  }
  .widget-color-orange > .widget-header > .widget-toolbar:before {
    border-color: #fea;
  }
  .widget-color-dark > .widget-header > .widget-toolbar:before {
    border-color: #222;
    box-shadow: -1px 0 0 rgba(255, 255, 255, 0.2),
      inset 1px 0 0 rgba(255, 255, 255, 0.1);
  }
  .widget-toolbar.no-border:before {
    display: none;
  }
  .widget-toolbar label {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
  }
  .widget-toolbar > a,
  .widget-toolbar > .widget-menu > a {
    font-size: 14px;
    margin: 0 1px;
    display: inline-block;
    padding: 0;
    line-height: 24px;
  }
  .widget-toolbar > a:hover,
  .widget-toolbar > .widget-menu > a:hover {
    text-decoration: none;
  }
  .widget-header-large > .widget-toolbar > a,
  .widget-header-large > .widget-toolbar > .widget-menu > a {
    font-size: 15px;
    margin: 0 1px;
  }
  .widget-toolbar > .btn {
    line-height: 27px;
    margin-top: -2px;
  }
  .widget-toolbar > .btn.smaller {
    line-height: 26px;
  }
  .widget-toolbar > .btn.bigger {
    line-height: 28px;
  }
  .widget-toolbar > .btn-sm {
    line-height: 24px;
  }
  .widget-toolbar > .btn-sm.smaller {
    line-height: 23px;
  }
  .widget-toolbar > .btn-sm.bigger {
    line-height: 25px;
  }
  .widget-toolbar > .btn-xs {
    line-height: 22px;
  }
  .widget-toolbar > .btn-xs.smaller {
    line-height: 21px;
  }
  .widget-toolbar > .btn-xs.bigger {
    line-height: 23px;
  }
  .widget-toolbar > .btn-minier {
    line-height: 18px;
  }
  .widget-toolbar > .btn-minier.smaller {
    line-height: 17px;
  }
  .widget-toolbar > .btn-minier.bigger {
    line-height: 19px;
  }
  .widget-toolbar > .btn-lg {
    line-height: 36px;
  }
  .widget-toolbar > .btn-lg.smaller {
    line-height: 34px;
  }
  .widget-toolbar > .btn-lg.bigger {
    line-height: 38px;
  }
  .widget-toolbar-dark {
    background: #444;
  }
  .widget-toolbar-light {
    background: rgba(255, 255, 255, 0.85);
  }
  .widget-toolbar > .widget-menu {
    display: inline-block;
    position: relative;
  }
  .widget-toolbar > a[data-action],
  .widget-toolbar > .widget-menu > a[data-action] {
    -webkit-transition: transform 0.1s;
    -o-transition: transform 0.1s;
    transition: transform 0.1s;
  }
  .widget-toolbar > a[data-action] > .ace-icon,
  .widget-toolbar > .widget-menu > a[data-action] > .ace-icon {
    margin-right: 0;
  }
  .widget-toolbar > a[data-action]:focus,
  .widget-toolbar > .widget-menu > a[data-action]:focus {
    text-decoration: none;
    outline: none;
  }
  .widget-toolbar > a[data-action]:hover,
  .widget-toolbar > .widget-menu > a[data-action]:hover {
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
  }
  .widget-body {
    background-color: #fff;
  }
  .widget-main {
    padding: 12px;
  }
  .widget-main.padding-32 {
    padding: 32px;
  }
  .widget-main.padding-30 {
    padding: 30px;
  }
  .widget-main.padding-28 {
    padding: 28px;
  }
  .widget-main.padding-26 {
    padding: 26px;
  }
  .widget-main.padding-24 {
    padding: 24px;
  }
  .widget-main.padding-22 {
    padding: 22px;
  }
  .widget-main.padding-20 {
    padding: 20px;
  }
  .widget-main.padding-18 {
    padding: 18px;
  }
  .widget-main.padding-16 {
    padding: 16px;
  }
  .widget-main.padding-14 {
    padding: 14px;
  }
  .widget-main.padding-12 {
    padding: 12px;
  }
  .widget-main.padding-10 {
    padding: 10px;
  }
  .widget-main.padding-8 {
    padding: 8px;
  }
  .widget-main.padding-6 {
    padding: 6px;
  }
  .widget-main.padding-4 {
    padding: 4px;
  }
  .widget-main.padding-2 {
    padding: 2px;
  }
  .widget-main.padding-0 {
    padding: 0px;
  }
  .widget-main.no-padding {
    padding: 0;
  }
  .widget-toolbar .progress {
    vertical-align: middle;
    display: inline-block;
    margin: 0;
  }
  .widget-toolbar > .dropdown,
  .widget-toolbar > .dropup {
    display: inline-block;
  }
  .widget-box > .widget-header > .widget-toolbar > [data-action='settings'],
  .widget-color-dark
    > .widget-header
    > .widget-toolbar
    > [data-action='settings'],
  .widget-box
    > .widget-header
    > .widget-toolbar
    > .widget-menu
    > [data-action='settings'],
  .widget-color-dark
    > .widget-header
    > .widget-toolbar
    > .widget-menu
    > [data-action='settings'] {
    color: #99cadb;
  }
  .widget-box > .widget-header > .widget-toolbar > [data-action='reload'],
  .widget-color-dark
    > .widget-header
    > .widget-toolbar
    > [data-action='reload'],
  .widget-box
    > .widget-header
    > .widget-toolbar
    > .widget-menu
    > [data-action='reload'],
  .widget-color-dark
    > .widget-header
    > .widget-toolbar
    > .widget-menu
    > [data-action='reload'] {
    color: #acd392;
  }
  .widget-box > .widget-header > .widget-toolbar > [data-action='collapse'],
  .widget-color-dark
    > .widget-header
    > .widget-toolbar
    > [data-action='collapse'],
  .widget-box
    > .widget-header
    > .widget-toolbar
    > .widget-menu
    > [data-action='collapse'],
  .widget-color-dark
    > .widget-header
    > .widget-toolbar
    > .widget-menu
    > [data-action='collapse'] {
    color: #aaa;
  }
  .widget-box > .widget-header > .widget-toolbar > [data-action='close'],
  .widget-color-dark > .widget-header > .widget-toolbar > [data-action='close'],
  .widget-box
    > .widget-header
    > .widget-toolbar
    > .widget-menu
    > [data-action='close'],
  .widget-color-dark
    > .widget-header
    > .widget-toolbar
    > .widget-menu
    > [data-action='close'] {
    color: #e09e96;
  }
  .widget-box[class*='widget-color-'] > .widget-header {
    color: #fff;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  }
  .widget-color-blue {
    border-color: #307ecc;
  }
  .widget-color-blue > .widget-header {
    background: #307ecc;
    border-color: #307ecc;
  }
  .widget-color-blue2 {
    border-color: #5090c1;
  }
  .widget-color-blue2 > .widget-header {
    background: #5090c1;
    border-color: #5090c1;
  }
  .widget-color-blue3 {
    border-color: #6379aa;
  }
  .widget-color-blue3 > .widget-header {
    background: #6379aa;
    border-color: #6379aa;
  }
  .widget-color-green {
    border-color: #82af6f;
  }
  .widget-color-green > .widget-header {
    background: #82af6f;
    border-color: #82af6f;
  }
  .widget-color-green2 {
    border-color: #2e8965;
  }
  .widget-color-green2 > .widget-header {
    background: #2e8965;
    border-color: #2e8965;
  }
  .widget-color-green3 {
    border-color: #4ebc30;
  }
  .widget-color-green3 > .widget-header {
    background: #4ebc30;
    border-color: #4ebc30;
  }
  .widget-color-red {
    border-color: #e2755f;
  }
  .widget-color-red > .widget-header {
    background: #e2755f;
    border-color: #e2755f;
  }
  .widget-color-red2 {
    border-color: #e04141;
  }
  .widget-color-red2 > .widget-header {
    background: #e04141;
    border-color: #e04141;
  }
  .widget-color-red3 {
    border-color: #d15b47;
  }
  .widget-color-red3 > .widget-header {
    background: #d15b47;
    border-color: #d15b47;
  }
  .widget-color-purple {
    border-color: #7e6eb0;
  }
  .widget-color-purple > .widget-header {
    background: #7e6eb0;
    border-color: #7e6eb0;
  }
  .widget-color-pink {
    border-color: #ce6f9e;
  }
  .widget-color-pink > .widget-header {
    background: #ce6f9e;
    border-color: #ce6f9e;
  }
  .widget-color-orange {
    border-color: #e8b10d;
  }
  .widget-color-orange > .widget-header {
    color: #855d10 !important;
    border-color: #e8b10d;
    background: #ffc657;
  }
  .widget-color-dark {
    border-color: #5a5a5a;
  }
  .widget-color-dark > .widget-header {
    border-color: #666666;
    background: #404040;
  }
  .widget-color-grey {
    border-color: #9e9e9e;
  }
  .widget-color-grey > .widget-header {
    border-color: #aaaaaa;
    background: #848484;
  }
  .widget-box.transparent {
    border-width: 0;
  }
  .widget-box.transparent > .widget-header {
    background: none;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    border-width: 0;
    border-bottom: 1px solid #dce8f1;
    color: #4383b4;
    padding-left: 3px;
  }
  .widget-box.transparent > .widget-header-large {
    padding-left: 5px;
  }
  .widget-box.transparent > .widget-header-small {
    padding-left: 1px;
  }
  .widget-box.transparent > .widget-body {
    border-width: 0;
    background-color: transparent;
  }
  [class*='widget-color-'] > .widget-header > .widget-toolbar > [data-action],
  [class*='widget-color-']
    > .widget-header
    > .widget-toolbar
    > .widget-menu
    > [data-action] {
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  }
  [class*='widget-color-']
    > .widget-header
    > .widget-toolbar
    > [data-action='settings'],
  [class*='widget-color-']
    > .widget-header
    > .widget-toolbar
    > .widget-menu
    > [data-action='settings'] {
    color: #d3e4ed;
  }
  [class*='widget-color-']
    > .widget-header
    > .widget-toolbar
    > [data-action='reload'],
  [class*='widget-color-']
    > .widget-header
    > .widget-toolbar
    > .widget-menu
    > [data-action='reload'] {
    color: #deead3;
  }
  [class*='widget-color-']
    > .widget-header
    > .widget-toolbar
    > [data-action='collapse'],
  [class*='widget-color-']
    > .widget-header
    > .widget-toolbar
    > .widget-menu
    > [data-action='collapse'] {
    color: #e2e2e2;
  }
  [class*='widget-color-']
    > .widget-header
    > .widget-toolbar
    > [data-action='close'],
  [class*='widget-color-']
    > .widget-header
    > .widget-toolbar
    > .widget-menu
    > [data-action='close'] {
    color: #ffd9d5;
  }
  .widget-color-orange > .widget-header > .widget-toolbar > [data-action],
  .widget-color-orange
    > .widget-header
    > .widget-toolbar
    > .widget-menu
    > [data-action] {
    text-shadow: none;
  }
  .widget-color-orange
    > .widget-header
    > .widget-toolbar
    > [data-action='settings'],
  .widget-color-orange
    > .widget-header
    > .widget-toolbar
    > .widget-menu
    > [data-action='settings'] {
    color: #559aab;
  }
  .widget-color-orange
    > .widget-header
    > .widget-toolbar
    > [data-action='reload'],
  .widget-color-orange
    > .widget-header
    > .widget-toolbar
    > .widget-menu
    > [data-action='reload'] {
    color: #7ca362;
  }
  .widget-color-orange
    > .widget-header
    > .widget-toolbar
    > [data-action='collapse'],
  .widget-color-orange
    > .widget-header
    > .widget-toolbar
    > .widget-menu
    > [data-action='collapse'] {
    color: #777;
  }
  .widget-color-orange
    > .widget-header
    > .widget-toolbar
    > [data-action='close'],
  .widget-color-orange
    > .widget-header
    > .widget-toolbar
    > .widget-menu
    > [data-action='close'] {
    color: #a05656;
  }
  .widget-box.light-border[class*='widget-color-']:not(.fullscreen) {
    border-width: 0;
  }
  .widget-box.light-border[class*='widget-color-']:not(.fullscreen)
    > .widget-header {
    border: 1px solid;
    border-color: inherit;
  }
  .widget-box.light-border[class*='widget-color-']:not(.fullscreen)
    > .widget-body {
    border: 1px solid;
    border-color: #d6d6d6;
    border-width: 0 1px 1px;
  }
  .widget-box.no-border {
    border-width: 0;
  }
  .widget-box.fullscreen {
    position: fixed;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    border-width: 3px;
    z-index: 1040 !important;
  }
  .widget-box.fullscreen:not([class*='widget-color-']) {
    border-color: #aaa;
  }
  .widget-body .table {
    border-top: 1px solid #e5e5e5;
  }
  .widget-body .table thead:first-child tr {
    background: #fff;
  }
  [class*='widget-color-'] > .widget-body .table thead:first-child tr {
    background: #f2f2f2;
    background-image: -webkit-linear-gradient(top, #f8f8f8 0%, #ececec 100%);
    background-image: -o-linear-gradient(top, #f8f8f8 0%, #ececec 100%);
    background-image: linear-gradient(to bottom, #f8f8f8 0%, #ececec 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#fff8f8f8',
        endColorstr='#ffececec',
        GradientType=0
      );
  }
  .widget-body .table.table-bordered thead:first-child > tr {
    border-top-width: 0;
  }
  .widget-main.no-padding .table {
    margin-bottom: 0;
    border-width: 0;
  }
  .widget-main.no-padding .table-bordered th:first-child,
  .widget-main.no-padding .table-bordered td:first-child {
    border-left-width: 0;
  }
  .transparent
    > .widget-body
    .widget-main
    .table-bordered
    > thead
    > tr
    > th:last-child,
  .widget-main.no-padding .table-bordered > thead > tr > th:last-child,
  .transparent
    > .widget-body
    .widget-main
    .table-bordered
    > tbody
    > tr
    > td:last-child,
  .widget-main.no-padding .table-bordered > tbody > tr > td:last-child,
  .transparent
    > .widget-body
    .widget-main
    .table-bordered
    > tfoot
    > tr
    > td:last-child,
  .widget-main.no-padding .table-bordered > tfoot > tr > td:last-child {
    border-right-width: 0 !important;
  }
  .transparent
    > .widget-body
    .widget-main
    .table-bordered
    > tbody
    > tr:last-child
    > td,
  .widget-main.no-padding .table-bordered > tbody > tr:last-child > td {
    border-bottom-width: 0 !important;
  }
  .table-bordered > thead.thin-border-bottom > tr > th,
  .table-bordered > thead.thin-border-bottom > tr > td {
    border-bottom-width: 1px;
  }
  .widget-body .alert:last-child {
    margin-bottom: 0;
  }
  .widget-main .tab-content {
    border-width: 0;
  }
  .widget-toolbar > .nav-tabs {
    border-bottom-width: 0;
    margin-bottom: 0;
    top: auto;
    margin-top: 3px !important;
  }
  .widget-toolbar > .nav-tabs > li {
    margin-bottom: auto;
  }
  .widget-toolbar > .nav-tabs > li > a {
    box-shadow: none;
    position: relative;
    top: 1px;
    margin-top: 1px;
  }
  .widget-toolbar > .nav-tabs > li:not(.active) > a {
    border-color: transparent;
    background-color: transparent;
  }
  .widget-toolbar > .nav-tabs > li:not(.active) > a:hover {
    background-color: transparent;
  }
  .widget-toolbar > .nav-tabs > li.active > a {
    background-color: #fff;
    border-bottom-color: transparent;
    box-shadow: none;
    margin-top: auto;
  }
  .widget-header-small > .widget-toolbar > .nav-tabs > li > a {
    line-height: 16px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .widget-header-small > .widget-toolbar > .nav-tabs > li.active > a {
    border-top-width: 2px;
  }
  .widget-header-large > .widget-toolbar > .nav-tabs > li > a {
    line-height: 22px;
    padding-top: 9px;
    padding-bottom: 9px;
    margin-top: 4px;
  }
  .widget-header-large > .widget-toolbar > .nav-tabs > li.active > a {
    margin-top: 3px;
  }
  [class*='widget-color-']
    > .widget-header
    > .widget-toolbar
    > .nav-tabs
    > li
    > a {
    border-color: transparent;
    background-color: transparent;
    color: #fff;
    margin-right: 1px;
  }
  [class*='widget-color-']
    > .widget-header
    > .widget-toolbar
    > .nav-tabs
    > li
    > a:hover {
    background-color: #fff;
    color: #555;
    border-top-color: #fff;
  }
  [class*='widget-color-']
    > .widget-header
    > .widget-toolbar
    > .nav-tabs
    > li.active
    > a {
    background-color: #fff;
    color: #555;
    border-top-width: 1px;
    margin-top: 0;
  }
  .widget-toolbar > .nav-tabs .widget-color-orange > .widget-header > li > a {
    color: #855d10;
  }
  .transparent > .widget-header > .widget-toolbar > .nav-tabs > li > a {
    color: #555;
    background-color: transparent;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
  }
  .transparent > .widget-header > .widget-toolbar > .nav-tabs > li.active > a {
    border-top-color: #4c8fbd;
    border-right: 1px solid #c5d0dc;
    border-left: 1px solid #c5d0dc;
    background-color: #fff;
    box-shadow: none;
  }
  .widget-toolbox {
    background-color: #eee;
  }
  .widget-toolbox:first-child {
    padding: 2px;
    border-bottom: 1px solid #ccc;
  }
  .widget-toolbox:last-child {
    padding: 2px;
    border-top: 1px solid #ccc;
  }
  .transparent > .widget-body > .widget-toolbox:last-child {
    border: none;
    border-top: 1px solid #ccc;
  }
  .widget-toolbox > .btn-toolbar {
    margin: 0 !important;
    padding: 0;
  }
  .widget-toolbox.center {
    text-align: center;
  }
  .widget-toolbox.toolbox-vertical {
    border-bottom-width: 0;
    border-right: 1px solid #d9d9d9;
    display: table-cell;
    padding: 6px 4px;
    vertical-align: top;
    width: 1px;
  }
  .widget-toolbox.toolbox-vertical + .widget-main {
    display: table-cell;
    vertical-align: top;
  }
  .widget-toolbox.padding-16 {
    padding: 16px;
  }
  .widget-toolbox.padding-14 {
    padding: 14px;
  }
  .widget-toolbox.padding-12 {
    padding: 12px;
  }
  .widget-toolbox.padding-10 {
    padding: 10px;
  }
  .widget-toolbox.padding-8 {
    padding: 8px;
  }
  .widget-toolbox.padding-6 {
    padding: 6px;
  }
  .widget-toolbox.padding-4 {
    padding: 4px;
  }
  .widget-toolbox.padding-2 {
    padding: 2px;
  }
  .widget-toolbox.padding-0 {
    padding: 0px;
  }
  .widget-box-overlay {
    position: absolute;
    top: -1px;
    bottom: -1px;
    right: -1px;
    left: -1px;
    z-index: 999;
    text-align: center;
    min-height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .widget-box-overlay > .loading-icon {
    position: relative;
    top: 20%;
    left: 0;
    right: 0;
    text-align: center;
  }
  .widget-box.collapsed .widget-box-overlay > .loading-icon {
    top: 10%;
  }
  .widget-box-overlay > .loading-icon.icon-spin {
    -moz-animation-duration: 1.2s;
    -webkit-animation-duration: 1.2s;
    -o-animation-duration: 1.2s;
    -ms-animation-duration: 1.2s;
    animation-duration: 1.2s;
  }
  .widget-main > form {
    margin-bottom: 0;
  }
  .widget-main > form .input-append,
  .widget-main > form .input-prepend {
    margin-bottom: 0;
  }
  .widget-main.no-padding > form > fieldset,
  .widget-main.padding-0 > form > fieldset {
    padding: 16px;
  }
  .widget-main.no-padding > form > fieldset + .form-actions,
  .widget-main.padding-0 > form > fieldset + .form-actions {
    padding: 10px 0 12px;
  }
  .widget-main.no-padding > form > .form-actions,
  .widget-main.padding-0 > form > .form-actions {
    margin: 0;
    padding: 10px 12px 12px;
  }
  .widget-placeholder {
    border: 2px dashed #d9d9d9;
  }
  .tooltip.in {
    opacity: 1;
    filter: alpha(opacity=100);
  }
  .tooltip-inner {
    background-color: #333333;
    color: #fff;
    font-size: 12px;
    text-shadow: 1px 1px 0 rgba(42, 45, 50, 0.5);
    border-radius: 0;
    padding: 5px 9px;
  }
  .tooltip.top .tooltip-arrow {
    border-bottom-width: 0;
    border-top-color: #333333;
    -moz-border-top-colors: #333333;
  }
  .tooltip.right .tooltip-arrow {
    border-left-width: 0;
    border-right-color: #333333;
    -moz-border-right-colors: #333333;
  }
  .tooltip.left .tooltip-arrow {
    border-right-width: 0;
    border-left-color: #333333;
    -moz-border-left-colors: #333333;
  }
  .tooltip.bottom .tooltip-arrow {
    border-top-width: 0;
    border-bottom-color: #333333;
    -moz-border-bottom-colors: #333333;
  }
  .tooltip-error + .tooltip > .tooltip-inner,
  .tooltip.tooltip-error > .tooltip-inner {
    background-color: #c94d32;
    text-shadow: 1px 1px 0 rgba(100, 60, 20, 0.3);
  }
  .tooltip-error + .tooltip.top .tooltip-arrow,
  .tooltip.tooltip-error.top .tooltip-arrow {
    border-top-color: #c94d32;
    -moz-border-top-colors: #c94d32;
  }
  .tooltip-error + .tooltip.right .tooltip-arrow,
  .tooltip.tooltip-error.right .tooltip-arrow {
    border-right-color: #c94d32;
    -moz-border-right-colors: #c94d32;
  }
  .tooltip-error + .tooltip.left .tooltip-arrow,
  .tooltip.tooltip-error.left .tooltip-arrow {
    border-left-color: #c94d32;
    -moz-border-left-colors: #c94d32;
  }
  .tooltip-error + .tooltip.bottom .tooltip-arrow,
  .tooltip.tooltip-error.bottom .tooltip-arrow {
    border-bottom-color: #c94d32;
    -moz-border-bottom-colors: #c94d32;
  }
  .tooltip-success + .tooltip > .tooltip-inner,
  .tooltip.tooltip-success > .tooltip-inner {
    background-color: #629b58;
    text-shadow: 1px 1px 0 rgba(60, 100, 20, 0.3);
  }
  .tooltip-success + .tooltip.top .tooltip-arrow,
  .tooltip.tooltip-success.top .tooltip-arrow {
    border-top-color: #629b58;
    -moz-border-top-colors: #629b58;
  }
  .tooltip-success + .tooltip.right .tooltip-arrow,
  .tooltip.tooltip-success.right .tooltip-arrow {
    border-right-color: #629b58;
    -moz-border-right-colors: #629b58;
  }
  .tooltip-success + .tooltip.left .tooltip-arrow,
  .tooltip.tooltip-success.left .tooltip-arrow {
    border-left-color: #629b58;
    -moz-border-left-colors: #629b58;
  }
  .tooltip-success + .tooltip.bottom .tooltip-arrow,
  .tooltip.tooltip-success.bottom .tooltip-arrow {
    border-bottom-color: #629b58;
    -moz-border-bottom-colors: #629b58;
  }
  .tooltip-warning + .tooltip > .tooltip-inner,
  .tooltip.tooltip-warning > .tooltip-inner {
    background-color: #ed9421;
    text-shadow: 1px 1px 0 rgba(100, 90, 10, 0.3);
  }
  .tooltip-warning + .tooltip.top .tooltip-arrow,
  .tooltip.tooltip-warning.top .tooltip-arrow {
    border-top-color: #ed9421;
    -moz-border-top-colors: #ed9421;
  }
  .tooltip-warning + .tooltip.right .tooltip-arrow,
  .tooltip.tooltip-warning.right .tooltip-arrow {
    border-right-color: #ed9421;
    -moz-border-right-colors: #ed9421;
  }
  .tooltip-warning + .tooltip.left .tooltip-arrow,
  .tooltip.tooltip-warning.left .tooltip-arrow {
    border-left-color: #ed9421;
    -moz-border-left-colors: #ed9421;
  }
  .tooltip-warning + .tooltip.bottom .tooltip-arrow,
  .tooltip.tooltip-warning.bottom .tooltip-arrow {
    border-bottom-color: #ed9421;
    -moz-border-bottom-colors: #ed9421;
  }
  .tooltip-info + .tooltip > .tooltip-inner,
  .tooltip.tooltip-info > .tooltip-inner {
    background-color: #4b89aa;
    text-shadow: 1px 1px 0 rgba(40, 50, 100, 0.3);
  }
  .tooltip-info + .tooltip.top .tooltip-arrow,
  .tooltip.tooltip-info.top .tooltip-arrow {
    border-top-color: #4b89aa;
    -moz-border-top-colors: #4b89aa;
  }
  .tooltip-info + .tooltip.right .tooltip-arrow,
  .tooltip.tooltip-info.right .tooltip-arrow {
    border-right-color: #4b89aa;
    -moz-border-right-colors: #4b89aa;
  }
  .tooltip-info + .tooltip.left .tooltip-arrow,
  .tooltip.tooltip-info.left .tooltip-arrow {
    border-left-color: #4b89aa;
    -moz-border-left-colors: #4b89aa;
  }
  .tooltip-info + .tooltip.bottom .tooltip-arrow,
  .tooltip.tooltip-info.bottom .tooltip-arrow {
    border-bottom-color: #4b89aa;
    -moz-border-bottom-colors: #4b89aa;
  }
  /* popover */
  .popover {
    border-radius: 0;
    padding: 0;
    border-color: #ccc;
    border-width: 1px;
    -webkit-box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);
    color: #4d5c73;
  }
  .popover-title {
    border-radius: 0;
    background-color: #eff3f8;
    color: #555;
    border-bottom: 1px solid #dde6f0;
    text-shadow: 1px 1px 1px rgba(220, 220, 220, 0.2);
  }
  .popover.bottom > .arrow:after {
    top: 1px;
    margin-left: -10px;
    border-bottom-color: #eff3f8;
    -moz-border-bottom-colors: #eff3f8;
    border-top-width: 0;
  }
  .popover.left > .arrow:after {
    -moz-border-left-colors: #fff;
  }
  .popover.right > .arrow:after {
    -moz-border-right-colors: #fff;
  }
  .popover.top > .arrow:after {
    -moz-border-top-colors: #fff;
  }
  .popover-error + .popover,
  .popover.popover-error {
    border-color: #f7f0ef;
  }
  .popover-error + .popover .popover-title,
  .popover.popover-error .popover-title {
    background-color: #f7f0ef;
    border-bottom-color: #efe0de;
    color: #b75445;
    text-shadow: none;
  }
  .popover-error + .popover.top .arrow:after,
  .popover.popover-error.top .arrow:after {
    border-top-color: #f7f0ef;
    -moz-border-top-colors: #f7f0ef;
  }
  .popover-error + .popover.bottom .arrow:after,
  .popover.popover-error.bottom .arrow:after {
    border-bottom-color: #f7f0ef;
    -moz-border-bottom-colors: #f7f0ef;
  }
  .popover-error + .popover.right .arrow:after,
  .popover.popover-error.right .arrow:after {
    border-right-color: #f7f0ef;
    -moz-border-right-colors: #f7f0ef;
  }
  .popover-error + .popover.left .arrow:after,
  .popover.popover-error.left .arrow:after {
    border-left-color: #f7f0ef;
    -moz-border-left-colors: #f7f0ef;
  }
  .popover-warning + .popover,
  .popover.popover-warning {
    border-color: #f4eee3;
  }
  .popover-warning + .popover .popover-title,
  .popover.popover-warning .popover-title {
    background-color: #f4eee3;
    border-bottom-color: #ede3d1;
    color: #d67e31;
    text-shadow: none;
  }
  .popover-warning + .popover.top .arrow:after,
  .popover.popover-warning.top .arrow:after {
    border-top-color: #f4eee3;
    -moz-border-top-colors: #f4eee3;
  }
  .popover-warning + .popover.bottom .arrow:after,
  .popover.popover-warning.bottom .arrow:after {
    border-bottom-color: #f4eee3;
    -moz-border-bottom-colors: #f4eee3;
  }
  .popover-warning + .popover.right .arrow:after,
  .popover.popover-warning.right .arrow:after {
    border-right-color: #f4eee3;
    -moz-border-right-colors: #f4eee3;
  }
  .popover-warning + .popover.left .arrow:after,
  .popover.popover-warning.left .arrow:after {
    border-left-color: #f4eee3;
    -moz-border-left-colors: #f4eee3;
  }
  .popover-success + .popover,
  .popover.popover-success {
    border-color: #e8f2e3;
  }
  .popover-success + .popover .popover-title,
  .popover.popover-success .popover-title {
    background-color: #e8f2e3;
    border-bottom-color: #daead2;
    color: #629b58;
    text-shadow: none;
  }
  .popover-success + .popover.top .arrow:after,
  .popover.popover-success.top .arrow:after {
    border-top-color: #e8f2e3;
    -moz-border-top-colors: #e8f2e3;
  }
  .popover-success + .popover.bottom .arrow:after,
  .popover.popover-success.bottom .arrow:after {
    border-bottom-color: #e8f2e3;
    -moz-border-bottom-colors: #e8f2e3;
  }
  .popover-success + .popover.right .arrow:after,
  .popover.popover-success.right .arrow:after {
    border-right-color: #e8f2e3;
    -moz-border-right-colors: #e8f2e3;
  }
  .popover-success + .popover.left .arrow:after,
  .popover.popover-success.left .arrow:after {
    border-left-color: #e8f2e3;
    -moz-border-left-colors: #e8f2e3;
  }
  .popover-info + .popover,
  .popover.popover-info {
    border-color: #e5edf8;
  }
  .popover-info + .popover .popover-title,
  .popover.popover-info .popover-title {
    background-color: #e5edf8;
    border-bottom-color: #d1dff3;
    color: #3f79b6;
    text-shadow: none;
  }
  .popover-info + .popover.top .arrow:after,
  .popover.popover-info.top .arrow:after {
    border-top-color: #e5edf8;
    -moz-border-top-colors: #e5edf8;
  }
  .popover-info + .popover.bottom .arrow:after,
  .popover.popover-info.bottom .arrow:after {
    border-bottom-color: #e5edf8;
    -moz-border-bottom-colors: #e5edf8;
  }
  .popover-info + .popover.right .arrow:after,
  .popover.popover-info.right .arrow:after {
    border-right-color: #e5edf8;
    -moz-border-right-colors: #e5edf8;
  }
  .popover-info + .popover.left .arrow:after,
  .popover.popover-info.left .arrow:after {
    border-left-color: #e5edf8;
    -moz-border-left-colors: #e5edf8;
  }
  .popover-notitle + .popover .popover-title,
  .popover.popover-notitle .popover-title {
    display: none;
  }
  .popover-notitle + .popover.top .arrow:after,
  .popover.popover-notitle.top .arrow:after {
    border-top-color: #fff;
    -moz-border-top-colors: #fff;
  }
  .popover-notitle + .popover.bottom .arrow:after,
  .popover.popover-notitle.bottom .arrow:after {
    border-bottom-color: #fff;
    -moz-border-bottom-colors: #fff;
  }
  .popover-notitle + .popover.left .arrow:after,
  .popover.popover-notitle.left .arrow:after {
    border-left-color: #fff;
    -moz-border-left-colors: #fff;
  }
  .popover-notitle + .popover.right .arrow:after,
  .popover.popover-notitle.right .arrow:after {
    border-right-color: #fff;
    -moz-border-right-colors: #fff;
  }
  .progress {
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #e9e9e9;
    height: 18px;
  }
  .progress .progress-bar {
    -webkit-box-shadow: none;
    box-shadow: none;
    line-height: 18px;
  }
  .progress[data-percent]:after {
    display: inline-block;
    content: attr(data-percent);
    color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    line-height: 16px;
    text-align: center;
    font-size: 12px;
  }
  .progress.progress-yellow[data-percent]:after {
    color: #996633;
  }
  .progress.progress-small {
    height: 12px;
  }
  .progress.progress-small .progress-bar {
    line-height: 10px;
    font-size: 11px;
  }
  .progress.progress-small[data-percent]:after {
    line-height: 10px;
    font-size: 11px;
  }
  .progress.progress-mini {
    height: 9px;
  }
  .progress.progress-mini .progress-bar {
    line-height: 8px;
    font-size: 11px;
  }
  .progress.progress-mini[data-percent]:after {
    line-height: 8px;
    font-size: 11px;
  }
  .progress-bar {
    background-color: #2a91d8;
  }
  .progress-striped .progress-bar {
    background-image: -webkit-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-image: -o-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
  }
  .progress-bar-danger {
    background-color: #ca5952;
  }
  .progress-striped .progress-bar-danger {
    background-image: -webkit-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-image: -o-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
  }
  .progress-bar-success {
    background-color: #59a84b;
  }
  .progress-striped .progress-bar-success {
    background-image: -webkit-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-image: -o-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
  }
  .progress-bar-warning {
    background-color: #f2bb46;
  }
  .progress-striped .progress-bar-warning {
    background-image: -webkit-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-image: -o-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
  }
  .progress-bar-pink {
    background-color: #d6487e;
  }
  .progress-striped .progress-bar-pink {
    background-image: -webkit-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-image: -o-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
  }
  .progress-bar-purple {
    background-color: #9585bf;
  }
  .progress-striped .progress-bar-purple {
    background-image: -webkit-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-image: -o-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
  }
  .progress-bar-yellow {
    background-color: #ffd259;
  }
  .progress-striped .progress-bar-yellow {
    background-image: -webkit-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-image: -o-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
  }
  .progress-bar-inverse {
    background-color: #404040;
  }
  .progress-striped .progress-bar-inverse {
    background-image: -webkit-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-image: -o-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
  }
  .progress-bar-grey {
    background-color: #8a8a8a;
  }
  .progress-striped .progress-bar-grey {
    background-image: -webkit-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-image: -o-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
  }
  /**
.progress {
  position: relative;
}
.progress:before {
  display: inline-block;
  content: "";

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  background: radial-gradient(9px 9px 0deg, circle cover, aqua 0%, rgba(0, 0, 255, 0) 100%, blue 95%);
}
*/
  .infobox-container {
    text-align: center;
    font-size: 0;
  }
  .infobox {
    display: inline-block;
    width: 210px;
    height: 66px;
    color: #555;
    background-color: #fff;
    box-shadow: none;
    border-radius: 0;
    margin: -1px 0 0 -1px;
    padding: 8px 3px 6px 9px;
    border: 1px dotted;
    border-color: #d8d8d8 !important;
    vertical-align: middle;
    text-align: left;
    position: relative;
  }
  .infobox > .infobox-icon {
    display: inline-block;
    vertical-align: top;
    width: 44px;
  }
  .infobox > .infobox-icon > .ace-icon {
    display: inline-block;
    height: 42px;
    margin: 0;
    padding: 1px 1px 0 2px;
    background-color: transparent;
    border-width: 0;
    text-align: center;
    position: relative;
    border-radius: 100%;
    -webkit-box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
  }
  .infobox > .infobox-icon > .ace-icon:before {
    font-size: 24px;
    display: block;
    padding: 6px 0 7px;
    width: 40px;
    text-align: center;
    border-radius: 100%;
    color: #fff;
    color: rgba(255, 255, 255, 0.9);
    background-color: transparent;
    background-color: rgba(255, 255, 255, 0.2);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.14);
  }
  .infobox .infobox-content {
    color: #555;
    max-width: 140px;
  }
  .infobox .infobox-content:first-child {
    font-weight: bold;
  }
  .infobox > .infobox-data {
    display: inline-block;
    border-width: 0;
    border-top-width: 0;
    font-size: 13px;
    text-align: left;
    line-height: 21px;
    min-width: 130px;
    padding-left: 8px;
    position: relative;
    top: 0;
  }
  .infobox > .infobox-data > .infobox-data-number {
    display: block;
    font-size: 22px;
    margin: 2px 0 4px;
    position: relative;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.15);
  }
  .infobox > .infobox-data > .infobox-text {
    display: block;
    font-size: 16px;
    margin: 2px 0 4px;
    position: relative;
    text-shadow: none;
  }
  .infobox.no-border {
    border-width: 0 !important;
  }
  @media only screen and (max-width: 479px) {
    .infobox {
      min-width: 95%;
      margin-top: 2px;
      margin-bottom: 2px;
    }
  }
  .infobox-purple {
    color: #6f3cc4;
    border-color: #6f3cc4;
  }
  .infobox-purple > .infobox-icon > .ace-icon {
    background-color: #6f3cc4;
  }
  .infobox-purple.infobox-dark {
    background-color: #6f3cc4;
    border-color: #6f3cc4;
  }
  .infobox-purple2 {
    color: #5f47b0;
    border-color: #5f47b0;
  }
  .infobox-purple2 > .infobox-icon > .ace-icon {
    background-color: #5f47b0;
  }
  .infobox-purple2.infobox-dark {
    background-color: #5f47b0;
    border-color: #5f47b0;
  }
  .infobox-pink {
    color: #cb6fd7;
    border-color: #cb6fd7;
  }
  .infobox-pink > .infobox-icon > .ace-icon {
    background-color: #cb6fd7;
  }
  .infobox-pink.infobox-dark {
    background-color: #cb6fd7;
    border-color: #cb6fd7;
  }
  .infobox-blue {
    color: #6fb3e0;
    border-color: #6fb3e0;
  }
  .infobox-blue > .infobox-icon > .ace-icon {
    background-color: #6fb3e0;
  }
  .infobox-blue.infobox-dark {
    background-color: #6fb3e0;
    border-color: #6fb3e0;
  }
  .infobox-blue2 {
    color: #3983c2;
    border-color: #3983c2;
  }
  .infobox-blue2 > .infobox-icon > .ace-icon {
    background-color: #3983c2;
  }
  .infobox-blue2.infobox-dark {
    background-color: #3983c2;
    border-color: #3983c2;
  }
  .infobox-blue3 {
    color: #1144eb;
    border-color: #1144eb;
  }
  .infobox-blue3 > .infobox-icon > .ace-icon {
    background-color: #1144eb;
  }
  .infobox-blue3.infobox-dark {
    background-color: #1144eb;
    border-color: #1144eb;
  }
  .infobox-red {
    color: #d53f40;
    border-color: #d53f40;
  }
  .infobox-red > .infobox-icon > .ace-icon {
    background-color: #d53f40;
  }
  .infobox-red.infobox-dark {
    background-color: #d53f40;
    border-color: #d53f40;
  }
  .infobox-brown {
    color: #c67a3e;
    border-color: #c67a3e;
  }
  .infobox-brown > .infobox-icon > .ace-icon {
    background-color: #c67a3e;
  }
  .infobox-brown.infobox-dark {
    background-color: #c67a3e;
    border-color: #c67a3e;
  }
  .infobox-wood {
    color: #7b3f25;
    border-color: #7b3f25;
  }
  .infobox-wood > .infobox-icon > .ace-icon {
    background-color: #7b3f25;
  }
  .infobox-wood.infobox-dark {
    background-color: #7b3f25;
    border-color: #7b3f25;
  }
  .infobox-light-brown {
    color: #cebea5;
    border-color: #cebea5;
  }
  .infobox-light-brown > .infobox-icon > .ace-icon {
    background-color: #cebea5;
  }
  .infobox-light-brown.infobox-dark {
    background-color: #cebea5;
    border-color: #cebea5;
  }
  .infobox-orange {
    color: #e8b110;
    border-color: #e8b110;
  }
  .infobox-orange > .infobox-icon > .ace-icon {
    background-color: #e8b110;
  }
  .infobox-orange.infobox-dark {
    background-color: #e8b110;
    border-color: #e8b110;
  }
  .infobox-orange2 {
    color: #f79263;
    border-color: #f79263;
  }
  .infobox-orange2 > .infobox-icon > .ace-icon {
    background-color: #f79263;
  }
  .infobox-orange2.infobox-dark {
    background-color: #f79263;
    border-color: #f79263;
  }
  .infobox-green {
    color: #9abc32;
    border-color: #9abc32;
  }
  .infobox-green > .infobox-icon > .ace-icon {
    background-color: #9abc32;
  }
  .infobox-green.infobox-dark {
    background-color: #9abc32;
    border-color: #9abc32;
  }
  .infobox-green2 {
    color: #0490a6;
    border-color: #0490a6;
  }
  .infobox-green2 > .infobox-icon > .ace-icon {
    background-color: #0490a6;
  }
  .infobox-green2.infobox-dark {
    background-color: #0490a6;
    border-color: #0490a6;
  }
  .infobox-grey {
    color: #999999;
    border-color: #999999;
  }
  .infobox-grey > .infobox-icon > .ace-icon {
    background-color: #999999;
  }
  .infobox-grey.infobox-dark {
    background-color: #999999;
    border-color: #999999;
  }
  .infobox-black {
    color: #393939;
    border-color: #393939;
  }
  .infobox-black > .infobox-icon > .ace-icon {
    background-color: #393939;
  }
  .infobox-black.infobox-dark {
    background-color: #393939;
    border-color: #393939;
  }
  .infobox-dark {
    margin: 1px 1px 0 0;
    border-color: transparent !important;
    border-width: 0;
    color: #fff;
    padding: 4px;
  }
  .infobox-dark > .infobox-icon > .ace-icon,
  .infobox-dark > .infobox-icon > .ace-icon:before {
    background-color: transparent;
    box-shadow: none !important;
    text-shadow: none;
    border-radius: 0;
    font-size: 30px;
  }
  .infobox-dark > .infobox-icon > .ace-icon:before {
    opacity: 1;
    filter: alpha(opacity=100);
  }
  .infobox-dark .infobox-content {
    color: #fff;
  }
  .infobox > .infobox-progress {
    padding-top: 0;
    display: inline-block;
    vertical-align: top;
    width: 44px;
  }
  .infobox > .infobox-chart {
    padding-top: 0;
    display: inline-block;
    vertical-align: text-bottom;
    width: 44px;
    text-align: center;
  }
  .infobox > .infobox-chart > .sparkline {
    font-size: 24px;
  }
  .infobox > .infobox-chart canvas {
    vertical-align: middle !important;
  }
  .infobox > .stat {
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 11px;
    text-shadow: none;
    color: #abbac3;
    font-size: 13px;
    font-weight: bold;
    padding-right: 18px;
    padding-top: 3px;
  }
  .infobox > .stat:before {
    display: inline-block;
    content: '';
    width: 8px;
    height: 11px;
    background-color: #abbac3;
    position: absolute;
    right: 4px;
    top: 7px;
  }
  .infobox > .stat:after {
    display: inline-block;
    content: '';
    position: absolute;
    right: 1px;
    top: -8px;
    border: 12px solid transparent;
    border-width: 8px 7px;
    border-bottom-color: #abbac3;
  }
  .infobox > .stat.stat-success {
    color: #77c646;
  }
  .infobox > .stat.stat-success:before {
    background-color: #77c646;
  }
  .infobox > .stat.stat-success:after {
    border-bottom-color: #77c646;
  }
  .infobox > .stat.stat-important {
    /*pointing down*/
    color: #e4564f;
  }
  .infobox > .stat.stat-important:before {
    background-color: #e4564f;
    top: 3px;
  }
  .infobox > .stat.stat-important:after {
    border-top-color: #e4564f;
    border-bottom-color: transparent;
    bottom: -6px;
    top: auto;
  }
  .infobox.infobox-dark > .stat {
    color: #fff;
  }
  .infobox.infobox-dark > .stat:before {
    background-color: #e1e5e8;
  }
  .infobox.infobox-dark > .stat:after {
    border-bottom-color: #e1e5e8;
  }
  .infobox.infobox-dark > .stat.stat-success {
    color: #fff;
  }
  .infobox.infobox-dark > .stat.stat-success:before {
    background-color: #d0e29e;
  }
  .infobox.infobox-dark > .stat.stat-success:after {
    border-bottom-color: #d0e29e;
  }
  .infobox.infobox-dark > .stat.stat-important {
    color: #fff;
  }
  .infobox.infobox-dark > .stat.stat-important:before {
    background-color: #ff8482;
    top: 3px;
  }
  .infobox.infobox-dark > .stat.stat-important:after {
    border-top-color: #ff8482;
    border-bottom-color: transparent;
    bottom: -6px;
    top: auto;
  }
  .infobox > .badge {
    position: absolute;
    right: 20px;
    top: 11px;
    border-radius: 0;
    text-shadow: none;
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    line-height: 15px;
    height: 16px;
    padding: 0 1px;
  }
  .infobox.infobox-dark > .badge {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.2) !important;
    border: 1px solid #f1f1f1;
    top: 2px;
    right: 2px;
  }
  .infobox.infobox-dark > .badge.badge-success > .ace-icon {
    color: #c6e9a1;
  }
  .infobox.infobox-dark > .badge.badge-important > .ace-icon {
    color: #ecb792;
  }
  .infobox.infobox-dark > .badge.badge-warning > .ace-icon {
    color: #ecb792;
  }
  .infobox-small {
    width: 135px;
    height: 52px;
    text-align: left;
    padding-bottom: 5px;
  }
  .infobox-small > .infobox-icon,
  .infobox-small > .infobox-chart,
  .infobox-small > .infobox-progress {
    display: inline-block;
    width: 40px;
    max-width: 40px;
    height: 42px;
    line-height: 38px;
    vertical-align: middle;
  }
  .infobox-small > .infobox-data {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    max-width: 72px;
    min-width: 0;
  }
  .infobox-small > .infobox-chart > .sparkline {
    font-size: 14px;
    margin-left: 2px;
  }
  .percentage {
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    vertical-align: top;
  }
  .infobox-small .percentage {
    font-size: 13px;
    font-weight: normal;
    margin-top: 2px;
    margin-left: 2px;
  }
  .pricing-box:not(:first-child) {
    padding-left: 7px;
  }
  .pricing-box:not(:last-child) {
    padding-right: 7px;
  }
  .pricing-box .price {
    font-size: 22px;
    line-height: 20px;
    height: 28px;
    text-align: center;
    color: #555;
  }
  .pricing-box .price small {
    font-size: 22px;
  }
  .pricing-box .btn {
    font-size: 16px;
  }
  .pricing-box .widget-header {
    /* the title */
    text-align: center;
    padding-left: 0;
  }
  @media only screen and (max-width: 480px) {
    .pricing-box {
      margin: 0;
      margin-bottom: 16px;
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-left: -1px;
    }
    .pricing-box:nth-child(odd) {
      padding-left: 12px !important;
    }
    .pricing-box:nth-child(even) {
      padding-right: 12px !important;
    }
  }
  @media only screen and (max-width: 479px) {
    .pricing-box {
      margin: 0;
      margin-bottom: 16px;
      width: 100%;
      padding-left: 12px !important;
      padding-right: 12px !important;
    }
  }
  .pricing-table-header {
    padding-top: 0;
    margin-top: 0;
    text-align: left;
  }
  .pricing-table-header > li {
    padding: 7px 0 7px 11px;
    font-size: 13px;
  }
  .pricing-table {
    margin-top: 0;
  }
  .pricing-table > li {
    text-align: center;
    padding: 7px 0;
    font-size: 13px;
  }
  .list-striped > li:nth-child(odd) {
    background-color: #fff;
  }
  .list-striped > li:nth-child(even) {
    background-color: #f2f3eb;
  }
  .list-striped.pricing-table-header > li:nth-child(even) {
    background-color: #eee;
  }
  .pricing-box-small {
    box-shadow: none;
    margin-left: -2px;
    background-color: #fff;
    position: relative;
    z-index: 10;
  }
  .pricing-box-small .price {
    line-height: 20px;
    height: 28px;
    text-align: center;
  }
  .pricing-box-small .price .label:before,
  .pricing-box-small .price .label:after {
    margin-top: -2px;
    opacity: 0.9;
    filter: alpha(opacity=90);
  }
  .pricing-box-small:hover {
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.15);
    z-index: 11;
    -webkit-transform: scale(1.04);
    -ms-transform: scale(1.04);
    -o-transform: scale(1.04);
    transform: scale(1.04);
  }
  .pricing-box-small:hover .price > .label {
    -webkit-transform: scale(0.96);
    -ms-transform: scale(0.96);
    -o-transform: scale(0.96);
    transform: scale(0.96);
  }
  .pricing-span {
    margin: 0;
    width: 19%;
    max-width: 150px !important;
    min-width: 110px !important;
    float: left !important;
  }
  .pricing-span-header {
    padding-right: 0;
  }
  @media only screen and (min-width: 480px) {
    .pricing-span-body {
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media only screen and (max-width: 480px) {
    .pricing-span-header,
    .pricing-span-body {
      width: 100%;
      padding-right: 12px;
    }
  }
  .login-container {
    width: 375px;
    margin: 0 auto;
  }
  .login-layout {
    background-color: #1d2024;
  }
  .login-layout .main-container:before {
    display: none;
  }
  .login-layout .main-content {
    margin-left: 0 !important;
    margin-right: 0 !important;
    min-height: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .login-layout label {
    margin-bottom: 11px;
  }
  .login-layout .widget-box {
    visibility: hidden;
    position: fixed;
    z-index: -5;
    border-bottom: none;
    box-shadow: none;
    padding: 6px;
    background-color: #394557;
    -moz-transform: scale(0, 1) translate(-150px);
    -webkit-transform: scale(0, 1) translate(-150px);
    -o-transform: scale(0, 1) translate(-150px);
    -ms-transform: scale(0, 1) translate(-150px);
    transform: scale(0, 1) translate(-150px);
  }
  .login-layout .widget-box.visible {
    visibility: visible;
    position: relative;
    z-index: auto;
    -moz-transform: scale(1, 1) translate(0);
    -webkit-transform: scale(1, 1) translate(0);
    -o-transform: scale(1, 1) translate(0);
    -ms-transform: scale(1, 1) translate(0);
    transform: scale(1, 1) translate(0);
    transition: transform 0.3s ease;
    -moz-transition: -moz-transform 0.3s ease;
    -webkit-transition: -webkit-transform 0.3s ease;
    -o-transition: -o-transform 0.2s ease;
  }
  .login-layout .widget-box .widget-main {
    padding: 16px 36px 36px;
    background: #f7f7f7;
  }
  .login-layout .widget-box .widget-main form {
    margin: 0;
  }
  .login-layout .widget-box .widget-body .toolbar > div > a {
    font-size: 15px;
    font-weight: 400;
    text-shadow: 1px 0px 1px rgba(0, 0, 0, 0.25);
  }
  .login-box .forgot-password-link {
    color: #fe9;
  }
  .login-box .user-signup-link {
    color: #cf7;
  }
  .login-box .toolbar {
    background: #5090c1;
    border-top: 2px solid #597597;
  }
  .login-box .toolbar > div {
    width: 50%;
    display: inline-block;
    padding: 9px 0 11px;
  }
  .login-box .toolbar > div:first-child {
    float: left;
    text-align: left;
  }
  .login-box .toolbar > div:first-child > a {
    margin-left: 11px;
  }
  .login-box .toolbar > div:first-child + div {
    float: right;
    text-align: right;
  }
  .login-box .toolbar > div:first-child + div > a {
    margin-right: 11px;
  }
  .forgot-box .toolbar {
    background: #c16050;
    border-top: 2px solid #976559;
    padding: 9px 18px;
  }
  .signup-box .toolbar {
    background: #76b774;
    border-top: 2px solid #759759;
    padding: 9px 18px;
  }
  .forgot-box .back-to-login-link,
  .signup-box .back-to-login-link {
    color: #fe9;
    font-size: 14px;
    font-weight: bold;
    text-shadow: 1px 0px 1px rgba(0, 0, 0, 0.25);
  }
  /* social login */
  .login-layout .login-box .widget-main {
    padding-bottom: 16px;
  }
  .social-or-login {
    margin-top: 4px;
    text-align: center;
    position: relative;
    z-index: 1;
  }
  .social-or-login :first-child {
    display: inline-block;
    background: #f7f7f7;
    padding: 0 8px;
    color: #5090c1;
    font-size: 13px;
  }
  .social-or-login:before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 0;
    right: 0;
    border-top: 1px dotted #a6c4db;
  }
  .social-login a {
    border-radius: 100%;
    width: 42px;
    height: 42px;
    line-height: 46px;
    padding: 0;
    margin: 0 1px;
    border-width: 0;
  }
  .social-login a > .ace-icon {
    font-size: 24px;
    margin: 0;
  }
  @media only screen and (max-width: 540px) {
    .login-layout .widget-box .widget-main {
      padding: 16px;
    }
    .login-container {
      width: 98%;
    }
    .login-layout .widget-box {
      padding: 0;
    }
    .login-layout .main-content {
      padding-left: 6px;
      padding-right: 6px;
    }
    .login-box .toolbar > div {
      width: auto;
    }
  }
  .light-login .widget-box {
    padding: 1px 1px 0;
    -webkit-box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.12);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(50, 50, 50, 0.33);
  }
  .light-login .widget-box .toolbar {
    border-top-width: 1px;
  }
  .light-login .widget-box.login-box {
    background-color: #bcc6cf;
    background-color: rgba(100, 110, 120, 0.4);
  }
  .light-login .widget-box.signup-box {
    background-color: #c1cec4;
    background-color: rgba(110, 120, 100, 0.4);
  }
  .light-login .widget-box.forgot-box {
    background-color: #d2c5c5;
    background-color: rgba(120, 110, 100, 0.4);
  }
  .invoice-info {
    line-height: 24px !important;
    color: #444;
    vertical-align: bottom;
    margin-left: 9px;
    margin-right: 9px;
  }
  .invoice-info-label {
    display: inline-block;
    max-width: 100px;
    text-align: right;
    font-size: 14px;
  }
  .invoice-box .label-large[class*='arrowed'] {
    margin-left: 11px !important;
    max-width: 95%;
  }
  .error-container {
    margin: 20px;
    padding: 0;
    background: #fff;
  }
  @media only screen and (max-width: 767px) {
    .error-container {
      margin: 12px;
    }
  }
  @media only screen and (max-width: 479px) {
    .error-container {
      margin: 6px;
    }
  }
  .ace-thumbnails {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .ace-thumbnails > li {
    float: left;
    display: block;
    position: relative;
    overflow: hidden;
    margin: 2px;
    border: 2px solid #333;
  }
  .ace-thumbnails > li > :first-child {
    display: block;
    position: relative;
  }
  .ace-thumbnails > li > :first-child:focus {
    outline: none;
  }
  .ace-thumbnails > li .tags {
    display: inline-block;
    position: absolute;
    bottom: 0;
    right: 0;
    overflow: visible;
    direction: rtl;
    padding: 0;
    margin: 0;
    height: auto;
    width: auto;
    background-color: transparent;
    border-width: 0;
    vertical-align: inherit;
  }
  .ace-thumbnails > li .tags > .label-holder {
    opacity: 0.92;
    filter: alpha(opacity=92);
    display: table;
    margin: 1px 0 0 0;
    direction: ltr;
    text-align: left;
  }
  .ace-thumbnails > li .tags > .label-holder:hover {
    opacity: 1;
    filter: alpha(opacity=100);
  }
  .ace-thumbnails > li > .tools {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -30px;
    width: 24px;
    background-color: rgba(0, 0, 0, 0.55);
    text-align: center;
    vertical-align: middle;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  .ace-thumbnails > li > .tools.tools-right {
    left: auto;
    right: -30px;
  }
  .ace-thumbnails > li > .tools.tools-bottom {
    width: auto;
    height: 28px;
    left: 0;
    right: 0;
    top: auto;
    bottom: -30px;
  }
  .ace-thumbnails > li > .tools.tools-top {
    width: auto;
    height: 28px;
    left: 0;
    right: 0;
    top: -30px;
    bottom: auto;
  }
  .ace-thumbnails > li:hover > .tools {
    left: 0;
    right: 0;
  }
  .ace-thumbnails > li:hover > .tools.tools-bottom {
    top: auto;
    bottom: 0;
  }
  .ace-thumbnails > li:hover > .tools.tools-top {
    bottom: auto;
    top: 0;
  }
  .ace-thumbnails > li:hover > .tools.tools-right {
    left: auto;
    right: 0;
  }
  .ace-thumbnails > li > .in.tools {
    left: 0;
    right: 0;
  }
  .ace-thumbnails > li > .in.tools.tools-bottom {
    top: auto;
    bottom: 0;
  }
  .ace-thumbnails > li > .in.tools.tools-top {
    bottom: auto;
    top: 0;
  }
  .ace-thumbnails > li > .in.tools.tools-right {
    left: auto;
    right: 0;
  }
  .ace-thumbnails > li > .tools > a,
  .ace-thumbnails > li > :first-child .inner a {
    display: inline-block;
    color: #fff;
    font-size: 18px;
    font-weight: normal;
    padding: 0 4px;
  }
  .ace-thumbnails > li > .tools > a:hover,
  .ace-thumbnails > li > :first-child .inner a:hover {
    text-decoration: none;
    color: #c9e2ea;
  }
  .ace-thumbnails > li .tools.tools-bottom > a,
  .ace-thumbnails > li .tools.tools-top > a {
    display: inline-block;
  }
  .ace-thumbnails > li > :first-child > .text {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    text-align: center;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.55);
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  .ace-thumbnails > li > :first-child > .text:before {
    /* makes the inner text become vertically centered*/
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: 0;
    /* Adjusts for spacing */
  }
  .ace-thumbnails > li > :first-child > .text > .inner {
    padding: 4px 0;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
    max-width: 90%;
  }
  .ace-thumbnails > li:hover > :first-child > .text {
    opacity: 1;
    filter: alpha(opacity=100);
  }
  @media only screen and (max-width: 480px) {
    .ace-thumbnails {
      text-align: center;
    }
    .ace-thumbnails > li {
      float: none;
      display: inline-block;
    }
  }
  .dialogs {
    padding: 9px;
    position: relative;
  }
  .itemdiv {
    padding-right: 3px;
    min-height: 66px;
    position: relative;
  }
  .itemdiv > .user {
    display: inline-block;
    width: 42px;
    position: absolute;
    left: 0;
  }
  .itemdiv > .user > img,
  .itemdiv > .user > .img {
    border-radius: 100%;
    border: 2px solid #5293c4;
    max-width: 40px;
    position: relative;
  }
  .itemdiv > .user > .img {
    padding: 2px;
  }
  .itemdiv > .body {
    width: auto;
    margin-left: 50px;
    margin-right: 12px;
    position: relative;
  }
  .itemdiv > .body > .time {
    display: block;
    font-size: 11px;
    font-weight: bold;
    color: #666;
    position: absolute;
    right: 9px;
    top: 0;
  }
  .itemdiv > .body > .time .ace-icon {
    font-size: 14px;
    font-weight: normal;
  }
  .itemdiv > .body > .name {
    display: block;
    color: #999;
  }
  .itemdiv > .body > .name > b {
    color: #777;
  }
  .itemdiv > .body > .text {
    display: block;
    position: relative;
    margin-top: 2px;
    padding-bottom: 19px;
    padding-left: 7px;
    font-size: 13px;
  }
  .itemdiv > .body > .text:after {
    display: block;
    content: '';
    height: 1px;
    font-size: 0;
    overflow: hidden;
    position: absolute;
    left: 16px;
    right: -12px;
    margin-top: 9px;
    border-top: 1px solid #e4ecf3;
  }
  .itemdiv > .body > .text > .ace-icon:first-child {
    color: #dce3ed;
    margin-right: 4px;
  }
  .itemdiv:last-child > .body > .text {
    border-bottom-width: 0;
  }
  .itemdiv:last-child > .body > .text:after {
    display: none;
  }
  .itemdiv.dialogdiv {
    padding-bottom: 14px;
  }
  .itemdiv.dialogdiv:before {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    bottom: 0;
    left: 19px;
    width: 3px;
    max-width: 3px;
    background-color: #e1e6ed;
    border: 1px solid #d7dbdd;
    border-width: 0 1px;
  }
  .itemdiv.dialogdiv:last-child {
    padding-bottom: 0;
  }
  .itemdiv.dialogdiv:last-child:before {
    display: none;
  }
  .itemdiv.dialogdiv > .user > img {
    border-color: #c9d6e5;
  }
  .itemdiv.dialogdiv > .body {
    border: 1px solid #dde4ed;
    padding: 5px 8px 8px;
    border-left-width: 2px;
    margin-right: 1px;
  }
  .itemdiv.dialogdiv > .body:before {
    content: '';
    display: block;
    position: absolute;
    left: -7px;
    top: 11px;
    width: 8px;
    height: 8px;
    border: 2px solid #dde4ed;
    border-width: 2px 0 0 2px;
    background-color: #fff;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .itemdiv.dialogdiv > .body > .time {
    position: static;
    float: right;
  }
  .itemdiv.dialogdiv > .body > .text {
    padding-left: 0;
    padding-bottom: 0;
  }
  .itemdiv.dialogdiv > .body > .text:after {
    display: none;
  }
  .itemdiv.dialogdiv .tooltip-inner {
    word-break: break-all;
  }
  .itemdiv.memberdiv {
    width: 175px;
    padding: 2px;
    margin: 3px 0;
    float: left;
    border-bottom: 1px solid #e8e8e8;
  }
  @media (min-width: 992px) {
    .itemdiv.memberdiv {
      max-width: 50%;
    }
  }
  @media (max-width: 991px) {
    .itemdiv.memberdiv {
      min-width: 33.333%;
    }
  }
  .itemdiv.memberdiv > .user > img {
    border-color: #dce3ed;
  }
  .itemdiv.memberdiv > .body > .time {
    position: static;
  }
  .itemdiv.memberdiv > .body > .name {
    line-height: 18px;
    height: 18px;
    margin-bottom: 0;
  }
  .itemdiv.memberdiv > .body > .name > a {
    display: inline-block;
    max-width: 100px;
    max-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }
  .itemdiv .tools {
    position: absolute;
    right: 5px;
    bottom: 10px;
    display: none;
  }
  .itemdiv .tools .btn {
    border-radius: 36px;
    margin: 1px 0;
  }
  .itemdiv .body .tools {
    bottom: 4px;
  }
  .itemdiv.commentdiv .tools {
    right: 9px;
  }
  .itemdiv:hover .tools {
    display: inline-block;
  }
  .item-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .item-list > li {
    padding: 9px;
    background-color: #fff;
    margin-top: -1px;
    position: relative;
  }
  .item-list > li.selected {
    color: #8090a0;
    background-color: #f4f9fc;
  }
  .item-list > li.selected label,
  .item-list > li.selected .lbl {
    text-decoration: line-through;
    color: #8090a0;
  }
  .item-list > li > .checkbox {
    display: inline-block;
  }
  .item-list > li > label.inline {
    display: inline-block;
  }
  .item-list > li label {
    font-size: 13px;
  }
  .item-list > li .percentage {
    font-size: 11px;
    font-weight: bold;
    color: #777;
  }
  .item-list > li.ui-sortable-helper {
    cursor: move;
  }
  li[class*='item-'] {
    border: 1px solid #ddd;
    border-left-width: 3px;
  }
  li.item-orange {
    border-left-color: #e8b110;
  }
  li.item-orange2 {
    border-left-color: #f79263;
  }
  li.item-red {
    border-left-color: #d53f40;
  }
  li.item-red2 {
    border-left-color: #d15b47;
  }
  li.item-green {
    border-left-color: #9abc32;
  }
  li.item-green2 {
    border-left-color: #0490a6;
  }
  li.item-blue {
    border-left-color: #4f99c6;
  }
  li.item-blue2 {
    border-left-color: #3983c2;
  }
  li.item-blue3 {
    border-left-color: #1144eb;
  }
  li.item-pink {
    border-left-color: #cb6fd7;
  }
  li.item-purple {
    border-left-color: #6f3cc4;
  }
  li.item-black {
    border-left-color: #505050;
  }
  li.item-grey {
    border-left-color: #a0a0a0;
  }
  li.item-brown {
    border-left-color: brown;
  }
  li.item-default {
    border-left-color: #abbac3;
  }
  .ui-sortable-placeholder,
  .ui-sortable-helper,
  .ui-sortable-placeholder > a,
  .ui-sortable-helper > a {
    cursor: move !important;
  }
  @media only screen and (max-width: 480px) {
    .itemdiv.memberdiv {
      float: none;
      width: auto;
    }
  }
  .profile-user-info {
    display: table;
    width: 98%;
    width: calc(100% - 24px);
    margin: 0 auto;
  }
  .profile-info-row {
    display: table-row;
  }
  .profile-info-name {
    text-align: right;
    padding: 6px 10px 6px 4px;
    font-weight: normal;
    color: #667e99;
    background-color: transparent;
    border-top: 1px dotted #d5e4f1;
    display: table-cell;
    width: 110px;
    vertical-align: middle;
  }
  .profile-info-value {
    display: table-cell;
    padding: 6px 4px 6px 6px;
    border-top: 1px dotted #d5e4f1;
  }
  .profile-info-value > span + span:before {
    /* for a list of values (such as location city & country) put a comma between them */
    display: inline;
    content: ',';
    margin-left: 1px;
    margin-right: 3px;
    color: #666;
    border-bottom: 1px solid #fff;
  }
  .profile-info-value > span + span.editable-container:before {
    display: none;
  }
  .profile-info-row:first-child .profile-info-name {
    border-top: none;
  }
  .profile-info-row:first-child .profile-info-value {
    border-top: none;
  }
  .profile-user-info-striped {
    border: 1px solid #dcebf7;
  }
  .profile-user-info-striped .profile-info-name {
    color: #336199;
    background-color: #edf3f4;
    border-top: 1px solid #f7fbff;
  }
  .profile-user-info-striped .profile-info-value {
    border-top: 1px dotted #dcebf7;
    padding-left: 12px;
  }
  .profile-picture {
    border: 1px solid #ccc;
    background-color: #fff;
    padding: 4px;
    display: inline-block;
    max-width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  }
  .profile-activity {
    padding: 10px 4px;
    border-bottom: 1px dotted #d0d8e0;
    position: relative;
    border-left: 1px dotted #fff;
    border-right: 1px dotted #fff;
  }
  .profile-activity:first-child {
    border-top: 1px dotted transparent;
  }
  .profile-activity:first-child:hover {
    border-top-color: #d0d8e0;
  }
  .profile-activity:hover {
    background-color: #f4f9fd;
    border-left: 1px dotted #d0d8e0;
    border-right: 1px dotted #d0d8e0;
  }
  .profile-activity img {
    border: 2px solid #c9d6e5;
    border-radius: 100%;
    max-width: 40px;
    margin-right: 10px;
    margin-left: 0px;
    box-shadow: none;
  }
  .profile-activity .thumbicon {
    background-color: #74abd7;
    display: inline-block;
    border-radius: 100%;
    width: 38px;
    height: 38px;
    color: #fff;
    font-size: 18px;
    text-align: center;
    line-height: 38px;
    margin-right: 10px;
    margin-left: 0px;
    text-shadow: none !important;
  }
  .profile-activity .time {
    display: block;
    margin-top: 4px;
    color: #777;
  }
  .profile-activity a.user {
    font-weight: bold;
    color: #9585bf;
  }
  .profile-activity .tools {
    position: absolute;
    right: 12px;
    bottom: 8px;
    display: none;
  }
  .profile-activity:hover .tools {
    display: block;
  }
  .user-profile .ace-thumbnails li {
    border: 1px solid #ccc;
    padding: 3px;
    margin: 6px;
  }
  .user-profile .ace-thumbnails li .tools {
    left: 3px;
    right: 3px;
  }
  .user-profile .ace-thumbnails li:hover .tools {
    bottom: 3px;
  }
  .user-title-label:hover {
    text-decoration: none;
  }
  .user-title-label + .dropdown-menu {
    margin-left: -12px;
  }
  .profile-contact-links {
    padding: 4px 2px 5px;
    border: 1px solid #e0e2e5;
    background-color: #f8fafc;
  }
  .btn-link:hover .ace-icon {
    text-decoration: none !important;
  }
  .profile-social-links > a {
    text-decoration: none;
    margin: 0 1px;
  }
  .profile-social-links > a:hover > .ace-icon {
    text-decoration: none;
  }
  .profile-skills .progress {
    height: 26px;
    margin-bottom: 2px;
    background-color: transparent;
  }
  .profile-skills .progress .progress-bar {
    line-height: 26px;
    font-size: 13px;
    font-weight: bold;
    font-family: 'Open Sans';
    padding: 0 8px;
  }
  .profile-users .user {
    display: block;
    position: static;
    text-align: center;
    width: auto;
  }
  .profile-users .user img {
    padding: 2px;
    border-radius: 100%;
    border: 1px solid #aaa;
    max-width: none;
    width: 64px;
    -webkit-transition: all 0.1s;
    -o-transition: all 0.1s;
    transition: all 0.1s;
  }
  .profile-users .user img:hover {
    -webkit-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.33);
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.33);
  }
  .profile-users .memberdiv {
    background-color: #fff;
    width: 100px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: none;
    text-align: center;
    margin: 0 8px 24px;
  }
  .profile-users .memberdiv .name a:hover .ace-icon {
    text-decoration: none;
  }
  .profile-users .memberdiv .body {
    display: inline-block;
    margin: 8px 0 0 0;
  }
  .profile-users .memberdiv .popover {
    visibility: hidden;
    min-width: 0;
    max-height: 0;
    max-width: 0;
    margin-left: 0;
    margin-right: 0;
    top: -5%;
    left: auto;
    right: auto;
    opacity: 0;
    display: none;
    position: absolute;
    -webkit-transition: opacity 0.2s linear 0s, visibility 0s linear 0.2s,
      max-height 0s linear 0.2s, max-width 0s linear 0.2s,
      min-width 0s linear 0.2s;
    -o-transition: opacity 0.2s linear 0s, visibility 0s linear 0.2s,
      max-height 0s linear 0.2s, max-width 0s linear 0.2s,
      min-width 0s linear 0.2s;
    transition: opacity 0.2s linear 0s, visibility 0s linear 0.2s,
      max-height 0s linear 0.2s, max-width 0s linear 0.2s,
      min-width 0s linear 0.2s;
  }
  .profile-users .memberdiv .popover.right {
    left: 100%;
    right: auto;
    display: block;
  }
  .profile-users .memberdiv .popover.left {
    left: auto;
    right: 100%;
    display: block;
  }
  .profile-users .memberdiv > :first-child:hover .popover {
    visibility: visible;
    opacity: 1;
    z-index: 1060;
    max-height: 250px;
    max-width: 250px;
    min-width: 150px;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
  }
  .profile-users .memberdiv .tools {
    position: static;
    display: block;
    width: 100%;
    margin-top: 2px;
  }
  .profile-users .memberdiv .tools > a {
    margin: 0 2px;
  }
  .profile-users .memberdiv .tools > a:hover {
    text-decoration: none;
  }
  .user-status {
    display: inline-block;
    width: 11px;
    height: 11px;
    background-color: #fff;
    border: 3px solid #aaa;
    border-radius: 100%;
    vertical-align: middle;
    margin-right: 1px;
  }
  .user-status.status-online {
    border-color: #8ac16c;
  }
  .user-status.status-busy {
    border-color: #e07f69;
  }
  .user-status.status-idle {
    border-color: #ffb752;
  }
  .tab-content.profile-edit-tab-content {
    border: 1px solid #ddd;
    padding: 8px 32px 32px;
    -webkit-box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.2);
    box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
  }
  @media only screen and (max-width: 480px) {
    .profile-info-name {
      width: 80px;
    }
    .profile-user-info-striped .profile-info-name {
      float: none;
      width: auto;
      text-align: left;
      padding: 6px 4px 6px 10px;
      display: block;
    }
    .profile-user-info-striped .profile-info-value {
      margin-left: 10px;
      display: block;
    }
  }
  @media only screen and (max-width: 480px) {
    .user-profile .memberdiv {
      width: 50%;
      margin-left: 0;
      margin-right: 0;
    }
  }
  .inbox-tabs.nav-tabs > li > a {
    background-color: #fafafa;
  }
  .inbox-tabs.nav-tabs > li.active:not(.open) > a,
  .inbox-tabs.nav-tabs > li.active:not(.open) > a:hover,
  .inbox-tabs.nav-tabs > li.active:not(.open) > a:focus {
    background-color: #f1f5fa;
    box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.1);
    color: #48768e;
  }
  .inbox-tabs.nav-tabs > li > a.btn-new-mail {
    background-color: transparent;
    border: none !important;
    padding: 0 !important;
  }
  .inbox-tabs.nav-tabs > li > a.btn-new-mail > .btn {
    border-width: 0 !important;
    border-radius: 3px !important;
    padding: 0 6px !important;
    position: relative;
    transition: none !important;
  }
  .inbox-tabs.nav-tabs > li.active > a.btn-new-mail {
    box-shadow: none !important;
  }
  .inbox-tabs.nav-tabs > li.active > a.btn-new-mail > .btn:before {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -6px;
    border-width: 6px 8px;
    border-style: solid;
    border-color: transparent;
    border-top-color: inherit;
  }
  .inbox-tabs.nav-tabs.tab-size-bigger > li > a {
    padding: 5px 15px 7px;
    font-size: 14px;
  }
  .inbox-tabs.nav-tabs.tab-size-bigger > li > a > .ace-icon:first-child {
    margin-bottom: 5px;
  }
  .inbox-tabs.nav-tabs.tab-size-bigger > li > a.btn-new-mail > .btn {
    padding: 10px !important;
    border-radius: 7px !important;
  }
  .inbox-tabs.nav-tabs.tab-size-bigger > li.active > a.btn-new-mail {
    margin-top: 0 !important;
    top: 1px;
  }
  .inbox-tabs.nav-tabs.tab-size-bigger
    > li.active
    > a.btn-new-mail
    > .btn:before {
    left: 50%;
    margin-left: -8px;
    border-width: 8px 10px;
  }
  @media only screen and (max-width: 479px) {
    .inbox-tabs > .li-new-mail {
      display: block;
      text-align: right;
      margin-bottom: 8px !important;
      float: none !important;
    }
    .inbox-tabs > .li-new-mail > .btn-new-mail {
      display: inline-block;
      width: auto;
    }
  }
  .message-container {
    position: relative;
  }
  .message-list {
    position: relative;
  }
  .message-item {
    border: 1px solid #eaedf1;
    border-bottom-width: 0;
    padding: 12px 12px 14px;
    line-height: 18px;
    position: relative;
    background-color: #fff;
  }
  .message-item:first-child {
    border-top-width: 0;
  }
  .message-item:hover {
    border-color: #e2eaf2;
    background-color: #f2f6f9;
  }
  .message-item:hover + .message-item {
    border-top-color: #e2eaf2;
  }
  .message-item:hover + .message-item.selected {
    border-top-color: #fff;
  }
  .message-item.selected {
    background-color: #eff4f7;
    border-color: #fff #e2eaf2;
  }
  .message-item.selected + .message-item {
    border-top-color: #fff;
  }
  .message-item.selected + .message-item:hover + .message-item {
    border-top-color: #fff;
  }
  .message-navbar input.ace + .lbl::before,
  .message-item input.ace + .lbl::before {
    top: auto;
  }
  .message-item .sender {
    margin: 0 6px 0 4px;
    vertical-align: middle;
    color: #467287;
    display: inline-block;
    width: 110px;
    height: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
  }
  .message-item.message-unread .sender {
    color: #6a9cba;
    font-weight: bold;
  }
  .message-item .summary {
    vertical-align: middle;
    display: inline-block;
    position: relative;
    margin-left: 30px;
    max-width: 250px;
    max-width: calc(100% - 300px);
    min-width: 200px;
    white-space: nowrap;
  }
  .message-item .summary .text {
    color: #555;
    vertical-align: middle;
    display: inline-block;
    width: auto;
    max-width: 100%;
    height: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
  }
  .message-item .summary .text:hover {
    text-decoration: underline;
  }
  .message-item .summary .message-flags {
    display: block;
    position: absolute;
    right: 100%;
    margin-right: 4px;
    height: 18px;
    white-space: nowrap;
  }
  .message-item.message-unread .summary .text {
    color: #609fc4;
    font-weight: bold;
  }
  .message-item .time {
    float: right;
    width: 60px;
    height: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #666;
  }
  .message-item.message-unread .time {
    font-weight: bold;
    color: #609fc4;
  }
  .message-item.message-unread .message-content .time {
    font-weight: normal;
  }
  .message-item .attachment {
    color: #999;
    font-size: 18px;
    vertical-align: middle;
    float: right;
    margin: 0 12px;
    position: relative;
  }
  .message-item.message-unread .attachment {
    color: #4f99c6;
  }
  .message-content .time {
    font-weight: normal;
  }
  .message-star {
    vertical-align: middle;
    margin: 2px 4px 0 6px;
    font-size: 15px;
    cursor: pointer;
  }
  .message-star:hover {
    color: #feb902 !important;
    text-decoration: none;
  }
  .mail-tag:empty {
    display: inline-block;
    width: 8px;
    height: 11px;
    padding: 0;
    line-height: normal;
    vertical-align: middle;
    margin: 0 1px 0 0;
  }
  .badge.mail-tag {
    border-radius: 2px;
  }
  .dropdown-menu > li > a > .mail-tag {
    vertical-align: inherit;
  }
  @media only screen and (max-width: 991px) {
    .message-item .summary {
      min-width: 0;
    }
    .message-item .sender {
      width: 100px;
    }
  }
  @media only screen and (max-width: 550px) {
    .message-item .summary {
      margin: 8px 0 0 32px;
      max-width: 95%;
      min-width: 0;
      display: block;
    }
    .message-item .sender {
      width: auto;
      max-width: 150px;
    }
    .message-item .summary .text {
      max-width: 95%;
    }
  }
  .btn-message,
  .btn-message:hover,
  .btn-message:focus,
  .btn-message:active,
  .open .btn-message.dropdown-toggle {
    background-color: #fff !important;
    border: 1px solid #94b9ce !important;
    color: #7ca3ba !important;
    text-shadow: none !important;
  }
  .message-content {
    padding: 16px 12px;
    border: 1px solid #e9e9e9;
    -webkit-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.02);
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.02);
    background-color: rgba(255, 255, 255, 0.8);
    border-top-width: 0;
  }
  .message-item .message-content {
    margin-top: 16px;
    border-top-width: 1px;
  }
  .message-body {
    padding: 0 9px;
    color: #6a7177;
  }
  .message-navbar {
    line-height: 24px;
    padding: 10px 12px;
    border: 1px solid #d6e1ea;
    border-color: #d6e1ea transparent;
    background-color: #f1f5fa;
    text-align: center;
    position: relative;
  }
  .message-navbar .dropdown-toggle,
  .message-content .dropdown-toggle {
    color: #777;
  }
  .message-navbar .dropdown-toggle:hover,
  .message-content .dropdown-toggle:hover,
  .message-navbar .dropdown-toggle:focus,
  .message-content .dropdown-toggle:focus {
    text-decoration: none;
    color: #2283c5;
  }
  .message-bar {
    display: inline-block;
    min-height: 28px;
  }
  @media only screen and (max-width: 480px) {
    .message-bar {
      display: block;
      min-height: 60px;
    }
  }
  .message-footer {
    background-color: #f1f1f1;
    padding: 12px 16px;
    border: 1px solid #e6e6e6;
    border-width: 1px 0;
    border-top: 1px solid #e4e9ee;
  }
  .message-footer .pagination {
    margin: 0;
  }
  .message-footer .pagination > li {
    margin: 0;
    padding: 0;
  }
  .message-footer .pagination > li > a,
  .message-footer .pagination > li > span {
    color: #777;
    padding: 3px 6px;
    margin-left: 1px;
    margin-right: 1px;
    background-color: transparent;
    border: 1px solid transparent;
  }
  .message-footer .pagination > li.disabled > span {
    color: #bbbbbb;
    cursor: default;
    background-color: transparent;
    border-color: transparent;
  }
  .message-footer .pagination > li > a:hover {
    border-color: #91bad6;
    color: #2283c5;
    text-decoration: none;
    background-color: #fff;
  }
  .message-footer input[type='text'] {
    font-size: 12px;
    width: 34px;
    height: 24px;
    line-height: 20px;
    margin-bottom: 0;
    padding: 3px;
    vertical-align: middle;
    text-align: center;
  }
  .message-footer-style2 .pagination > li > a,
  .message-footer-style2 .pagination > li > span {
    border: 1px solid #b5b5b5;
    border-radius: 100% !important;
    width: 26px;
    height: 26px;
    line-height: 26px;
    display: inline-block;
    text-align: center;
    padding: 0;
  }
  .message-footer-style2 .pagination > li > span,
  .message-footer-style2 .pagination > li.disabled > span {
    border-color: #ccc;
  }
  .message-footer-style2 .pagination > li > a:hover {
    border-color: #84afc9;
    background-color: #f7f7f7;
  }
  .message-item.message-inline-open {
    background-color: #f2f6f9;
    border: 1px solid #ddd;
    border-bottom-color: #ccc;
  }
  .message-item.message-inline-open:first-child {
    border-top-color: #eee;
  }
  .message-item.message-inline-open:last-child {
    border-bottom-color: #ddd;
  }
  .message-item.message-inline-open + .message-item {
    border-bottom-color: transparent;
  }
  .message-loading-overlay {
    position: absolute;
    z-index: 14;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    text-align: center;
  }
  .message-loading-overlay > .ace-icon {
    position: absolute;
    top: 15%;
    left: 0;
    right: 0;
    text-align: center;
  }
  .message-content .sender {
    color: #6a9cba;
    font-weight: bold;
    width: auto;
    text-overflow: inherit;
    vertical-align: middle;
    margin: 0;
  }
  .message-content .time {
    width: auto;
    text-overflow: inherit;
    white-space: normal;
    float: none;
    vertical-align: middle;
  }
  ul.attachment-list {
    margin: 6px 0 4px 8px;
  }
  ul.attachment-list > li {
    margin-bottom: 3px;
  }
  .message-attachment {
    padding-left: 10px;
    padding-right: 10px;
  }
  .attached-file {
    color: #777;
    width: 200px;
    display: inline-block;
  }
  .attached-file > .ace-icon {
    display: inline-block;
    width: 16px;
    margin-right: 2px;
    vertical-align: middle;
  }
  .attached-file:hover {
    text-decoration: none;
    color: #438eb9;
  }
  .attached-file:hover .attached-name {
    color: #2283c5;
  }
  .attached-file .attached-name {
    display: inline-block;
    max-width: 175px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
  }
  .messagebar-item-left,
  .messagebar-item-right {
    position: absolute;
    bottom: 14px;
    left: 12px;
    text-align: left;
  }
  .messagebar-item-right {
    right: 12px;
    left: auto;
  }
  .message-navbar .nav-search {
    right: auto;
    left: 5px;
    top: auto;
    bottom: 11px;
  }
  .message-navbar .messagebar-item-left ~ .nav-search {
    left: 60px;
  }
  .message-form {
    border: 1px solid #ddd;
    border-top: none;
    padding-top: 22px;
  }
  @media only screen and (max-width: 480px) {
    .message-form {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .message-form .form-actions {
    margin-bottom: 0;
  }
  .message-form .wysiwyg-editor {
    overflow: auto;
    min-height: 150px;
    max-height: 250px;
    height: auto;
  }
  .btn-send-message {
    position: relative;
    top: 6px;
    vertical-align: middle;
  }
  .btn-back-message-list {
    color: #777;
  }
  .btn-back-message-list:hover {
    color: #478fca;
    text-decoration: none;
  }
  .message-condensed .message-item {
    padding-top: 8px;
    padding-bottom: 9px;
  }
  .message-condensed .message-navbar,
  .message-condensed .message-footer {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .message-condensed .messagebar-item-left,
  .message-condensed .messagebar-item-right {
    bottom: 9px;
  }
  .message-condensed .message-navbar .nav-search {
    bottom: 7px;
  }
  @media only screen and (max-width: 480px) {
    .message-condensed .message-bar {
      min-height: 42px;
    }
  }
  .inbox-folders .btn-block {
    margin-top: 0;
  }
  @media only screen and (max-width: 767px) {
    .inbox-folders.inbox-folders-responsive .btn-block {
      width: 24%;
    }
  }
  @media only screen and (max-width: 600px) {
    .inbox-folders.inbox-folders-responsive .btn-block {
      width: 48%;
    }
  }
  @media only screen and (max-width: 320px) {
    .inbox-folders.inbox-folders-responsive .btn-block {
      width: 99%;
    }
  }
  .inbox-folders .btn-lighter,
  .inbox-folders .btn-lighter.active {
    background-color: #f4f4f4 !important;
    text-shadow: none !important;
    color: #7c8395 !important;
    border: 1px solid #fff !important;
    padding: 5px 11px;
  }
  .inbox-folders .btn-lighter.active {
    background-color: #edf2f8 !important;
    color: #53617c !important;
  }
  .inbox-folders .btn-lighter:hover {
    background-color: #efefef !important;
    color: #6092c4 !important;
  }
  .inbox-folders .btn > .ace-icon:first-child {
    display: inline-block;
    width: 14px;
    text-align: left;
  }
  .inbox-folders .btn-lighter + .btn-lighter {
    border-top-width: 0 !important;
  }
  .inbox-folders .btn.active:before {
    display: block;
    content: '';
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: -1px;
    border-left: 3px solid #4f99c6;
  }
  .inbox-folders .btn.active:after {
    display: none;
  }
  .inbox-folders .btn .counter {
    border-radius: 3px;
    position: absolute;
    right: 8px;
    top: 8px;
    padding-left: 6px;
    padding-right: 6px;
    opacity: 0.75;
    filter: alpha(opacity=75);
  }
  .inbox-folders .btn:hover .badge {
    opacity: 1;
    filter: alpha(opacity=100);
  }
  .timeline-container {
    position: relative;
    padding-top: 4px;
    margin-bottom: 32px;
  }
  .timeline-container:last-child {
    margin-bottom: 0;
  }
  .timeline-container:before {
    /* the vertical line running through icons */
    content: '';
    display: block;
    position: absolute;
    left: 28px;
    top: 0;
    bottom: 0;
    border: 1px solid #e2e3e7;
    background-color: #e7eaef;
    width: 4px;
    border-width: 0 1px;
  }
  .timeline-container:first-child:before {
    border-top-width: 1px;
  }
  .timeline-container:last-child:before {
    border-bottom-width: 1px;
  }
  .timeline-item {
    position: relative;
    margin-bottom: 8px;
  }
  .timeline-item .widget-box {
    background-color: #f2f6f9;
    color: #595c66;
  }
  .timeline-item .transparent.widget-box {
    border-left: 3px solid #dae1e5;
  }
  .timeline-item .transparent .widget-header {
    background-color: #ecf1f4;
    border-bottom-width: 0;
  }
  .timeline-item .transparent .widget-header > .widget-title {
    margin-left: 8px;
  }
  .timeline-item:nth-child(even) .widget-box {
    background-color: #f3f3f3;
    color: #616161;
  }
  .timeline-item:nth-child(even) .widget-box.transparent {
    border-left-color: #dbdbdb !important;
  }
  .timeline-item:nth-child(even) .widget-box.transparent .widget-header {
    background-color: #eee !important;
  }
  .timeline-item .widget-box {
    margin: 0;
    position: relative;
    max-width: none;
    margin-left: 60px;
  }
  .timeline-item .widget-main {
    margin: 0;
    position: relative;
    max-width: none;
    border-bottom-width: 0;
  }
  .timeline-item .widget-body {
    background-color: transparent;
  }
  .timeline-item .widget-toolbox {
    padding: 4px 8px 0 !important;
    background-color: transparent !important;
    border-width: 0 !important;
    margin: 0 0px !important;
  }
  .timeline-info {
    float: left;
    width: 60px;
    text-align: center;
    position: relative;
  }
  .timeline-info img {
    border-radius: 100%;
    max-width: 42px;
  }
  .timeline-info .label,
  .timeline-info .badge {
    font-size: 12px;
  }
  .timeline-container:not(.timeline-style2) .timeline-indicator {
    opacity: 1;
    border-radius: 100%;
    display: inline-block;
    font-size: 16px;
    height: 36px;
    line-height: 30px;
    width: 36px;
    text-align: center;
    text-shadow: none !important;
    padding: 0;
    cursor: default;
    border: 3px solid #fff !important;
  }
  .timeline-label {
    display: block;
    clear: both;
    margin: 0 0 18px;
    margin-left: 34px;
  }
  .timeline-item img {
    border: 1px solid #aaa;
    padding: 2px;
    background-color: #fff;
  }
  .timeline-style2:before {
    display: none;
  }
  .timeline-style2 .timeline-item {
    padding-bottom: 22px;
    margin-bottom: 0;
  }
  .timeline-style2 .timeline-item:last-child {
    padding-bottom: 0;
  }
  .timeline-style2 .timeline-item:before {
    content: '';
    display: block;
    position: absolute;
    left: 90px;
    top: 5px;
    bottom: -5px;
    border-width: 0;
    background-color: #ddd;
    width: 2px;
    max-width: 2px;
  }
  .timeline-style2 .timeline-item:last-child:before {
    display: none;
  }
  .timeline-style2 .timeline-item:first-child:before {
    display: block;
  }
  .timeline-style2 .timeline-item .transparent .widget-header {
    background-color: transparent !important;
  }
  .timeline-style2 .timeline-item .transparent.widget-box {
    background-color: transparent !important;
    border-left: none !important;
  }
  .timeline-style2 .timeline-info {
    width: 100px;
  }
  .timeline-style2 .timeline-indicator {
    font-size: 0;
    height: 12px;
    line-height: 12px;
    width: 12px;
    border-width: 1px !important;
    background-color: #ffffff !important;
    position: absolute;
    left: 85px;
    top: 3px;
    opacity: 1;
    border-radius: 100%;
    display: inline-block;
    padding: 0;
  }
  .timeline-style2 .timeline-date {
    display: inline-block;
    width: 72px;
    text-align: right;
    margin-right: 25px;
    color: #777;
  }
  .timeline-style2 .timeline-item .widget-box {
    margin-left: 112px;
  }
  .timeline-style2 .timeline-label {
    width: 75px;
    text-align: center;
    margin-left: 0;
    margin-bottom: 10px;
    text-align: right;
    color: #666;
    font-size: 14px;
  }
  .timeline-time {
    text-align: center;
    position: static;
  }
  .well.search-area {
    background-color: #ecf1f4;
    border-color: #d6e1ea;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .search-thumbnail {
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
  }
  .search-thumbnail:hover {
    border-color: #75a8ce;
  }
  .search-thumbnail .search-title {
    margin-top: 15px;
  }
  .search-media {
    border: 1px solid #ddd;
    margin-top: -1px;
    padding: 12px;
    -webkit-transition: border 0.1s ease-in-out 0s;
    -o-transition: border 0.1s ease-in-out 0s;
    transition: border 0.1s ease-in-out 0s;
    position: relative;
    padding-right: 150px;
  }
  .search-media:hover {
    border-color: #75a8ce;
    z-index: 1;
  }
  .search-media .search-actions {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 20%;
    min-width: 100px;
    max-width: 150px;
    padding: 6px 9px;
  }
  .search-media .search-actions::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 8px;
    bottom: 16px;
    width: 1px;
    background-image: -webkit-linear-gradient(top, #ffffff 0%, #dddddd 100%);
    background-image: -o-linear-gradient(top, #ffffff 0%, #dddddd 100%);
    background-image: linear-gradient(to bottom, #ffffff 0%, #dddddd 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ffffffff',
        endColorstr='#ffdddddd',
        GradientType=0
      );
  }
  .search-media:hover .search-actions {
    background-color: #f0f4f7;
  }
  .search-media.disabled:hover .search-actions {
    background-color: #f6f6f6;
  }
  .search-media:not(.disabled):hover .search-actions::before {
    background-image: -webkit-linear-gradient(top, #ffffff 0%, #84bee5 100%);
    background-image: -o-linear-gradient(top, #ffffff 0%, #84bee5 100%);
    background-image: linear-gradient(to bottom, #ffffff 0%, #84bee5 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ffffffff',
        endColorstr='#ff84bee5',
        GradientType=0
      );
  }
  .search-filter-header {
    padding: 8px;
    margin: -4px;
  }
  .search-btn-action {
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    width: auto;
    -webkit-transition: bottom 0.15s;
    -o-transition: bottom 0.15s;
    transition: bottom 0.15s;
  }
  .search-media:hover .search-btn-action {
    bottom: 1px;
  }
  .search-promotion.label {
    position: absolute;
    margin-top: -1px;
    margin-left: -1px;
  }
  .search-filter-element {
    padding: 12px;
    background-color: #fff;
    border: 1px solid #c9dde7;
  }
  .search-results {
    padding: 24px 12px;
    min-height: 20px;
  }
  .search-result {
    margin-top: -1px;
    position: relative;
    padding: 12px;
    border: 1px dotted;
    border-color: #ddd #fff #fff;
    border-color: rgba(0, 0, 0, 0.11) transparent transparent;
  }
  .search-result:hover {
    background-color: #f7f7f7;
    border-color: #d6e1ea;
    border-style: solid;
    z-index: 1;
  }
  .search-result:first-child {
    border-top-color: #fff;
    border-top-color: transparent;
  }
  .search-result:first-child:hover {
    border-top-color: #d6e1ea;
  }
  .search-result .search-title {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 6px;
  }
  .search-result .search-content {
    margin-top: 2px;
  }
  .dataTables_length select {
    width: 70px;
    height: 25px;
    padding: 2px 3px;
  }
  .dataTables_length label {
    font-weight: normal;
  }
  .dataTables_filter {
    text-align: right;
  }
  .dataTables_filter input[type='text'],
  .dataTables_filter input[type='search'] {
    width: 125px;
    height: 18px;
    line-height: 18px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    padding: 4px 6px;
  }
  .dataTables_filter label {
    font-weight: normal;
  }
  .dataTables_info {
    font-size: 14px;
  }
  .dataTables_paginate {
    text-align: right;
  }
  .dataTables_paginate .pagination {
    margin: 0 12px;
  }
  .dataTables_wrapper label {
    display: inline-block;
    font-size: 13px;
  }
  .dataTables_wrapper input[type='text'],
  .dataTables_wrapper input[type='search'],
  .dataTables_wrapper select {
    margin-bottom: 0 !important;
    margin: 0 4px;
  }
  .dataTables_wrapper .row {
    margin: 0 !important;
  }
  .dataTables_wrapper .row:first-child {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #eff3f8;
  }
  .dataTables_wrapper .row:first-child + .dataTable {
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }
  .dataTables_wrapper .row:last-child {
    border-bottom: 1px solid #e0e0e0;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #eff3f8;
  }
  .dataTables_wrapper .dataTables_scroll + .row {
    border-top: 1px solid #e0e0e0;
  }
  .dataTable {
    margin-bottom: 0;
  }
  .dataTable > thead > tr > th[class*='sort'] {
    cursor: pointer;
  }
  .dataTable > thead > tr > th[class*='sort']:after {
    float: right;
    display: inline;
    content: '\f0dc';
    font-family: FontAwesome;
    font-size: 13px;
    font-weight: normal;
    color: #555;
  }
  .dataTable > thead > tr > th[class*='sort']:hover {
    color: #547ea8;
  }
  .dataTable > thead > tr > th[class*='sorting_'] {
    color: #307ecc;
  }
  .dataTable > thead > tr > th.sorting_desc,
  .dataTable > thead > tr > th.sorting_asc {
    background-image: -webkit-linear-gradient(top, #eff3f8 0%, #e3e7ed 100%);
    background-image: -o-linear-gradient(top, #eff3f8 0%, #e3e7ed 100%);
    background-image: linear-gradient(to bottom, #eff3f8 0%, #e3e7ed 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ffeff3f8',
        endColorstr='#ffe3e7ed',
        GradientType=0
      );
  }
  .dataTable > thead > tr > th.sorting_desc:after {
    content: '\f0dd';
    top: -6px;
    color: #307ecc;
  }
  .dataTable > thead > tr > th.sorting_asc:after {
    content: '\f0de';
    top: 4px;
    color: #307ecc;
  }
  .dataTable > thead > tr > th.sorting_disabled {
    cursor: inherit;
  }
  .dataTable > thead > tr > th.sorting_disabled:after {
    display: none;
  }
  .dataTables_scrollHead
    + .dataTables_scrollBody
    > .dataTable
    > thead
    > tr
    > th:after {
    display: none;
  }
  .dataTables_scrollHeadInner {
    width: auto !important;
  }
  .dataTables_scrollHeadInner > .dataTable > thead > tr > th {
    border-bottom-width: 0 !important;
  }
  .dataTables_borderWrap .dataTables_scrollBody,
  .dataTables_borderWrap .dataTables_scrollHead {
    border: 1px solid #dddddd !important;
    border-width: 0 1px !important;
  }
  .dataTables_borderWrap .dataTables_scrollBody .table-bordered,
  .dataTables_borderWrap .dataTables_scrollHead .table-bordered {
    border-left-width: 0;
    border-right-width: 0;
  }
  .dataTables_borderWrap
    .dataTables_scrollBody
    .table-bordered
    > thead
    > tr
    > th:first-child,
  .dataTables_borderWrap
    .dataTables_scrollHead
    .table-bordered
    > thead
    > tr
    > th:first-child,
  .dataTables_borderWrap
    .dataTables_scrollBody
    .table-bordered
    > tbody
    > tr
    > td:first-child,
  .dataTables_borderWrap
    .dataTables_scrollHead
    .table-bordered
    > tbody
    > tr
    > td:first-child {
    border-left-width: 0;
  }
  .dataTables_borderWrap
    .dataTables_scrollBody
    .table-bordered
    > thead
    > tr
    > th:last-child,
  .dataTables_borderWrap
    .dataTables_scrollHead
    .table-bordered
    > thead
    > tr
    > th:last-child,
  .dataTables_borderWrap
    .dataTables_scrollBody
    .table-bordered
    > tbody
    > tr
    > td:last-child,
  .dataTables_borderWrap
    .dataTables_scrollHead
    .table-bordered
    > tbody
    > tr
    > td:last-child {
    border-right-width: 0;
  }
  table.dataTable {
    clear: both;
    max-width: none !important;
  }
  table.dataTable th:active {
    outline: none;
  }
  div.dataTables_scrollHead table {
    margin-bottom: 0 !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  div.dataTables_scrollHead table thead tr:last-child th:first-child,
  div.dataTables_scrollHead table thead tr:last-child td:first-child {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  div.dataTables_scrollBody table {
    border-top: none;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  div.dataTables_scrollBody tbody tr:first-child th,
  div.dataTables_scrollBody tbody tr:first-child td {
    border-top: none;
  }
  div.dataTables_scrollFoot table {
    margin-top: 0 !important;
    border-top: none;
  }
  .tableTools-container {
    margin-bottom: 8px;
    position: relative;
  }
  .gritter-item-wrapper.dt-button-info {
    padding: 12px 11px 8px;
    z-index: 1999;
  }
  .gritter-item-wrapper.dt-button-info > h2 {
    margin-top: 0;
  }
  .dt-button-collection .dropdown-menu {
    display: block;
    z-index: 1101;
  }
  .dt-button-collection .dropdown-menu > li > a {
    color: #888;
    text-decoration: line-through;
  }
  .dt-button-collection .dropdown-menu > li > a.active {
    color: #333;
    text-decoration: none;
  }
  div.dt-button-background {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    z-index: 1100;
    opacity: 0.1;
  }
  .dataTable > tbody > tr.selected > td {
    background-color: #dff0d8;
  }
  .dataTable > tbody > tr.selected:hover > td {
    background-color: #d0e9c6;
  }
  div.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 60px;
    margin-left: -40%;
    margin-top: -25px;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    font-size: 1.2em;
    background-color: white;
    border: 2px solid #ddd;
    background-color: rgba(255, 255, 255, 0.66);
  }
  .fc-toolbar h2 {
    font-size: 22px;
    color: #65a0ce;
  }
  .fc-unthemed th,
  .fc-unthemed td,
  .fc-unthemed hr,
  .fc-unthemed thead,
  .fc-unthemed tbody,
  .fc-unthemed .fc-row,
  .fc-unthemed .fc-popover {
    border-color: #bcd4e5;
  }
  .fc-unthemed .fc-today {
    background: #ffc;
  }
  .fc-event {
    border-width: 0;
    color: #ffffff;
    padding: 1px 1px 2px 2px;
    border-radius: 0;
  }
  .fc-event:not([class*='label-']) {
    background-color: #abbac3;
  }
  .fc-event.label-yellow {
    color: #996633;
  }
  .fc-event.label-light {
    color: #888888;
  }
  .label-yellow .fc-event {
    color: #996633;
  }
  .label-light .fc-event {
    color: #888;
  }
  [class*='label-'] > .fc-event,
  [class*='label-'] > .fc-event > .fc-event-skin.fc-event-head {
    background-color: inherit;
  }
  .fc-event.ui-draggable-dragging {
    cursor: move;
  }
  .fc-event.fc-event-vert,
  .fc-event-vert > .fc-event {
    padding: 0 0 1px;
  }
  .fc-day-number {
    color: #2e6589;
    opacity: 1;
    filter: alpha(opacity=100);
  }
  .fc-widget-header,
  .fc .fc-axis {
    background: #ecf2f7;
    color: #8090a0;
  }
  .fc-event-hori,
  .fc-event-vert {
    border-radius: 0 !important;
    border-color: transparent;
  }
  .fc-event-vert .fc-event-content {
    padding-left: 1px;
    padding-right: 1px;
  }
  .fc-event-vert .fc-event-time {
    padding: 0;
  }
  .fc-state-default {
    border: none;
  }
  .fc-state-default,
  .fc-state-default .fc-button-inner {
    border: none;
    background-color: #abbac3;
    color: #fff;
    background-image: none;
    box-shadow: none;
    text-shadow: none;
    border-radius: 0 !important;
    margin-left: 2px;
  }
  .fc-state-default .fc-button-effect {
    display: none;
  }
  .fc-state-disabled,
  .fc-state-disabled .fc-button-inner {
    opacity: 0.75;
    filter: alpha(opacity=75);
    color: #ddd;
  }
  .fc-state-active,
  .fc-state-active .fc-button-inner {
    border-color: #4f99c6;
    background-color: #6fb3e0;
  }
  .fc-state-hover,
  .fc-state-hover .fc-button-inner {
    background-color: #8b9aa3;
  }
  .fc .fc-button-group > * {
    margin: 0 1px 0 0;
  }
  .external-event {
    margin: 6px 0;
    padding: 0;
    cursor: default;
    display: block;
    font-size: 13px;
    line-height: 28px;
    color: #ffffff;
  }
  .external-event:not([class*='label-']) {
    background-color: #abbac3;
  }
  .external-event:hover {
    opacity: 1;
    filter: alpha(opacity=100);
  }
  .external-event.ui-draggable-dragging {
    cursor: move;
  }
  .external-event.label-yellow {
    color: #996633;
  }
  .external-event.label-light {
    color: #888888;
  }
  .external-event > .ace-icon:first-child {
    display: inline-block;
    height: 32px;
    width: 32px;
    text-align: center;
    line-height: 30px;
    margin-right: 5px;
    font-size: 15px;
    border-right: 1px solid #fff;
  }
  /**
.widget-main {
	.fc {
		position:relative;
		top:-40px;

		> .fc-header {
			position:relative;
			z-index:10;
		}

		.fc-header-space {
			padding-left:2px;
		}
	}

	.fc-header-title > h2 {
		font-size: floor(@base-font-size * 1.4);
		line-height: 36px;
	}

	.fc-content {
		top:-14px;
		z-index:11;
	}

	.fc-button-content {
		height:37px;
		line-height:36px;
	}

}
*/
  @media only screen and (max-width: 480px) {
    .fc-header td {
      display: block;
      width: auto;
      text-align: left;
    }
  }
  .chosen-container + .help-inline {
    vertical-align: middle;
  }
  /**
.chosen-select {
  display: inline !important; //for validation plugin to work it must be displayed
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  border-width: 0;
}
*/
  .chosen-container,
  [class*='chosen-container'] {
    vertical-align: middle;
  }
  .chosen-container > .chosen-single,
  [class*='chosen-container'] > .chosen-single {
    line-height: 28px;
    height: 32px;
    box-shadow: none;
    background: #fafafa;
  }
  .chosen-choices {
    box-shadow: none !important;
  }
  .chosen-container-single .chosen-single abbr {
    background: none;
  }
  .chosen-container-single .chosen-single abbr:after {
    content: '\f00d';
    display: inline-block;
    color: #888;
    font-family: FontAwesome;
    font-size: 13px;
    position: absolute;
    right: 0;
    top: -7px;
  }
  .chosen-container-single .chosen-single abbr:hover:after {
    color: #464646;
  }
  .chosen-container-single.chosen-disabled .chosen-single abbr:hover:after {
    color: #464646;
  }
  .chosen-single div b {
    background: none !important;
  }
  .chosen-single div b:before {
    content: '\f0d7';
    display: inline-block;
    color: #888;
    font-family: FontAwesome;
    font-size: 12px;
    position: relative;
    top: -1px;
    left: 1px;
  }
  .chosen-container-active.chosen-with-drop .chosen-single div b:before {
    content: '\f0d8';
  }
  .chosen-container-single .chosen-search {
    position: relative;
  }
  .chosen-container-single .chosen-search input[type='text'] {
    background: none;
    border-radius: 0;
    line-height: 28px;
    height: 28px;
  }
  .chosen-container-single .chosen-search:after {
    content: '\f002';
    display: inline-block;
    color: #888;
    font-family: FontAwesome;
    font-size: 14px;
    position: absolute;
    top: 8px;
    right: 12px;
  }
  .chosen-container-multi .chosen-choices li.search-field input[type='text'] {
    height: 25px;
  }
  .chosen-container-multi .chosen-choices li.search-choice {
    line-height: 16px;
    padding-bottom: 4px;
  }
  .chosen-container-multi
    .chosen-choices
    li.search-choice
    .search-choice-close {
    background: none;
  }
  .chosen-container-multi
    .chosen-choices
    li.search-choice
    .search-choice-close:before {
    content: '\f00d';
    display: inline-block;
    color: #888;
    font-family: FontAwesome;
    font-size: 13px;
    position: absolute;
    right: 2px;
    top: -1px;
  }
  .chosen-container-multi
    .chosen-choices
    li.search-choice
    .search-choice-close:hover {
    text-decoration: none;
  }
  .chosen-container-multi
    .chosen-choices
    li.search-choice
    .search-choice-close:hover:before {
    color: #464646;
  }
  .chosen-container-multi
    .chosen-choices
    li.search-choice-focus
    .search-choice-close:before {
    color: #464646;
  }
  .chosen-container .chosen-results-scroll-down span,
  .chosen-container .chosen-results-scroll-up span {
    background: none;
  }
  .chosen-container .chosen-results-scroll-down span:before,
  .chosen-container .chosen-results-scroll-up span:before {
    content: '\f0d7';
    display: inline-block;
    color: #888;
    font-family: FontAwesome;
    font-size: 12px;
    position: relative;
    top: -1px;
    left: 1px;
  }
  .chosen-container .chosen-results-scroll-up span:before {
    content: '\f0d8';
  }
  .chosen-container-active .chosen-single-with-drop div b:before {
    content: '\f0d8';
  }
  .chosen-rtl .chosen-search input[type='text'] {
    background: none;
  }
  .chosen-rtl .chosen-search:after {
    content: '';
    display: none;
  }
  .chosen-rtl .chosen-search:before {
    content: '\f002';
    display: inline-block;
    color: #888;
    font-family: FontAwesome;
    font-size: 14px;
    position: absolute;
    top: 9px;
    left: 12px;
  }
  /** chosen - etc */
  .chosen-container-single .chosen-single {
    border-radius: 0;
  }
  .chosen-container .chosen-results li.highlighted {
    background: #316ac5;
    color: #fff;
  }
  .chosen-container-single .chosen-drop {
    border-radius: 0;
    border-bottom: 3px solid #4492c9;
    border-color: #4492c9;
  }
  .chosen-single.chosen-single-with-drop,
  .chosen-container-active .chosen-single {
    border-color: #4492c9;
  }
  .form-group.has-error .chosen-single {
    border-color: #f2a696 !important;
  }
  .form-group.has-info .chosen-single {
    border-color: #72aec2 !important;
  }
  .form-group.has-warning .chosen-single {
    border-color: #e3c94c !important;
  }
  .form-group.has-success .chosen-single {
    border-color: #9cc573 !important;
  }
  .chosen-container-active.chosen-with-drop .chosen-single {
    border-color: #4492c9;
  }
  .chosen-container.chosen-with-drop .chosen-drop {
    left: auto;
    right: auto;
    display: block;
  }
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min-resolution: 144dpi) {
    .chosen-rtl .chosen-search input[type='text'],
    .chosen-container-single .chosen-single abbr,
    .chosen-container-single .chosen-single div b,
    .chosen-container-single .chosen-search input[type='text'],
    .chosen-container-multi
      .chosen-choices
      li.search-choice
      .search-choice-close,
    .chosen-container .chosen-results-scroll-down span,
    .chosen-container .chosen-results-scroll-up span {
      background-image: none !important;
      background-repeat: no-repeat !important;
      background-size: auto !important;
    }
  }
  .tag-input-style + .chosen-container-multi .chosen-choices li.search-choice {
    background-image: none;
    background-color: #91b8d0;
    color: #ffffff;
    display: inline-block;
    font-size: 13px;
    font-weight: normal;
    margin-bottom: 3px;
    margin-right: 3px;
    padding: 6px 22px 7px 9px;
    position: relative;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
    transition: all 0.2s ease 0s;
    vertical-align: baseline;
    white-space: nowrap;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
  }
  .tag-input-style
    + .chosen-container-multi
    .chosen-choices
    li.search-choice
    .search-choice-close {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 18px;
    height: auto;
    line-height: 25px;
    text-align: center;
  }
  .tag-input-style
    + .chosen-container-multi
    .chosen-choices
    li.search-choice
    .search-choice-close:before {
    color: #fff;
    position: static;
    font-size: 11px;
  }
  .tag-input-style
    + .chosen-container-multi
    .chosen-choices
    li.search-choice
    .search-choice-close:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .tag-input-style
    + .chosen-container-multi
    .chosen-choices
    li.search-choice
    .search-choice-close:hover:before {
    color: #fff;
  }
  .tag-input-style
    + .chosen-container-multi.chosen-rtl
    .chosen-choices
    li.search-choice {
    padding: 6px 9px 7px 22px;
    margin-left: 0;
    margin-right: 3px !important;
  }
  .tag-input-style
    + .chosen-container-multi.chosen-rtl
    .chosen-choices
    li.search-choice
    .search-choice-close {
    right: auto;
    left: 0;
  }
  .select2-container--default .select2-selection {
    border-radius: 0;
    line-height: 24px;
  }
  .select2-container--default .select2-selection {
    border-color: #aaa;
  }
  .select2-container--default.select2-container--focus .select2-selection {
    border-color: #4492c9;
  }
  .select2-container--open .select2-dropdown {
    border-radius: 0;
  }
  .select2-container--open .select2-dropdown {
    border-color: #4492c9;
    -webkit-box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
    box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
  }
  .select2-container--default .select2-selection .select2-selection__clear {
    position: absolute;
    right: -32px;
    font-size: 16px;
  }
  .select2-container .select2-selection--single {
    height: 32px;
  }
  .select2-container--default
    .select2-selection
    .select2-selection__choice__remove {
    font-size: 15px;
    margin-right: 3px;
  }
  .select2-container--default .select2-results__option[aria-selected='true'] {
    background-color: #e4eef5;
  }
  .select2-container--default
    .select2-results__option--highlighted[aria-selected] {
    background-color: #4f99c6;
  }
  .select2-search {
    background: none;
  }
  .select2-search:before {
    font-family: FontAwesome;
    font-size: 12px;
    display: inline;
    content: '\f002';
    color: #888;
    position: absolute;
    right: 12px;
    top: 8px;
  }
  .select2-search:hover:before {
    color: #555;
  }
  .select2-selection--multiple .select2-search:before {
    display: none;
  }
  .form-group.has-error .select2-container--default .select2-selection {
    border-color: #f2a696 !important;
  }
  .form-group.has-info .select2-container--default .select2-selection {
    border-color: #72aec2 !important;
  }
  .form-group.has-warning .select2-container--default .select2-selection {
    border-color: #e3c94c !important;
  }
  .form-group.has-success .select2-container--default .select2-selection {
    border-color: #9cc573 !important;
  }
  .select2.tag-input-style .select2-selection .select2-selection__choice {
    background-color: #91b8d0;
    color: #ffffff;
    display: inline-block;
    font-size: 13px;
    font-weight: normal;
    margin-bottom: 2px;
    margin-right: 2px;
    padding: 6px 22px 7px 9px;
    position: relative;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
    transition: all 0.2s ease 0s;
    vertical-align: baseline;
    white-space: nowrap;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
  }
  .select2.tag-input-style
    .select2-selection
    .select2-selection__choice
    .select2-selection__choice__remove {
    position: absolute;
    top: 0;
    bottom: 0;
    right: -2px;
    left: auto;
    width: 18px;
    height: auto;
    line-height: 36px;
    text-align: center;
    color: #fff;
  }
  .select2.tag-input-style
    .select2-selection
    .select2-selection__choice
    .select2-selection__choice__remove:hover {
    background-color: rgba(0, 0, 0, 0.2);
    color: #fff;
  }
  #colorbox:focus,
  #colorbox:active {
    outline: none;
  }
  #cboxTopLeft,
  #cboxTopCenter,
  #cboxTopRight,
  #cboxMiddleLeft,
  #cboxMiddleRight,
  #cboxBottomLeft,
  #cboxBottomCenter,
  #cboxBottomRight {
    background: none !important;
    opacity: 0;
  }
  #cboxContent {
    border: 12px solid #000;
    background-color: #fff;
    padding: 7px;
  }
  #cboxOverlay {
    background: rgba(0, 0, 0, 0.95);
    background: #000;
  }
  #cboxCurrent {
    left: 64px;
    margin-bottom: 4px;
    font-size: 14px;
  }
  #cboxTitle {
    margin-bottom: 4px;
    font-size: 14px;
    color: #777;
  }
  #cboxNext,
  #cboxPrevious,
  #cboxClose {
    background: none;
    text-indent: 0;
    width: 26px;
    height: 26px;
    line-height: 22px;
    padding: 0 4px;
    text-align: center;
    border: 2px solid #999;
    border-radius: 16px;
    color: #666;
    font-size: 12px;
    margin-left: 5px;
    margin-bottom: 5px;
  }
  #cboxNext:hover,
  #cboxPrevious:hover {
    color: #333;
    border-color: #666;
  }
  #cboxContent {
    overflow: visible;
  }
  #cboxClose {
    background-color: #000;
    border: 2px solid #fff;
    border-radius: 32px;
    color: #fff;
    font-size: 21px;
    height: 28px;
    width: 28px;
    padding-bottom: 2px;
    margin-left: 0;
    right: -14px;
    top: -14px;
  }
  #cboxLoadingOverlay {
    background: none !important;
  }
  #cboxLoadingGraphic {
    background: #fff none !important;
    text-align: center;
  }
  #cboxLoadingGraphic > .ace-icon {
    display: inline-block;
    background-color: #fff;
    border-radius: 8px;
    width: 32px;
    height: 32px;
    position: relative;
    top: 48%;
    text-align: center;
    vertical-align: middle;
    font-size: 24px;
    color: #fe7e3e;
  }
  .ace-spinner {
    display: inline-block;
  }
  .ace-spinner .spinbox-buttons.btn-group-vertical {
    min-width: 18px;
  }
  .ace-spinner .spinbox-buttons.btn-group-vertical > .btn {
    font-size: 10px;
    padding: 0;
    width: 22px;
    height: 16px;
    line-height: 8px;
    margin-left: 0;
  }
  .ace-spinner .spinbox-buttons.btn-group-vertical > .btn:first-child {
    margin-top: 0;
  }
  .ace-spinner .spinbox-buttons.btn-group-vertical > .btn > .ace-icon {
    margin: 0;
    padding: 0;
  }
  .ace-spinner .spinbox-buttons.btn-group-vertical > .btn + .btn {
    margin-top: 2px;
  }
  .ace-spinner .spinbox-buttons.btn-group-vertical > .btn-xs {
    height: 14px;
    line-height: 7px;
  }
  .ace-spinner .spinbox-buttons.btn-group-vertical > .btn-lg {
    height: 22px;
    line-height: 10px;
    width: 22px;
  }
  .ace-spinner .spinbox-buttons > button.btn.spinbox-up:active {
    top: -1px;
  }
  .ace-spinner:not(.touch-spinner) .spinbox-buttons > .btn > .ace-icon {
    margin-top: -1px;
  }
  .ace-spinner.touch-spinner .spinbox-buttons {
    margin: 0;
    font-size: 0;
  }
  .ace-spinner.touch-spinner .spinbox-buttons .btn-sm {
    width: 32px;
    padding-left: 6px;
    padding-right: 6px;
  }
  .ace-spinner.touch-spinner .spinbox-buttons .btn-xs {
    width: 24px;
    padding-left: 4px;
    padding-right: 4px;
  }
  .ace-spinner.touch-spinner .spinbox-buttons .btn-lg {
    width: 40px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .ace-spinner.touch-spinner .spinbox-buttons > .btn {
    margin: 0 1px !important;
  }
  .ace-spinner.touch-spinner .spinbox-buttons > .btn-xs {
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .ace-spinner.touch-spinner .spinbox-buttons > .btn > .ace-icon {
    vertical-align: middle;
    display: inline-block;
  }
  .steps {
    list-style: none;
    display: table;
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
  }
  .steps li {
    display: table-cell;
    text-align: center;
    width: 1%;
  }
  .steps li .step {
    border: 5px solid #ced1d6;
    color: #546474;
    font-size: 15px;
    border-radius: 100%;
    background-color: #fff;
    position: relative;
    z-index: 2;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 30px;
    text-align: center;
  }
  .steps li:before {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    font-size: 0;
    overflow: hidden;
    border-top: 4px solid #ced1d6;
    position: relative;
    top: 21px;
    z-index: 1;
  }
  .steps li.last-child:before {
    max-width: 50%;
    width: 50%;
  }
  .steps li:last-child:before {
    max-width: 50%;
    width: 50%;
  }
  .steps li:first-child:before {
    max-width: 51%;
    left: 50%;
  }
  .steps li.active:before,
  .steps li.complete:before,
  .steps li.active .step,
  .steps li.complete .step {
    border-color: #5293c4;
  }
  .steps li.complete .step {
    cursor: default;
    color: #fff;
    -webkit-transition: transform ease 0.1s;
    -o-transition: transform ease 0.1s;
    transition: transform ease 0.1s;
  }
  .steps li.complete .step:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    line-height: 30px;
    text-align: center;
    border-radius: 100%;
    content: '\f00c';
    background-color: #fff;
    z-index: 3;
    font-family: FontAwesome;
    font-size: 17px;
    color: #87ba21;
  }
  .steps li.complete:hover .step {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    border-color: #80afd4;
  }
  .steps li.complete:hover:before {
    border-color: #80afd4;
  }
  .steps li .title {
    display: block;
    margin-top: 4px;
    max-width: 100%;
    color: #949ea7;
    font-size: 14px;
    z-index: 104;
    text-align: center;
    table-layout: fixed;
    word-wrap: break-word;
  }
  .steps li.complete .title,
  .steps li.active .title {
    color: #2b3d53;
  }
  .step-content {
    position: relative;
  }
  .step-content .step-pane {
    display: none;
    min-height: 200px;
    padding: 4px 8px 12px;
  }
  .step-content .step-pane.active {
    display: block;
  }
  .wizard-actions {
    text-align: right;
  }
  @media only screen and (max-width: 767px) {
    .steps li .step {
      width: 30px;
      height: 30px;
      line-height: 24px;
      border-width: 3px;
    }
    .steps li:before,
    .steps li:after {
      border-width: 3px;
    }
    .steps li.complete .step:before {
      line-height: 24px;
      font-size: 13px;
    }
    .steps li:before {
      top: 16px;
    }
    .step-content .step-pane {
      padding: 4px 4px 6px;
      min-height: 150px;
    }
  }
  .tree {
    margin: auto;
    padding: 0 0 0 9px;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
  }
  .tree:before {
    display: inline-block;
    content: '';
    position: absolute;
    top: -20px;
    bottom: 16px;
    left: 0;
    z-index: 1;
    border: 1px dotted #67b2dd;
    border-width: 0 0 0 1px;
  }
  .tree .tree-branch-name,
  .tree .tree-item-name {
    cursor: pointer;
  }
  .tree .icon-caret {
    vertical-align: baseline !important;
  }
  .tree .tree-branch {
    width: auto;
    min-height: 20px;
    cursor: pointer;
  }
  .tree .tree-branch .tree-branch-header {
    position: relative;
    height: 20px;
    line-height: 20px;
  }
  .tree .tree-branch .tree-branch-header:hover {
    background-color: rgba(98, 168, 209, 0.1);
  }
  .tree .tree-branch .icon-caret + .tree-branch-header {
    display: inline-block;
    width: 80%;
    width: calc(100% - 36px);
  }
  .tree .tree-branch.tree-selected > .tree-branch-header {
    background-color: rgba(98, 168, 209, 0.18);
  }
  .tree .tree-branch .tree-branch-header .tree-branch-name,
  .tree .tree-item .tree-item-name {
    display: inline;
    z-index: 2;
  }
  .tree
    .tree-branch
    .tree-branch-header
    > .tree-branch-name
    > .ace-icon:first-child,
  .tree .tree-item > .tree-item-name > .ace-icon:first-child {
    display: inline-block;
    position: relative;
    z-index: 2;
    top: -1px;
  }
  .tree .tree-branch > .tree-branch-header > .tree-branch-name > .tree-label {
    margin-left: 2px;
  }
  .tree
    .tree-branch
    > .tree-branch-header
    > .tree-branch-name
    > .ace-icon:first-child {
    margin: -2px 0 0 -2px;
  }
  .tree .tree-branch:last-child:after {
    display: inline-block;
    content: '';
    position: absolute;
    z-index: 1;
    top: 15px;
    bottom: 0;
    left: -15px;
    border-left: 1px solid #fff;
  }
  .tree .tree-branch .tree-branch-children {
    margin: 0 0 0 23px;
    padding: 0;
    position: relative;
  }
  .tree .tree-branch .tree-branch-children:before {
    display: inline-block;
    content: '';
    position: absolute;
    z-index: 1;
    top: -14px;
    bottom: 16px;
    left: -14px;
    border: 1px dotted #67b2dd;
    border-width: 0 0 0 1px;
  }
  .tree
    .tree-branch.tree-selected
    > .icon-caret
    + .tree-branch-header
    > .tree-branch-name
    > .tree-label {
    font-weight: bold;
  }
  .tree .tree-item {
    position: relative;
    height: 20px;
    line-height: 20px;
    cursor: pointer;
    max-width: calc(100% - 20px);
  }
  .tree .tree-item:hover {
    background-color: rgba(98, 168, 209, 0.1);
  }
  .tree .tree-item.tree-selected,
  .tree .tree-item.tree-selected:hover {
    background-color: rgba(98, 168, 209, 0.16);
  }
  .tree .tree-item > .tree-item-name > .ace-icon:first-child {
    margin-right: 3px;
  }
  .tree .tree-item > .tree-item-name > .tree-label > .ace-icon:first-child {
    margin-left: 3px;
    margin-right: 3px;
  }
  .tree .tree-item > .ace-icon:first-child {
    margin-top: -1px;
  }
  .tree .tree-branch,
  .tree .tree-item {
    position: relative;
    list-style: none;
  }
  .tree .tree-branch:before,
  .tree .tree-item:before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 14px;
    left: -13px;
    width: 18px;
    height: 0;
    border-top: 1px dotted #67b2dd;
    z-index: 1;
  }
  .tree .tree-selected {
    color: #6398b0;
  }
  .tree .tree-item,
  .tree .tree-branch {
    border-left: 1px solid #fff;
    margin: 1px 0;
  }
  .tree .tree-branch .tree-branch-header {
    border-radius: 0;
  }
  .tree .tree-item,
  .tree .tree-branch .tree-branch-header {
    padding: 5px;
    color: #4d6878;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  .tree .tree-item > .tree-item-name > .ace-icon:first-child {
    color: #f9e8ce;
    width: 13px;
    height: 13px;
    line-height: 13px;
    font-size: 11px;
    text-align: center;
    border-radius: 3px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    background-color: #fafafa;
    border: 1px solid #ccc;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  .tree .tree-selected > .tree-item-name > .ace-icon:first-child {
    background-color: #f9a021;
    border-color: #f9a021;
    color: #fff;
  }
  .tree .tree-plus.ace-icon:first-child,
  .tree .tree-minus.ace-icon:first-child {
    display: inline-block;
    font-style: normal;
    border: 1px solid #ddd;
    vertical-align: middle;
    height: 11px;
    width: 11px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    text-align: center;
    border: 1px solid #8baebf;
    line-height: 10px;
    background-color: #fff;
    position: relative;
    z-index: 2;
  }
  .tree .tree-plus.ace-icon:first-child:before,
  .tree .tree-minus.ace-icon:first-child:before {
    content: '';
    display: block;
    width: 7px;
    height: 0;
    border-top: 1px solid #4d6878;
    position: absolute;
    top: 5px;
    left: 2px;
  }
  .tree .tree-plus.ace-icon:first-child:after {
    content: '';
    display: block;
    height: 7px;
    width: 0;
    border-left: 1px solid #4d6878;
    position: absolute;
    top: 2px;
    left: 5px;
  }
  .tree
    .tree-unselectable
    .tree-item
    > .tree-item-name
    > .tree-label
    > .ace-icon:first-child {
    color: #5084a0;
    width: 13px;
    height: 13px;
    line-height: 13px;
    font-size: 10px;
    text-align: center;
    border-radius: 0;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  .tree .ace-icon[class*='-down'] {
    transform: rotate(-45deg);
  }
  .tree .ace-icon[class*='-download'] {
    transform: none;
  }
  .tree .fa-spin {
    height: auto;
  }
  .tree .tree-loading {
    margin-left: 36px;
  }
  .tree img {
    display: inline;
    veritcal-align: middle;
  }
  .tree .icon-open {
    width: 16px;
    position: relative;
    z-index: 2;
    padding-left: 2px;
  }
  .tree .tree .tree-open > .tree-branch-header .icon-open {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .tree-container {
    background-color: #fff;
    border: 1px solid #ddd;
    border-left-color: #67b2dd;
    display: block;
    padding: 0;
    max-width: 200px;
    max-height: 250px;
  }
  .tree-container .tree::before {
    margin-left: -1px;
  }
  .gritter-item-wrapper {
    background-image: none !important;
    box-shadow: 0 2px 10px rgba(50, 50, 50, 0.5);
    background: rgba(50, 50, 50, 0.92);
  }
  .gritter-item-wrapper.gritter-info {
    background: rgba(49, 81, 133, 0.92);
  }
  .gritter-item-wrapper.gritter-error {
    background: rgba(153, 40, 18, 0.92);
  }
  .gritter-item-wrapper.gritter-success {
    background: rgba(89, 131, 75, 0.92);
  }
  .gritter-item-wrapper.gritter-warning {
    background: rgba(190, 112, 31, 0.92);
  }
  .gritter-item-wrapper.gritter-light {
    background: rgba(245, 245, 245, 0.95);
    border: 1px solid #bbb;
  }
  .gritter-item-wrapper.gritter-light.gritter-info {
    background: rgba(232, 242, 255, 0.95);
  }
  .gritter-item-wrapper.gritter-light.gritter-info .gritter-item {
    color: #4a577d;
  }
  .gritter-item-wrapper.gritter-light.gritter-error {
    background: rgba(255, 235, 235, 0.95);
  }
  .gritter-item-wrapper.gritter-light.gritter-error .gritter-item {
    color: #894a38;
  }
  .gritter-item-wrapper.gritter-light.gritter-success {
    background: rgba(239, 250, 227, 0.95);
  }
  .gritter-item-wrapper.gritter-light.gritter-success .gritter-item {
    color: #416131;
  }
  .gritter-item-wrapper.gritter-light.gritter-warning {
    background: rgba(252, 248, 227, 0.95);
  }
  .gritter-item-wrapper.gritter-light.gritter-warning .gritter-item {
    color: #946446;
  }
  .gritter-item p {
    line-height: 1.8;
  }
  .gritter-top,
  .gritter-bottom,
  .gritter-item {
    background-image: none;
  }
  .gritter-close {
    left: auto;
    right: 3px;
    background-image: none;
    width: 18px;
    height: 18px;
    line-height: 17px;
    text-align: center;
    border: 2px solid transparent;
    border-radius: 16px;
    color: #e17b67;
  }
  .gritter-close:before {
    font-family: FontAwesome;
    font-size: 16px;
    content: '\f00d';
  }
  .gritter-info .gritter-close {
    color: #ffa500;
  }
  .gritter-error .gritter-close,
  .gritter-success .gritter-close,
  .gritter-warning .gritter-close {
    color: #ffea07;
  }
  .gritter-close:hover {
    color: #fff !important;
  }
  .gritter-title {
    text-shadow: none;
  }
  .gritter-light .gritter-item,
  .gritter-light .gritter-bottom,
  .gritter-light .gritter-top,
  .gritter-light .gritter-close {
    background-image: none;
    color: #444;
  }
  .gritter-light .gritter-title {
    text-shadow: none;
  }
  .gritter-light .gritter-close:hover {
    color: #8a3104 !important;
  }
  .gritter-center {
    position: fixed;
    left: 33%;
    right: 33%;
    top: 33%;
  }
  @media only screen and (max-width: 767px) {
    .gritter-center {
      left: 16%;
      right: 16%;
      top: 30%;
    }
  }
  @media only screen and (max-width: 480px) {
    .gritter-center {
      left: 30px;
      right: 30px;
    }
  }
  @media only screen and (max-width: 320px) {
    .gritter-center {
      left: 10px;
      right: 10px;
    }
  }
  .wysiwyg-editor {
    max-height: 250px;
    height: 250px;
    background-color: #f7f8fa;
    border-collapse: separate;
    border: 1px solid #bbc0ca;
    padding: 4px;
    box-sizing: content-box;
    overflow-y: scroll;
    overflow-x: hidden;
    outline: none;
  }
  .wysiwyg-editor:focus {
    background-color: #fff;
  }
  .wysiwyg-toolbar {
    line-height: 33px;
    margin: 0 !important;
    position: relative;
  }
  .wysiwyg-toolbar .dropdown-menu {
    text-align: left;
  }
  .wysiwyg-toolbar .btn-group {
    float: none !important;
    font-size: 0;
  }
  .wysiwyg-toolbar .btn-group > .btn {
    float: none;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    margin-left: 1px;
    /**
		&.active:after {
			border-color: transparent;
			border-style: solid;
			border-top-color: inherit;
			border-width: 6px 14px;
			bottom: -13px;
			left: 0;
			right: 0;
		}
		*/
  }
  .wysiwyg-toolbar .btn-group > .btn > .ace-icon:first-child {
    font-size: 14px;
    width: 25px;
    max-width: 25px;
    display: inline-block;
    border-width: 1px !important;
  }
  .wysiwyg-toolbar .btn-group > .btn.dropdown-toggle > .ace-icon:last-child {
    margin-right: 4px;
  }
  .wysiwyg-style1 .btn-group > .btn,
  .wysiwyg-style2 .btn-group > .btn,
  .wysiwyg-style1 .btn-group > .inline > .btn,
  .wysiwyg-style2 .btn-group > .inline > .btn {
    margin: 0 !important;
    background: #fff !important;
    border-width: 0 !important;
    color: #adb3be !important;
    text-shadow: none !important;
  }
  .wysiwyg-style1 .btn-group > .btn.active,
  .wysiwyg-style2 .btn-group > .btn.active,
  .wysiwyg-style1 .btn-group > .inline > .btn.active,
  .wysiwyg-style2 .btn-group > .inline > .btn.active {
    color: #5b80ce !important;
  }
  .wysiwyg-style1 .btn-group > .btn.active:after,
  .wysiwyg-style2 .btn-group > .btn.active:after,
  .wysiwyg-style1 .btn-group > .inline > .btn.active:after,
  .wysiwyg-style2 .btn-group > .inline > .btn.active:after {
    display: none;
  }
  .wysiwyg-style1 .btn-group,
  .wysiwyg-style2 .btn-group {
    position: relative;
  }
  .wysiwyg-style1 .btn-group:after,
  .wysiwyg-style2 .btn-group:after {
    display: block;
    content: '';
    position: absolute;
    left: -2px;
    top: 6px;
    bottom: 6px;
    width: 0;
    max-width: 0;
    border-left: 1px solid #e1e6ea;
  }
  .wysiwyg-style1 .btn-group:first-child:after,
  .wysiwyg-style2 .btn-group:first-child:after {
    display: none;
  }
  .wysiwyg-style2 {
    background-color: #e5e5e5;
  }
  .wysiwyg-style2 + .wysiwyg-editor {
    border-color: #ddd;
    background-color: #fff;
    border-top: none;
  }
  .wysiwyg-style2 .btn-group > .btn,
  .wysiwyg-style2 .btn-group > .inline > .btn {
    margin: 0 1px 0 0 !important;
    background: #fff !important;
    border: none !important;
    color: #8d939e !important;
    text-shadow: none !important;
  }
  .wysiwyg-style2 .btn-group > .btn.active,
  .wysiwyg-style2 .btn-group > .inline > .btn.active {
    color: #fff !important;
    background: #6aaedf !important;
  }
  .wysiwyg-style2 .btn-group:after {
    display: none;
  }
  .wysiwyg-toolbar .btn-colorpicker {
    width: 24px;
    height: 24px;
    position: relative;
    background: #87b87f;
    /* Old browsers */
    background: -moz-linear-gradient(
      top,
      #cf3e73 10%,
      #ffffff 20%,
      #2283c5 30%,
      #ffffff 40%,
      #87b87f 50%,
      #ffffff 60%,
      #ffb752 70%,
      #ffffff 80%,
      #d15b47 90%,
      #ffffff 100%
    );
    /*  FF3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(10%, #cf3e73),
      color-stop(20%, #ffffff),
      color-stop(30%, #2283c5),
      color-stop(40%, #ffffff),
      color-stop(50%, #87b87f),
      color-stop(60%, #ffffff),
      color-stop(70%, #ffb752),
      color-stop(80%, #ffffff),
      color-stop(90%, #d15b47),
      color-stop(100%, #ffffff)
    );
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
      top,
      #cf3e73 10%,
      #ffffff 20%,
      #2283c5 30%,
      #ffffff 40%,
      #87b87f 50%,
      #ffffff 60%,
      #ffb752 70%,
      #ffffff 80%,
      #d15b47 90%,
      #ffffff 100%
    );
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(
      top,
      #cf3e73 10%,
      #ffffff 20%,
      #2283c5 30%,
      #ffffff 40%,
      #87b87f 50%,
      #ffffff 60%,
      #ffb752 70%,
      #ffffff 80%,
      #d15b47 90%,
      #ffffff 100%
    );
    /* Opera11.10+ */
    background: -ms-linear-gradient(
      top,
      #cf3e73 10%,
      #ffffff 20%,
      #2283c5 30%,
      #ffffff 40%,
      #87b87f 50%,
      #ffffff 60%,
      #ffb752 70%,
      #ffffff 80%,
      #d15b47 90%,
      #ffffff 100%
    );
    /* IE10+ */
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#CF3E73',
        endColorstr='#FFB752',
        GradientType=0
      );
    /* IE6-9 */
    background: linear-gradient(
      to top,
      #cf3e73 10%,
      #ffffff 20%,
      #2283c5 30%,
      #ffffff 40%,
      #87b87f 50%,
      #ffffff 60%,
      #ffb752 70%,
      #ffffff 80%,
      #d15b47 90%,
      #ffffff 100%
    );
    /* W3C */
  }
  .wysiwyg-toolbar .dropdown-colorpicker > .dropdown-menu {
    top: auto;
  }
  .wysiwyg-toolbar input[type='file'] {
    position: fixed;
    z-index: -10;
    opacity: 0;
    max-width: 0;
    max-height: 0;
    display: block;
  }
  .wysiwyg-toolbar .wysiwyg-choose-file {
    display: inline-block;
    width: auto;
    margin: 4px auto 0;
    padding-left: 5px;
    padding-right: 5px;
  }
  .wysiwyg-toolbar .dropdown-menu input[type='text'] {
    margin-left: 8px;
    margin-bottom: 0;
  }
  .wysiwyg-toolbar .dropdown-menu input[type='text'].form-control {
    min-width: 150px;
  }
  .wysiwyg-toolbar .dropdown-menu .btn {
    margin-right: 8px;
    margin-left: 8px;
  }
  .wysiwyg-style1 .btn-colorpicker {
    width: 20px;
    height: 20px;
    margin-left: 4px;
  }
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    /* for adding image resize functionality in chrome and safari */
    .wysiwyg-editor img {
      display: inline !important;
    }
    .wysiwyg-editor .ui-wrapper {
      border: 1px dotted #d00;
      overflow: visible !important;
      /* because it's image only, so it's ok */
      display: inline-block !important;
      vertical-align: middle;
    }
    .wysiwyg-editor .ui-wrapper:after {
      content: '';
      display: block;
      position: absolute;
      right: -3px;
      bottom: -3px;
      width: 7px;
      height: 7px;
      border: 1px solid #d00;
      background-color: #fff;
      z-index: 1;
    }
  }
  /* inside widget */
  .widget-header .wysiwyg-toolbar {
    background-color: transparent;
  }
  .widget-header .wysiwyg-toolbar .btn-group > .btn,
  .widget-header .wysiwyg-toolbar .btn-group > .inline > .btn {
    border-color: transparent;
    background: rgba(255, 255, 255, 0.25) !important;
    color: #fff !important;
    min-width: 32px;
    border-width: 1px !important;
    border-radius: 4px !important;
    padding: 2px 1px 4px;
  }
  .widget-header .wysiwyg-toolbar .btn-group > .btn.active,
  .widget-header .wysiwyg-toolbar .btn-group > .inline > .btn.active {
    background: rgba(0, 0, 0, 0.25) !important;
  }
  .widget-body .wysiwyg-editor {
    border-width: 0;
  }
  .wysiwyg-speech-input {
    width: 20px !important;
    color: transparent !important;
    background: transparent none !important;
    border-width: 0 !important;
    -moz-transform: scale(2, 2);
    -webkit-transform: scale(2, 2);
    -o-transform: scale(2, 2);
    -ms-transform: scale(2, 2);
    transform: scale(2, 2);
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    position: absolute;
    right: 0;
    top: -10px;
    cursor: pointer;
  }
  .wysiwyg-speech-input:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  /**
 * Bootstrap-Markdown.less
 *
 * @author Taufan Aditya @taufanaditya
 * @copyright 2013-2015 Taufan Aditya
 */
  .md-editor {
    display: block;
    border: 1px solid #dddddd;
  }
  .md-editor > .md-header,
  .md-editor .md-footer {
    display: block;
    padding: 6px 4px;
    background: #f5f5f5;
  }
  .md-editor > .md-header {
    margin: 0;
  }
  .md-editor > .md-preview {
    border-top: 1px dashed #dddddd;
    border-bottom: 1px dashed #dddddd;
    min-height: 10px;
    overflow: auto;
  }
  .md-editor > textarea {
    font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
    font-size: 14px;
    outline: 0;
    outline: thin dotted \9;
    /* IE6-9 */
    margin: 0;
    display: block;
    padding: 0;
    width: 100%;
    border: 0;
    border-top: 1px dashed #dddddd;
    border-bottom: 1px dashed #dddddd;
    border-radius: 0;
    box-shadow: none;
    background: #f7f8fa;
  }
  .md-editor > textarea:focus {
    box-shadow: none;
    background: #ffffff;
  }
  .md-editor.active {
    border-color: #f59942;
    outline: 0;
  }
  .md-editor .md-controls {
    float: right;
    padding: 3px;
  }
  .md-editor .md-controls .md-control {
    right: 5px;
    color: #bebebe;
    padding: 3px 3px 3px 10px;
  }
  .md-editor .md-controls .md-control:hover {
    color: #333;
  }
  .md-editor.md-fullscreen-mode {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    padding: 60px 30px 15px;
    background: #fff !important;
    border: 0 !important;
  }
  .md-editor.md-fullscreen-mode .md-footer {
    display: none;
  }
  .md-editor.md-fullscreen-mode .md-input,
  .md-editor.md-fullscreen-mode .md-preview {
    margin: 0 auto !important;
    height: 100% !important;
    font-size: 20px !important;
    padding: 20px !important;
    color: #999;
    line-height: 1.6em !important;
    resize: none !important;
    box-shadow: none !important;
    background: #fff !important;
    border: 0 !important;
  }
  .md-editor.md-fullscreen-mode .md-preview {
    color: #333;
    overflow: auto;
  }
  .md-editor.md-fullscreen-mode .md-input:hover,
  .md-editor.md-fullscreen-mode .md-input:focus {
    color: #333;
    background: #fff !important;
  }
  .md-editor.md-fullscreen-mode .md-header {
    background: none;
    text-align: center;
    position: fixed;
    width: 100%;
    top: 20px;
  }
  .md-editor.md-fullscreen-mode .btn-group {
    float: none;
  }
  .md-editor.md-fullscreen-mode .btn {
    border: 0;
    background: none;
    color: #b3b3b3;
  }
  .md-editor.md-fullscreen-mode .btn:hover,
  .md-editor.md-fullscreen-mode .btn:focus,
  .md-editor.md-fullscreen-mode .btn.active,
  .md-editor.md-fullscreen-mode .btn:active {
    box-shadow: none;
    color: #333;
  }
  .md-editor.md-fullscreen-mode .md-fullscreen-controls {
    position: absolute;
    top: 20px;
    right: 20px;
    text-align: right;
    z-index: 1002;
    display: block;
  }
  .md-editor.md-fullscreen-mode .md-fullscreen-controls a {
    color: #b3b3b3;
    clear: right;
    margin: 10px;
    width: 30px;
    height: 30px;
    text-align: center;
  }
  .md-editor.md-fullscreen-mode .md-fullscreen-controls a:hover {
    color: #333;
    text-decoration: none;
  }
  .md-editor.md-fullscreen-mode .md-editor {
    height: 100% !important;
    position: relative;
  }
  .md-editor .md-fullscreen-controls {
    display: none;
  }
  .md-nooverflow {
    overflow: hidden;
    position: fixed;
    width: 100%;
  }
  .editable-container .popover-title {
    color: #438eb9;
  }
  .editable-click {
    border-bottom: 1px dashed #bbb;
    cursor: pointer;
    font-weight: normal;
  }
  img.editable-click {
    border: 1px dotted #bbb;
  }
  .editable-click:hover {
    border-color: #0088cc;
    color: #0088cc;
  }
  img.editable-click:hover {
    opacity: 0.75;
    filter: alpha(opacity=75);
  }
  .editable-buttons,
  .editable-input {
    display: inline-block;
  }
  .editable-buttons {
    margin-left: 1px;
  }
  .editable-buttons .btn {
    padding: 0;
    width: 28px;
    line-height: 24px;
    border-width: 3px;
    font-size: 12px;
    margin: 0 1px 0 0;
  }
  .editable-buttons .btn > .ace-icon {
    margin: 0;
  }
  .editable-clear-x {
    cursor: pointer;
    color: #888;
    background: none;
  }
  .editable-clear-x:hover {
    color: #d15b47;
  }
  .editable-clear-x:before {
    display: inline-block;
    content: '\f057';
    font-family: FontAwesome;
    font-size: 15px;
    position: absolute;
    margin-top: -9px;
    width: 16px;
    height: 30px;
    line-height: 30px;
    text-align: center;
  }
  .editable-input .ace-spinner {
    margin-right: 8px;
  }
  .editable-input .ace-spinner .spinner-input {
    width: 100%;
  }
  .editable-inline .editable-slider {
    margin-top: 10px;
    margin-right: 4px;
  }
  .editable-popup .editable-slider {
    display: block;
    margin-bottom: 16px;
    margin-top: 4px;
  }
  .editable-slider input[type='text'] {
    display: none;
  }
  .editable-slider input[type='range'] {
    outline: none !important;
  }
  .editable-input .ace-file-input {
    display: block;
  }
  .editable-image .ace-file-multiple .ace-file-container.selected {
    border-color: transparent;
  }
  .editable-image + .editable-buttons,
  .editable-wysiwyg + .editable-buttons {
    display: block;
    text-align: center;
    margin-top: 8px;
  }
  .editable-wysiwyg {
    width: 95%;
  }
  .editable-wysiwyg .wysiwyg-editor {
    height: auto;
    overflow-y: hidden;
  }
  .editableform .input-append.dropdown-menu {
    display: none;
  }
  .editableform .open .input-append.dropdown-menu {
    display: block;
  }
  .editable-container .editableform {
    margin-bottom: 10px;
  }
  .editable-inline .editableform {
    margin-bottom: 0;
  }
  .editableform .control-group {
    display: block;
  }
  .editableform-loading {
    background: none;
  }
  .editableform-loading .ace-icon,
  .editableform-loading .progress {
    position: relative;
    top: 35%;
  }
  .input-group.date .input-group-addon {
    cursor: pointer;
  }
  .datepicker td,
  .daterangepicker td,
  .datepicker th,
  .daterangepicker th {
    border-radius: 0 !important;
    font-size: 13px;
  }
  .datepicker td.active,
  .daterangepicker td.active,
  .datepicker td.active:hover,
  .daterangepicker td.active:hover {
    background: #2283c5 !important;
  }
  .datepicker td.active.disabled,
  .daterangepicker td.active.disabled,
  .datepicker td.active.disabled:hover,
  .daterangepicker td.active.disabled:hover {
    background: #8b9aa3 !important;
  }
  .datepicker td,
  .datepicker th {
    min-width: 32px;
  }
  .datepicker-dropdown.datepicker-orient-bottom:after,
  .datepicker-dropdown.datepicker-orient-bottom:before {
    top: auto;
  }
  .daterangepicker .calendar-date {
    border-radius: 0;
  }
  .datepicker-months .month,
  .datepicker-years .year {
    border-radius: 0 !important;
  }
  .datepicker-months .month.active,
  .datepicker-years .year.active,
  .datepicker-months .month.active:hover,
  .datepicker-years .year.active:hover,
  .datepicker-months .month.active:focus,
  .datepicker-years .year.active:focus,
  .datepicker-months .month.active:active,
  .datepicker-years .year.active:active {
    background-image: none !important;
    background-color: #2283c5 !important;
  }
  .bootstrap-timepicker-widget table td input {
    width: 32px;
  }
  .well .datepicker table tr td.day:hover {
    background-color: #7d8893;
    color: #fff;
  }
  .bootstrap-timepicker-widget table td a:hover {
    border-radius: 0;
  }
  .daterangepicker.opensleft:before,
  .daterangepicker.opensright:before {
    -moz-border-bottom-colors: rgba(0, 0, 0, 0.2);
  }
  .daterangepicker.opensleft:after,
  .daterangepicker.opensright:after {
    -moz-border-bottom-colors: #fff;
  }
  .datepicker-dropdown:before {
    -moz-border-bottom-colors: rgba(0, 0, 0, 0.2);
  }
  .datepicker-dropdown:after {
    -moz-border-bottom-colors: #fff;
  }
  .datepicker-dropdown.datepicker-orient-bottom:before {
    -moz-border-top-colors: #999;
  }
  .datepicker-dropdown.datepicker-orient-bottom:after {
    -moz-border-top-colors: #fff;
  }
  .bootstrap-timepicker-widget.dropdown-menu:before {
    -moz-border-bottom-colors: rgba(0, 0, 0, 0.2);
  }
  .bootstrap-timepicker-widget.dropdown-menu:after {
    -moz-border-bottom-colors: #fff;
  }
  .bootstrap-timepicker-widget.timepicker-orient-bottom:before {
    -moz-border-top-colors: #999;
  }
  .bootstrap-timepicker-widget.timepicker-orient-bottom:after {
    -moz-border-top-colors: #fff;
  }
  .bootstrap-datetimepicker-widget [class='btn'] {
    border-width: 0 !important;
    background-color: transparent !important;
    color: #7399b8 !important;
    text-shadow: none !important;
  }
  .bootstrap-datetimepicker-widget [class='btn']:hover {
    color: #1b6aaa !important;
  }
  .bootstrap-datetimepicker-widget .btn.btn-primary {
    border-width: 3px !important;
  }
  .bootstrap-datetimepicker-widget .picker-switch {
    margin-bottom: 2px;
  }
  .bootstrap-datetimepicker-widget .picker-switch a {
    width: 90% !important;
    background-color: #eee !important;
    color: #478fca !important;
    font-size: 16px;
  }
  .bootstrap-datetimepicker-widget .picker-switch a:hover {
    background-color: #e3edf5 !important;
  }
  .bootstrap-datetimepicker-widget .picker-switch a span {
    background: none !important;
    width: 90%;
  }
  .bootstrap-datetimepicker-widget table td span {
    border-radius: 0;
  }
  .bootstrap-datetimepicker-widget .timepicker-hour,
  .bootstrap-datetimepicker-widget .timepicker-minute,
  .bootstrap-datetimepicker-widget .timepicker-second {
    color: #555 !important;
  }
  .ui-slider {
    background-color: #d5d5d5;
  }
  .ui-slider-horizontal {
    height: 9px;
  }
  .ui-slider-vertical {
    width: 9px;
  }
  .ui-slider .ui-slider-handle {
    border-radius: 0;
    width: 1.45em;
    height: 1.45em;
    background-color: #f8f8f8;
    border: 1px solid;
  }
  .ui-slider .ui-slider-handle:before,
  .ui-slider .ui-slider-handle:after {
    display: block;
    content: '';
    position: absolute;
    top: 4px;
    left: 5px;
    width: 4px;
    height: 8px;
    border: 1px solid;
    border-width: 0 1px;
    border-color: inherit;
  }
  .ui-slider .ui-slider-handle:after {
    left: 8px;
    border-width: 0 1px 0 0;
  }
  .ui-slider .ui-slider-handle:hover {
    background-color: #fff;
  }
  .ui-slider .ui-slider-handle:hover,
  .ui-slider .ui-slider-handle:focus,
  .ui-slider .ui-slider-handle:active {
    outline: none;
    -webkit-box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.3);
  }
  .ui-slider-horizontal .ui-slider-handle {
    margin-left: -0.725em;
    top: -0.4em;
  }
  .ui-slider-vertical .ui-slider-handle {
    left: -0.35em;
    margin-bottom: -0.65em;
  }
  .ui-slider-small.ui-slider-horizontal {
    height: 5px;
  }
  .ui-slider-small.ui-slider-vertical {
    width: 5px;
  }
  .ui-slider-small .ui-slider-handle {
    border-radius: 100%;
    width: 17px;
    height: 17px;
    margin-bottom: -0.45em;
    left: -0.35em;
  }
  .ui-slider-small .ui-slider-handle:before,
  .ui-slider-small .ui-slider-handle:after {
    height: 7px;
    left: 5px;
    width: 3px;
  }
  .ui-slider-small .ui-slider-handle:after {
    left: 7px;
  }
  .ui-slider-simple .ui-slider-handle:after,
  .ui-slider-simple .ui-slider-handle:before {
    display: none;
  }
  /* colors */
  .ui-slider-range {
    background-color: #4aa4ce;
  }
  .ui-slider-handle {
    outline: none !important;
    border-color: #4aa4ce !important;
  }
  .ui-state-disabled.ui-slider {
    background-color: #e5e5e5;
  }
  .ui-state-disabled .ui-slider-range {
    background-color: #8daebe;
  }
  .ui-state-disabled .ui-slider-handle {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-color: #8daebe !important;
  }
  .ui-slider-green .ui-slider-range {
    background-color: #8bbc67;
  }
  .ui-slider-green .ui-slider-handle {
    border-color: #8bbc67 !important;
  }
  .ui-slider-green.ui-state-disabled .ui-slider-range {
    background-color: #aab0a6;
  }
  .ui-slider-green.ui-state-disabled .ui-slider-handle {
    border-color: #aab0a6 !important;
  }
  .ui-slider-red .ui-slider-range {
    background-color: #d36e6e;
  }
  .ui-slider-red .ui-slider-handle {
    border-color: #d36e6e !important;
  }
  .ui-slider-red.ui-state-disabled .ui-slider-range {
    background-color: #c8acac;
  }
  .ui-slider-red.ui-state-disabled .ui-slider-handle {
    border-color: #c8acac !important;
  }
  .ui-slider-purple .ui-slider-range {
    background-color: #ac68ba;
  }
  .ui-slider-purple .ui-slider-handle {
    border-color: #ac68ba !important;
  }
  .ui-slider-purple.ui-state-disabled .ui-slider-range {
    background-color: #ada7ae;
  }
  .ui-slider-purple.ui-state-disabled .ui-slider-handle {
    border-color: #ada7ae !important;
  }
  .ui-slider-orange .ui-slider-range {
    background-color: #efad62;
  }
  .ui-slider-orange .ui-slider-handle {
    border-color: #efad62 !important;
  }
  .ui-slider-orange.ui-state-disabled .ui-slider-range {
    background-color: #e0c4a4;
  }
  .ui-slider-orange.ui-state-disabled .ui-slider-handle {
    border-color: #e0c4a4 !important;
  }
  .ui-slider-dark .ui-slider-range {
    background-color: #606060;
  }
  .ui-slider-dark .ui-slider-handle {
    border-color: #606060 !important;
  }
  .ui-slider-dark.ui-state-disabled .ui-slider-range {
    background-color: #7a7a7a;
  }
  .ui-slider-dark.ui-state-disabled .ui-slider-handle {
    border-color: #7a7a7a !important;
  }
  .ui-slider-pink .ui-slider-range {
    background-color: #d6487e;
  }
  .ui-slider-pink .ui-slider-handle {
    border-color: #d6487e !important;
  }
  .ui-slider-pink.ui-state-disabled .ui-slider-range {
    background-color: #c38ea2;
  }
  .ui-slider-pink.ui-state-disabled .ui-slider-handle {
    border-color: #c38ea2 !important;
  }
  .ui-datepicker {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  .ui-datepicker .ui-datepicker-prev,
  .ui-datepicker .ui-datepicker-next {
    height: 26px;
    min-width: 32px;
    max-width: 32px;
    text-align: center;
    cursor: pointer;
    color: transparent;
    line-height: 26px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  .ui-datepicker .ui-datepicker-prev .ui-icon,
  .ui-datepicker .ui-datepicker-next .ui-icon {
    color: transparent;
    visibility: hidden;
  }
  .ui-datepicker .ui-datepicker-prev:hover,
  .ui-datepicker .ui-datepicker-next:hover {
    background-color: #eee;
    text-decoration: none;
  }
  .ui-datepicker .ui-datepicker-prev:before,
  .ui-datepicker .ui-datepicker-next:before {
    display: inline;
    font-family: FontAwesome;
    font-size: 14px;
    content: '\f060';
    color: #393939;
  }
  .ui-datepicker .ui-datepicker-prev-hover,
  .ui-datepicker .ui-datepicker-next-hover {
    top: 2px;
  }
  .ui-datepicker .ui-datepicker-next:before {
    content: '\f061';
  }
  .ui-datepicker .ui-datepicker-prev-hover {
    left: 2px;
  }
  .ui-datepicker .ui-datepicker-next-hover {
    right: 2px;
  }
  .ui-datepicker td {
    padding: 0;
  }
  .ui-datepicker td > a,
  .ui-datepicker td > span {
    display: inline-block;
    height: 22px;
    min-width: 24px;
    max-width: 24px;
    text-align: center;
    color: #393939;
    font-size: 13px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  .ui-datepicker td > a:hover {
    background-color: #eee;
  }
  .ui-datepicker td > a.ui-state-highlight {
    background-color: #d5e5ef;
  }
  .ui-datepicker td > a.ui-state-active {
    background-color: #2283c5;
    color: #fff;
  }
  .ui-datepicker td > a.ui-priority-secondary {
    color: #888;
  }
  .ui-datepicker td > span {
    color: #999;
  }
  .ui-datepicker td .ui-datepicker-title select {
    height: 24px;
    line-height: 24px;
    padding: 2px 3px;
  }
  .ui-datepicker td .ui-datepicker-buttonpane {
    background-color: #ddd;
    height: 1px;
  }
  /* dialog */
  .ui-widget-overlay {
    background: rgba(0, 0, 0, 0.25);
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
    z-index: 1049 !important;
  }
  .ui-dialog,
  .ui-jqdialog {
    z-index: 1050 !important;
    background-color: #fff;
    padding: 0;
    border: 1px solid #ddd;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  .ui-dialog .ui-dialog-titlebar,
  .ui-jqdialog .ui-dialog-titlebar,
  .ui-dialog .ui-jqdialog-titlebar,
  .ui-jqdialog .ui-jqdialog-titlebar {
    background-color: #f1f1f1;
    font-size: 16px;
    color: #669fc7;
    padding: 0;
  }
  .ui-dialog .ui-dialog-title,
  .ui-jqdialog .ui-dialog-title,
  .ui-dialog .ui-jqdialog-title,
  .ui-jqdialog .ui-jqdialog-title {
    float: none !important;
    width: auto;
  }
  .ui-dialog .widget-header,
  .ui-jqdialog .widget-header {
    margin: 0;
    border-width: 0 0 1px 0;
  }
  .ui-dialog .ui-dialog-buttonpane,
  .ui-jqdialog .ui-dialog-buttonpane,
  .ui-dialog .ui-jqdialog-buttonpane,
  .ui-jqdialog .ui-jqdialog-buttonpane {
    background-color: #eff3f8;
    border-top: 1px solid #e4e9ee;
  }
  .ui-dialog .ui-dialog-buttonpane button,
  .ui-jqdialog .ui-dialog-buttonpane button,
  .ui-dialog .ui-jqdialog-buttonpane button,
  .ui-jqdialog .ui-jqdialog-buttonpane button {
    font-size: 14px;
  }
  .ui-dialog .ui-dialog-titlebar-close,
  .ui-jqdialog .ui-dialog-titlebar-close,
  .ui-dialog .ui-jqdialog-titlebar-close,
  .ui-jqdialog .ui-jqdialog-titlebar-close {
    border: none;
    background: transparent;
    opacity: 0.4;
    color: #d15b47;
    padding: 0;
    top: 50%;
    right: 8px !important;
    text-align: center;
  }
  .ui-dialog .ui-dialog-titlebar-close:before,
  .ui-jqdialog .ui-dialog-titlebar-close:before,
  .ui-dialog .ui-jqdialog-titlebar-close:before,
  .ui-jqdialog .ui-jqdialog-titlebar-close:before {
    content: '\f00d';
    display: inline;
    font-family: FontAwesome;
    font-size: 16px;
  }
  .ui-dialog .ui-dialog-titlebar-close:hover,
  .ui-jqdialog .ui-dialog-titlebar-close:hover,
  .ui-dialog .ui-jqdialog-titlebar-close:hover,
  .ui-jqdialog .ui-jqdialog-titlebar-close:hover {
    opacity: 1;
    text-decoration: none;
    padding: 0;
  }
  .ui-dialog .ui-dialog-titlebar-close .ui-button-text,
  .ui-jqdialog .ui-dialog-titlebar-close .ui-button-text,
  .ui-dialog .ui-jqdialog-titlebar-close .ui-button-text,
  .ui-jqdialog .ui-jqdialog-titlebar-close .ui-button-text {
    text-indent: 0;
    visibility: hidden;
  }
  .ui-dialog .widget-header .ui-dialog-titlebar-close,
  .ui-jqdialog .widget-header .ui-dialog-titlebar-close,
  .ui-dialog .widget-header .ui-jqdialog-titlebar-close,
  .ui-jqdialog .widget-header .ui-jqdialog-titlebar-close {
    right: 10px !important;
  }
  /* accordion */
  .ui-accordion .ui-accordion-header {
    color: #478fca;
    font-weight: normal;
    background-color: #f9f9f9;
    border: 1px solid #cdd8e3;
    padding: 8px 8px 9px 24px;
  }
  .ui-accordion .ui-accordion-header:hover {
    color: #6ea6cc;
    background-color: #f1f8fd;
  }
  .ui-accordion .ui-accordion-header.ui-state-active {
    color: #4c8fbd;
    background-color: #eef4f9;
    position: relative;
    font-weight: bold;
  }
  .ui-accordion .ui-accordion-header .ui-accordion-header-icon {
    text-indent: 0;
    margin-top: 0;
    position: absolute;
    left: 10px;
    top: 7px;
  }
  .ui-accordion .ui-accordion-header .ui-accordion-header-icon:before {
    display: inline;
    font-family: FontAwesome;
    font-size: 15px;
    content: '\f0da';
  }
  .ui-accordion
    .ui-accordion-header.ui-state-active
    .ui-accordion-header-icon:before {
    content: '\f0d7';
    font-weight: normal;
  }
  .ui-accordion .ui-accordion-content {
    border: 1px solid #cdd8e3;
    border-top-width: 0;
    padding: 11px 16px;
  }
  /* tabs */
  .ui-tabs .ui-tabs-nav {
    padding: 0;
    border-bottom: 1px solid #c5d0dc;
  }
  .ui-tabs .ui-tabs-nav li.ui-state-default > a {
    background-color: #f9f9f9;
    border: 1px solid #c5d0dc;
    border-bottom-width: 0;
    color: #999;
    line-height: 16px;
    margin-right: -1px;
    z-index: 11;
    padding: 8px 12px;
    position: relative;
    top: 2px;
  }
  .ui-tabs .ui-tabs-nav li > a:focus {
    outline: none;
  }
  .ui-tabs .ui-tabs-nav li.ui-tabs-active > a {
    background-color: #fff;
    border: 1px solid;
    border-color: #4c8fbd #c5d0dc transparent;
    border-top-width: 2px;
    -webkit-box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.15);
    color: #576373;
    position: relative;
    top: 1px;
  }
  .ui-tabs .ui-tabs-panel {
    border: 1px solid #c5d0dc;
    border-top-width: 0;
    margin: 0;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
  }
  /* menu */
  .ui-menu {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    width: 150px;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 3px;
  }
  .ui-menu .ui-state-focus,
  .ui-menu .ui-state-active {
    margin: auto;
  }
  .ui-menu .ui-menu-item {
    padding: 5px 10px 6px;
    color: #444;
    cursor: pointer;
    display: block;
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }
  .ui-menu .ui-menu-item .ui-menu-icon {
    float: right;
    position: relative;
    left: auto;
    right: 4px;
    bottom: auto;
    text-indent: 0;
  }
  .ui-menu .ui-menu-item .ui-menu-icon:before {
    content: '\f105';
    font-family: FontAwesome;
    font-size: 14px;
    display: inline;
  }
  .ui-menu .ui-menu-item:hover,
  .ui-menu .ui-state-focus,
  .ui-menu .ui-state-active,
  .ui-menu .ui-menu-item:hover > .ui-menu-icon,
  .ui-menu .ui-state-focus > .ui-menu-icon,
  .ui-menu .ui-state-active > .ui-menu-icon {
    text-decoration: none;
    background-color: #4f99c6;
    color: #fff;
    margin: auto;
    font-weight: normal;
  }
  .ui-menu .ui-state-disabled,
  .ui-menu .ui-state-disabled .ui-menu-icon {
    color: #999;
    cursor: default;
  }
  .ui-menu .ui-state-disabled:hover,
  .ui-menu .ui-state-disabled.ui-state-focus,
  .ui-menu .ui-state-disabled.ui-state-active,
  .ui-menu .ui-state-disabled:hover .ui-menu-icon,
  .ui-menu .ui-state-disabled.ui-state-focus .ui-menu-icon,
  .ui-menu .ui-state-disabled.ui-state-active .ui-menu-icon {
    background-color: #fff;
    color: #999;
  }
  /* auto complete */
  .ui-autocomplete {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  .ui-autocomplete-category {
    padding: 6px;
    position: relative;
    background-color: #eef4f9;
    color: #478fca;
    font-weight: bolder;
    border: 1px solid #dae6ed;
    border-width: 1px 0;
  }
  .ui-spinner-button {
    border-width: 0 !important;
    font-size: 10px;
    height: 16px;
    line-height: 16px;
    width: 18px;
    color: #ffffff !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25) !important;
    display: inline-block;
    position: absolute;
    text-align: center;
    padding: 0;
  }
  .ui-spinner-button > .ace-icon {
    width: 18px;
    display: inline-block;
  }
  .ui-spinner-up {
    top: 0;
    right: 5px;
  }
  .ui-spinner-down {
    bottom: 3px;
    right: 5px;
  }
  .ui-spinner-input {
    margin-top: 0;
    padding: 5px;
    max-width: 100px;
    font-size: 14px;
  }
  .ui-tooltip {
    background-color: #444;
    color: #fff;
  }
  .ui-progressbar {
    background-color: #f5f5f5;
    height: 22px;
  }
  .ui-progressbar .ui-progressbar-value {
    margin: 0;
  }
  .ui-progressbar .ui-progressbar-value[class='progress-bar'] {
    background-color: #2a91d8;
  }
  .ui-selectmenu-button {
    border: 1px solid #aaa;
  }
  .ui-selectmenu-button[aria-expanded='true'] {
    border-color: #4492c9;
  }
  .ui-selectmenu-button span.ui-icon {
    text-indent: 0;
    margin-top: -10px;
  }
  .ui-selectmenu-button .ui-icon:before {
    content: '\f0d7';
    display: inline-block;
    color: #888;
    font-family: FontAwesome;
    font-size: 14px;
  }
  .ui-jqgrid .ui-jqgrid-view {
    z-index: auto;
  }
  .ui-jqgrid .ui-jqgrid-view,
  .ui-jqgrid .ui-paging-info,
  .ui-jqgrid .ui-pg-table,
  .ui-jqgrid .ui-pg-selbox {
    font-size: 13px;
  }
  .ui-jqgrid .ui-jqgrid-title {
    float: left;
    margin: 8px;
  }
  .ui-jqgrid .ui-jqgrid-title-rtl {
    float: right;
    margin: 8px;
  }
  .ui-jqgrid-view > .ui-jqgrid-titlebar {
    height: 40px;
    line-height: 24px;
    color: #fff;
    background: #307ecc;
    padding: 0;
    font-size: 15px;
  }
  .ui-jqgrid tr.jqgrow.ui-row-rtl td:last-child {
    border-right: none;
    border-left: 1px solid #e1e1e1;
  }
  .ui-jqgrid .ui-jqgrid-hdiv {
    background-color: #eff3f8;
    border: 1px solid #d3d3d3;
    border-width: 1px 0 0 1px;
    line-height: 15px;
    font-weight: bold;
    color: #777;
    text-shadow: none;
  }
  .ui-jqgrid .ui-jqgrid-htable thead {
    background-color: #eff3f8;
  }
  .ui-jqgrid .ui-jqgrid-htable th span.ui-jqgrid-resize {
    height: 45px !important;
  }
  .ui-jqgrid .ui-jqgrid-htable th div {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .ui-jqgrid-hdiv .ui-jqgrid-htable {
    border-top: none;
  }
  .ui-jqgrid-hdiv .ui-jqgrid-htable {
    border-top: 1px solid #e1e1e1;
  }
  .ui-jqgrid-titlebar {
    position: relative;
    top: 1px;
    z-index: 1;
  }
  .ui-jqgrid tr.jqgrow,
  .ui-jqgrid tr.ui-row-ltr,
  .ui-jqgrid tr.ui-row-rtl {
    border: none;
  }
  .ui-jqgrid tr.ui-row-ltr td,
  .ui-jqgrid tr.ui-row-rtl td {
    border-bottom: 1px solid #e1e1e1;
    padding: 6px 4px;
    border-color: #e1e1e1;
  }
  .ui-jqgrid tr.ui-state-highlight.ui-row-ltr td {
    border-right-color: #c7d3a9;
  }
  .ui-jqgrid tr.ui-state-highlight.ui-row-rtl td {
    border-left-color: #c7d3a9;
  }
  .ui-jqgrid-btable .ui-widget-content.ui-priority-secondary {
    background-image: none;
    background-color: #f9f9f9;
    opacity: 1;
  }
  .ui-jqgrid-btable .ui-widget-content.ui-state-hover {
    background-image: none;
    background-color: #eff4f7;
    opacity: 1;
  }
  .ui-jqgrid-btable .ui-widget-content.ui-state-highlight {
    background-color: #e4efc9;
  }
  .ui-jqgrid .ui-jqgrid-pager {
    line-height: 15px;
    height: 55px;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    background-color: #eff3f8 !important;
    border-bottom: 1px solid #e1e1e1 !important;
    border-top: 1px solid #e1e1e1 !important;
  }
  .ui-jqgrid .ui-pg-input {
    font-size: inherit;
    width: 24px;
    height: 20px;
    line-height: 16px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    text-align: center;
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .ui-jqgrid .ui-pg-selbox {
    display: block;
    height: 24px;
    width: 60px;
    margin: 0;
    padding: 1px;
    line-height: normal;
  }
  .ui-jqgrid .ui-jqgrid-htable th div {
    overflow: visible;
  }
  .ui-jqgrid .ui-pager-control {
    height: 50px;
    position: relative;
    padding-left: 9px;
    padding-right: 9px;
  }
  .ui-jqgrid .ui-jqgrid-toppager {
    height: auto !important;
    background-color: #eff3f8;
    border-bottom: 1px solid #e1e1e1 !important;
  }
  .ui-jqgrid .jqgrow .editable {
    max-width: 90%;
    max-width: calc(92%) !important;
  }
  .ui-pg-table .navtable .ui-corner-all {
    border-radius: 0;
  }
  .ui-jqgrid .ui-pg-button .ui-separator {
    margin-left: 4px;
    margin-right: 4px;
    border-color: #c9d4db;
  }
  .ui-jqgrid .ui-jqgrid-btable {
    border-left: 1px solid #e1e1e1;
  }
  .ui-jqgrid .ui-jqgrid-bdiv {
    border-top: 1px solid #e1e1e1;
    overflow-x: hidden;
  }
  .ui-jqgrid .loading {
    position: absolute;
    top: 45%;
    left: 45%;
    width: auto;
    height: auto;
    z-index: 101;
    padding: 6px;
    margin: 5px;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    background-color: #fff;
    border: 2px solid #8eb8d1;
    color: #e2b018;
  }
  .ui-jqgrid .ui-search-toolbar {
    border-top: 1px solid #e1e1e1;
  }
  .ui-jqgrid .ui-jqgrid-labels {
    border-bottom: none;
    background: #f2f2f2;
    background-image: -webkit-linear-gradient(top, #f8f8f8 0%, #ececec 100%);
    background-image: -o-linear-gradient(top, #f8f8f8 0%, #ececec 100%);
    background-image: linear-gradient(to bottom, #f8f8f8 0%, #ececec 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#fff8f8f8',
        endColorstr='#ffececec',
        GradientType=0
      );
    padding: 0 !important;
    border-left: 1px solid #e1e1e1 !important;
  }
  .ui-jqgrid .ui-jqgrid-labels th {
    border-right: 1px solid #e1e1e1 !important;
    text-align: left !important;
  }
  /* checkbox container */
  .ui-jqgrid-labels th[id*='_cb']:first-child > div {
    padding-top: 0;
    text-align: center !important;
  }
  .ui-jqgrid-sortable {
    padding-left: 4px;
    font-size: 13px;
    color: #777;
    font-weight: bold;
  }
  .ui-jqgrid-sortable:hover {
    color: #547ea8;
  }
  th[aria-selected='true'] {
    background-image: -webkit-linear-gradient(top, #eff3f8 0%, #e3e7ed 100%);
    background-image: -o-linear-gradient(top, #eff3f8 0%, #e3e7ed 100%);
    background-image: linear-gradient(to bottom, #eff3f8 0%, #e3e7ed 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ffeff3f8',
        endColorstr='#ffe3e7ed',
        GradientType=0
      );
  }
  th[aria-selected='true'] .ui-jqgrid-sortable {
    color: #307ecc;
  }
  .ui-jqgrid .ui-icon {
    text-indent: 0;
    color: #307ecc;
    float: none;
    right: 2px;
  }
  .rtl .ui-jqgrid .ui-icon {
    right: auto;
    left: 2px;
  }
  .ui-jqgrid .ui-icon.ui-state-disabled {
    color: #bbb;
  }
  .ui-jqgrid .ui-icon.ui-state-disabled:hover {
    padding: 0;
  }
  .ui-grid-ico-sort:before {
    display: inline;
    content: '\f0d7';
    font-family: FontAwesome;
    font-size: 12px;
  }
  .ui-icon-asc:before {
    content: '\f0d8';
  }
  .ui-pg-table > tbody > tr > .ui-pg-button > .ui-icon {
    display: inline-block;
    padding: 0;
    width: 24px;
    height: 24px;
    line-height: 22px;
    text-align: center;
    position: static;
    float: none;
    margin: 0 2px !important;
    color: #808080;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 100%;
  }
  .ui-pg-table > tbody > tr > .ui-pg-button > .ui-icon:hover {
    color: #699ab5;
    border-color: #699ab5;
  }
  .ui-pg-table > tbody > tr > .ui-pg-button > .ui-icon:before {
    width: 20px;
    text-align: center;
    display: inline-block;
  }
  .ui-pg-table > tbody > tr > .ui-pg-button.ui-state-disabled .ui-icon {
    color: #b0b0b0;
    background-color: #f7f7f7;
    border-color: #ddd;
    -moz-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -o-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
  .ui-jqgrid-btable input,
  .ui-jqgrid-btable textarea,
  .ui-jqgrid-btable select {
    padding: 2px;
    width: auto;
    max-width: 100%;
    margin-bottom: 0;
  }
  .ui-jqgrid-btable select {
    padding: 1px;
    height: 25px;
    line-height: 25px;
  }
  .ui-jqgrid select.inline-edit-cell {
    padding: 1px;
  }
  .ui-pg-div .ui-icon {
    display: inline-block;
    width: 18px;
    float: none;
    position: static;
    text-align: center;
    opacity: 0.85;
    -webkit-transition: all 0.12s;
    -o-transition: all 0.12s;
    transition: all 0.12s;
    margin: 0 1px;
    vertical-align: middle;
    cursor: pointer;
    font-size: 17px;
  }
  .ui-pg-div .ui-icon:hover {
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 1;
    position: static;
    margin: 0 1px;
  }
  .ui-pg-div .ui-icon:before {
    font-family: FontAwesome;
    display: inline;
  }
  .ui-jqgrid .ui-icon-pencil {
    color: #478fca;
  }
  .ui-jqgrid .ui-icon-pencil:before {
    content: '\f040';
  }
  .ui-jqgrid .ui-icon-trash {
    color: #dd5a43;
  }
  .ui-jqgrid .ui-icon-trash:before {
    content: '\f014';
  }
  .ui-jqgrid .ui-icon-disk {
    color: #69aa46;
  }
  .ui-jqgrid .ui-icon-disk:before {
    content: '\f00c';
  }
  .ui-jqgrid .ui-icon-cancel {
    color: #dd5a43;
  }
  .ui-jqgrid .ui-icon-cancel:before {
    content: '\f00d';
  }
  .ui-jqdialog-content,
  .ui-jqdialog .ui-jqdialog-content {
    font-size: 13px;
    padding: 4px 0 0;
  }
  .ui-jqdialog-content .formdata,
  .ui-jqdialog .ui-jqdialog-content .formdata {
    font-size: 13px;
    padding: 6px 12px;
  }
  .ui-jqdialog-content .form-view-data,
  .ui-jqdialog .ui-jqdialog-content .form-view-data {
    vertical-align: middle;
    font-size: 13px;
  }
  .ui-jqdialog-content[id*='alertcnt_'],
  .ui-jqdialog .ui-jqdialog-content[id*='alertcnt_'] {
    padding: 8px 11px;
  }
  .ui-jqdialog-content .CaptionTD {
    font-size: 12px;
    text-align: right;
    color: #666;
  }
  .ui-jqdialog-content .FormData {
    border-bottom: 1px dotted #e8e8e8;
  }
  .ui-jqdialog-content .FormData:last-child {
    border-bottom: none;
  }
  .ui-jqdialog-content .FormData > td {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .ui-jqdialog-content input.FormElement {
    width: auto;
  }
  .ui-jqdialog-content select.FormElement {
    padding: 1px;
    height: 25px;
    line-height: 25px;
    width: auto;
  }
  .ui-jqdialog-content td.EditButton {
    padding: 8px;
  }
  .EditTable {
    background-color: #eff3f8;
    border-top: 1px solid #d6e1ea !important;
    padding: 8px;
  }
  .EditTable tr:first-child {
    display: none;
  }
  .EditTable .navButton .fm-button {
    float: none !important;
    width: auto !important;
    margin: 1px 1px 2px !important;
    background-color: transparent;
    border-radius: 100%;
  }
  .EditTable .navButton .fm-button:hover {
    background-color: transparent;
  }
  .EditTable .navButton .fm-button:focus {
    outline: none;
  }
  .EditTable .navButton .fm-button .ace-icon {
    display: inline-block;
    color: #999;
    border: 1px solid #aaa;
    width: 26px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    border-radius: 100%;
    background-color: #fff;
  }
  .EditTable .navButton .fm-button:hover .ace-icon {
    color: #699ab5;
    border-color: #699ab5;
  }
  .EditTable .navButton .fm-button.ui-state-disabled .ace-icon,
  .EditTable .navButton .fm-button.ui-state-disabled:hover .ace-icon {
    color: #bbb;
    border-color: #ccc;
    -moz-transform: scale(0.88);
    -webkit-transform: scale(0.88);
    -o-transform: scale(0.88);
    -ms-transform: scale(0.88);
    transform: scale(0.88);
  }
  .FormGrid .EditTable {
    background-color: #fff;
    border-top: none !important;
    padding: 0;
  }
  .FormGrid .EditTable tr:first-child {
    display: none;
  }
  .ui-jqgrid .ui-jqgrid-view input,
  .ui-jqgrid .ui-jqgrid-view select,
  .ui-jqgrid .ui-jqgrid-view textarea,
  .ui-jqgrid .ui-jqgrid-view button {
    font-size: 13px;
  }
  .ui-jqdialog-content .searchFilter select {
    padding: 1px;
    height: 26px;
    line-height: 26px;
    width: auto;
    max-width: 95%;
    margin-bottom: 0;
  }
  .ui-jqdialog-content .searchFilter .input-elm {
    margin-bottom: 0;
    height: 18px;
    line-height: 18px;
    width: 95% !important;
    padding-left: 1px;
    padding-right: 1px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  .ui-jqdialog-content .searchFilter table {
    margin-left: 4px;
  }
  .ui-jqdialog-content .searchFilter tr td {
    padding: 5px 0;
  }
  .ui-jqdialog-content .searchFilter .add-group,
  .ui-jqdialog-content .searchFilter .add-rule,
  .ui-jqdialog-content .searchFilter .delete-group {
    margin-left: 4px !important;
    font-size: 15px !important;
  }
  .ui-jqdialog-content .searchFilter .delete-rule {
    border: none;
    background-color: #fff;
    color: #d15b47;
    font-size: 20px;
    width: 22px;
    line-height: 10px;
    padding: 0;
    text-shadow: none !important;
    display: inline-block;
    -webkit-transition: all 0.1s;
    -o-transition: all 0.1s;
    transition: all 0.1s;
    opacity: 0.85;
  }
  .ui-jqdialog-content .searchFilter .delete-rule:hover {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    color: #b74635;
    opacity: 1;
  }
  .ui-jqdialog-content .searchFilter .queryresult {
    margin-bottom: 11px;
  }
  .ui-jqdialog-content .searchFilter .queryresult td.query {
    padding: 6px 11px;
    border: 1px solid #e1e1e1;
    background-color: #eeeeee;
  }
  .ui-jqdialog-content .searchFilter .queryresult td.query:empty {
    display: none;
  }
  .ui-state-error {
    background-color: #f2dede;
    border: 1px solid #ebccd1;
    color: #a94442;
    margin: 4px 4px 8px;
    padding: 6px 10px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    font-size: 13px;
  }
  .ui-jqdialog .ui-widget-header {
    background-image: -webkit-linear-gradient(top, #ffffff 0%, #eeeeee 100%);
    background-image: -o-linear-gradient(top, #ffffff 0%, #eeeeee 100%);
    background-image: linear-gradient(to bottom, #ffffff 0%, #eeeeee 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ffffffff',
        endColorstr='#ffeeeeee',
        GradientType=0
      );
    border-image: none;
    border-bottom: 1px solid solid;
    color: #669fc7;
    min-height: 38px;
    position: relative;
  }
  .ui-jqdialog .ui-widget-header .ui-jqdialog-title {
    line-height: 38px;
    margin: 0;
    padding: 0;
    padding-left: 12px;
    text-align: left;
  }
  .widget-header .ui-jqdialog-title {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .ui-jqdialog .ui-widget-header .widget-header {
    border-bottom: none;
  }
  .ui-jqdialog .ui-jqdialog-titlebar {
    border-bottom: 1px solid #ddd !important;
  }
  .fm-button {
    margin: 0 4px;
    height: auto;
  }
  .fm-button:not(.btn) {
    background-color: #abbac3;
    border-radius: 0;
    box-shadow: none;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-size: 13px;
    line-height: 28px;
    padding: 0 12px 1px;
    margin: 0 8px;
    position: relative;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    -webkit-transition: all 0.15s;
    -o-transition: all 0.15s;
    transition: all 0.15s;
    vertical-align: middle;
  }
  .fm-button.ui-state-default:hover {
    background-color: #8b9aa3;
  }
  .ui-jqgrid .ui-jqgrid-htable .ui-search-toolbar th {
    height: 30px;
    padding-top: 2px;
    white-space: normal;
  }
  .ui-jqgrid .ui-jqgrid-htable .ui-search-toolbar th div {
    padding-top: 0;
    padding-bottom: 0;
    height: 30px;
    line-height: 26px;
  }
  .ui-jqgrid .ui-jqgrid-titlebar-close {
    top: 10%;
    height: auto;
    padding: 0;
    margin: 2px 8px 0 0;
    text-align: center;
    border-radius: 4px;
  }
  .ui-jqgrid .ui-jqgrid-titlebar-close:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  .ui-jqgrid .ui-jqgrid-titlebar-close .ui-icon:before {
    display: inline-block;
    font-family: FontAwesome;
    content: '\f077';
    color: #fff;
  }
  .ui-jqgrid .ui-jqgrid-titlebar-close .ui-icon-circle-triangle-s:before {
    content: '\f078';
  }
  .ui-jqgrid .tree-wrap-ltr {
    margin: 0 4px;
    float: none;
    display: inline;
  }
  .ui-jqgrid .tree-wrap-rtl {
    margin: 2px 4px 0;
  }
  .ui-jqgrid .ui-subgrid {
    border-bottom: 1px solid #e1e1e1;
    background-color: #f6faff;
  }
  .ui-jqgrid .ui-subgrid .ui-jqgrid-btable {
    background-color: #fff;
  }
  .ui-jqgrid .ui-subgrid .ui-jqgrid .ui-jqgrid-hdiv {
    background-color: transparent;
    margin-top: 4px;
  }
  .ui-jqgrid
    .ui-subgrid
    .ui-jqgrid
    .ui-jqgrid-hdiv
    .ui-jqgrid-htable
    .ui-jqgrid-labels {
    border-bottom: 1px solid #e1e1e1;
    background: #f1f1f1;
  }
  .ui-jqgrid
    .ui-subgrid
    .ui-jqgrid
    .ui-jqgrid-hdiv
    .ui-jqgrid-htable
    th[aria-selected='true'] {
    background: #e5e9ef;
  }
  .ui-jqgrid
    .ui-subgrid
    .ui-jqgrid
    .ui-jqgrid-hdiv
    .ui-jqgrid-htable
    th
    .ui-jqgrid-sortable {
    font-size: 12px;
  }
  .ui-jqgrid .ui-subgrid .ui-jqgrid .ui-jqgrid-hdiv .ui-jqgrid-htable th div {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .ui-jqgrid
    .ui-subgrid
    .ui-jqgrid
    .ui-jqgrid-hdiv
    .ui-jqgrid-htable
    th
    span.ui-jqgrid-resize {
    height: 36px !important;
  }
  .ui-jqgrid .ui-subgrid .ui-jqgrid .ui-jqgrid-bdiv {
    height: auto !important;
    max-height: 150px;
    margin-bottom: 4px;
    border-top-width: 0;
    border-bottom: 1px solid #e1e1e1;
  }
  .ui-jqgrid .ui-sgcollapsed > a:hover {
    text-decoration: none;
  }
  .ui-jqgrid .ui-pg-button:hover {
    padding: 0 1px;
  }
  .ui-jqgrid .ui-state-disabled:hover {
    padding: 0 1px;
  }
  .ui-jqgrid
    .ui-pg-table
    .ui-pg-button.ui-state-disabled:hover
    > .ui-separator {
    margin-left: 4px;
    margin-right: 4px;
  }
  .ui-jqgrid
    .ui-pg-table
    .ui-pg-button.ui-state-disabled:hover
    > .ui-pg-div
    > .ui-icon {
    margin-left: 4px;
    margin-right: 4px;
  }
  @media only screen and (max-width: 767px) {
    .ui-jqgrid .ui-jqgrid-pager {
      height: 90px;
    }
    .ui-jqgrid .ui-jqgrid-pager > .ui-pager-control {
      height: 85px;
      padding-top: 10px !important;
    }
    .ui-jqgrid
      .ui-jqgrid-pager
      > .ui-pager-control
      > .ui-pg-table
      > tbody
      > tr
      > td {
      vertical-align: top;
    }
    .ui-jqgrid
      .ui-jqgrid-pager
      > .ui-pager-control
      > .ui-pg-table
      > tbody
      > tr
      > td#grid-pager_center {
      width: 0 !important;
      position: static;
    }
    .ui-jqgrid
      .ui-jqgrid-pager
      > .ui-pager-control
      > .ui-pg-table
      > tbody
      > tr
      > td#grid-pager_center
      > .ui-pg-table {
      margin: 36px auto 0;
      position: absolute;
      right: 0;
      left: 0;
      text-align: center;
    }
    .ui-jqgrid .ui-jqgrid-pager .navtable {
      height: auto;
    }
  }
  @media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 0) {
    .ui-jqgrid
      .ui-jqgrid-pager
      > .ui-pager-control
      > .ui-pg-table
      > tbody
      > tr
      > td#grid-pager_center
      > .ui-pg-table {
      width: 300px;
    }
  }
  .dd {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    max-width: 600px;
    list-style: none;
    line-height: 20px;
  }
  .dd-list {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .dd-list .dd-list {
    padding-left: 30px;
  }
  .dd-collapsed .dd-list {
    display: none;
  }
  .dd-item,
  .dd-empty,
  .dd-placeholder {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    min-height: 20px;
    line-height: 20px;
  }
  .dd-handle,
  .dd2-content {
    display: block;
    min-height: 38px;
    margin: 5px 0;
    padding: 8px 12px;
    background: #f8faff;
    border: 1px solid #dae2ea;
    color: #7c9eb2;
    text-decoration: none;
    font-weight: bold;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .dd-handle:hover,
  .dd2-content:hover {
    color: #438eb9;
    background: #f4f6f7;
    border-color: #dce2e8;
  }
  .dd-handle[class*='btn-'],
  .dd2-content[class*='btn-'] {
    color: #fff;
    border: none;
    padding: 9px 12px;
  }
  .dd-handle[class*='btn-']:hover,
  .dd2-content[class*='btn-']:hover {
    opacity: 0.85;
    color: #fff;
  }
  .dd2-handle + .dd2-content,
  .dd2-handle + .dd2-content[class*='btn-'] {
    padding-left: 44px;
  }
  .dd-handle[class*='btn-']:hover,
  .dd2-content[class*='btn-']
    .dd2-handle[class*='btn-']:hover
    + .dd2-content[class*='btn-'] {
    color: #fff;
  }
  .dd-item > button:hover ~ .dd-handle,
  .dd-item > button:hover ~ .dd2-content {
    color: #438eb9;
    background: #f4f6f7;
    border-color: #dce2e8;
  }
  .dd-item > button:hover ~ .dd-handle[class*='btn-'],
  .dd-item > button:hover ~ .dd2-content[class*='btn-'] {
    opacity: 0.85;
    color: #fff;
  }
  .dd2-handle:hover ~ .dd2-content {
    color: #438eb9;
    background: #f4f6f7;
    border-color: #dce2e8;
  }
  .dd2-handle:hover ~ .dd2-content[class*='btn-'] {
    opacity: 0.85;
    color: #fff;
  }
  .dd2-item.dd-item > button {
    margin-left: 34px;
  }
  .dd-item > button {
    display: block;
    position: relative;
    z-index: 1;
    cursor: pointer;
    float: left;
    width: 25px;
    height: 20px;
    margin: 5px 1px 5px 5px;
    padding: 0;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    border: 0;
    background: transparent;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    font-weight: bold;
    top: 4px;
    left: 1px;
    color: #707070;
  }
  .dd-item > button:before {
    font-family: FontAwesome;
    content: '\f067';
    display: block;
    position: absolute;
    width: 100%;
    text-align: center;
    text-indent: 0;
    font-weight: normal;
    font-size: 14px;
  }
  .dd-item > button[data-action='collapse']:before {
    content: '\f068';
  }
  .dd-item > button:hover {
    color: #707070;
  }
  .dd-item.dd-colored > button,
  .dd-item.dd-colored > button:hover {
    color: #eee;
  }
  .dd-placeholder,
  .dd-empty {
    margin: 5px 0;
    padding: 0;
    min-height: 30px;
    background: #f0f9ff;
    border: 2px dashed #bed2db;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .dd-empty {
    border-color: #aaa;
    border-style: solid;
    background-color: #e5e5e5;
  }
  .dd-dragel {
    position: absolute;
    pointer-events: none;
    z-index: 999;
    opacity: 0.8;
  }
  .dd-dragel > li > .dd-handle {
    color: #4b92be;
    background: #f1f5fa;
    border-color: #d6e1ea;
    border-left: 2px solid #777;
    position: relative;
  }
  .dd-dragel > li > .dd-handle[class*='btn-'] {
    color: #fff;
  }
  .dd-dragel > .dd-item > .dd-handle {
    margin-top: 0;
  }
  .dd-list > li[class*='item-'] {
    border-width: 0;
    padding: 0;
  }
  .dd-list > li[class*='item-'] > .dd-handle {
    border-left: 2px solid;
    border-left-color: inherit;
  }
  .dd-list > li > .dd-handle .sticker {
    position: absolute;
    right: 0;
    top: 0;
  }
  .dd2-handle,
  .dd-dragel > li > .dd2-handle {
    left: 0;
    top: 0;
    width: 36px;
    margin: 0;
    border-width: 1px 1px 0 0;
    text-align: center;
    padding: 0 !important;
    line-height: 38px;
    height: 38px;
    background: #ebedf2;
    border: 1px solid #dee4ea;
    cursor: pointer;
    overflow: hidden;
    position: absolute;
    z-index: 1;
  }
  .dd2-handle:hover,
  .dd-dragel > li > .dd2-handle {
    background: #e3e8ed;
  }
  .dd2-content[class*='btn-'] {
    text-shadow: none !important;
  }
  .dd2-handle[class*='btn-'] {
    text-shadow: none !important;
    background: rgba(0, 0, 0, 0.1) !important;
    border-right: 1px solid #eee;
  }
  .dd2-handle[class*='btn-']:hover {
    background: rgba(0, 0, 0, 0.08) !important;
  }
  .dd-dragel .dd2-handle[class*='btn-'] {
    border-color: transparent;
    border-right-color: #eee;
  }
  .dd2-handle.btn-yellow {
    text-shadow: none !important;
    background: rgba(0, 0, 0, 0.05) !important;
    border-right: 1px solid #fff;
  }
  .dd2-handle.btn-yellow:hover {
    background: rgba(0, 0, 0, 0.08) !important;
  }
  .dd-dragel .dd2-handle.btn-yellow {
    border-color: transparent;
    border-right-color: #fff;
  }
  .dd-item > .dd2-handle .drag-icon {
    display: none;
  }
  .dd-dragel > .dd-item > .dd2-handle .drag-icon {
    display: inline;
  }
  .dd-dragel > .dd-item > .dd2-handle .normal-icon {
    display: none;
  }
  .dropzone {
    border-radius: 0;
    border: 1px solid rgba(0, 0, 0, 0.06);
  }
  .dropzone.well {
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
  }
  .dropzone .dz-default.dz-message {
    background-image: none;
    font-size: 24px;
    text-align: center;
    line-height: 32px;
    left: 0;
    width: 100%;
    margin-left: auto;
  }
  .dropzone .dz-default.dz-message span {
    display: inline;
    color: #555;
  }
  .dropzone .dz-default.dz-message span .upload-icon {
    opacity: 0.7;
    filter: alpha(opacity=70);
    margin-top: 8px;
    cursor: pointer;
  }
  .dropzone .dz-default.dz-message span .upload-icon:hover {
    opacity: 1;
    filter: alpha(opacity=100);
  }
  .dropzone .dz-preview.dz-image-preview {
    background-color: transparent;
  }
  input.typeahead,
  input.tt-query,
  input.tt-hint {
    min-width: 175px;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid #ccc;
    border-radius: 0;
    outline: none;
  }
  input.tt-hint,
  .form-group input.tt-hint {
    background-color: #fff !important;
    color: #b0b0b0 !important;
  }
  .tt-menu {
    text-align: left;
    position: absolute;
    left: 0 !important;
    right: 0 !important;
    min-width: 175px;
    margin-top: 2px;
    padding: 8px 0;
    background-color: #fff;
    border: 1px solid #d0d0d0;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  .tt-suggestion {
    padding: 3px 12px 4px;
    font-size: 16px;
    line-height: 24px;
  }
  .tt-suggestion.tt-selectable:hover,
  .tt-suggestion.tt-cursor {
    color: #fff;
    background-color: #4f99c6;
    cursor: pointer;
  }
  .tt-suggestion p {
    margin: 0;
  }
  input.typeahead.scrollable ~ .tt-menu {
    max-height: 200px;
    overflow-y: auto;
  }
  .btn-group > .btn.moveall:first-child,
  .btn-group > .btn.remove:first-child {
    margin: 0;
  }
  .btn-group > .btn.moveall:first-child + .btn.move,
  .btn-group > .btn.remove:first-child + .btn.removeall {
    margin: 0;
  }
  .bootstrap-duallistbox-container .info {
    font-size: 12px;
  }
  .bootstrap-duallistbox-container .clear1,
  .bootstrap-duallistbox-container .clear2 {
    font-size: 12px;
  }
  .btn.multiselect-clear-filter {
    padding-left: 6px;
    padding-right: 6px;
    line-height: 1.45;
  }
  .multiselect-container > li > a {
    padding: 0;
  }
  .multiselect-container > li > a > label {
    padding: 7px 10px 7px 20px;
  }
  .cancel-on-png,
  .cancel-off-png,
  .star-on-png,
  .star-off-png,
  .star-half-png {
    font-size: 2em;
  }
  .cancel-on-png,
  .cancel-off-png,
  .star-on-png,
  .star-off-png,
  .star-half-png {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: 'FontAwesome';
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
    color: #777777;
  }
  .cancel-on-png {
    color: #dd5a43;
  }
  .cancel-on-png:before {
    content: '\f057';
  }
  .cancel-off-png {
    color: #e08374;
  }
  .cancel-off-png:before {
    content: '\f05c';
  }
  .star-on-png {
    color: #feb902;
  }
  .star-on-png:before {
    content: '\f005';
  }
  .star-off-png {
    color: #777777;
  }
  .star-off-png:before {
    content: '\f006';
  }
  .star-half-png {
    color: #feb902;
  }
  .star-half-png:before {
    content: '\f123';
  }
  /* custom animated icons */
  .icon-animated-bell {
    display: inline-block;
    -moz-animation: ringing 2s 5 ease 1s;
    -webkit-animation: ringing 2s 5 ease 1s;
    -o-animation: ringing 2s 5 ease 1s;
    -ms-animation: ringing 2s 5 ease 1s;
    animation: ringing 2s 5 ease 1s;
    -moz-transform-origin: 50% 0%;
    -webkit-transform-origin: 50% 0%;
    -o-transform-origin: 50% 0%;
    -ms-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }
  @-moz-keyframes ringing {
    0% {
      -moz-transform: rotate(-15deg);
    }
    2% {
      -moz-transform: rotate(15deg);
    }
    4% {
      -moz-transform: rotate(-18deg);
    }
    6% {
      -moz-transform: rotate(18deg);
    }
    8% {
      -moz-transform: rotate(-22deg);
    }
    10% {
      -moz-transform: rotate(22deg);
    }
    12% {
      -moz-transform: rotate(-18deg);
    }
    14% {
      -moz-transform: rotate(18deg);
    }
    16% {
      -moz-transform: rotate(-12deg);
    }
    18% {
      -moz-transform: rotate(12deg);
    }
    20% {
      -moz-transform: rotate(0deg);
    }
  }
  @-webkit-keyframes ringing {
    0% {
      -webkit-transform: rotate(-15deg);
    }
    2% {
      -webkit-transform: rotate(15deg);
    }
    4% {
      -webkit-transform: rotate(-18deg);
    }
    6% {
      -webkit-transform: rotate(18deg);
    }
    8% {
      -webkit-transform: rotate(-22deg);
    }
    10% {
      -webkit-transform: rotate(22deg);
    }
    12% {
      -webkit-transform: rotate(-18deg);
    }
    14% {
      -webkit-transform: rotate(18deg);
    }
    16% {
      -webkit-transform: rotate(-12deg);
    }
    18% {
      -webkit-transform: rotate(12deg);
    }
    20% {
      -webkit-transform: rotate(0deg);
    }
  }
  @-ms-keyframes ringing {
    0% {
      -ms-transform: rotate(-15deg);
    }
    2% {
      -ms-transform: rotate(15deg);
    }
    4% {
      -ms-transform: rotate(-18deg);
    }
    6% {
      -ms-transform: rotate(18deg);
    }
    8% {
      -ms-transform: rotate(-22deg);
    }
    10% {
      -ms-transform: rotate(22deg);
    }
    12% {
      -ms-transform: rotate(-18deg);
    }
    14% {
      -ms-transform: rotate(18deg);
    }
    16% {
      -ms-transform: rotate(-12deg);
    }
    18% {
      -ms-transform: rotate(12deg);
    }
    20% {
      -ms-transform: rotate(0deg);
    }
  }
  @keyframes ringing {
    0% {
      transform: rotate(-15deg);
    }
    2% {
      transform: rotate(15deg);
    }
    4% {
      transform: rotate(-18deg);
    }
    6% {
      transform: rotate(18deg);
    }
    8% {
      transform: rotate(-22deg);
    }
    10% {
      transform: rotate(22deg);
    }
    12% {
      transform: rotate(-18deg);
    }
    14% {
      transform: rotate(18deg);
    }
    16% {
      transform: rotate(-12deg);
    }
    18% {
      transform: rotate(12deg);
    }
    20% {
      transform: rotate(0deg);
    }
  }
  .icon-animated-vertical {
    display: inline-block;
    -moz-animation: vertical 2s 5 ease 2s;
    -webkit-animation: vertical 2s 5 ease 2s;
    -o-animation: vertical 2s 5 ease 2s;
    -ms-animation: vertical 2s 5 ease 2s;
    animation: vertical 2s 5 ease 2s;
  }
  @-moz-keyframes vertical {
    0% {
      -moz-transform: translate(0, -3px);
    }
    4% {
      -moz-transform: translate(0, 3px);
    }
    8% {
      -moz-transform: translate(0, -3px);
    }
    12% {
      -moz-transform: translate(0, 3px);
    }
    16% {
      -moz-transform: translate(0, -3px);
    }
    20% {
      -moz-transform: translate(0, 3px);
    }
    22% {
      -moz-transform: translate(0, 0);
    }
  }
  @-webkit-keyframes vertical {
    0% {
      -webkit-transform: translate(0, -3px);
    }
    4% {
      -webkit-transform: translate(0, 3px);
    }
    8% {
      -webkit-transform: translate(0, -3px);
    }
    12% {
      -webkit-transform: translate(0, 3px);
    }
    16% {
      -webkit-transform: translate(0, -3px);
    }
    20% {
      -webkit-transform: translate(0, 3px);
    }
    22% {
      -webkit-transform: translate(0, 0);
    }
  }
  @-ms-keyframes vertical {
    0% {
      -ms-transform: translate(0, -3px);
    }
    4% {
      -ms-transform: translate(0, 3px);
    }
    8% {
      -ms-transform: translate(0, -3px);
    }
    12% {
      -ms-transform: translate(0, 3px);
    }
    16% {
      -ms-transform: translate(0, -3px);
    }
    20% {
      -ms-transform: translate(0, 3px);
    }
    22% {
      -ms-transform: translate(0, 0);
    }
  }
  @keyframes vertical {
    0% {
      transform: translate(0, -3px);
    }
    4% {
      transform: translate(0, 3px);
    }
    8% {
      transform: translate(0, -3px);
    }
    12% {
      transform: translate(0, 3px);
    }
    16% {
      transform: translate(0, -3px);
    }
    20% {
      transform: translate(0, 3px);
    }
    22% {
      transform: translate(0, 0);
    }
  }
  .icon-animated-hand-pointer {
    display: inline-block;
    -moz-animation: hand-pointer 2s 4 ease 2s;
    -webkit-animation: hand-pointer 2s 4 ease 2s;
    -o-animation: hand-pointer 2s 4 ease 2s;
    -ms-animation: hand-pointer 2s 4 ease 2s;
    animation: hand-pointer 2s 4 ease 2s;
  }
  @-moz-keyframes hand-pointer {
    0% {
      -moz-transform: translate(0, 0);
    }
    6% {
      -moz-transform: translate(5px, 0);
    }
    12% {
      -moz-transform: translate(0, 0);
    }
    18% {
      -moz-transform: translate(5px, 0);
    }
    24% {
      -moz-transform: translate(0, 0);
    }
    30% {
      -moz-transform: translate(5px, 0);
    }
    36% {
      -moz-transform: translate(0, 0);
    }
  }
  .icon-animated-wrench {
    display: inline-block;
    -moz-animation: wrenching 2.5s 4 ease;
    -webkit-animation: wrenching 2.5s 4 ease;
    -o-animation: wrenching 2.5s 4 ease;
    -ms-animation: wrenching 2.5s 4 ease;
    animation: wrenching 2.5s 4 ease;
    -moz-transform-origin: 90% 35%;
    -webkit-transform-origin: 90% 35%;
    -o-transform-origin: 90% 35%;
    -ms-transform-origin: 90% 35%;
    transform-origin: 90% 35%;
  }
  @-moz-keyframes wrenching {
    0% {
      -moz-transform: rotate(-12deg);
    }
    8% {
      -moz-transform: rotate(12deg);
    }
    10% {
      -moz-transform: rotate(24deg);
    }
    18% {
      -moz-transform: rotate(-24deg);
    }
    20% {
      -moz-transform: rotate(-24deg);
    }
    28% {
      -moz-transform: rotate(24deg);
    }
    30% {
      -moz-transform: rotate(24deg);
    }
    38% {
      -moz-transform: rotate(-24deg);
    }
    40% {
      -moz-transform: rotate(-24deg);
    }
    48% {
      -moz-transform: rotate(24deg);
    }
    50% {
      -moz-transform: rotate(24deg);
    }
    58% {
      -moz-transform: rotate(-24deg);
    }
    60% {
      -moz-transform: rotate(-24deg);
    }
    68% {
      -moz-transform: rotate(24deg);
    }
    75% {
      -moz-transform: rotate(0deg);
    }
  }
  @-webkit-keyframes wrenching {
    0% {
      -webkit-transform: rotate(-12deg);
    }
    8% {
      -webkit-transform: rotate(12deg);
    }
    10% {
      -webkit-transform: rotate(24deg);
    }
    18% {
      -webkit-transform: rotate(-24deg);
    }
    20% {
      -webkit-transform: rotate(-24deg);
    }
    28% {
      -webkit-transform: rotate(24deg);
    }
    30% {
      -webkit-transform: rotate(24deg);
    }
    38% {
      -webkit-transform: rotate(-24deg);
    }
    40% {
      -webkit-transform: rotate(-24deg);
    }
    48% {
      -webkit-transform: rotate(24deg);
    }
    50% {
      -webkit-transform: rotate(24deg);
    }
    58% {
      -webkit-transform: rotate(-24deg);
    }
    60% {
      -webkit-transform: rotate(-24deg);
    }
    68% {
      -webkit-transform: rotate(24deg);
    }
    75% {
      -webkit-transform: rotate(0deg);
    }
  }
  @-o-keyframes wrenching {
    0% {
      -o-transform: rotate(-12deg);
    }
    8% {
      -o-transform: rotate(12deg);
    }
    10% {
      -o-transform: rotate(24deg);
    }
    18% {
      -o-transform: rotate(-24deg);
    }
    20% {
      -o-transform: rotate(-24deg);
    }
    28% {
      -o-transform: rotate(24deg);
    }
    30% {
      -o-transform: rotate(24deg);
    }
    38% {
      -o-transform: rotate(-24deg);
    }
    40% {
      -o-transform: rotate(-24deg);
    }
    48% {
      -o-transform: rotate(24deg);
    }
    50% {
      -o-transform: rotate(24deg);
    }
    58% {
      -o-transform: rotate(-24deg);
    }
    60% {
      -o-transform: rotate(-24deg);
    }
    68% {
      -o-transform: rotate(24deg);
    }
    75% {
      -o-transform: rotate(0deg);
    }
  }
  @-ms-keyframes wrenching {
    0% {
      -ms-transform: rotate(-12deg);
    }
    8% {
      -ms-transform: rotate(12deg);
    }
    10% {
      -ms-transform: rotate(24deg);
    }
    18% {
      -ms-transform: rotate(-24deg);
    }
    20% {
      -ms-transform: rotate(-24deg);
    }
    28% {
      -ms-transform: rotate(24deg);
    }
    30% {
      -ms-transform: rotate(24deg);
    }
    38% {
      -ms-transform: rotate(-24deg);
    }
    40% {
      -ms-transform: rotate(-24deg);
    }
    48% {
      -ms-transform: rotate(24deg);
    }
    50% {
      -ms-transform: rotate(24deg);
    }
    58% {
      -ms-transform: rotate(-24deg);
    }
    60% {
      -ms-transform: rotate(-24deg);
    }
    68% {
      -ms-transform: rotate(24deg);
    }
    75% {
      -ms-transform: rotate(0deg);
    }
  }
  @keyframes wrenching {
    0% {
      transform: rotate(-12deg);
    }
    8% {
      transform: rotate(12deg);
    }
    10% {
      transform: rotate(24deg);
    }
    18% {
      transform: rotate(-24deg);
    }
    20% {
      transform: rotate(-24deg);
    }
    28% {
      transform: rotate(24deg);
    }
    30% {
      transform: rotate(24deg);
    }
    38% {
      transform: rotate(-24deg);
    }
    40% {
      transform: rotate(-24deg);
    }
    48% {
      transform: rotate(24deg);
    }
    50% {
      transform: rotate(24deg);
    }
    58% {
      transform: rotate(-24deg);
    }
    60% {
      transform: rotate(-24deg);
    }
    68% {
      transform: rotate(24deg);
    }
    75% {
      transform: rotate(0deg);
    }
  }
  @-moz-keyframes blinking {
    0% {
      opacity: 1;
    }
    40% {
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
  }
  @-webkit-keyframes blinking {
    0% {
      opacity: 1;
    }
    40% {
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
  }
  @-ms-keyframes blinking {
    0% {
      opacity: 1;
    }
    40% {
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
  }
  @keyframes blinking {
    0% {
      opacity: 1;
    }
    40% {
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
  }
  @-moz-keyframes pulsating {
    0% {
      -moz-transform: scale(1);
    }
    5% {
      -moz-transform: scale(0.75);
    }
    10% {
      -moz-transform: scale(1);
    }
    15% {
      -moz-transform: scale(1.25);
    }
    20% {
      -moz-transform: scale(1);
    }
    25% {
      -moz-transform: scale(0.75);
    }
    30% {
      -moz-transform: scale(1);
    }
    35% {
      -moz-transform: scale(1.25);
    }
    40% {
      -moz-transform: scale(1);
    }
  }
  @-webkit-keyframes pulsating {
    0% {
      -webkit-transform: scale(1);
    }
    5% {
      -webkit-transform: scale(0.75);
    }
    10% {
      -webkit-transform: scale(1);
    }
    15% {
      -webkit-transform: scale(1.25);
    }
    20% {
      -webkit-transform: scale(1);
    }
    25% {
      -webkit-transform: scale(0.75);
    }
    30% {
      -webkit-transform: scale(1);
    }
    35% {
      -webkit-transform: scale(1.25);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }
  @-ms-keyframes pulsating {
    0% {
      -ms-transform: scale(1);
    }
    5% {
      -ms-transform: scale(0.75);
    }
    10% {
      -ms-transform: scale(1);
    }
    15% {
      -ms-transform: scale(1.25);
    }
    20% {
      -ms-transform: scale(1);
    }
    25% {
      -ms-transform: scale(0.75);
    }
    30% {
      -ms-transform: scale(1);
    }
    35% {
      -ms-transform: scale(1.25);
    }
    40% {
      -ms-transform: scale(1);
    }
  }
  @keyframes pulsating {
    0% {
      transform: scale(1);
    }
    5% {
      transform: scale(0.75);
    }
    10% {
      transform: scale(1);
    }
    15% {
      transform: scale(1.25);
    }
    20% {
      transform: scale(1);
    }
    25% {
      transform: scale(0.75);
    }
    30% {
      transform: scale(1);
    }
    35% {
      transform: scale(1.25);
    }
    40% {
      transform: scale(1);
    }
  }
  .btn-scroll-up {
    border-width: 0;
    position: fixed;
    right: 2px;
    z-index: 99;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    opacity: 0;
    filter: alpha(opacity=0);
    bottom: -24px;
    visibility: hidden;
  }
  .btn-scroll-up.display {
    opacity: 0.7;
    filter: alpha(opacity=70);
    bottom: 2px;
    visibility: visible;
  }
  .btn-scroll-up:hover {
    opacity: 1;
    filter: alpha(opacity=100);
  }
  .btn-scroll-up:focus {
    outline: none;
  }
  @media (min-width: 768px) {
    .main-container.container > .btn-scroll-up {
      right: auto;
      margin-left: 714px;
    }
  }
  @media (min-width: 992px) {
    .main-container.container > .btn-scroll-up {
      right: auto;
      margin-left: 934px;
    }
  }
  @media (min-width: 1200px) {
    .main-container.container > .btn-scroll-up {
      right: auto;
      margin-left: 1134px;
    }
  }
  .ace-settings-container {
    position: absolute;
    right: 0;
    top: auto;
    z-index: 12;
  }
  .btn.btn-app.ace-settings-btn {
    float: left;
    display: block;
    text-align: center;
    border-radius: 6px 0 0 6px;
    opacity: 0.55;
    vertical-align: top;
    margin: 0;
  }
  .btn.btn-app.ace-settings-btn:hover,
  .btn.btn-app.ace-settings-btn.open {
    opacity: 1;
  }
  .btn.btn-app.ace-settings-btn.btn-xs {
    width: 42px;
  }
  .ace-settings-box {
    display: block;
    float: left;
    max-width: 0;
    max-height: 0;
    overflow: hidden;
    padding: 0;
    -moz-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    background-color: #fff;
    border: 0 solid #ffb34b;
    -webkit-transition: max-width 0.25s linear 0s, max-height 0s linear 0.25s,
      padding 0s linear 0.25s, border-width 0s linear 0.25s;
    -o-transition: max-width 0.25s linear 0s, max-height 0s linear 0.25s,
      padding 0s linear 0.25s, border-width 0s linear 0.25s;
    transition: max-width 0.25s linear 0s, max-height 0s linear 0.25s,
      padding 0s linear 0.25s, border-width 0s linear 0.25s;
  }
  .ace-settings-box.open {
    max-width: 320px;
    max-height: 1000px;
    padding: 0 14px;
    border-width: 2px;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
  }
  .ace-settings-box.open .ace-settings-item {
    z-index: auto;
    min-width: 140px;
  }
  .ace-settings-box .ace-settings-item {
    margin: 6px 0;
    color: #444;
    max-height: 24px;
    position: relative;
    z-index: -1;
    white-space: nowrap;
  }
  .ace-settings-box .ace-settings-item > label.lbl {
    font-size: 13px;
  }
  @media (max-width: 480px) {
    .ace-settings-container {
      text-align: right;
    }
    .ace-settings-box {
      float: none !important;
      text-align: left;
    }
    .btn.ace-settings-btn {
      float: none !important;
      display: inline-block;
    }
  }
  @media (max-width: 320px) {
    .ace-settings-box > .clearfix > .pull-left,
    .ace-settings-box > .clearfix > .pull-right {
      float: none !important;
    }
  }
  .grid2,
  .grid3,
  .grid4 {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    margin: 0 1%;
    padding: 0 2%;
    float: left;
    border-left: 1px solid #e3e3e3;
  }
  .grid2:first-child,
  .grid3:first-child,
  .grid4:first-child {
    border-left: none;
  }
  .grid2 {
    width: 48%;
  }
  .grid3 {
    width: 31.33%;
  }
  .grid4 {
    width: 23%;
    padding: 0 1%;
  }
  .draggable-placeholder {
    border: 2px dashed #d9d9d9 !important;
    background-color: #f7f7f7 !important;
  }
  .easyPieChart,
  .easy-pie-chart {
    position: relative;
    text-align: center;
  }
  .easyPieChart canvas,
  .easy-pie-chart canvas {
    position: absolute;
    top: 0;
    left: 0;
  }
  .knob-container {
    direction: ltr;
    text-align: left;
  }
  .tags {
    display: inline-block;
    padding: 4px 6px;
    color: #777777;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #d5d5d5;
    width: 206px;
  }
  .tags:hover {
    border-color: #b5b5b5;
  }
  .tags-hover,
  .tags-hover:hover {
    border-color: #f59942;
    outline: 0;
  }
  .tags[class*='span'] {
    float: none;
    margin-left: 0;
  }
  .tags input[type='text'],
  .tags input[type='text']:focus {
    border: none;
    display: inline;
    outline: 0;
    margin: 0;
    padding: 0;
    line-height: 18px;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100%;
  }
  .tags .tag {
    display: inline-block;
    position: relative;
    font-size: 13px;
    font-weight: normal;
    vertical-align: baseline;
    white-space: nowrap;
    background-color: #91b8d0;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
    padding: 4px 22px 5px 9px;
    margin-bottom: 3px;
    margin-right: 3px;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    /**
  &:nth-child(5n+1) {
	background-color:#48A2E0;
  }
  &:nth-child(5n+2) {
	background-color:#34C896;
  }
  &:nth-child(5n+3) {
	background-color:#B57BB3;
  }
  &:nth-child(5n+4) {
	background-color:#CC7DA8;
  }
  &:nth-child(5n+5) {
	background-color:#666;
  }
*/
  }
  .tags .tag:empty {
    display: none;
  }
  .tags .tag-important {
    background-color: #d15b47;
  }
  .tags .tag-warning {
    background-color: #ffb752;
  }
  .tags .tag-success {
    background-color: #87b87f;
  }
  .tags .tag-info {
    background-color: #6fb3e0;
  }
  .tags .tag-inverse {
    background-color: #555555;
  }
  .tags .tag .close {
    font-size: 15px;
    line-height: 20px;
    opacity: 1;
    filter: alpha(opacity=100);
    color: #fff;
    text-shadow: none;
    float: none;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 18px;
    text-align: center;
  }
  .tags .tag .close:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .page-content > .row .col-xs-12,
  .page-content > .row .col-sm-12,
  .page-content > .row .col-md-12,
  .page-content > .row .col-lg-12 {
    float: left;
    max-width: 100%;
  }
  .col-xs-reset {
    width: auto;
    padding-left: 0;
    padding-right: 0;
    float: none !important;
  }
  @media (min-width: 768px) {
    .col-sm-reset {
      width: auto;
      padding-left: 0;
      padding-right: 0;
      float: none !important;
    }
  }
  @media (min-width: 992px) {
    .col-md-reset {
      width: auto;
      padding-left: 0;
      padding-right: 0;
      float: none !important;
    }
  }
  @media (min-width: 1200px) {
    .col-lg-reset {
      width: auto;
      padding-left: 0;
      padding-right: 0;
      float: none !important;
    }
  }
  .jqstooltip,
  .legendColorBox div {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  .legendLabel {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 22px;
    padding-left: 2px;
    font-size: 10px;
  }
  @media only screen and (max-width: 991px) {
    body {
      overflow-x: hidden;
    }
  }
  .navbar-fixed-top + .main-container {
    padding-top: 45px;
  }
  @media (max-width: 479px) {
    .navbar-fixed-top + .main-container {
      padding-top: 90px;
    }
    .navbar-fixed-top.navbar-collapse + .main-container {
      padding-top: 45px;
    }
  }
  @media only screen and (max-width: 360px) {
    .grid2,
    .grid3,
    .grid4 {
      float: none;
      display: block;
      width: 96%;
      border-left-width: 0;
      position: relative;
      margin-bottom: 11px;
      border-bottom: 1px solid #e3e3e3;
      padding-bottom: 4px;
    }
    .grid2 > [class*='pull-'],
    .grid3 > [class*='pull-'],
    .grid4 > [class*='pull-'] {
      float: none !important;
      display: inline-block;
      position: absolute;
      right: 11px;
      top: 0;
      margin-top: 0;
    }
    .grid2:last-child,
    .grid3:last-child,
    .grid4:last-child {
      border-bottom-width: 0;
    }
  }
  @media only screen and (max-width: 480px) {
    .hidden-480 {
      display: none !important;
    }
  }
  @media only screen and (max-width: 320px) {
    .hidden-320 {
      display: none !important;
    }
  }
  .no-skin {
    /**
	.nav-list > li.disabled.active:after {
		border-color: #999;
	}
	.nav-list li.disabled li.active > a:after,
	.nav-list li.active.disabled > a:after {
		-moz-border-right-colors: #999 !important;
		border-right-color: #999 !important;
	}
	*/
  }
  .no-skin .navbar .navbar-toggle {
    background-color: #75b3d7;
  }
  .no-skin .navbar .navbar-toggle:focus {
    background-color: #75b3d7;
    border-color: transparent;
  }
  .no-skin .navbar .navbar-toggle:hover {
    background-color: #61a8d1;
    border-color: rgba(255, 255, 255, 0.1);
  }
  .no-skin .navbar .navbar-toggle.display,
  .no-skin .navbar .navbar-toggle[data-toggle='collapse']:not(.collapsed) {
    background-color: #4d9dcc;
    box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.25);
    border-color: rgba(255, 255, 255, 0.35);
  }
  .no-skin .sidebar {
    background-color: #f2f2f2;
    border-style: solid;
    border-color: #cccccc;
    border-width: 0 1px 0 0;
  }
  .no-skin .nav-list .open > a,
  .no-skin .nav-list .open > a:hover,
  .no-skin .nav-list .open > a:focus {
    background-color: #fafafa;
  }
  .no-skin .nav-list > li {
    border-color: #e5e5e5;
  }
  .no-skin .nav-list > li > a {
    background-color: #f8f8f8;
    color: #585858;
  }
  .no-skin .nav-list > li > a:focus {
    background-color: #f8f8f8;
    color: #1963aa;
  }
  .no-skin .nav-list > li:hover > a {
    background-color: #ffffff;
    color: #266cad;
  }
  .no-skin .nav-list > li.open > a {
    background-color: #fafafa;
    color: #1963aa;
  }
  .no-skin .nav-list > li.active > a {
    font-weight: bold;
    color: #2b7dbc;
  }
  .no-skin .nav-list > li.active > a,
  .no-skin .nav-list > li.active > a:hover,
  .no-skin .nav-list > li.active > a:focus {
    background-color: #ffffff;
  }
  .no-skin .nav-list > li .submenu {
    background-color: #ffffff;
    border-color: #e5e5e5;
  }
  .no-skin .nav-list > li .submenu > li > a {
    border-top-color: #e4e4e4;
    background-color: #ffffff;
    color: #616161;
  }
  .no-skin .nav-list > li .submenu > li > a:hover {
    color: #4b88b7;
    background-color: #f1f5f9;
  }
  .no-skin .nav-list > li .submenu > li.active > a {
    color: #2b7dbc;
  }
  .no-skin .nav-list > li .submenu > li.active > a > .menu-icon {
    color: #c86139;
  }
  .no-skin .nav-list > li .submenu > li.active.open > a > .menu-icon {
    color: inherit;
  }
  @media only screen and (min-width: 992px) {
    .no-skin
      .nav-list
      > li
      .submenu
      > li.active.hover
      > a.dropdown-toggle
      > .menu-icon {
      color: inherit;
    }
  }
  .no-skin .nav-list > li .submenu > li.active:not(.open) > a {
    background-color: #f5f7fa;
  }
  .no-skin .nav-list > li .submenu > li.active:not(.open) > a:hover {
    background-color: #f1f5f9;
  }
  .no-skin .nav-list > li > .submenu .open > a,
  .no-skin .nav-list > li > .submenu .open > a:hover,
  .no-skin .nav-list > li > .submenu .open > a:focus {
    border-color: #e4e4e4;
  }
  .no-skin .nav-list > li > .submenu li > .submenu > li a {
    color: #757575;
  }
  .no-skin .nav-list > li > .submenu li > .submenu > li a:hover {
    color: #4b88b7;
    background-color: #f1f5f9;
  }
  .no-skin .nav-list > li > .submenu li.open > a {
    color: #4b88b7;
  }
  .no-skin .nav-list > li > .submenu li > .submenu li.open > a,
  .no-skin .nav-list > li > .submenu li > .submenu li.active > a {
    color: #4b88b7;
  }
  .no-skin .nav-list > li > .submenu:before,
  .no-skin .nav-list > li > .submenu > li:before {
    border-color: #9dbdd6;
  }
  .no-skin .nav-list > li.active > .submenu:before,
  .no-skin .nav-list > li.active > .submenu > li:before {
    border-color: #8eb3d0;
  }
  .no-skin .sidebar-toggle {
    background-color: #f3f3f3;
    border-color: #e0e0e0;
  }
  .no-skin .sidebar-toggle > .ace-icon {
    border-color: #bbbbbb;
    color: #aaaaaa;
    background-color: #ffffff;
  }
  .no-skin .sidebar-shortcuts {
    background-color: #fafafa;
  }
  .no-skin .sidebar-fixed .sidebar-shortcuts {
    border-color: #dddddd;
  }
  .no-skin .sidebar-shortcuts-mini {
    background-color: #ffffff;
  }
  .no-skin .nav-list li > .arrow:before {
    border-right-color: #b8b8b8;
    -moz-border-right-colors: #b8b8b8;
    border-width: 10px 10px 10px 0;
    left: -11px;
  }
  .no-skin .nav-list li > .arrow:after {
    border-right-color: #ffffff;
    -moz-border-right-colors: #ffffff;
    border-width: 10px 10px 10px 0;
    left: -10px;
  }
  .no-skin .nav-list > li.pull_up > .arrow:after {
    border-right-color: #ffffff !important;
    -moz-border-right-colors: #ffffff !important;
    border-width: 10px 10px 10px 0 !important;
    left: -10px !important;
  }
  .no-skin .nav-list > li.pull_up > .arrow:before {
    border-width: 10px 10px 10px 0 !important;
    left: -11px !important;
  }
  .no-skin .nav-list li.active > a:after {
    border-right-color: #2b7dbc;
    -moz-border-right-colors: #2b7dbc;
  }
  .no-skin .nav-list > li.active:after {
    display: block;
    content: '';
    position: absolute;
    right: -2px;
    top: -1px;
    bottom: 0;
    z-index: 1;
    border: 2px solid;
    border-width: 0 2px 0 0;
    border-color: #2b7dbc;
  }
  .no-skin .sidebar-scroll .nav-list > li.active:after {
    right: 0;
  }
  @media only screen and (max-width: 991px) {
    .no-skin .sidebar.responsive .nav-list > li.active.open > a:after,
    .no-skin .sidebar.responsive-max .nav-list > li.active.open > a:after {
      display: block;
    }
    .no-skin .sidebar.responsive .nav-list li li.active > a:after,
    .no-skin .sidebar.responsive-max .nav-list li li.active > a:after {
      display: none;
    }
    .no-skin .sidebar.responsive .nav-list > li.active:after,
    .no-skin .sidebar.responsive-max .nav-list > li.active:after {
      height: 41px;
    }
  }
  .no-skin .sidebar.menu-min .nav-list > li > a > .menu-text {
    background-color: #f5f5f5;
    -webkit-box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.1);
    border-color: #cccccc;
  }
  .no-skin .sidebar.menu-min .nav-list > li > a.dropdown-toggle > .menu-text {
    -webkit-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
  }
  .no-skin .sidebar.menu-min .nav-list > li.active > .submenu {
    border-left-color: #83b6d1;
  }
  .no-skin .sidebar.menu-min .nav-list > li > .submenu {
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-top-color: #e6e6e6;
    -webkit-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
  }
  .no-skin .sidebar.menu-min .nav-list > li > .arrow:after {
    border-right-color: #f5f5f5;
    -moz-border-right-colors: #f5f5f5;
    border-width: 8px 8px 8px 0;
    left: -8px;
  }
  .no-skin .sidebar.menu-min .nav-list > li > .arrow:before {
    border-width: 8px 8px 8px 0;
    left: -9px;
  }
  .no-skin .sidebar.menu-min .nav-list > li.active > .arrow:before {
    border-right-color: #5a9ec2;
    -moz-border-right-colors: #5a9ec2;
  }
  .no-skin .sidebar.menu-min .nav-list > li.active > a > .menu-text {
    border-left-color: #83b6d1;
  }
  .no-skin .sidebar.menu-min .sidebar-shortcuts-large {
    background-color: #ffffff;
    -webkit-box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.1);
    border-color: #cccccc;
  }
  .no-skin .sidebar.menu-min .sidebar-toggle > .ace-icon {
    border-color: #b1b1b1;
  }
  @media (max-width: 991px) {
    .no-skin .sidebar.responsive-min .nav-list > li > a > .menu-text {
      background-color: #f5f5f5;
      -webkit-box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.1);
      border-color: #cccccc;
    }
    .no-skin
      .sidebar.responsive-min
      .nav-list
      > li
      > a.dropdown-toggle
      > .menu-text {
      -webkit-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
    }
    .no-skin .sidebar.responsive-min .nav-list > li.active > .submenu {
      border-left-color: #83b6d1;
    }
    .no-skin .sidebar.responsive-min .nav-list > li > .submenu {
      background-color: #ffffff;
      border: 1px solid #cccccc;
      border-top-color: #e6e6e6;
      -webkit-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
    }
    .no-skin .sidebar.responsive-min .nav-list > li > .arrow:after {
      border-right-color: #f5f5f5;
      -moz-border-right-colors: #f5f5f5;
      border-width: 8px 8px 8px 0;
      left: -8px;
    }
    .no-skin .sidebar.responsive-min .nav-list > li > .arrow:before {
      border-width: 8px 8px 8px 0;
      left: -9px;
    }
    .no-skin .sidebar.responsive-min .nav-list > li.active > .arrow:before {
      border-right-color: #5a9ec2;
      -moz-border-right-colors: #5a9ec2;
    }
    .no-skin .sidebar.responsive-min .nav-list > li.active > a > .menu-text {
      border-left-color: #83b6d1;
    }
    .no-skin .sidebar.responsive-min .sidebar-shortcuts-large {
      background-color: #ffffff;
      -webkit-box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.1);
      border-color: #cccccc;
    }
    .no-skin .sidebar.responsive-min .sidebar-toggle > .ace-icon {
      border-color: #b1b1b1;
    }
  }
  @media only screen and (min-width: 992px) {
    .no-skin .nav-list li.hover > .submenu {
      -webkit-box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.1);
      border-color: #cccccc;
    }
    .no-skin .nav-list li.hover > .submenu > li.active > a {
      background-color: #f5f5f5;
    }
    .no-skin .nav-list li.hover > .submenu > li:hover > a {
      background-color: #eef3f7;
      color: #2e7db4;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 991px) {
    .no-skin .sidebar.navbar-collapse .nav-list li li.hover.active.open > a {
      background-color: #ffffff;
    }
    .no-skin .sidebar.navbar-collapse .nav-list li li.hover:hover > a {
      background-color: #ffffff;
    }
    .no-skin .sidebar.navbar-collapse .nav-list li li.hover > a:hover,
    .no-skin .sidebar.navbar-collapse .nav-list li li.hover.open > a:hover,
    .no-skin
      .sidebar.navbar-collapse
      .nav-list
      li
      li.hover.open.active
      > a:hover {
      background-color: #f1f5f9;
    }
    .no-skin
      .sidebar.navbar-collapse
      .nav-list
      > li
      .submenu
      > li.active.hover
      > a.dropdown-toggle
      > .menu-icon {
      color: #c86139;
    }
    .no-skin
      .sidebar.navbar-collapse
      .nav-list
      > li
      .submenu
      > li.active.open.hover
      > a.dropdown-toggle
      > .menu-icon {
      color: inherit;
    }
  }
  @media only screen and (min-width: 992px) {
    .no-skin
      .sidebar.navbar-collapse
      .nav-list
      > li.open.hover:not(:hover):not(:focus):not(.active)
      > a {
      color: #585858;
    }
    .no-skin
      .sidebar.navbar-collapse
      .nav-list
      > li.open.hover:not(:hover):not(:focus):not(.active)
      > a
      > .arrow {
      color: inherit;
    }
    .no-skin .sidebar.navbar-collapse .nav-list > li.open.hover:hover > a {
      background-color: #ffffff;
    }
    .no-skin
      .sidebar.navbar-collapse
      .nav-list
      > li
      > .submenu
      li.open.hover:not(:hover):not(:focus):not(.active)
      > a {
      color: #616161;
    }
    .no-skin
      .sidebar.navbar-collapse
      .nav-list
      > li
      > .submenu
      li.open.hover:not(:hover):not(:focus):not(.active)
      > a
      > .arrow {
      color: inherit;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 991px) {
    .no-skin .sidebar.navbar-collapse .nav-list li.hover > .submenu {
      border-top-color: #e5e5e5;
      background-color: #ffffff;
    }
    .no-skin .nav-list li.hover > .submenu > li.active:not(.open) > a {
      background-color: #f5f7fa;
    }
    .no-skin .nav-list li.hover > .submenu > li.active:not(.open) > a:hover {
      background-color: #f1f5f9;
    }
  }
  @media only screen and (min-width: 992px) {
    .no-skin .sidebar.h-sidebar {
      background-color: #f8f8f8;
    }
    .no-skin .sidebar.h-sidebar:before {
      background-color: #e4e4e4;
      -webkit-box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.05) inset;
      box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.05) inset;
    }
    .no-skin .sidebar.h-sidebar .nav-list > li:hover,
    .no-skin .sidebar.h-sidebar .nav-list > li:hover + li {
      border-left-color: #cedde5;
    }
    .no-skin .sidebar.h-sidebar .nav-list > li:last-child:hover {
      border-right-color: #cedde5;
    }
    .no-skin .sidebar.h-sidebar .nav-list > li.active,
    .no-skin .sidebar.h-sidebar .nav-list > li.active + li,
    .no-skin .sidebar.h-sidebar .nav-list > li:hover + li.active {
      border-left-color: #79b0ce;
    }
    .no-skin .sidebar.h-sidebar .nav-list > li.active:last-child {
      border-right-color: #79b0ce;
    }
    .no-skin .sidebar.h-sidebar .nav-list > li.active:before {
      background-color: #2b7dbc;
    }
    .no-skin .sidebar.h-sidebar .nav-list > li.active > a:after {
      border-width: 0 0 2px 0;
      border-color: transparent;
      border-bottom-color: #fff;
      left: 0;
      right: 0;
      top: auto;
      bottom: -2px;
    }
    .no-skin .sidebar.h-sidebar .sidebar-shortcuts-large {
      background-color: #fff;
      border-color: #cccccc;
      -webkit-box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.1);
    }
    .no-skin .sidebar.h-sidebar .sidebar-shortcuts-large:after {
      border-bottom-color: #fff;
      -moz-border-bottom-colors: #fff;
    }
    .no-skin .sidebar.h-sidebar .nav-list > li.hover > .submenu {
      border-color: #cccccc;
    }
    .no-skin .sidebar.h-sidebar.menu-min .nav-list > li.hover > .submenu {
      border-top-color: #e6e6e6;
    }
    .no-skin .sidebar.h-sidebar .nav-list > li.hover > .arrow:after {
      border-color: transparent;
      -moz-border-right-colors: none;
      -moz-border-top-colors: none;
      -moz-border-left-colors: none;
      border-bottom-color: #fff;
      -moz-border-bottom-colors: #fff;
    }
    .no-skin .sidebar.h-sidebar .nav-list > li.hover > .arrow:before {
      border-color: transparent;
      -moz-border-right-colors: none;
      -moz-border-top-colors: none;
      -moz-border-left-colors: none;
      -moz-border-bottom-colors: #b8b8b8;
      border-bottom-color: #b8b8b8;
    }
    .no-skin .sidebar.h-sidebar.menu-min .nav-list > li.hover > .arrow:after {
      -moz-border-bottom-colors: #f5f5f5;
      border-bottom-color: #f5f5f5;
    }
    .no-skin
      .sidebar.h-sidebar.menu-min
      .nav-list
      > li.active
      > a
      > .menu-text {
      border-left-color: #cccccc;
    }
  }
  @media only screen and (max-width: 991px) {
    .no-skin .sidebar {
      border-width: 0 1px 1px 0;
      border-top-color: #d6d6d6;
    }
    .no-skin .menu-toggler + .sidebar.responsive {
      border-top-width: 1px;
    }
    .no-skin .sidebar.responsive-min {
      border-width: 0 1px 0 0;
    }
    .no-skin .sidebar.navbar-collapse {
      border-width: 0;
      border-bottom-width: 1px !important;
      border-bottom-color: #cccccc;
      -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1) !important;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1) !important;
    }
    .no-skin .sidebar.navbar-collapse.menu-min .nav-list > li > .submenu {
      background-color: #ffffff;
    }
  }
  .no-skin .sidebar-scroll .sidebar-shortcuts {
    border-bottom-color: #dddddd;
  }
  .no-skin .sidebar-scroll .sidebar-toggle {
    border-top-color: #dddddd;
  }
  .no-skin .main-container .menu-toggler {
    background-color: #444444;
  }
  .no-skin .main-container .menu-toggler:before {
    border-top-color: #87b87f;
    border-bottom-color: #6fb3e0;
  }
  .no-skin .main-container .menu-toggler:after {
    border-top-color: #ffa24d;
    border-bottom-color: #d15b47;
  }
  .no-skin .main-container .menu-toggler > .toggler-text {
    border-top-color: #444444;
    -moz-border-top-colors: #444444;
  }
  .no-skin .nav-list > li.disabled:before {
    display: none !important;
  }
  .no-skin .nav-list > li.disabled > a {
    background-color: #ebebeb !important;
    color: #656565 !important;
  }
  .no-skin .nav-list li .submenu > li.disabled > a,
  .no-skin .nav-list li.disabled .submenu > li > a {
    background-color: #f2f2f2 !important;
    color: #7a7a7a !important;
    cursor: not-allowed !important;
  }
  .no-skin .nav-list li .submenu > li.disabled > a > .menu-icon,
  .no-skin .nav-list li.disabled .submenu > li > a > .menu-icon {
    display: none;
  }
  .nav-list li.highlight li.active > a:after,
  .nav-list li li.highlight.active > a:after,
  .nav-list li.highlight li.active > a:before,
  .nav-list li li.highlight.active > a:before {
    display: none;
  }
  .nav-list > li.highlight.active > a:after,
  .nav-list > li.highlight.active > a:before {
    display: block;
  }
  .no-skin .nav-list > li.highlight:hover,
  .no-skin .nav-list > li.highlight.active {
    border-color: #c8d8e2;
  }
  .no-skin .nav-list > li.highlight:hover + li,
  .no-skin .nav-list > li.highlight.active + li {
    border-top-color: #c8d8e2;
  }
  .no-skin .nav-list > li.highlight:hover > a,
  .no-skin .nav-list > li.highlight.active > a {
    background-color: #fff;
  }
  .no-skin .nav-list > li.highlight.active:after {
    display: none;
  }
  .no-skin .nav-list > li.highlight.active:before {
    display: block;
    background-color: #4f90c2;
  }
  .no-skin .nav-list > li.highlight.active > a {
    background-color: #f2f6f9 !important;
  }
  .no-skin .nav-list > li.highlight.active > a:before,
  .no-skin .nav-list > li.highlight.active > a:after {
    display: block;
    content: '';
    position: absolute;
    top: -1px;
    right: -10px;
    bottom: auto;
    z-index: 1;
    border-style: solid;
    border-width: 20px 0 21px 10px;
    border-color: transparent;
  }
  .no-skin .nav-list > li.highlight.active > a:before {
    border-left-color: #a0bccd;
    -moz-border-left-colors: #a0bccd;
    right: -11px;
  }
  .no-skin .nav-list > li.highlight.active > a:after {
    border-left-color: #f2f6f9;
    -moz-border-left-colors: #f2f6f9;
  }
  .no-skin .nav-list li li.highlight.active > a {
    background-color: #f2f6f9;
  }
  .no-skin .nav-list li li.highlight.active.open > a {
    background-color: #fff;
  }
  .no-skin .nav-list li li.highlight.active.open > a:hover {
    background-color: #f1f5f9;
  }
  @media (min-width: 992px) {
    .no-skin .sidebar.h-sidebar .nav-list > li.highlight.active > a:after {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      top: auto;
      right: auto;
      bottom: -2px;
      left: 50%;
      margin-left: -7px;
      border-color: transparent;
      -moz-border-right-colors: none;
      -moz-border-left-colors: none;
      -moz-border-top-colors: none;
      border-width: 8px 7px;
      border-bottom-color: #fff;
      -moz-border-bottom-colors: #fff;
    }
    .no-skin .sidebar.h-sidebar .nav-list > li.highlight.active > a:before {
      content: '';
      position: absolute;
      display: block;
      left: 0;
      top: auto;
      right: auto;
      bottom: -1px;
      left: 50%;
      margin-left: -7px;
      border-width: 8px;
      border-color: transparent;
      -moz-border-right-colors: none;
      -moz-border-left-colors: none;
      -moz-border-top-colors: none;
      border-bottom-color: #74add7;
      -moz-border-bottom-colors: #74add7;
    }
    .no-skin .sidebar.h-sidebar .nav-list > li.highlight.active:hover > a:after,
    .no-skin
      .sidebar.h-sidebar
      .nav-list
      > li.highlight.active.hover-show
      > a:after,
    .no-skin
      .sidebar.h-sidebar
      .nav-list
      > li.highlight.active:hover
      > a:before,
    .no-skin
      .sidebar.h-sidebar
      .nav-list
      > li.highlight.active.hover-show
      > a:before {
      display: none;
    }
  }
  @media (min-width: 992px) {
    .sidebar.compact .nav-list li.highlight.active > a:after,
    .sidebar.compact .nav-list li.highlight.active > a:before {
      display: none;
    }
    .sidebar.compact.menu-min .nav-list li.highlight.active > a:after,
    .sidebar.compact.menu-min .nav-list li.highlight.active > a:before {
      display: block;
    }
  }
  @media (min-width: 992px) {
    .sidebar.h-sidebar .nav-list li.highlight.active > a:after,
    .sidebar.h-sidebar .nav-list li.highlight.active > a:before {
      display: block;
      border-width: 8px !important;
      -moz-border-left-colors: none !important;
      -moz-border-right-colors: none !important;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
    }
    .sidebar.h-sidebar .nav-list > li.highlight.active:hover > a:after,
    .sidebar.h-sidebar .nav-list > li.highlight.active:hover > a:before {
      display: none !important;
    }
  }
  .sidebar-scroll .nav-list > li.active.highlight > a:after,
  .sidebar-scroll .nav-list > li.active.highlight > a:before {
    display: none !important;
  }
  .onpage-help-backdrop {
    position: absolute;
    z-index: 99990;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    filter: alpha(opacity=5);
    background-color: rgba(0, 0, 0, 0.05);
  }
  .onpage-help-section {
    display: block;
    position: absolute;
    z-index: 100000;
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#4DFFFFFF',
        endColorstr='#4DFFFFFF',
        GradientType=0
      );
    background-color: rgba(255, 255, 255, 0.3);
    border: 1px dashed #8bbcd3;
    border-radius: 4px;
    transition: background-color 0.2s, border-color 0.2s;
    -webkit-transition: background-color 0.2s, border-color 0.2s;
    text-align: center;
    vertical-align: middle;
    outline: none !important;
  }
  .onpage-help-section > .ie-hover-fix {
    /* ie8-9 fix*/
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    filter: alpha(opacity=1);
  }
  .onpage-help-section:focus,
  .onpage-help-section:active {
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#33C9D8EA',
        endColorstr='#33C9D8EA',
        GradientType=0
      );
    background-color: rgba(201, 216, 234, 0.2);
    border-color: #77acc4;
  }
  .onpage-help-section:hover {
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#66C9D8EA',
        endColorstr='#66C9D8EA',
        GradientType=0
      );
    background-color: rgba(201, 216, 234, 0.4);
    border-color: #77acc4;
    border-style: solid;
  }
  .onpage-help-section > .help-icon-1 {
    font-size: 18px;
    display: inline-block;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    opacity: 0;
    filter: alpha(opacity=0);
    color: #fff;
    text-shadow: 0 0 2px black;
    background-color: #85b8db;
    border: 2px solid #fff;
    border-radius: 100%;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.4);
    width: 48px;
    height: 48px;
    line-height: 46px;
    transition: opacity 0.2s;
    -webkit-transition: opacity 0.2s;
  }
  .onpage-help-section:focus > .help-icon-1,
  .onpage-help-section:active > .help-icon-1 {
    opacity: 0.5;
  }
  .onpage-help-section:hover > .help-icon-1 {
    opacity: 1;
    filter: alpha(opacity=100);
  }
  .onpage-help-section.help-section-small > .help-icon-1 {
    font-size: 18px;
    width: 28px;
    height: 28px;
    line-height: 26px;
    border-radius: 12px;
  }
  .onpage-help-section.help-section-smaller > .help-icon-1 {
    font-size: 14px;
    width: 20px;
    height: 20px;
    line-height: 17px;
    border-radius: 8px;
  }
  .onpage-help-section > .help-icon-2 {
    position: absolute;
    z-index: 2;
    left: -4px;
    top: -4px;
    width: 18px;
    height: 18px;
    line-height: 16px;
    display: block;
    font-size: 16px;
    color: orange;
    background-color: #fff;
    border: 1px solid orange;
    border-radius: 4px;
    opacity: 1;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
  }
  .onpage-help-section:hover > .help-icon-2 {
    color: #59a34e;
    border-color: #59a34e;
    transform: scale(1.25);
    -webkit-transform: scale(1.25);
  }
  .onpage-help-modal {
    z-index: 100010;
  }
  .onpage-help-modal + .modal-backdrop {
    z-index: 100009;
  }
  .onpage-help-modal-buttons button {
    border-radius: 100%;
    border-width: 2px !important;
  }
  .onpage-help-modal-buttons button:focus {
    outline: none !important;
  }
  .onpage-help-modal-buttons button.disabled {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
  }
  .onpage-help-content {
    max-width: 800px;
    margin: 0 auto;
  }
  .code-modal.onpage-help-modal .modal-dialog {
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .code-modal.onpage-help-modal .modal-header {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .code-modal.onpage-help-modal .modal-body {
    padding: 10px 12px;
  }
  .onpage-help-modal pre {
    border-width: 0;
    box-shadow: none;
    border-radius: 0;
    margin-top: 8px;
  }
  .onpage-help-modal .modal-title code {
    font-size: 13px;
  }
  .onpage-help-modal .modal-body {
    font-size: 14px;
  }
  @media print {
    .navbar {
      display: none !important;
      /**
	background: transparent none !important;
	border-bottom: 1px solid #DDD;

	.navbar-brand {
		color: @text-color !important;
	}

	.ace-nav {
		display: none !important;
	}
	*/
    }
    .sidebar {
      display: none !important;
    }
    .main-content {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    .main-content .nav-search {
      display: none !important;
    }
    .main-content .breadcrumbs {
      float: right;
      border-width: 0 !important;
    }
    .main-content .breadcrumbs .breadcrumb > li + li:before {
      content: '/';
    }
    .main-content .breadcrumbs .breadcrumb .home-icon {
      display: none;
    }
    .ace-settings-container {
      display: none !important;
    }
    .footer {
      width: 100%;
      height: auto;
      position: relative;
    }
    .footer .footer-inner,
    .footer .footer-inner .footer-content {
      width: 100%;
    }
    .btn-scroll-up {
      display: none !important;
    }
    .btn,
    .btn.btn-app {
      background: transparent none !important;
      border-width: 1px !important;
      border-radius: 0 !important;
      color: #555 !important;
    }
    .label {
      border: 1px solid #666 !important;
    }
    .label[class*='arrowed']:before,
    .label[class*='arrowed']:after {
      display: none !important;
    }
  }
}
